import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as homeActions from '../../store/actions/Home';
import * as rulesActions from '../../store/actions/Rules';
import * as usersActions from '../../store/actions/Users';

import { Loader, Table, Input, InputGroup, Tooltip, Whisper, Alert } from 'rsuite';
import { Modal } from 'rsuite';
import { SelectPicker, CheckPicker} from 'rsuite';

import { Transition } from 'react-transition-group';

import { Fragment } from 'react'

import Options from '../shared/Options';

import { isEmptyObject } from 'jquery';

import { getAnalytics, logEvent } from "firebase/analytics";

import amplitude from 'amplitude-js';
import { async } from '@firebase/util';

const analytics = getAnalytics();

const { Column, HeaderCell, Cell } = Table;

const lengthMenu = [
    {
        value: 20,
        label: 20
    },
    {
        value: 50,
        label: 50
    },
    {
        value: 100,
        label: 100
    }
];

const isValidRule = rule => {

    if (rule.location === null) {
        return false;
    }

    if (rule.graph === null) {
        return false;
    }

    if (rule.rule_name === null) {
        return false;
    }

    if (rule.value === null) {
        return false;
    }

    if (!(rule.monday || rule.tuesday || rule.wednesday || rule.thursday || rule.friday || rule.saturday || rule.sunday)) {
        return false;
    }

    return true;

}

const showError = (rule, props) => {

    if (rule.location === null) {
        Alert.error(props.language.alert_rules_popup_location);
        return;
    }

    if (rule.property === null) {
        Alert.error(props.language.alert_rules_popup_property);
        return;
    }

    if (rule.rule_name === null) {
        Alert.error(props.language.alert_rules_popup_rule_name);
        return;
    }

    if (isEmptyObject(rule.value)) {
        Alert.error(props.language.alert_rules_popup_value);
        return;
    }

    if (!(rule.monday || rule.tuesday || rule.wednesday || rule.thursday || rule.friday || rule.saturday || rule.sunday)) {
        Alert.error(props.language.alert_rules_popup_available_days);
        return;
    }

}

const ImageCell = ({ rowData, dataKey, language, setDaysRules, ...props }) => {

    return (
        <Cell {...props} style={{ padding: 0 }}>
            <div className="row-center txt-12 txt-semibold align-items-center h-100 px-4">
                <div className={`rules-btn-day row-center align-items-center mx-2 c-pointer rounded-circle ${rowData.monday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                    onClick={() => {
                        if (rowData.customizable_days) {
                            rowData.monday = !rowData.monday;
                            setDaysRules(rowData);
                        }
                    }}>
                    {language.option_table_rules_day_monday}
                </div>
                <div className={`rules-btn-day row-center align-items-center mx-2 c-pointer rounded-circle ${rowData.tuesday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                    onClick={() => {
                        if (rowData.customizable_days) {
                            rowData.tuesday = !rowData.tuesday;
                            setDaysRules(rowData);
                        }
                    }}>
                    {language.option_table_rules_day_tuesday}
                </div>
                <div className={`rules-btn-day row-center align-items-center mx-2 c-pointer rounded-circle ${rowData.wednesday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                    onClick={() => {
                        if (rowData.customizable_days) {
                            rowData.wednesday = !rowData.wednesday;
                            setDaysRules(rowData);
                        }
                    }}>
                    {language.option_table_rules_day_wednesday}
                </div>
                <div className={`rules-btn-day row-center align-items-center mx-2 c-pointer rounded-circle ${rowData.thursday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                    onClick={() => {
                        if (rowData.customizable_days) {
                            rowData.thursday = !rowData.thursday;
                            setDaysRules(rowData);
                        }
                    }}>
                    {language.option_table_rules_day_thursday}
                </div>
                <div className={`rules-btn-day row-center align-items-center mx-2 c-pointer rounded-circle ${rowData.friday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                    onClick={() => {
                        if (rowData.customizable_days) {
                            rowData.friday = !rowData.friday;
                            setDaysRules(rowData);
                        }
                    }}>
                    {language.option_table_rules_day_friday}
                </div>
                <div className={`rules-btn-day row-center align-items-center mx-2 c-pointer rounded-circle ${rowData.saturday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                    onClick={() => {
                        if (rowData.customizable_days) {
                            rowData.saturday = !rowData.saturday;
                            setDaysRules(rowData);
                        }
                    }}>
                    {language.option_table_rules_day_saturday}
                </div>
                <div className={`rules-btn-day row-center align-items-center mx-2 c-pointer rounded-circle ${rowData.sunday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                    onClick={() => {
                        if (rowData.customizable_days) {
                            rowData.sunday = !rowData.sunday;
                            setDaysRules(rowData);
                        }
                    }}>
                    {language.option_table_rules_day_sunday}
                </div>
            </div>
        </Cell>);

};

const Rules = (props) => {

    const ruleNames = [
        {
            value: 0,
            label: props.language.label_rules_select_rule_name_greater
        },
        {
            value: 1,
            label: props.language.label_rules_select_rule_name_less
        },
        {
            value: 2,
            label: props.language.label_rules_select_rule_name_equal
        }
    ];

    const intervals = [
        {
            value: 0,
            label: props.language.label_shared_popup_select_interval_hourly
        },
        {
            value: 1,
            label: props.language.label_shared_popup_select_interval_daily
        },
        {
            value: 2,
            label: props.language.label_shared_popup_select_interval_weekly
        },
        {
            value: 3,
            label: props.language.label_shared_popup_select_interval_monthly
        }
    ];

    const days = [
        {
            value: 0,
            label: props.language.option_filter_table_rules_day_monday
        },
        {
            value: 1,
            label: props.language.option_filter_table_rules_day_tuesday
        },
        {
            value: 2,
            label: props.language.option_filter_table_rules_day_wednesday
        },
        {
            value: 3,
            label: props.language.option_filter_table_rules_day_thursday
        },
        {
            value: 4,
            label: props.language.option_filter_table_rules_day_friday
        },
        {
            value: 5,
            label: props.language.option_filter_table_rules_day_saturday
        },
        {
            value: 6,
            label: props.language.option_filter_table_rules_day_sunday
        }
    ];

    const initRuleForm = {
        id_alarm: null,
        location: null,
        graph: null,
        interval: intervals[0].value,
        rule_name: ruleNames[0].value,
        customizable_days: true,
        value: null,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
        users: []
    }

    const initFilterData = {
        searchValue: "",
        locations: [],
        graphs: [],
        intervals: [],
        rule_names: [],
        value: "",
        days: []
    }

    let availableData = props.availableData;

    const [activePage, setActivePage] = useState(1);
    const [pageLength, setPageLength] = useState(20);
    const [totalPages, setTotalPages] = useState(0);

    const [showRulesModal, setRulesModal] = useState(false);
    const [editRule, setEditRule] = useState(false);
    const [deleteRule, setDeleteRule] = useState(0);
    const [showFilters, setShowFilters] = useState(false);

    const [ruleForm, setRuleForm] = useState(initRuleForm);
    const [filterData, setFilterData] = useState(initFilterData);

    const setActiveData = props.setActiveData;
    const setAvailableData = props.setAvailableData;
    const totalData = props.totalData;
    const locations = props.locations;
    const graphs = props.graphs;
    const users = props.user_project;
    const user_alarm = props.user_alarm;
    const loadRulesAsync = props.loadRulesAsync;
    const loadLocationsAsync = props.loadLocationsAsync;
    const loadGraphOptionsAsync = props.loadGraphOptionsAsync;
    const loadUserByProject = props.loadUserByProject;
    const loadRulesUser = props.loadRulesUser;

    useEffect(() => {
        if (!totalData.length || editRule)
            loadRulesAsync();

        if (!locations.length)
            loadLocationsAsync();

        if (!graphs.length)
            loadGraphOptionsAsync();

    }, [loadRulesAsync, loadLocationsAsync, loadGraphOptionsAsync, totalData, locations, graphs]);

    useEffect(() => {

        let data = totalData;
        const search = filterData.searchValue.toLowerCase();

        data = data.filter(option =>
        ((filterData.locations.includes(option.id_project) || !filterData.locations.length)
            && (filterData.graphs.includes(option.property_name) || !filterData.graphs.length)
            && (filterData.intervals.includes(option.interval_code) || !filterData.intervals.length)
            && (filterData.rule_names.includes(option.rule_name) || !filterData.rule_names.length)
            && (option.value === parseFloat(filterData.value) || !filterData.value.length)
            && (!filterData.days.length || (filterData.days.includes(0) && option.monday) || (filterData.days.includes(1) && option.tuesday)
                || (filterData.days.includes(2) && option.wednesday) || (filterData.days.includes(3) && option.thursday) || (filterData.days.includes(4) && option.friday)
                || (filterData.days.includes(5) && option.saturday) || (filterData.days.includes(6) && option.sunday))
            && (option.property_name.toLowerCase().includes(search) || option.project.toLowerCase().includes(search) || search === "")
        ));

        setActivePage(1);
        setAvailableData(data);

        amplitude.getInstance().logEvent('New Rule Filtered', filterData);

    }, [setActivePage, setAvailableData, filterData, totalData]);

    useEffect(() => {
        setTotalPages(availableData.length);
        setActiveData(availableData.slice((activePage - 1) * pageLength, activePage * pageLength));
    }, [setActiveData, setTotalPages, activePage, pageLength, availableData]);

    useEffect(() => {
        logEvent(analytics, "rules", {
            id_user: props.user.id_user,
            name: `${props.user.name} ${props.user.lastname}`
        })

        amplitude.getInstance().logEvent('Rules Screen Viewed');

    }, []);

    useEffect(() => {        
        loadUserByProject(ruleForm.location);        
    }, [ruleForm.location]);    

    useEffect(() => {   
        if(editRule){  
            const userValue =   user_alarm.map(value => value.id_user);        
            setRuleForm({ ...ruleForm, users: userValue});                    
        }else{
            const userValue =   users.map(value => value.id_user);        
            setRuleForm({ ...ruleForm, users: userValue});                           
        }
    }, [users, deleteRule]);  

    useEffect(() => {
        if(editRule){
            loadRulesUser(deleteRule);
        }
    }, [deleteRule]);    

    return (
        <Fragment>
            <Modal
                size="sm"
                show={showRulesModal}                
                onHide={() => {
                    setRulesModal(false);                    
                    setRuleForm(initRuleForm); 
                    setEditRule(false);  
                    props.setUserByProject([]);                  
                }}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{editRule ? props.language.title_popup_update_rule : props.language.title_rules_popup_create_rule}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column p-3">
                        <div className="row-center align-items-center flex-wrap my-2">
                            <div className="col-md-6 col-12">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_rules_popup_location}</div>
                                <SelectPicker
                                    className="w-100"
                                    value={ruleForm.location}
                                    searchable={false}
                                    cleanable={false}
                                    placeholder={props.language.placeholder_rules_popup_location}
                                    data={locations}
                                    valueKey={"id_project"}
                                    labelKey={"name"}
                                    onChange={value => setRuleForm({ ...ruleForm, location: value })}
                                />
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_rules_popup_properties}</div>
                                <SelectPicker
                                    className="w-100"
                                    groupBy={"group"}
                                    value={ruleForm.graph}
                                    searchable={false}
                                    cleanable={false}
                                    placeholder={props.language.placeholder_rules_popup_properties}
                                    valueKey={"id_graph"}
                                    data={props.graphs}
                                    onChange={value => setRuleForm({ ...ruleForm, graph: value })}
                                />
                            </div>
                        </div>
                        <div className="row-center align-items-center flex-wrap my-2">
                            <div className="col-md-6 col-12">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_rules_popup_interval}</div>
                                <SelectPicker
                                    className="w-100"
                                    defaultValue={ruleForm.interval}
                                    searchable={false}
                                    cleanable={false}
                                    data={intervals}
                                    onChange={value => {
                                        if (value > 1) {
                                            setRuleForm({ ...ruleForm, interval: value, customizable_days: false, monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true });
                                        }
                                        else {
                                            setRuleForm({ ...ruleForm, interval: value, customizable_days: true });
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_rules_popup_rule_names}</div>
                                <SelectPicker
                                    className="w-100"
                                    value={ruleForm.rule_name}
                                    searchable={false}
                                    cleanable={false}
                                    data={ruleNames}
                                    onChange={value => setRuleForm({ ...ruleForm, rule_name: value })}
                                />
                            </div>
                        </div>
                        <div className="row-center align-items-center flex-wrap my-2">
                            <div className="col-md-6 col-12">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_rules_popup_value}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-align-justify txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.placeholder_rules_popup_value}
                                        value={ruleForm.value}                                        
                                        type="number"                                        
                                        onChange={value => setRuleForm({ ...ruleForm, value: value })} />
                                </InputGroup>
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_rules_popup_available_days}</div>
                                <div className="row-start txt-12 txt-semibold align-items-center h-100">
                                    <div className={`rules-btn-day row-center align-items-center rounded-circle c-pointer ${ruleForm.monday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                                        onClick={() => {
                                            if (ruleForm.customizable_days) {
                                                setRuleForm({ ...ruleForm, monday: !ruleForm.monday })
                                            }
                                        }}>
                                        {props.language.option_table_rules_day_monday}
                                    </div>
                                    <div className={`rules-btn-day row-center align-items-center rounded-circle mx-1 c-pointer ${ruleForm.tuesday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                                        onClick={() => {
                                            if (ruleForm.customizable_days) {
                                                setRuleForm({ ...ruleForm, tuesday: !ruleForm.tuesday })
                                            }
                                        }}>
                                        {props.language.option_table_rules_day_tuesday}
                                    </div>
                                    <div className={`rules-btn-day row-center align-items-center rounded-circle c-pointer ${ruleForm.wednesday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                                        onClick={() => {
                                            if (ruleForm.customizable_days) {
                                                setRuleForm({ ...ruleForm, wednesday: !ruleForm.wednesday })
                                            }
                                        }}>
                                        {props.language.option_table_rules_day_wednesday}
                                    </div>
                                    <div className={`rules-btn-day row-center align-items-center rounded-circle mx-1 c-pointer ${ruleForm.thursday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                                        onClick={() => {
                                            if (ruleForm.customizable_days) {
                                                setRuleForm({ ...ruleForm, thursday: !ruleForm.thursday })
                                            }
                                        }}>
                                        {props.language.option_table_rules_day_thursday}
                                    </div>
                                    <div className={`rules-btn-day row-center align-items-center rounded-circle c-pointer ${ruleForm.friday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                                        onClick={() => {
                                            if (ruleForm.customizable_days) {
                                                setRuleForm({ ...ruleForm, friday: !ruleForm.friday })
                                            }
                                        }}>
                                        {props.language.option_table_rules_day_friday}
                                    </div>
                                    <div className={`rules-btn-day row-center align-items-center rounded-circle mx-1 c-pointer ${ruleForm.saturday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                                        onClick={() => {
                                            if (ruleForm.customizable_days) {
                                                setRuleForm({ ...ruleForm, saturday: !ruleForm.saturday })
                                            }
                                        }}>
                                        {props.language.option_table_rules_day_saturday}
                                    </div>
                                    <div className={`rules-btn-day row-center align-items-center rounded-circle c-pointer ${ruleForm.sunday ? "bg-blue txt-white" : "bg-light-blue2 txt-dark-blue2"}`}
                                        onClick={() => {
                                            if (ruleForm.customizable_days) {
                                                setRuleForm({ ...ruleForm, sunday: !ruleForm.sunday })
                                            }
                                        }}>
                                        {props.language.option_table_rules_day_sunday}
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div className="row-start align-items-center flex-wrap my-2">
                            <div className="col-md-6 col-12">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_rules_popup_users}</div>                    
                                    <CheckPicker
                                        className="w-100"
                                        maxHeight={180}
                                        disabled={!users.length && !editRule}
                                        searchable={false}
                                        cleanable={false}
                                        value={ruleForm.users}                                                                                            
                                        placeholder={props.language.placeholder_rules_popup_user}
                                        data={users}
                                        valueKey={"id_user"}
                                        labelKey={"name_user"}
                                        onChange={value => setRuleForm({ ...ruleForm, users: value})}
                                    />                                
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 mr-4 ${isValidRule(ruleForm) ? "bg-blue" : "bg-light-blue"}`}
                            onClick={() => {
                                if (isValidRule(ruleForm)) {
                                    setFilterData(initFilterData);

                                    if (editRule){
                                        props.updateRuleAsync(ruleForm);   
                                        setRuleForm(initRuleForm);                                     
                                        setRulesModal(false);  
                                        setEditRule(false); 
                                        props.setUserByProject([]);                                                                     
                                    }
                                    else
                                        props.createRuleAsync(ruleForm);

                                    setRulesModal(false);
                                    setShowFilters(false);                                                                       
                                    document.getElementById("input-search").value = '';
                                    document.getElementById("input-value").value = '';

                                    amplitude.getInstance().logEvent('New Rule Finished', ruleForm);

                                } else {
                                    showError(ruleForm, props);
                                }
                                setRuleForm(initRuleForm); 
                            }}>
                            {editRule ? props.language.title_rules_popup_button_update : props.language.title_rules_popup_button_create}
                        </button>
                        {editRule ?
                        <button
                            style={{ width: 150 }}
                            className={`txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 mr-4 ${isValidRule(ruleForm) ? "bg-blue" : "bg-light-blue"}`}
                            onClick={(() => {
                                props.deleteRuleAsync(deleteRule);

                                amplitude.getInstance().logEvent('New Rule Deleted', {
                                    id_rule: deleteRule
                                });
                                setRulesModal(false); 
                                setEditRule(false);
                                setRuleForm(initRuleForm);
                                props.setUserByProject([]); 
                            })}>
                            {props.language.title_rules_popup_button_delete}
                        </button> : null}
                    </div>
                </Modal.Footer>
            </Modal>
            <div id="rules" className="column-start align-items-start px-3 py-2 h-100 w-100">
                <div className="row-between align-items-center flex-wrap-reverse w-100 pb-2">
                    <div className="row-start align-items-center flex-wrap col-md-8 col-12 p-0">
                        <div className="row-start align-items-center col-md-6 col-12 my-md-0 my-2 p-0">
                            <div className="txt-20 txt-bold txt-dark-blue w-auto">{props.language.title_rules}</div>
                            <Whisper
                                delay={500}
                                placement="right"
                                trigger="hover"
                                speaker={<Tooltip>{props.language.tooltip_rules_create_rule}</Tooltip>}>
                                <button
                                    id="rules-add-rule"
                                    className="d-flex justify-content-center align-items-center border-0 rounded-circle p-2 bg-blue ml-3 mr-2"
                                    onClick={() => {

                                        logEvent(analytics, "click_rules_add", {
                                            id_user: props.user.id_user,
                                            name: `${props.user.name} ${props.user.lastname}`
                                        });

                                        amplitude.getInstance().logEvent('New Rule Started');

                                        setRulesModal(true);

                                    }}>
                                    <i className="fas fa-plus txt-12 txt-white"></i>
                                </button>
                            </Whisper>
                        </div>
                        <div className="row-start align-items-center col-md-6 col-12 p-0">
                            <Whisper
                                delay={1000}
                                placement="bottom"
                                trigger="focus"
                                speaker={<Tooltip>{props.language.tooltip_shared_search}</Tooltip>}>
                                <InputGroup
                                    inside>
                                    <Input
                                        id="input-search"
                                        placeholder={`${props.language.title_shared_search}`}
                                        onPressEnter={e => setFilterData({ ...filterData, searchValue: e.target.value })} />
                                    <InputGroup.Addon>
                                        <i className="fas fa-search txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                </InputGroup>
                            </Whisper>
                        </div>
                    </div>
                    <div className="d-block d-md-flex justify-content-end align-items-center col-md-4 col-12 p-0">
                        <Whisper
                            placement="bottom"
                            trigger="click"
                            speaker={<Tooltip>{props.language.tooltip_rules_info}</Tooltip>}>
                            <i className="fas fa-info-circle d-none d-md-block txt-12 txt-light-blue txt-hover-blue mx-2 c-pointer"></i>
                        </Whisper>
                        <Options />
                    </div>
                </div>
                <div className="position-relative column-start flex-grow-1 w-100 bg-white rounded shadow overflow-hidden">
                    <Transition
                        in={props.loading}
                        timeout={100}>
                        {state => (
                            <div className={`trans-fade trans-fade-${state} position-absolute bg-white w-100 h-100 top-0 left-0 zindex-1`}>
                                <div className="column-center align-items-center w-100 h-100">
                                    <Loader center size="md" content={props.language.loading_message} />
                                </div>
                            </div>
                        )}
                    </Transition>
                    {!props.activeData.length && !props.loading ?
                        <div className={`position-absolute column-center align-items-center left-0 bg-white border-top w-100 p-3 zindex-1 empty-message-${!showFilters ? "complete" : "filtered"}`}>
                            <div className="row-center align-items-center w-100">
                                <i className="fas fa-pencil-ruler txt-20 txt-light-blue" />
                                <div className="txt-20 txt-bold txt-light-blue w-auto mx-3">{props.language.title_rules_empty}</div>
                            </div>
                        </div> : null}
                    <div className="table-data-container flex-grow-1 w-100">
                        <div className="position-relative d-flex flex-column h-100" style={{ minWidth: 1200 }}>
                            <div className="table-header position-absolute top-0 row-start align-items-center zindex-1 py-3 w-100" style={{ paddingRight: 20 }}>
                                <div className="text-center" style={{ width: 60, paddingLeft: 10, paddingRight: 10 }}>
                                    <Whisper
                                        delay={500}
                                        placement="top"
                                        trigger="hover"
                                        speaker={<Tooltip>{props.language.tooltip_shared_filter}</Tooltip>}>
                                        <i className="fas fa-filter txt-10 txt-light-blue txt-hover-blue c-pointer"
                                            onClick={() => setShowFilters(!showFilters)} />
                                    </Whisper>
                                </div>
                                <div className="text-left" style={{ width: 220, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_location}</div>
                                </div>
                                <div className="text-left" style={{ width: 230, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_rules_property}</div>
                                </div>
                                <div className="text-left" style={{ width: 130, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_rules_interval}</div>
                                </div>
                                <div className="text-left " style={{ width: 140, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_rules_rule}</div>
                                </div>
                                <div className="text-left " style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_rules_value}</div>
                                </div>
                                <div className="text-center flex-grow-1 " style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_rules_available}</div>
                                </div>
                            </div>
                            <div className={`table-filter position-absolute row-start align-items-end zindex-1 pb-3 w-100 ${!showFilters ? "d-none" : null}`} style={{ paddingRight: 20 }}>
                                <div className="text-left" style={{ marginLeft: 60, width: 220, paddingLeft: 10, paddingRight: 10 }}>
                                    {locations.length?
                                    <SelectPicker
                                        className="w-100"
                                        searchable={false}
                                        placeholder={props.language.placeholder_rules_filter_table_all_locations}
                                        valueKey={"id_project"}
                                        labelKey={"name"}
                                        value={filterData.locations}
                                        onChange={value => setFilterData({ ...filterData, locations: value })}
                                        data={locations}
                                    />: null}
                                </div>
                                <div className="text-left" style={{ width: 230, paddingLeft: 10, paddingRight: 10 }}>
                                    <SelectPicker
                                        className="w-100"
                                        groupBy={"group"}
                                        searchable={false}
                                        placeholder={props.language.placeholder_rules_filter_table_all_properties}
                                        value={filterData.properties}
                                        valueKey={"label"}
                                        labelKey={"label"}
                                        data={graphs}
                                        onChange={value => setFilterData({ ...filterData, graphs: value })}
                                    />
                                </div>
                                <div className="text-left " style={{ width: 130, paddingLeft: 10, paddingRight: 10 }}>
                                    <SelectPicker
                                        className="w-100"
                                        searchable={false}
                                        placeholder={props.language.placeholder_rules_filter_table_all_intervals}
                                        value={filterData.intervals}
                                        onChange={value => setFilterData({ ...filterData, intervals: value })}
                                        data={intervals}
                                    />
                                </div>
                                <div className="text-left " style={{ width: 140, paddingLeft: 10, paddingRight: 10 }}>
                                    <SelectPicker
                                        className="w-100"
                                        searchable={false}
                                        placeholder={props.language.placeholder_rules_filter_table_all_rule_names}
                                        value={filterData.rule_names}
                                        onChange={value => setFilterData({ ...filterData, rule_names: value })}
                                        data={ruleNames}
                                    />
                                </div>
                                <div className="text-left " style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}>
                                    <Input
                                        id="input-value"
                                        placeholder={props.language.placeholder_rules_filter_table_value}
                                        type="number"
                                        inside={true}
                                        onPressEnter={e => setFilterData({ ...filterData, value: e.target.value })} />
                                </div>
                                <div className="text-left flex-grow-1 " style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <SelectPicker
                                        className="w-100"
                                        searchable={false}
                                        placeholder={props.language.placeholder_rules_filter_table_all_days}
                                        data={days}
                                        onChange={value => setFilterData({ ...filterData, days: value })}
                                    />
                                </div>
                            </div>
                            <div className={`position-relative flex-grow-1 table-vertical-scroll border-top table-content-${!showFilters ? "complete" : "filtered"}`}>
                                <Table
                                    autoHeight
                                    wordWrap
                                    data={props.activeData}
                                    hover={false}
                                    showHeader={false}>
                                    <Column width={60} verticalAlign="middle">
                                        <HeaderCell>trash</HeaderCell>
                                        <Cell dataKey="trash">
                                            {rowData => (
                                                    <Whisper
                                                        placement="top"
                                                        trigger="hover"
                                                        speaker={<Tooltip>{props.language.tooltip_rules_edit_rule}</Tooltip>}>
                                                        <i className="fas fa-pen txt-12 txt-light-blue txt-hover-blue ml-3 c-pointer"
                                                            onClick={async() => {    
                                                                
                                                                await loadRulesUser(rowData.id_alarm);

                                                                setRuleForm({
                                                                    ...ruleForm,
                                                                    id_alarm: rowData.id_alarm,                                                           
                                                                    graph: rowData.id_graph,       
                                                                    location: rowData.id_project,                                                       
                                                                    interval: rowData.interval_code,
                                                                    customizable_days: rowData.customizable_days,
                                                                    rule_name: rowData.rule_name,
                                                                    value: rowData.value,
                                                                    monday: rowData.monday,
                                                                    tuesday: rowData.tuesday,
                                                                    wednesday: rowData.wednesday,
                                                                    thursday: rowData.thursday,
                                                                    friday: rowData.friday,
                                                                    saturday: rowData.saturday,
                                                                    sunday: rowData.sunday,
                                                                    users: props.user_alarm                                                        
                                                                });

                                                                setDeleteRule(rowData.id_alarm);
                                                                setEditRule(true);
                                                                setRulesModal(true);
                                                            }}
                                                        />
                                                    </Whisper>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={220} verticalAlign="middle">
                                        <HeaderCell>project</HeaderCell>
                                        <Cell dataKey="project">
                                            {rowData => (
                                                <div style={{ width: 230 }}>
                                                    <div className="txt-10 txt-dark-blue txt-semibold">{rowData.project}</div>
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={230} verticalAlign="middle">
                                        <HeaderCell>property_name</HeaderCell>
                                        <Cell dataKey="property_name">
                                            {rowData => (
                                                <div style={{ width: 230 }}>
                                                    <div className="txt-10 txt-dark-blue txt-semibold">{rowData.property_name}</div>
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={130} verticalAlign="middle">
                                        <HeaderCell>interval</HeaderCell>
                                        <Cell dataKey="interval">
                                            {rowData => (
                                                <div style={{ width: 230 }}>
                                                    <div className="txt-10 txt-dark-blue txt-semibold">{rowData.interval_code <= 3 ? intervals[rowData.interval_code].label : ''}</div>
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={140} verticalAlign="middle">
                                        <HeaderCell>rule_name</HeaderCell>
                                        <Cell dataKey="rule_name">
                                            {rowData => (
                                                <div style={{ width: 150 }}>
                                                    <div className="txt-10 txt-dark-blue txt-semibold">{rowData.rule_name <= 2 ? ruleNames[rowData.rule_name].label : ''}</div>
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={90} verticalAlign="middle">
                                        <HeaderCell>value</HeaderCell>
                                        <Cell dataKey="value">
                                            {rowData => (
                                                <div style={{ width: 90 }}>
                                                    <div className="txt-10 txt-dark-blue txt-semibold">{rowData.value}</div>
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={1} verticalAlign="middle">
                                        <HeaderCell>days</HeaderCell>
                                        <ImageCell
                                            dataKey="days"
                                            language={props.language}
                                            setDaysRules={props.setDaysRulesAsync} />
                                    </Column>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className={`border-top ${!props.activeData.length ? "d-none" : null}`}>
                        <Table.Pagination
                            lengthMenu={lengthMenu}
                            activePage={activePage}
                            displayLength={pageLength}
                            total={totalPages}
                            renderLengthMenu={picker => picker}
                            onChangeLength={value => {
                                const maxActivePage = Math.ceil(props.availableData.length / value);
                                const page = activePage <= maxActivePage ? activePage : maxActivePage;

                                setPageLength(value);
                                setActivePage(page);
                            }}
                            onChangePage={value => { setActivePage(value) }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    totalData: state.rules.totalData,
    availableData: state.rules.availableData,
    activeData: state.rules.activeData,
    loading: state.rules.loading,
    locations: state.home.locations,
    language: state.shared.language,
    graphs: state.home.graphs,
    user_project: state.users.userProject,
    user_alarm: state.rules.users
})

const mapDispatchToProps = dispatch => ({
    loadRulesAsync: () => dispatch(rulesActions.loadRulesAsync()),
    loadGraphOptionsAsync: () => dispatch(homeActions.loadGraphOptionsAsync()),
    loadLocationsAsync: () => dispatch(homeActions.loadLocationsAsync()),
    setAvailableData: payload => dispatch(rulesActions.setAvailableData(payload)),
    setActiveData: payload => dispatch(rulesActions.setActiveData(payload)),
    createRuleAsync: payload => dispatch(rulesActions.createRuleAsync(payload)),
    deleteRuleAsync: payload => dispatch(rulesActions.deleteRuleAsync(payload)),
    setDaysRulesAsync: payload => dispatch(rulesActions.setDaysRulesAsync(payload)),
    loadUserByProject: payload => dispatch(usersActions.loadUserByProject(payload)),
    setUserByProject: payload => dispatch(usersActions.setUserByProject(payload)),
    loadRulesUser : payload => dispatch(rulesActions.loadRulesUser(payload)),
    updateRuleAsync: payload => dispatch(rulesActions.updateRuleAsync(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Rules)

