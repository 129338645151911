import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import * as gameActions from '../../../store/actions/Game';

import { Tooltip, Whisper, Popover } from 'rsuite';

import RankingBubble from './RankingBubble';
import LoaderMore from '../shared/LoaderMore';

const Speaker = ({ type, setType, loadRankingAsync, language, ...props }) => {

    const handleChange = (action) => {
        if (type !== action) {
            setType(action);
            loadRankingAsync();
        }
    }

    return (
        <Popover id='games-popover-ranking-filter' {...props}>
            <div className='column-start align-items-center px-3 py-2'>
                <div className={`txt-semibold txt-10 txt-${!type ? "blue" : "white"} pb-2 c-pointer`}
                    onClick={() => handleChange(0)}>Mensual</div>
                <div className={`txt-semibold txt-10 txt-${type ? "blue" : "white"} c-pointer`}
                    onClick={() => handleChange(1)}>Historico</div>
            </div>
        </Popover>
    );
};

const Ranking = (props) =>{ 
         
        let typePlace = props.typePlace;

        return(
            <div className='d-flex flex-column w-100 h-100'>
                <div className='row-between align-items-center w-100 h-100 pb-3'>
                    <div className="position-relative bg-dark rounded-pill row-center align-items-center game-picker-container">
                        <div id="game-picker" className="position-absolute rounded-pill bg-blue col-6" style={{ left: `${50 * props.typePlace}%` }}></div>
                        <div className="row-between align-items-center w-100">
                            <div className={`home-graph-range-picker-option col-6 txt-bold txt-10 txt-${typePlace === 0 ? "white" : "light-blue"} text-center c-pointer h-100`}
                                onClick={()=>{
                                    if (typePlace !== 0) {
                                        props.setTypePlace(0);
                                        props.loadRankingAsync();
                                    }
                                }}>{props.language.title_game_ranking_button_city}</div>
                            <div
                                className={`home-graph-range-picker-option col-6 txt-bold txt-10 txt-${typePlace=== 1 ? "white" : "light-blue"} text-center c-pointer h-100`}
                            onClick={()=>{
                                if (typePlace !== 1) {
                                    props.setTypePlace(1);
                                    props.loadRankingAsync();
                                }
                            }}>{props.language.title_game_ranking_button_country}</div>                                        
                        </div>
                    </div>
                    <div className='row-end align-items-center'>                
                        <Whisper
                            trigger="click"
                            placement="bottom"
                            speaker={<Speaker
                                language={props.language}
                                type={props.typeTime}
                                setType={props.setTypeTime}
                                loadRankingAsync={props.loadRankingAsync}
                                />}>
                            <i className="fas fa-filter txt-12 txt-white c-pointer mx-4"></i>
                        </Whisper>
                        <Whisper
                            trigger="click"
                            placement="bottom"
                            speaker={<Tooltip>{props.language.text_game_ranking_tooltip_info}</Tooltip>}>
                            <i className="fas fa-info-circle txt-14 txt-white c-pointer"></i>
                        </Whisper>
                    </div> 
                </div>
                {props.positions ?
                    props.positions.map((position, i) => {

                        const colorTrophy = position.position <= 3 ? props.colorsTrophy[position.position - 1] : null;
                    
                            if (position.position === 3 && props.previousPage) {
                                return (
                                    <Fragment>
                                        <RankingBubble
                                            key={i}
                                            name={position.user}
                                            user={position.id_user}
                                            image={position.image}
                                            points={position.points}
                                            colorTrophy={colorTrophy}
                                            position={position.position}
                                            inverse={position.active_user} />
                                        <LoaderMore loading={props.loadingPrevious} fun={props.loadPreviousAsync} val={props.previousPage} />
                                    </Fragment>                                      
                                    )
                            }
                    
                            return (
                                <RankingBubble
                                    key={i}
                                    name={position.user}
                                    image={position.image}
                                    points={position.points}
                                    colorTrophy={colorTrophy}
                                    position={position.position}
                                    inverse={position.active_user} />)  
                        }):null}                                
                        {props.nextPage && <LoaderMore loading={props.loadingNext} fun={props.loadNextAsync} val={props.nextPage} />}                   
            </div>
        )
    }

    const mapStateToProps = (state) => ({
    user: state.login.user,
    language: state.shared.language,
    colorsTrophy: state.game.colorsTrophy,
    positions: state.game.positions,
    nextPage: state.game.nextPage,
    previousPage: state.game.previousPage,
    loadingNext: state.game.loadingNext,
    loadingPrevious: state.game.loadingPrevious,
    loadingInitial: state.game.loadingInitial,
    typePlace: state.game.rankingTypePlace,
    typeTime: state.game.rankingTypeTime,
    showRanking: state.game.showRanking
})

const mapDispatchToProps = dispatch => ({
    loadRankingAsync: () => dispatch(gameActions.loadRankingAsync()),
    loadPreviousAsync: (url) => dispatch(gameActions.loadPreviousAsync(url)),
    loadNextAsync: (url) => dispatch(gameActions.loadNextAsync(url)),
    setTypeTime: (payload) => dispatch(gameActions.setRankingTypeTime(payload)),
    setTypePlace: (payload) => dispatch(gameActions.setRankingTypePlace(payload)),    
})

export default connect(mapStateToProps, mapDispatchToProps)(Ranking)