import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import CurrencyFormat from 'react-currency-format';

import { Loader } from 'rsuite';

export default class PaidStatus extends Component {
    render(props) {

        let statusMessage;
        let statusIcon;
        let transaction = this.props.transaction;
        let language = this.props.language;

        if (transaction !== null) {

            switch (transaction.state) {
                case "APROBADA":
                    statusMessage = <div className="txt-bold txt-16 txt-green">{language.title_paid_state_approved}</div>;
                    statusIcon = <i className="far fa-check-circle txt-26 txt-green mr-3"></i>;
                    break;
                case "RECHAZADA":
                case "FALLIDA":
                    statusMessage = <div className="txt-bold txt-16 txt-red">{language.title_paid_state_declined}</div>;
                    statusIcon = <i className="far fa-times-circle txt-26 txt-red mr-3"></i>;
                    break;
                default:
                    statusMessage = <div className="txt-bold txt-16 txt-light-blue">{language.title_paid_state_pending}</div>;
                    statusIcon = <i className="far fa-clock txt-26 txt-light-blue mr-3"></i>;
                    break;
            }

        }

        return (
            <div className="column-between align-items-center flex-grow-1 w-100 p-4">
                {transaction !== null && Object.entries(language).length ?
                    <Fragment>
                        <div className="row-start align-items-center my-4">
                            {statusIcon}
                            {statusMessage}
                        </div>
                        <div className="w-100">
                            <div className="row-between align-items-center bg-clear-gray my-2 py-2 rounded">
                                <div className="col-6">
                                    <div className="txt-bold txt-12 txt-dark-blue">{language.title_paid_email}</div>
                                </div>
                                <div className="col-6">
                                    <div className="txt-semibold txt-12 txt-dark-blue">{transaction.email}</div>
                                </div>
                            </div>
                            <div className="row-between align-items-center my-2 py-2">
                                <div className="col-6">
                                    <div className="txt-bold txt-12 txt-dark-blue">{language.title_paid_date}</div>
                                </div>
                                <div className="col-6">
                                    <div className="txt-semibold txt-12 txt-dark-blue">{transaction.date}</div>
                                </div>
                            </div>
                            <div className="row-between align-items-center bg-clear-gray my-2 py-2 rounded">
                                <div className="col-6">
                                    <div className="txt-bold txt-12 txt-dark-blue">{language.title_paid_reference}</div>
                                </div>
                                <div className="col-6">
                                    <div className="txt-semibold txt-12 txt-dark-blue">{transaction.reference}</div>
                                </div>
                            </div>
                            <div className="row-between align-items-center my-2 py-2">
                                <div className="col-6">
                                    <div className="txt-bold txt-12 txt-dark-blue">{language.title_paid_description}</div>
                                </div>
                                <div className="col-6">
                                    <div className="txt-semibold txt-12 txt-dark-blue text-wrap">{transaction.description}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row-between align-items-center bg-clear-gray w-100 px-2 py-3 rounded">
                            <div className="col-6">
                                <div className="txt-bold txt-14 txt-dark-blue">{language.title_paid_total}</div>
                            </div>
                            <div className="d-flex col-6">
                                <CurrencyFormat
                                    className="txt-bold txt-14 txt-dark-blue text-right"
                                    value={transaction.amount}
                                    prefix="$"
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={1}
                                    fixedDecimalScale={true} />
                            </div>
                        </div>
                    </Fragment> :
                    <Loader center size="md" content={language.loading_message} />
                }
            </div>
        )
    }
};
