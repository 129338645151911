import { createStore, combineReducers, applyMiddleware } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';

import * as sharedCreators from './creators/Shared';

import { homeReducer } from './reducers/Home';
import { notificacionsReducer } from './reducers/Notifications';
import { invoicesReducer } from './reducers/Invoices';
import { reportsReducer } from './reducers/Reports';
import { rulesReducer } from './reducers/Rules';
import { loginReducer } from './reducers/Login';
import { usersReducer } from './reducers/Users';
import { sharedReducer } from './reducers/Shared';
import  neuPlusReducer from './reducers/NEUPlus';
import locationsReducer from './reducers/Locations';
import tagsReducer from './reducers/Tags';
import { gameReducer }  from './reducers/Game';

const allReducers = combineReducers({
    home: homeReducer,
    notifications: notificacionsReducer,
    invoices: invoicesReducer,
    reports: reportsReducer,
    rules: rulesReducer,
    locations: locationsReducer,
    login: loginReducer,
    users: usersReducer,
    shared: sharedReducer,
    neuPlus: neuPlusReducer,
    tags: tagsReducer,
    game: gameReducer
});

const root = (state, action) => {
    if (action.type === sharedCreators.LOGOUT) {
        const { shared } = state;
        state = { shared };
    }

    return allReducers(state, action);
}

const Store = createStore(root, composeWithDevTools(applyMiddleware(thunk)));

export default Store;