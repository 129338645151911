import * as game from '../creators/Game';
import { GETData, SETData } from '../../services/WebServices';

const game_url = "https://games.neu.com.co/";
//const game_url = "http://localhost:8000/";

export const setGames = payload => {
    return {
        type: game.SET_GAMES,
        payload
    }
}

export const setNewAchievements = payload => {
    return {
        type: game.SET_NEW_ACHIEVEMENTS,
        payload
    }
}

export const setMedals = payload => {
    return {
        type: game.SET_MEDALS,
        payload
    }
}

export const setShowMedals = payload => {
    return {
        type: game.SET_SHOW_MEDALS,
        payload
    }
}

export const setShowRanking = payload => {
    return {
        type: game.SET_SHOW_RANKING,
        payload
    }
}

export const setProfile = payload => {
    return {
        type: game.SET_PROFILE,
        payload
    }
}

export const setRanking = payload => {
    return {
        type: game.SET_RANKING,
        payload
    }
}

export const setRankingUri = payload => {
    return {
        type: game.SET_RANKING_URI,
        payload
    }
}

export const setRankingTypePlace = payload => {
    return {
        type: game.SET_RANKING_TYPE_PLACE,
        payload
    }
}

export const setRankingTypeTime = payload => {
    return {
        type: game.SET_RANKING_TYPE_TIME,
        payload
    }
}

export const setPrevious = payload => {
    return {
        type: game.SET_PREVIOUS,
        payload
    }
}

export const setNext = payload => {
    return {
        type: game.SET_NEXT,
        payload
    }
}

export const setLoadingNext = payload => {
    return {
        type: game.SET_LOADING_NEXT,
        payload
    }
}

export const setLoadingPrevious = payload => {
    return {
        type: game.SET_LOADING_PREVIOUS,
        payload
    }
}

export const setLoadingInitial = payload => {
    return {
        type: game.SET_LOADING_INITIAL,
        payload
    }
}

export const setLoadingInitialChallenge = payload => {
    return {
        type: game.SET_LOADING_INITIAL_CHALLENGE,
        payload
    }
}

export const setGamesPcType = payload => {
    return {
        type: game.SET_GAMES_PC_TYPE,
        payload
    }
}

export const setGamesPcChallenges = payload => {
    return {
        type: game.SET_GAMES_PC_CHALLENGES,
        payload
    }
}

export const setGamesPcNextPage = payload => {
    return {
        type: game.SET_GAMES_PC_NEXT_PAGE,
        payload
    }
}

export const loadGamesAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;
        const language = getState().shared.language.language_locale;    

        GETData(`games/?pk=${userId}&&language=${language}/`, "GET", game_url, true)
            .then(response => {
                if (response !== null) { dispatch(setGames(response)); }
            })
            .catch(response => console.error(response));
    }
}

export const loadNewAchievementsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;
        const language = getState().shared.language.language_locale;    

        GETData(`new-achievements/?pk=${userId}&&language=${language}/`, "GET", game_url, true)
            .then(response => {                
                if (response !== null) { dispatch(setNewAchievements([...response.medals, ...response.challenges])); }
            })
            .catch(response => console.error(response));
    }
}

export const loadNewUsersConnectionAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        GETData(`users/connections/?pk=${userId}`, "GET", game_url, true)
            .then(response => {
                if (response !== null) { dispatch(setNewAchievements([...response.medals, ...response.challenges])); }
            })
            .catch(response => console.error(response));
    }
}

export const loadMedalsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;
        const language = getState().shared.language.language_locale;     

        GETData(`medals/?pk=${userId}&&language=${language}/`, "GET", game_url, true)
            .then(response => {                
                if (response !== null) { dispatch(setMedals(response)); }
            })
            .catch(response => console.error(response));
    }
}

export const loadProfileAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;        

        GETData(`profile/?pk=${userId}`, "GET", game_url, true)
            .then(response => {   
                           
                if (response !== null) { dispatch(setProfile(response));}                           
            })
            .catch(response => console.error(response));
    }
}

export const loadRankingAsync = () => {
    return (dispatch, getState) => {

        let uri;

        const userId = getState().login.user.id_user;
        const typePlace = getState().game.rankingTypePlace;       
        const typeTime = getState().game.rankingTypeTime;        

        dispatch(setLoadingInitial(true));

        uri = typePlace ? '' : 'city/';
        uri = typeTime ? uri + '' : uri + 'monthly/';

        GETData(`ranking/${uri}?pk=${userId}`, "GET", game_url, true)
            .then(response => {                
                if (response !== null) { dispatch(setRanking(response)); }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoadingInitial(false)));
    }
}

export const loadPreviousAsync = (url) => {
    return (dispatch) => {

        dispatch(setLoadingPrevious(true));
        GETData(url, "GET", '', true)
            .then(response => {
                if (response !== null) { dispatch(setPrevious(response)); }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoadingPrevious(false)));
    }
}

export const loadNextAsync = (url) => {
    return (dispatch) => {

        dispatch(setLoadingNext(true));
        GETData(url, "GET", '', true)
            .then(response => {
                if (response !== null) { dispatch(setNext(response)); }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoadingNext(false)));
    }
}

export const setGameScoreAsync = (payload) => {
    return (dispatch, getState) => {

        let games = getState().game.games;

        const data = {
            id_user: getState().login.user.id_user,
            id_game: payload.idGame,
            score: payload.score
        }

        const index = games
            .findIndex(game => game.id_game === payload.idGame);

        SETData("games/score/", "POST", data, game_url)
            .then(() => {
                games[index] = { ...games[index], survey: false }
                dispatch(setGames([...games]));
            })
            .catch(response => console.error(response));
    }
}

//Power Challenge

export const loadGamesPcChallengesAsync = () => {
    return (dispatch, getState) => {

        const type = getState().game.gamesPcType;
        const userId = getState().login.user.id_user;        
        const language = getState().shared.language.language_locale;    
        const uri = type ? 'historic' : 'active';

        let data = [];
        let next = null;

        dispatch(setLoadingInitialChallenge(true));

        GETData(`power-challenge/${uri}/?pk=${userId}&&language=${language}/`, "GET", game_url, true)
            .then(response => {
                if (response !== null) {
                    data = response.results;
                    next = response.next;
                }
            })
            .catch(response => console.log(response))
            .finally(() => {
                dispatch(setGamesPcChallenges(data));
                dispatch(setGamesPcNextPage(next))
                dispatch(setLoadingInitialChallenge(false))
            });

    }
}

export const loadGamesPcNextAsync = (url) => {
    return (dispatch, getState) => {

        const challenges = getState().game.gamesPcChallenges;

        dispatch(setLoadingNext(true));

        GETData(url, "GET", '', true)
            .then(response => {
                if (response !== null) {
                    dispatch(setGamesPcChallenges([...challenges, ...response.results]));
                    dispatch(setGamesPcNextPage(response.next))
                }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoadingNext(false)));
    }
}

export const gamesPcChoseChallengeAsync = (challenge, index, survey) => {
    return (dispatch, getState) => {

        const challenges = getState().game.gamesPcChallenges;

        const data = {
            id_user_id: getState().login.user.id_user,
            id_challenge_id: challenge.id_challenge,
            initial_timestamp: challenge.initial_timestamp,
            final_timestamp: challenge.final_timestamp,
            final_values: challenge.final_values,
            format_values: challenge.format_values,
            won: null,
            survey: survey
        }

        if (index !== null)
            data.oldId = challenges[index].id_user_challenge;

        const newIndex = challenges
            .findIndex(challenge => challenge.id_challenge === data.id_challenge_id);

        challenges[newIndex] = { ...challenges[newIndex], state: 6 }

        dispatch(setGamesPcChallenges([...challenges]));

        SETData(`power-challenge/chose-challenge/`, "POST", data, game_url)
            .then(response => {
                if (response !== null) {                   

                    if (index !== null) {
                        challenges[index] = { ...challenges[index], state: 0, progress: null }
                    }

                    challenges[newIndex] = { ...challenges[newIndex], ...response }

                    dispatch(setGamesPcChallenges([...challenges]));

                    dispatch(loadNewAchievementsAsync());
                } else {                    
                    throw 'Empty'                    
                }
            })
            .catch(response => {
                console.error(response)
                challenges[newIndex] = { ...challenges[newIndex], state: 0 }
            })
            .finally(() => dispatch(setGamesPcChallenges([...challenges])));

    }
}

export const gamesPcRefreshChallengeAsync = (payload) => {
    return (dispatch, getState) => {

        const challenges = getState().game.gamesPcChallenges;

        const index = challenges
            .findIndex(challenge => challenge.id_user_challenge === payload);

        GETData(`power-challenge/chose-challenge/?pk=${payload}`, "GET", game_url, true)
            .then(response => {
                if (response !== null) {                    
                    challenges[index] = { ...challenges[index], ...response }
                    dispatch(setGamesPcChallenges([...challenges]));
                }
            })
            .catch(response => console.error(response));

    }
}
