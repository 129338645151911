import React from 'react';

const GraphChallenge = ({ plot_type, unit, plot_labels, final_values }) => {

    let graph = null;

    switch (plot_type) {
        case 1:
            graph = <div className='row mt-2'>
                <div className='col-12 row-center align-items-center'>
                    <div className='col-6 column-end' style={{height: 180}}>
                        <p className='text-center txt-semibold txt-8 txt-white pb-1'>{Number(final_values[0].toFixed(3))} {unit}</p>
                        <div className='bg-green mx-1' style={{ height: `${(final_values[0] < final_values[1] ? final_values[0] / final_values[1] : 1) * 100}%`, borderRadius: 5}}/>
                        <p className='text-center txt-semibold txt-12 txt-white'>{plot_labels[0]}</p>
                    </div>
                    <div className='col-6 column-end' style={{height: 180}}>
                        <p className='text-center txt-semibold txt-8 txt-white pb-1'>{Number(final_values[1].toFixed(3))} {unit}</p>
                        <div className='bg-blue mx-1' style={{ height: `${(final_values[0] > final_values[1] ? final_values[1] / final_values[0] : 1) * 100}%`, borderRadius: 5 }} />
                        <p className='text-center txt-semibold txt-12 txt-white'>{plot_labels[1]}</p>
                    </div>
                </div>
            </div>

            break;
        default:
            break;
    }

    return graph;

}

export default GraphChallenge