import React, { useState } from 'react'

import { Tooltip, Whisper } from 'rsuite';

import { bounceIn, zoomOut } from 'react-animations';

import Radium, { StyleRoot } from 'radium';

const styles = {
    bounceIn: {
        animation: 'x 0.3s',
        animationName: Radium.keyframes(bounceIn, 'bounceIn')
    },
    zoomOut: {
        animation: 'x 0.3s',
        animationName: Radium.keyframes(zoomOut, 'zoomOut')
    }
}

const Location = (props) => {

    const [isShown, setVisualization] = useState(true);

    return (
        <StyleRoot>
            <Whisper
                delay={1000}
                placement="bottom"
                trigger="hover"
                speaker={<Tooltip>{`${props.project.acronym} - ${props.project.name}`}</Tooltip>}>
                <div className={`home-location position-relative row-center align-items-center overflow-hidden border-0 rounded-circle p-2 mr-2 c-pointer ${props.project.image === null ? "bg-blue" : null}`}
                    style={isShown ? styles.bounceIn : styles.zoomOut}
                    onClick={() => {
                        setVisualization(false);
                        setTimeout(() => { props.handleDeleteLocation(props.project) }, 250);
                    }}>
                    {props.project.image === null ?
                        <i className="fas fa-building txt-12 txt-dark-blue3"></i> :
                        <img className="home-location-img position-absolute w-100 h-100" src={`${process.env.REACT_APP_IMAGES_URL}/locations/photos/${props.project.id_project}/50x50/${props.project.image}`} alt="location" />
                    }
                </div>
            </Whisper>
        </StyleRoot>
    );
}

export default Location

