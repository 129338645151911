import * as USERS from '../creators/Users';
import * as loginActions from '../../store/actions/Login';


import { GETData, SETData } from '../../services/WebServices';

import { Alert } from 'rsuite';

export const setData = payload => {
    return {
        type: USERS.SET_USERS_DATA,
        payload
    }
}

export const setAvailableData = payload => {
    return {
        type: USERS.SET_USERS_AVAILABLE_DATA,
        payload
    }
}

export const setActiveData = payload => {
    return {
        type: USERS.SET_USERS_ACTIVE_DATA,
        payload
    }
}

export const setLoading = payload => {
    return {
        type: USERS.SET_USERS_LOADING,
        payload
    }
}

export const setUserByProject = payload => {
    return {
        type: USERS.SET_USERS_BY_PROJECT,
        payload
    }
}

export const loadUsersAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        dispatch(setLoading(true));

        GETData(`user/access/${userId}`, "GET")
            .then(response => {
                if (response !== null) {
                    dispatch(setData(response));
                    dispatch(setAvailableData(response));
                }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoading(false)));

    }
}

export const createUserAsync = (payload) => {
    return (dispatch, getState) => {

        let users = getState().users.totalData;
        let language = getState().shared.language;

        const newUser = {
            type_code: getState().login.user.type_code,
            name: payload.name,
            lastname: payload.lastname,
            id_document: payload.id_document,
            email: payload.email,
            phone: payload.phone,
            address: payload.address,
            status: true,
            id_city: payload.city,
            locations: payload.locations.toString(),
        };

        GETData(`user/access/validate/${newUser.id_document}/${newUser.email}/${newUser.phone}`, "GET")
            .then(response => {
                if (response == null) {
                    SETData("user/access/create", "POST", newUser)
                        .then(({ userId }) => {
                            newUser.id_user = userId;
                            dispatch(setData([newUser, ...users]))
                        })
                        .catch(response => {
                            console.error(response)
                            Alert.error(language.alert_userAccess_error_create);
                        });
                }
                else {
                    Alert.error(language.alert_userAccess_user_exist);
                }
            })
            .catch(response => {
                console.error(response)
                Alert.error(language.alert_userAccess_error_create);
            });
    }
}

export const updateUserAsync = (payload) => {
    return (dispatch, getState) => {

        let users = getState().users.totalData;
        let language = getState().shared.language;
        let locations = getState().home.locations;

        const locations_unselect = locations
            .filter(location => !payload.locations.includes(location.id_project))
            .map(l => l.id_project);

        const newUserInfo = {
            id_user: payload.id_user,
            name: payload.name,
            lastname: payload.lastname,
            id_document: payload.id_document,
            email: payload.email,
            phone: payload.phone,
            address: payload.address,
            id_city: payload.city,
            status: true,
            locations: payload.locations.toString(),
            locations_unselect: locations_unselect.toString()
        };

        const index = users
            .findIndex(user => user.id_user === payload.id_user);

        users[index] = newUserInfo;

        SETData("user/access/update", "PUT", newUserInfo)
            .then(() => {
                dispatch(setData([...users]));
                Alert.success(language.alert_userAccess_success_update);
            })
            .catch(response => {
                console.error(response)
                Alert.error(language.alert_userAccess_error_update);
            });

    }
}

export const updateUserStatusAsync = (payload) => {
    return (dispatch, getState) => {

        let users = getState().users.totalData;
        let language = getState().shared.language;

        const index = users
            .findIndex(user => user.id_user === payload.id_user);

        users[index].status = !users[index].status;

        SETData("user/access/update/status", "PUT", users[index])
            .then(() => {
                dispatch(setData([...users]));
            })
            .catch(response => {
                console.error(response)
                Alert.error(language.alert_userAccess_error_update_status);
            });

    }
}

export const generateUserApiTokenAsync = (payload) => {
    return (dispatch, getState) => {

        let user = getState().login.user;
        let language = getState().shared.language;

        user = { ...user, credential: "JNKDind0348NJF38HSj739hn93h87B37IOJN0hB73B8h3uUJNh8"}

        SETData("token/create", "POST", user, "https://api.neu.com.co/")
            .then((response) => {
                if (response != null) { dispatch(loginActions.setUser({ ...user, api_token: response.token}))}
            })
            .catch(response => {
                console.error(response)
                Alert.error(language.alert_generate_user_api_token);
            });

    }
}

export const loadUserByProject= (payload) => {
    return (dispatch) => {       

        GETData(`user/user-name/${payload}`, "GET")
            .then(response => {
                if (response !== null) {
                    dispatch(setUserByProject(response));                    
                }
            })
            .catch(response => console.error(response))
    }
}