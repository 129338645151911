import React, {useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as loginActions from '../../store/actions/Login';
import * as sharedActions from '../../store/actions/Shared';
import * as usersActions from '../../store/actions/Users';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { Loader, Drawer, Input, InputGroup, Alert, SelectPicker, InputNumber} from 'rsuite';

import { Transition } from 'react-transition-group';

import { SETFile } from '../../services/WebServices';

import { getAnalytics, logEvent } from "firebase/analytics";

import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const isValidateEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const isValidUser = user => {

    if (user.name === null || user.name === "") {
        return false;
    }

    if (user.lastname === null || user.lastname === "") {
        return false;
    }

    if (!isValidateEmail(user.email)) {
        return false;
    }

    if (user.phone === null || user.phone === "") {
        return false;
    }

    if (user.address === null) {
        return false;
    }

    return true;

}

const showError = (user, props) => {

    if (user.name === null || user.name === "") {
        Alert.error(props.language.alert_user_popup_name);
        return;
    }

    if (user.lastname === null || user.lastname === "") {
        Alert.error(props.language.alert_user_popup_lastname);
        return;
    }

    if (!isValidateEmail(user.email)) {
        Alert.error(props.language.alert_user_popup_email);
        return;
    }

    if (user.phone === null || user.phone === "") {
        Alert.error(props.language.alert_user_popup_phone);
        return;
    }

    if (user.address === null || user.address === "") {
        Alert.error(props.language.alert_user_popup_address);
        return;
    }

}

const setUserImage = (user, setUser, setIsLoading, e) => {

    const file = e.target.files[0];

    const data = new FormData();

    const metadata = {
        id: user.id_user,
        folder: "profile-photos"
    }

    data.append("file", file);
    data.append("metadata", JSON.stringify(metadata));

    setIsLoading(true);

    SETFile("user/profile", "PUT", data)
        .then(result => {
            setIsLoading(false);
            setUser({ ...user, image: result.name });
        });

}

const User = (props) => {

    let type;

    let _user = props.user;

    let languages = [
        {value: 1, label: props.language.language_notifications_spanish},
        {value: 2, label: props.language.language_notifications_english}
    ];

    let property_types = [
        {
            value: 1,
            label: props.language.option_user_property_type_aparment
        },
        {
            value: 2,
            label: props.language.option_user_property_type_house
        },
        {
            value: 3,
            label: props.language.option_user_property_type_country_house
        },
        {
            value: 4,
            label: props.language.option_user_property_type_farm
        }
    ];

    switch (props.user.type) {
        case "PROSUMER":
            type = props.language.title_shared_user_type_prosumer;
            break;
        case "CONSUMER":
            type = props.language.title_shared_user_type_consumer;
            break;
        case "GENERATOR":
            type = props.language.title_shared_user_type_generator;
            break;
        default:
            break;
    }

    const [user, setUser] = useState({});

    const [showInfo, setShowInfo] = useState(false);
    const [isEnable, setEnable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [showMainInfo, setShowMainInfo] = useState(false);
    const [showHealthInfo, setShowHealthInfo] = useState(false);
    const [showHomeInfo, setShowHomeInfo] = useState(false);
    const [showVehicleInfo, setShowVehicleInfo] = useState(false);

    useEffect(() => {
        setEnable(isValidUser(user));
        setProgress(Object.values(user).filter(x => x !== undefined && x!==null && x!=='').length / Object.keys(user).length * 100);
    }, [setEnable, setProgress, user]);

    logEvent(analytics, "user", {
        id_user: _user.id_user,
        name: `${_user.name} ${_user.lastname}`
    });

    useEffect(() =>  {

        const initUser = {
            id_user: _user.id_user,
            id_document: _user.id_document,
            name: _user.name,
            lastname: _user.lastname,
            email: _user.email,
            phone: _user.phone,
            phone2: _user.phone2,
            address: _user.address,
            address2: _user.address2,
            id_language: _user.id_language,
            height: _user.height,
            weight: _user.weight,
            eps: _user.eps,
            insurance: _user.insurance,
            property_type: _user.property_type,
            own_home: _user.own_home,
            house_people: _user.house_people,
            house_area: _user.house_area,
            floors: _user.floors,
            floor: _user.floor,
            solar_system: _user.solar_system,
            own_vehicle: _user.own_vehicle,
            armored_vehicle: _user.armored_vehicle,
            ev: _user.ev
        }

        setUser(initUser);

    }, [_user]);

    const PickerBool = ({attribute_name}) => {
    
        return(
            <div className="row-start align-items-center">
                <div className={`px-3 py-2 bg-${user[attribute_name] === true ? "blue" : "clear-gray"} rounded-10 c-pointer`}
                    onClick={() => {
                        user[attribute_name] = true;
                        setUser({...user});
                    }}>
                    <div className={`txt-semibold txt-${user[attribute_name] === true ? "white" : "light-blue"} txt-10 c-pointer`}>{props.language.text_shared_yes}</div>
                </div>
                <div className={`mx-2 px-3 py-2 bg-${user[attribute_name] === false ? "blue" : "clear-gray"} rounded-10 c-pointer`}
                        onClick={() => {
                            user[attribute_name] = false;
                            setUser({...user});
                        }}>
                    <div className={`txt-semibold txt-${user[attribute_name] === false ? "white" : "light-blue"} txt-10 c-pointer`}>{props.language.text_shared_no}</div>
                </div>
            </div>
        )
    }

    const handleChangeNumber = (action, attribute_name, max = 100) => {

        if (typeof(user[attribute_name]) !== 'number'){
            user[attribute_name] = 0;
            setUser({ ...user });   
        }

        if (action){

            if (user[attribute_name] < max) {
                user[attribute_name] = user[attribute_name] + 1;
                setUser({ ...user });
            }

        } else {

            if (user[attribute_name] > 0) {
                user[attribute_name] = parseInt(user[attribute_name]) - 1;
                setUser({ ...user });
            }

        }
    }

    return (
        <div>
            <Drawer
                size="xs"
                show={showInfo}
                onHide={() => setShowInfo(false)}>
                <Drawer.Header>
                    <div className="row-center align-items-center h-100 w-100">
                        <div className="txt-16 txt-bold txt-dark-blue mx-4">{type}</div>
                    </div>
                </Drawer.Header>
                <Drawer.Body>
                    <div className="p-4">
                        <div className="column-center align-items-center w-100">
                            <CircularProgressbarWithChildren
                                value={progress}
                                strokeWidth={5}
                                styles={buildStyles({
                                    trailColor: '#F1F1F1',
                                    pathColor: '#A1C52A'
                                })} >
                                <div className="row-center align-items-center">
                                    <div id="shared-profile-image" className={`position-relative overflow-hidden row-center align-items-center border-0 rounded-circle ${props.user.image === null ? "bg-blue" : null}`}>
                                        {isLoading ? <div className="black-screen position-absolute row-center align-items-center w-100 h-100 zindex-1"><Loader inverse /></div> : null}
                                        {props.user.image === null ?
                                            <i className="fas fa-user txt-30 txt-dark-blue3"></i> :
                                            <img className="home-location-img position-absolute w-100 h-100" src={`${process.env.REACT_APP_IMAGES_URL}/profile-photos/${props.user.id_user}/150x150/${props.user.image}`} alt="user-profile" />
                                        }
                                    </div>
                                    <div id="user-profile-progress" className="position-absolute bg-green rounded-pill px-3 py-1">
                                        <div className="txt-semibold txt-12 txt-white">{progress.toFixed(0)}%</div>
                                    </div>
                                    <label id="shared-profile-image-edit" htmlFor="user-img" className="position-absolute row-center align-items-center bg-clear-gray right-0 bottom-0 zindex-1 rounded-circle c-pointer">
                                        <i className="fas fa-pen txt-10 txt-light-blue"></i>
                                    </label>
                                    <input type="file" id="user-img" name="user-img" accept="image/*" className="d-none" onChange={e => setUserImage(props.user, props.setUser, setIsLoading, e)}></input>
                                </div>
                            </CircularProgressbarWithChildren>
                            <div className="txt-20 txt-bold txt-dark-blue mt-5 mb-4">{props.language.title_shared_user_salutation} {user.name}</div>
                            <div className="row-start align-items-center txt-semibold txt-10">
                                <div className="txt-light-blue text-left text-wrap">{props.language.text_user_info_description}</div>
                                <p className="txt-green ml-1">NEUgets</p>
                            </div>
                            <div className="txt-light-blue text-left text-wrap txt-semibold txt-10">{props.language.text_user_info_description2}</div>
                        </div>
                        <div className="col-12 p-0 row-between align-items-center c-pointer" onClick={() => setShowMainInfo(!showMainInfo)}>
                            <div className="txt-16 txt-bold txt-dark-blue my-4 mr-2 c-pointer">{props.language.title_shared_user_info}</div>
                            <i className={`fas fa-${showMainInfo ? "caret-down" : "caret-right"} txt-14 txt-dark-blue`}></i>
                        </div>
                        <Transition
                            in={showMainInfo}
                            timeout={150}>
                            {state => (
                                <div className={`user-info-section user-info-section-${state} overflow-hidden`}>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_shared_user_document}</div>
                                        <InputGroup
                                            inside >
                                            <InputGroup.Addon>
                                                <i className="fas fa-id-card txt-10 txt-light-blue"></i>
                                            </InputGroup.Addon>
                                            <Input disabled value={user.id_document ?? ""} />
                                        </InputGroup>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_shared_user_name}</div>
                                        <InputGroup
                                            inside >
                                            <InputGroup.Addon>
                                                <i className="fas fa-align-justify txt-10 txt-light-blue"></i>
                                            </InputGroup.Addon>
                                            <Input
                                                value={user.name ?? ""}
                                                placeholder={`${props.language.placeholder_shared_user_name_example}`}
                                                onChange={value => setUser({ ...user, name: value })} />
                                        </InputGroup>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_shared_user_lastname}</div>
                                        <InputGroup
                                            inside >
                                            <InputGroup.Addon>
                                                <i className="fas fa-align-justify txt-10 txt-light-blue"></i>
                                            </InputGroup.Addon>
                                            <Input
                                                value={user.lastname ?? ""}
                                                placeholder={`${props.language.placeholder_shared_user_lastname_example}`}
                                                onChange={value => setUser({ ...user, lastname: value })} />
                                        </InputGroup>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_shared_user_email}</div>
                                        <InputGroup
                                            inside >
                                            <InputGroup.Addon>
                                                <i className="fas fa-envelope txt-10 txt-light-blue"></i>
                                            </InputGroup.Addon>
                                            <Input
                                                value={user.email ?? ""}
                                                placeholder={`${props.language.placeholder_shared_user_email_example}`}
                                                onChange={value => setUser({ ...user, email: value })} />
                                        </InputGroup>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_shared_user_phone}</div>
                                        <InputGroup
                                            inside >
                                            <InputGroup.Addon>
                                                <i className="fas fa-mobile txt-10 txt-light-blue"></i>
                                            </InputGroup.Addon>
                                            <Input
                                                value={user.phone ?? ""}
                                                type={"number"}
                                                placeholder={`${props.language.placeholder_shared_user_phone_example}`}
                                                onChange={value => setUser({ ...user, phone: value })} />
                                        </InputGroup>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_shared_user_phone_two}</div>
                                        <InputGroup
                                            inside >
                                            <InputGroup.Addon>
                                                <i className="fas fa-mobile txt-10 txt-light-blue"></i>
                                            </InputGroup.Addon>
                                            <Input
                                                value={user.phone2 ?? ""}
                                                type={"number"}
                                                placeholder={`${props.language.placeholder_shared_user_phone_two_example}`}
                                                onChange={value => setUser({ ...user, phone2: value })} />
                                        </InputGroup>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_shared_user_address}</div>
                                        <InputGroup
                                            inside >
                                            <InputGroup.Addon>
                                                <i className="fas fa-map-marker-alt txt-10 txt-light-blue"></i>
                                            </InputGroup.Addon>
                                            <Input
                                                value={user.address ?? ""}
                                                placeholder={`${props.language.placeholder_shared_user_address_example}`}
                                                onChange={value => setUser({ ...user, address: value })} />
                                        </InputGroup>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_shared_user_address_two}</div>
                                        <InputGroup
                                            inside >
                                            <InputGroup.Addon>
                                                <i className="fas fa-map-marker-alt txt-10 txt-light-blue"></i>
                                            </InputGroup.Addon>
                                            <Input
                                                value={user.address2 ?? ""}
                                                placeholder={`${props.language.placeholder_shared_user_address_two_example}`}
                                                onChange={value => setUser({ ...user, address2: value })} />
                                        </InputGroup>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_language_notifications}</div>
                                        <SelectPicker
                                            className="w-100"
                                            data={languages}
                                            size="lg"
                                            appearance="default"
                                            searchable={false}
                                            cleanable={false}
                                            placeholder={languages.filter(value => value.value === user.id_language)[0].label}
                                            onSelect={(value) => { setUser({ ...user, id_language: value }) }}/>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_api_token}</div>
                                        <InputGroup>
                                            <div className="h-auto row-center align-items-center input-addon">
                                                <i className="fas fa-key txt-10 txt-light-blue"></i>
                                            </div>
                                            <Input value={props.user.api_token ?? ""}
                                                placeholder={`${props.language.placeholder_user_api_token}`}                                />
                                            <InputGroup.Button onClick={() => props.generateUserApiTokenAsync()}>
                                                <div className="txt-semi-bold txt-10 txt-light-blue">{props.language.title_user_btn_generate_api_token}</div>
                                            </InputGroup.Button>
                                        </InputGroup>
                                    </div>
                                </div>
                            )}
                        </Transition>
                        <div className="col-12 p-0 row-between align-items-center c-pointer" onClick={() => setShowHealthInfo(!showHealthInfo)}>
                            <div className="txt-16 txt-bold txt-dark-blue my-4 mr-2 c-pointer">{props.language.title_user_info_section_health}</div>
                            <i className={`fas fa-${showHealthInfo ? "caret-down" : "caret-right"} txt-14 txt-dark-blue`}></i>
                        </div>
                        <Transition
                            in={showHealthInfo}
                            timeout={150}>
                            {state => (
                                <div className={`user-info-section user-info-section-${state} overflow-hidden`}>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_height.split(" ")[0]}{props.user.hide_invoices?" (ft)":" (m)"}</div>
                                        <InputGroup
                                            inside >
                                            <InputGroup.Addon>
                                                <i className="fas fa-arrows-alt-v txt-10 txt-light-blue"></i>
                                            </InputGroup.Addon>
                                            <Input
                                                value={user.height ?? ""}
                                                placeholder={`${props.language.placeholder_user_height}`}
                                                onChange={value => setUser({ ...user, height: value })} />
                                        </InputGroup>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_weight.split(" ")[0]}{props.user.hide_invoices?" (lb)":" (kg)"}</div>
                                        <InputGroup
                                            inside >
                                            <InputGroup.Addon>
                                                <i className="fas fa-weight txt-10 txt-light-blue"></i>
                                            </InputGroup.Addon>
                                            <Input
                                                value={user.weight ?? ""}
                                                placeholder={`${props.language.placeholder_user_weight}`}
                                                onChange={value => setUser({ ...user, weight: value })} />
                                        </InputGroup>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_eps}</div>
                                        <InputGroup
                                            inside >
                                            <InputGroup.Addon>
                                                <i className="fas fa-medkit txt-10 txt-light-blue"></i>
                                            </InputGroup.Addon>
                                            <Input
                                                value={user.eps ?? ""}
                                                placeholder={`${props.language.placeholder_user_eps}`}
                                                onChange={value => setUser({ ...user, eps: value })} />
                                        </InputGroup>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_insurance}</div>
                                        <PickerBool attribute_name='insurance'/>
                                    </div>
                                </div>
                            )}
                        </Transition>
                        <div className="col-12 p-0 row-between align-items-center c-pointer" onClick={() => setShowHomeInfo(!showHomeInfo)}>
                            <div className="txt-16 txt-bold txt-dark-blue my-4 mr-2 c-pointer">{props.language.title_user_info_section_home}</div>
                            <i className={`fas fa-${showHomeInfo ? "caret-down" : "caret-right"} txt-14 txt-dark-blue`}></i>
                        </div>
                        <Transition
                            in={showHomeInfo}
                            timeout={150}>
                            {state => (
                                <div className={`user-info-section user-info-section-${state} overflow-hidden`}>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_property_type}</div>
                                        <SelectPicker
                                            value={user.property_type}
                                            className="w-100"
                                            data={property_types}
                                            size="lg"
                                            appearance="default"
                                            searchable={false}
                                            cleanable={false}
                                            placeholder={props.language.placeholder_user_property_type}
                                            onSelect={(value) => { setUser({ ...user, property_type: value }) }}/>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_own_home}</div>
                                        <PickerBool attribute_name='own_home'/>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_house_area}</div>
                                        <InputGroup
                                            inside >
                                            <InputGroup.Addon>
                                                <i className="far fa-square txt-10 txt-light-blue"></i>
                                            </InputGroup.Addon>
                                            <Input
                                                value={user.house_area ?? ""}
                                                placeholder={`${props.language.placeholder_user_house_area}`}
                                                onChange={value => setUser({ ...user, house_area: value })} />
                                        </InputGroup>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_house_people}</div>
                                        <div className='col-4 p-0'>
                                            <InputGroup>
                                                <InputGroup.Button onClick={() => handleChangeNumber(0, 'house_people')}>-</InputGroup.Button>
                                                <InputNumber
                                                    className='input-number-user'
                                                    size="xs"
                                                    scrollable
                                                    min={0}
                                                    max={100}
                                                    value={user.house_people}
                                                    onChange={value => setUser({ ...user, house_people: value })}
                                                />
                                                <InputGroup.Button onClick={() => handleChangeNumber(1, 'house_people')}>+</InputGroup.Button>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_floors}</div>
                                        <div className='col-4 p-0'>
                                            <InputGroup>
                                                <InputGroup.Button onClick={() => handleChangeNumber(0, 'floors')}>-</InputGroup.Button>
                                                <InputNumber
                                                    className='input-number-user'
                                                    size="xs"
                                                    scrollable
                                                    min={0}
                                                    max={100}
                                                    value={user.floors}
                                                    onChange={value => setUser({ ...user, floors: value })}
                                                />
                                                <InputGroup.Button onClick={() => handleChangeNumber(1, 'floors')}>+</InputGroup.Button>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_floor}</div>
                                        <div className='col-4 p-0'>
                                            <InputGroup>
                                                <InputGroup.Button onClick={() => handleChangeNumber(0, 'floor')}>-</InputGroup.Button>
                                                <InputNumber
                                                    className='input-number-user'
                                                    size="xs"
                                                    scrollable
                                                    min={0}
                                                    max={100}
                                                    value={user.floor}
                                                    onChange={value => setUser({ ...user, floor: value })}
                                                />
                                                <InputGroup.Button onClick={() => handleChangeNumber(1, 'floor')}>+</InputGroup.Button>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_solar_system}</div>
                                        <PickerBool attribute_name='solar_system'/>
                                    </div>
                                </div>
                            )}
                        </Transition>
                        <div className="col-12 p-0 row-between align-items-center c-pointer" onClick={() => setShowVehicleInfo(!showVehicleInfo)}>
                            <div className="txt-16 txt-bold txt-dark-blue my-4 mr-2 c-pointer">{props.language.title_user_info_section_vehicle}</div>
                            <i className={`fas fa-${showVehicleInfo ? "caret-down" : "caret-right"} txt-14 txt-dark-blue`}></i>
                        </div>
                        <Transition
                            in={showVehicleInfo}
                            timeout={150}>
                            {state => (
                                <div className={`user-info-section user-info-section-${state} overflow-hidden`}>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_own_vehicle}</div>
                                        <PickerBool attribute_name='own_vehicle'/>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_ev}</div>
                                        <PickerBool attribute_name='ev'/>
                                    </div>
                                    <div className="my-2">
                                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_user_armored_vehicle}</div>
                                        <PickerBool attribute_name='armored_vehicle'/>
                                    </div>
                                </div>
                            )}
                        </Transition>
                    </div>
                </Drawer.Body>
                <Drawer.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`row-center align-items-center txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 ml-4 bg-${isEnable ? "blue" : "light-blue"}`}
                            onClick={() => {

                                logEvent(analytics, "click_myaccount_save", {
                                    user: {
                                        id_user: props.user.id_user,
                                        name: `${props.user.name} ${props.user.lastname}`
                                    }
                                });
                                
                                if (!props.userLogin && isEnable) { props.updateInfoUserAsync({...user, progress: progress}) }
                                else if (!isEnable) { showError(user, props); }

                            }}>{!props.userLogin ? props.language.title_shared_user_save : <Loader inverse />}</button>
                        <button className="row-center align-items-center txt-12 txt-semibold txt-light-blue bg-white w-auto border-0 py-2 px-4"
                            onClick={() => props.logout()}>{props.language.title_shared_user_signout}</button>
                    </div>
                </Drawer.Footer>
            </Drawer>
            <button
                id="shared-user-info"
                className={`position-relative overflow-hidden row-center align-items-center border-0 rounded-circle p-2 ${props.user.image === null ? "bg-blue" : null}`}
                onClick={() => {
                    setShowInfo(true); 
                    amplitude.getInstance().logEvent('Profile Screen Viewed'); 
                }}>
                {props.user.image === null ?
                    <i className="fas fa-user txt-12 txt-dark-blue3"></i> :
                    <img className="home-location-img position-absolute w-100 h-100" src={`${process.env.REACT_APP_IMAGES_URL}/profile-photos/${props.user.id_user}/50x50/${props.user.image}`} alt="user-profile" />
                }
            </button>
        </div >
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    language: state.shared.language,
    userLogin: state.login.userLoading
})

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(sharedActions.logout()),
    updateInfoUserAsync: payload => dispatch(loginActions.updateInfoUserAsync(payload)),
    generateUserApiTokenAsync: () => dispatch(usersActions.generateUserApiTokenAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(User)
