import React from 'react';

const RankingBubble = (props) =>{
        return(
             <div className={`row-between align-items-center py-2 my-2 px-3 ${props.inverse ? 'bg-dark-blue4' : 'bg-dark-blue'} w-100 rounded-10`}> 
                <div id="games-profile-image-ranking" className={`overflow-hidden row-center align-items-center border-0 rounded-circle ${props.image !== null ? "bg-blue" : null}`}>
                    {props.image !== null ?
                        <i className="fas fa-user txt-20 txt-white"></i> :
                        <img className="home-location-img w-100 h-100" src={`${process.env.REACT_APP_IMAGES_URL}/profile-photos/${props.user}/150x150/${props.image}`} alt="user-profile" />
                    }
                </div>
                <div className='row-between align-items-center col p-0 overflow-hidden'>
                    <div className={`column-center align-items-start col-10 overflow-hidden txt-white`}>
                        <div className='txt-semibold mb-2 txt-10 text-nowrap'>{props.name}</div>
                        <div className='txt-regular txt-8 text-nowrap'>{props.points} xp</div>
                    </div>
                    <div className='row-end align-items-center col-2 p-0'>
                        <div>
                            {props.position <= 3 ? <i className= {`fas fa-solid fa-trophy txt-12 txt-${props.colorTrophy}`} ></i> : null}
                        </div>
                        <div className='ml-1 txt-semibold text-right txt-10 txt-white'><h5>{props.position}</h5></div> 
                    </div>
                </div>
            </div>
        )
    }

    export default RankingBubble;