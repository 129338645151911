import update from 'immutability-helper';
import { startOfMonth } from 'date-fns';

import * as creators from '../creators/Home';

import lang from '../../language/es';

const dateLocale = {
    sunday: lang.option_home_datepicker_sunday,
    monday: lang.option_home_datepicker_monday,
    tuesday: lang.option_home_datepicker_tuesday,
    wednesday: lang.option_home_datepicker_wednesday,
    thursday: lang.option_home_datepicker_thursday,
    friday: lang.option_home_datepicker_friday,
    saturday: lang.option_home_datepicker_saturday,
    ok: lang.option_home_datepicker_ok,
    today: lang.option_home_datepicker_today,
    yesterday: lang.option_home_datepicker_yesterday,
    last7Days: lang.option_home_datepicker_last_7_Days,
}

const initialState = {
    locations: [],
    activeLocations: [],
    addresses: [],
    activeAddresses: [],
    activeGraphs: [],
    graphData: [],
    invoices: [],
    graphs: [],
    graphsRelations: [],
    disabledGraphsExtra: ['ACTIVE_ENERGY_COST'],
    infoLocation: {},
    dateLocale: dateLocale,
    graphInterval: 1,
    graphType: 'linear',
    graphDateFrom: startOfMonth(new Date()),
    graphDateTo: new Date(),
    totalGeneration: null,
    totalValueGeneration: null,
    sustaintability: null,
    graphLoading: false,
    exportLoading: false,
    invoicesLoading: true,
    payLoading: false,
    sustainabilityLoading: true,
    cardsActive: false,
    infoLocationLoading: false,
    isCommercialized: true,
    savingIndexValues: [],
    planted_trees_total: null,
    co2_emission_total: null,
}

export const homeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_ACTIVE_LOCATION:
            return update(state, {
                activeLocations: { $push: [payload] },
                addresses: { $push: payload.addresses },
                activeAddresses: { $push: payload.addresses }
            });
        case creators.DELETE_ACTIVE_LOCATION:

            let index = state.activeLocations
                .findIndex(Location => Location.id_Location === payload.id_Location);

            let addressesIds = payload.addresses
                .map(address => address.id_address);

            let addresses = state.addresses
                .filter(address => !addressesIds.includes(address.id_address));

            let activeAddressesRemove = state.activeAddresses
                .filter(address => !addressesIds.includes(address.id_address));

            return update(state, {
                activeLocations: { $splice: [[index, 1]] },
                addresses: { $set: addresses },
                activeAddresses: { $set: activeAddressesRemove }
            });
        case creators.SET_ACTIVE_ADDRESSES:
            let activeAddresses = state.addresses
                .filter(address => payload.includes(address.id_address));
            return { ...state, activeAddresses: activeAddresses };
        case creators.SET_LOCATIONS:
            return { ...state, locations: payload };
        case creators.SET_ACTIVE_LOCATIONS_INFO:
            return { ...state, activeLocations: payload };
        case creators.SET_CARDS_ACTIVE:
            return { ...state, cardsActive: payload };
        case creators.SET_IS_COMMERCIALIZED:
            return { ...state, isCommercialized: payload };
        case creators.SET_GRAPH_OPTIONS:
            return {
                ...state,
                graphs: payload.options,
                graphsRelations: payload.relations
            };
        case creators.SET_GRAPH_DATES:

            let _disabledGraphs = state.disabledGraphsExtra;
            let _activeGraphs = state.activeGraphs;

            if (payload.graphDateTo.getMonth() === new Date().getMonth()) {

                _disabledGraphs = [...new Set([..._disabledGraphs, 'ACTIVE_ENERGY_COST'])];
                _activeGraphs = _activeGraphs.filter(graph => graph !== 'ACTIVE_ENERGY_COST');

            } else {

                _disabledGraphs = _disabledGraphs.filter(dg => dg !== 'ACTIVE_ENERGY_COST')

            }

            return {
                ...state,
                graphDateFrom: payload.graphDateFrom,
                graphDateTo: payload.graphDateTo,
                disabledGraphsExtra: _disabledGraphs,
                activeGraphs: _activeGraphs
            };

        case creators.SET_GRAPH_INTERVAL:
            return { ...state, graphInterval: payload };
        case creators.SET_GRAPH_ACTIVE:
            return { ...state, activeGraphs: payload };
        case creators.SET_GRAPH_DATA:
            return { ...state, graphData: payload };
        case creators.SET_GRAPH_TYPE:
            return { ...state, graphType: payload };
        case creators.SET_SUSTAINABILITY:
            return {
                ...state,
                totalGeneration: payload.total_generation,
                totalValueGeneration: payload.total_value_generation,
                sustaintability: payload.avg_generation_consumption,
                planted_trees_total: payload.planted_trees_total,
                co2_emission_total: payload.co2_emission_total
            };
        case creators.SET_INVOICES:
            return { ...state, invoices: payload };
        case creators.SET_INVOICES_LOADING:
            return { ...state, invoicesLoading: payload };
        case creators.SET_PAY_LOADING:
            return { ...state, payLoading: payload };
        case creators.SET_SUSTAINABILITY_LOADING:
            return { ...state, sustainabilityLoading: payload };
        case creators.SET_GRAPH_LOADING:
            return { ...state, graphLoading: payload };
        case creators.SET_EXPORT_LOADING:
            return { ...state, exportLoading: payload };
        case creators.SET_INFO_LOCATION:
            return { ...state, infoLocation: payload };
        case creators.SET_INFO_LOCATION_LOADING:
            return { ...state, infoLocationLoading: payload };
        case creators.SET_SAVING_INDEX_VALUES:
            return { ...state, savingIndexValues: payload };
        case creators.SET_DATE_LOCALE:
            return { ...state, dateLocale: payload };
        default:
            return state;
    }

}