
import * as neuplusActions from '../store/actions/NEUPlus';

import { GETData } from './NEUPlusServices';

import { connect } from 'react-redux';
import React from 'react';

const Period = () => {
    setInterval(async () => await GETData(), 2600);
    return <div></div>
}

const mapDispatchToProps = dispatch => ({
    updateDataGraphic: payload => dispatch(neuplusActions.updateDataGraphic(payload)),
    setDataGraphic: () => dispatch(neuplusActions.setDataGraphic()),
    lengthData: payload => dispatch(neuplusActions.lengthData(payload)),
    updateDate: payload => dispatch(neuplusActions.updateDate(payload)),
    setGraphLoading: payload => dispatch(neuplusActions.setGraphLoading(payload)),
    setDataFlag: payload => dispatch(neuplusActions.setDataFlag(payload)),
    rebootStates: () => dispatch(neuplusActions.rebootStates())

})

export default connect(null, mapDispatchToProps)(Period);