import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as sharedActions from '../../store/actions/Shared';

import Neugets from './Neugets';
import Share from './Share';
import Notifications from './Notifications';
import User from './User';
import Dashboard from '../games/dashboard/Dashboard';

import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import {Input, Radio, RadioGroup} from 'rsuite';
import logoNeu from '../../imgs/logo-neu-gray.svg'

const RateNumber = (props) => {

    let elements = Array.from({length: props.max}, (e, i)=> i);
    const [score, setScore] = useState(props.default);

    useEffect(() => {
        props.onChange(score);
    }, [score])

    return(
        <div className='w-100 row-center'>
            {elements.map(item => 
                <div className='col-md-auto px-1 column-between align-items-center'>
                    <i className={`${item + 1 <= score? "fas" : "far"} fa-circle txt-10 ${item + 1 <= 6 ? "txt-red" :  item + 1 >= 7 && item + 1 <= 8 ? "txt-yellow" : "txt-green2" }`} onClick={() => setScore(item + 1)}/>
                    <div className={`txt-10 text-center ${item + 1 <= 6 ? "txt-red" :  item + 1 >= 7 && item + 1 <= 8 ? "txt-yellow" : "txt-green2" } txt-regular`}>{item + 1}</div>
                </div>)}
        </div>
    )
}


const Options = (props) => {

    const PopUpScore = () => {
        const initValue = {
            score: 8,
            reason: '',
            reasonSpecific: ''
        }
    
        const [surveyValue, setSurveyValue] = useState(initValue);
        const [firstSend, setFirstSend] = useState(false);
        const [service, setService] = useState(0);
        
        const handleSend = () => {
            if(surveyValue.score === 7 && !firstSend) {
                props.setNpsSurveyAsync(surveyValue); 
                toast.dismiss()
            } else if (firstSend) {
                if(surveyValue.score <= 6) props.setNpsSurveyAsync({...surveyValue, service, good: false});
                if(surveyValue.score >= 8) props.setNpsSurveyAsync({...surveyValue, service, good: true});
                toast.dismiss()
            } else {
                setFirstSend(true)
            }
        }
        
        return (
            <div className='w-100 align-items-center column-center'>
                {!firstSend && (
                    <div className='w-100 align-items-center column-center'>
                        <div className='col-10 px-0 text-wrap text-center txt-12 txt-regular txt-dark-blue my-2'>{props.language.score_popup_invitation}</div>
                        <div className='col-12 px-0 text-wrap text-center txt-10 txt-bold txt-green'>{props.language.score_popup_button}</div>
                        <div className='col-12 px-0 text-wrap txt-regular text-center txt-green my-2' style={{fontSize: 9}}>{props.language.score_popup_explication}</div>
                        <div className='txt-8 txt-regular text-center txt-green'>{props.language.score_popup_invitation2}</div>
                        <div className='w-100 align-items-center column-center mt-3'>
                            <RateNumber max={10} default={8}
                                        onChange={value => setSurveyValue({...surveyValue, score: value})}/>
                        </div>
                        <div className='w-100 h-100 my-2'>
                            <Input
                                value={surveyValue.reason}
                                as="textarea"
                                rows={3}
                                placeholder={props.language.score_popup_placeholder}
                                onChange={value => setSurveyValue({...surveyValue, reason: value})}/>
                        </div>
                    </div>
                )}
                {firstSend && (
                    <div className='w-100 align-items-center column-center'>
                        <div className='col-10 px-0 text-wrap text-center txt-12 txt-regular txt-green my-2'>{props.language.score_popup_thanks}</div>
                        <div className='col-12 px-0 text-wrap text-center txt-12 txt-bold txt-green'>{props.language.score_popup_subtitle}</div>
                        <div className='col-12 px-0 text-wrap txt-regular text-center txt-dark-blue my-2' style={{fontSize: 9}}>{surveyValue.score <= 6 ? props.language.score_popup_paragraph_bad : surveyValue.score >= 8 && props.language.score_popup_paragraph_good }</div>
                        <div className='col-12 column-start'>
                            <RadioGroup name="radioList" value={service} onChange={setService}>
                                <Radio value={0}>{props.language.score_popup_service1}</Radio>
                                <Radio value={1}>{props.language.score_popup_service2}</Radio>
                                <Radio value={2}>{props.language.score_popup_service3}</Radio>
                                <Radio value={3}>{props.language.score_popup_service4}</Radio>
                                <Radio value={4}>{props.language.score_popup_other}</Radio>
                            </RadioGroup>
                        </div>
                        {service === 4 && (<div className='w-100 h-100 my-2'>
                            <Input
                                value={surveyValue.reasonSpecific}
                                as="textarea"
                                rows={3}
                                placeholder={props.language.score_popup_placeholder}
                                onChange={value => setSurveyValue({...surveyValue, reasonSpecific: value})}/>
                        </div>)}
                    </div>
                )}
                <button className="row-start my-2 align-items-center txt-10 txt-semibold txt-white w-auto rounded-pill px-4 py-2 bg-green"
                    onClick={handleSend}>
                    {props.language.score_popup_button_send}
                </button>
                <div className='txt-12 text-center my-2'>{props.language.score_popup_gratitude}</div>
                <img src={logoNeu} width={70} alt="logo" className='mb-2'/>
            </div>
        )
    }


    useEffect(() => props.loadUserNpsAsync(), [props.loadUserNpsAsync]);

    useEffect(() => { 
        if(props.showNpsSurvey && props.language && props.language.score_popup_invitation && props.user?.type_code !== 2){
            toast(<PopUpScore/>, {delay: 10000}, {toastId: 'popup'});
        }
    }, [props.showNpsSurvey, props.language])

    return (
        <div className="d-flex flex-md-row flex-row-reverse justify-content-md-end justify-content-between align-items-center">
            <div className="row-start align-items-center">
                <Share />
                <Notifications />
                {/* <Dashboard /> */}
                {/* <Neugets /> */}
                                {/* <ToastContainer 
                    position='bottom-right' 
                    autoClose={false} 
                    style={{maxWidth: 420}} 
                    pauseOnFocusLoss={false} 
                    closeOnClick={false}/> */}
            </div>
            <div className="row-start align-items-center">
                <User />
                <i className="d-block d-md-none fas fa-bars txt-18 txt-light-blue ml-3 c-pointer pt-1" onClick={() => props.setMenu(true)}></i>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    showNpsSurvey: state.shared.showNpsSurvey,
    user: state.login.user,
})

const mapDispatchToProps = dispatch => ({
    loadUserNpsAsync: () => dispatch(sharedActions.loadUserNpsAsync()),
    setNpsSurveyAsync: payload => dispatch(sharedActions.setNpsSurveyAsync(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Options)
