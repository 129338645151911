import React from 'react';
import Medal from './Medal';

const MedalBubble = ({points, name, description, icon, color, iconColor, won}) =>{

        return(
            <div className="position-ralative p-2 bg-dark-blue text-white w-100 rounded column-start align-items-center" style={{height:165, marginBottom:40}}>                                    
                {/*<div className="row-end w-100">
                    <div className='py-1 px-3 bg-blue rounded'>
                        <div className="txt-regular txt-8 txt-white text-center">{points} xp</div>
                    </div>
                </div>*/}
                <div className={`w-100 overflow-hidden txt-white mt-4`}>
                    <div className='txt-semibold my-2 txt-10 text-wrap'>{name}</div>
                    <div className='txt-regular txt-8 text-wrap'>{description}</div>
                </div>
                <div className='zindex-2 d-flex align-items-center position-absolute' style={{left:'30%', bottom:8}}>
                    <Medal won={won} icon={icon} iconSize={18} color={color} iconColor={iconColor}/>
                </div>                                                                 
            </div>  
        )
    }

    export default MedalBubble;