export const setDisbaledGraphsValues = (values, graphs, graphsRelations) => {

    let graphsDisabled = [];
    let _graphsRelations = [];

    let _graphs = graphs
        .sort((a, b) => a.id_graph - b.id_graph);

    values.forEach(value => {

        let graph = _graphs
            .filter(graph => graph.value === value);

        let array = graphsRelations
            .filter(relation => relation.id_graph === graph[0].id_graph)
            .sort((a, b) => a.id_graph_relation - b.id_graph_relation)
            .map(relation => relation.available);

        _graphsRelations.push(array);

    });

    _graphs.forEach((graph, i) => { if (!_graphsRelations.every((_value, j, array) => array[j][i])) { graphsDisabled.push(graph.value) } });

    return (graphsDisabled);

}
