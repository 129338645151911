import update from 'immutability-helper';
import * as creators from '../creators/NEUPlus';

import { format } from 'date-fns';

const initialState = {
    data: [],
    data_graphic: [],
    dataNoModification: [],
    run: true,
    zoom: 1,
    dataFlag: false,
    graphLoading: false,
    locations: [],
    activeLocations: [],
    devices: [],
    activeDevices: [],
    graphicType: 1,
    minValue: '0',
    dateCurrent: "",
    currentTime: 1,
    dataNull: false,
    appliances: [],
    timeStampTags: [],
    flagEventTag: false,
    enablePopUpTag: false,
    enableTags: false,
    appliancesInquiry: [],
    base_appliances: [],
    brands_appliances: [],
    totalAppliancesInquiry: 0,
    loadingAppliancesInquiry: false,
    flagInquiry: false,
    flagInquiryCreate: false,
    deviceTagEdit: 0,
    enableAllVariables: 0,
};


const neuPlusReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_LOCATIONS:
            return { ...state, locations: payload };
        case creators.SET_ACTIVE_LOCATION:
            return update(state, {
                graphLoading: { $set: true },
                activeLocations: { $push: [payload] },
                devices: { $push: payload.dissagregators },
                activeDevices: { $push: payload.dissagregators }
            });
        case creators.DELETE_ACTIVE_LOCATION:

            let index = state.activeLocations
                .findIndex(Location => Location.id_Location === payload.id_Location);

            let devicesIds = payload.dissagregators
                .map(device => device.id_device);

            let devices = state.devices
                .filter(device => !devicesIds.includes(device.id_device));

            let activeDevicesRemove = state.activeDevices
                .filter(device => !devicesIds.includes(device.id_device));

            return update(state, {
                activeLocations: { $splice: [[index, 1]] },
                devices: { $set: devices },
                activeDevices: { $set: activeDevicesRemove }
            });
        case creators.SET_ACTIVE_DEVICES:
            let activeDevices = state.devices
                .filter(device => payload.includes(device.id_device));
            return { ...state, activeDevices: activeDevices };
        case creators.SET_GRAPH_LOADING:
            return { ...state, graphLoading: payload };
        case creators.UPDATE_DATA:

            var graphicType;
            var dataPast;
            var minValue = '0';
            var currentData;
            var currentDataToGraphic;
            var currentTime = 1;
            var flagNegative = false;
            var enableAllVariables = 0;

            if (state.graphicType === 1) {
                graphicType = /real/g;
                try {
                    if (state.dataNoModification.length !== 0) {
                        dataPast = state.dataNoModification.replace(graphicType, "y");
                    }
                }
                catch (error) { console.log(error) };
            }
            if (state.graphicType === 2) {
                graphicType = /reactive/g;
                try {
                    if (state.dataNoModification.length !== 0) {
                        dataPast = state.dataNoModification.replace(graphicType, "y");
                    }
                }
                catch (error) { console.log(error) };
            }
            if (state.graphicType === 3){
                graphicType = /power_factor_1/g;
                try {
                    if (state.dataNoModification.length !== 0) {
                        dataPast = state.dataNoModification.replace(graphicType, "y");
                    }
                }
                catch (error) { console.log(error) };
            }
            if (state.graphicType === 4){
                graphicType = /rms_voltage_1/g;
                try {
                    if (state.dataNoModification.length !== 0) {
                        dataPast = state.dataNoModification.replace(graphicType, "y");
                    }
                }
                catch (error) { console.log(error) };
            }
            if (state.graphicType === 5){
                graphicType = /rms_current_1/g;
                try {
                    if (state.dataNoModification.length !== 0) {
                        dataPast = state.dataNoModification.replace(graphicType, "y");
                    }
                }
                catch (error) { console.log(error) };
            }
            if (state.graphicType === 6){
                graphicType = /power_factor_2/g;
                try {
                    if (state.dataNoModification.length !== 0) {
                        dataPast = state.dataNoModification.replace(graphicType, "y");
                    }
                }
                catch (error) { console.log(error) };
            }
            if (state.graphicType === 7){
                graphicType = /rms_voltage_2/g;
                try {
                    if (state.dataNoModification.length !== 0) {
                        dataPast = state.dataNoModification.replace(graphicType, "y");
                    }
                }
                catch (error) { console.log(error) };
            }
            if (state.graphicType === 8){
                graphicType = /rms_current_2/g;
                try {
                    if (state.dataNoModification.length !== 0) {
                        dataPast = state.dataNoModification.replace(graphicType, "y");
                    }
                }
                catch (error) { console.log(error) };
            }
            if (state.graphicType === 9){
                graphicType = /power_factor_3/g;
                try {
                    if (state.dataNoModification.length !== 0) {
                        dataPast = state.dataNoModification.replace(graphicType, "y");
                    }
                }
                catch (error) { console.log(error) };
            }
            if (state.graphicType === 10){
                graphicType = /rms_voltage_3/g;
                try {
                    if (state.dataNoModification.length !== 0) {
                        dataPast = state.dataNoModification.replace(graphicType, "y");
                    }
                }
                catch (error) { console.log(error) };
            }
            if (state.graphicType === 11){
                graphicType = /rms_current_3/g;
                try {
                    if (state.dataNoModification.length !== 0) {
                        dataPast = state.dataNoModification.replace(graphicType, "y");
                    }
                }
                catch (error) { console.log(error) };
            }

            try {

                var payloadObject = JSON.parse(payload);
                
                currentTime = Date.parse(payloadObject.slice(-1)[0].date + "T" + payloadObject.slice(-1)[0].x);
                
                currentData = [];

                var previousCurrentData = state.currentTime;
                
                payloadObject.forEach(element => {

                    var timeElement = Date.parse(element.date + "T" + element.x);
                    
                    if (timeElement > previousCurrentData) {
                        currentData.push(element);
                        previousCurrentData = timeElement;
                    }

                    if (element.real < 0 || element.reactive < 0) {
                        flagNegative = true;
                    }

                });

            }
            catch(error) { };

            if (currentData.length !== 0) {

                currentDataToGraphic = JSON.parse(JSON.stringify(currentData).replace(graphicType, 'y'));

                var indexNegative;

                if (state.graphicType === 1 && state.dataNoModification.length !== 0) {
                    indexNegative = JSON.parse(state.dataNoModification).findIndex(x => x.real < 0);
                    flagNegative = indexNegative !== -1 ? true : false;
                }

                if (state.graphicType === 2 && state.dataNoModification.length !== 0) {
                    indexNegative = JSON.parse(state.dataNoModification).findIndex(x => x.reactive < 0);
                    flagNegative = indexNegative !== -1 ? true : false;
                }

                if (state.graphicType === 3 && state.dataNoModification.length !== 0) {
                    indexNegative = JSON.parse(state.dataNoModification).findIndex(x => x.power_factor_1 < 0);
                    flagNegative = indexNegative !== -1 ? true : false;
                }

                if (state.graphicType === 4 && state.dataNoModification.length !== 0) {
                    indexNegative = JSON.parse(state.dataNoModification).findIndex(x => x.rms_voltage_1 < 0);
                    flagNegative = indexNegative !== -1 ? true : false;
                }

                if (state.graphicType === 5 && state.dataNoModification.length !== 0) {
                    indexNegative = JSON.parse(state.dataNoModification).findIndex(x => x.rms_current_1 < 0);
                    flagNegative = indexNegative !== -1 ? true : false;
                }

                if (state.graphicType === 6 && state.dataNoModification.length !== 0) {
                    indexNegative = JSON.parse(state.dataNoModification).findIndex(x => x.power_factor_2 < 0);
                    flagNegative = indexNegative !== -1 ? true : false;
                }

                if (state.graphicType === 7 && state.dataNoModification.length !== 0) {
                    indexNegative = JSON.parse(state.dataNoModification).findIndex(x => x.rms_voltage_2 < 0);
                    flagNegative = indexNegative !== -1 ? true : false;
                }

                if (state.graphicType === 8 && state.dataNoModification.length !== 0) {
                    indexNegative = JSON.parse(state.dataNoModification).findIndex(x => x.rms_current_2 < 0);
                    flagNegative = indexNegative !== -1 ? true : false;
                }

                if (state.graphicType === 9 && state.dataNoModification.length !== 0) {
                    indexNegative = JSON.parse(state.dataNoModification).findIndex(x => x.power_factor_3 < 0);
                    flagNegative = indexNegative !== -1 ? true : false;
                }

                if (state.graphicType === 10 && state.dataNoModification.length !== 0) {
                    indexNegative = JSON.parse(state.dataNoModification).findIndex(x => x.rms_voltage_3 < 0);
                    flagNegative = indexNegative !== -1 ? true : false;
                }

                if (state.graphicType === 11 && state.dataNoModification.length !== 0) {
                    indexNegative = JSON.parse(state.dataNoModification).findIndex(x => x.rms_current_3 < 0);
                    flagNegative = indexNegative !== -1 ? true : false;
                }

                enableAllVariables = currentData[0].hasOwnProperty('rms_voltage_1') ?  2 : 0; 
                enableAllVariables = currentData[0].hasOwnProperty('rms_voltage_3') ?  3 : enableAllVariables;
                minValue = flagNegative ? 'auto' : minValue;

                if (state.data === initialState.data) {
                    return {
                        ...state,
                        data: JSON.stringify(state.data.concat(currentDataToGraphic)),
                        dataNoModification: JSON.stringify(state.dataNoModification.concat(currentData)),
                        currentTime: currentTime,
                        graphLoading: false,
                        enableAllVariables: enableAllVariables
                    };
                }
                else {

                    return {
                        ...state,
                        data: JSON.stringify(JSON.parse(dataPast).concat(currentDataToGraphic)),
                        dataNoModification: JSON.stringify(JSON.parse(state.dataNoModification).concat(currentData)),
                        minValue: minValue,
                        currentTime: currentTime,
                        graphLoading: false,
                        enableAllVariables: enableAllVariables
                    };
                }
            }
            else {
                return state
            }
        case creators.RUN_GRAPHIC:
            return {
                ...state,
                run: true
            };
        case creators.PAUSE_GRAPHIC:
            return {
                ...state,
                run: false
            };
        case creators.SET_DATA_GRAPHIC:
            if (state.run) {
                return {
                    ...state,
                    data_graphic: state.data
                };
            }
            else {
                return {
                    ...state
                };
            };
        case creators.ZOOM_IN:
            if (state.zoom < 8) {
                return {
                    ...state,
                    zoom: state.zoom * 2
                };
            }
            else {
                return state;
            }
        case creators.ZOOM_OUT:
            if (state.zoom > 1) {
                return {
                    ...state,
                    zoom: state.zoom / 2
                };
            }
            else {
                return state;
            }

        case creators.LENGTH_DATA:
            if (payload === 'ALL') {
                var data = JSON.stringify(JSON.parse(state.data).slice(-2));
                return {
                    ...state,
                    data: data,
                    data_graphic: data,
                    dataNoModification: JSON.stringify(JSON.parse(state.dataNoModification).slice(-2))
                };
            }
            else {
                return {
                    ...state,
                    data: JSON.stringify(JSON.parse(state.data).slice(-payload)),
                    dataNoModification: JSON.stringify(JSON.parse(state.dataNoModification).slice(-payload))
                };
            }

        case creators.UPDATE_TYPE_GRAPHIC:
            if (payload - state.graphicType !== 0 && state.activeLocations.length !== 0) {
                return {
                    ...state,
                    graphicType: payload,
                    graphLoading: true
                }
            }
            else {
                return {
                    ...state,
                    graphicType: payload,
                }
            }

        case creators.UPDATE_CURRENT_DATE:
            return {
                ...state,
                currentDate: payload
            }

        case creators.SET_DATA_FLAG:
            return {
                ...state,
                dataFlag: payload
            }

        case creators.REBOOT_STATES:
            return {
                ...state,
                data: JSON.stringify([]),
                data_graphic: data,
                dataNoModification: JSON.stringify([]),
                dateCurrent: "",
                graphLoading: false,
                run: true,
                zoom: 1,
                currentTime: 1,
                flagEventTag: true,
                enablePopUpTag: false

            };

        case creators.SET_DATA_NULL:
            return {
                ...state,
                dataNull: payload
            }

        case creators.SET_APPLIANCES:
            return {
                ...state,
                appliances: payload
            }

        case creators.SET_TIMESTAMP_TAGS:
            var tagsTimeStampFormat = payload.map(tag => Date.parse(tag));
            tagsTimeStampFormat.sort(function (a, b) {
                if (a > b) {
                    return 1;
                }
                if (a < b) {
                    return -1;
                }
                return 0;
            });
            var tagsTimeFormat = tagsTimeStampFormat.map(tag => String(format(new Date(tag), "yyyy-MM-dd"))+"T"+String(format(new Date(tag), "HH:mm:ss")))

            return {
                ...state,
                timeStampTags: tagsTimeFormat
            }

        case creators.SET_FLAG_EVENT_TAG:
            return {
                ...state,
                flagEventTag: payload
            }

        case creators.SET_ENABLE_POPUP_TAG:
            return {
                ...state,
                enablePopUpTag: payload
            }

        case creators.SET_ENABLE_TAGS:
            return {
                ...state,
                enableTags: payload
            }

        case creators.DELETE_ACTIVE_DEVICES:
            return{
                    ...state,
                    devices: [],
                    activeDevices: []
            }
        
        case creators.SET_APPLIANCES_INQUIRY:
            return {
                ...state,
                appliancesInquiry: payload
            }

        case creators.SET_BASE_APPLIANCES:
            return {
                ...state,
                base_appliances: payload
            }
        
        case creators.SET_BRANDS_APPLIANCES:
            return {
                ...state,
                brands_appliances: payload
            }
        
        case creators.SET_TOTAL_APPLIANCES_INQUIRY:
            return {
                ...state,
                totalAppliancesInquiry: payload
            }

        case creators.SET_LOADING_APPLIANCES_INQUIRY:
            return {
                ...state,
                loadingAppliancesInquiry: payload
            }
        
        case creators.SET_FLAG_INQUIRY:
            return {
                ...state,
                flagInquiry: payload
            }
        
        case creators.SET_FLAG_INQUIRY_CREATE:
            return {
                ...state,
                flagInquiryCreate: payload
            }
        
        case creators.SET_DEVICE_TAG_EDIT:
            return {
                ...state,
                deviceTagEdit: payload
            }

        default:
            return state;

    }
};

export default neuPlusReducer;