import React, { useState } from 'react';
import { connect } from 'react-redux';

import * as gameActions from '../../../store/actions/Game';

import { Modal, Rate } from 'rsuite';

const Survey = (props) => {

    const [score, setScore] = useState(5);    

    return (
        <Modal show={props.visible} onHide={() => props.setVisible(false)}>           
            <div className='column-center align-items-center h-100 p-4 bg-black'>                
                <div className='align-items-center mx-2 p-3 bg-black w-100' style={{flexDirection:"column", justifyContent:"space-between"}}>
                    <p className='txt-white txt-Bold txt-18 txt-center mb-4'>{props.language.text_game_survey}</p>
                    <div className='my-4'>
                        <Rate size={"xs"} defaultValue={score} allowHalf={true} onChange={value => setScore(value)}/>
                    </div>
                    <div onClick={() => {
                        props.setGameScoreAsync({ idGame: props.idGame, score: score });
                        props.setVisible(false);
                    }}>
                        <div className='bg-blue align-items-center justify-content-center h-40 min-width-100 px-4' style={{borderRadius:800}}>
                            <p className='txt-white txt-center txt-Semi-Bold txt-14'>{props.language.text_game_survey_button_send}</p>
                        </div>
                    </div>
                </div>                
            </div>            
        </Modal>);
}

const mapStateToProps = (state, ownProps) => ({
    language: state.shared.language,
    setVisible: ownProps.setVisible,
    visible: ownProps.visible,
    idGame: ownProps.idGame
})

const mapDispatchToProps = dispatch => ({
    setGameScoreAsync: (payload) => dispatch(gameActions.setGameScoreAsync(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Survey)