export const SET_LOCATIONS = 'SET_NEU_PLUS_LOCATIONS';
export const SET_ACTIVE_LOCATION = 'SET_NEU_PLUS_ACTIVE_LOCATION';
export const DELETE_ACTIVE_LOCATION = 'DELETE_NEU_PLUS_ACTIVE_LOCATION';
export const SET_ACTIVE_DEVICES = 'SET_NEU_PLUS_ACTIVE_DEVICES';
export const SET_GRAPH_LOADING = 'SET_NEU_PLUS_GRAPH_LOADING';
export const UPDATE_DATA = 'UPDATE_DATA';
export const RUN_GRAPHIC = 'RUN_GRAPHIC';
export const PAUSE_GRAPHIC = 'PAUSE_GRAPHIC';
export const SET_DATA_GRAPHIC = 'SET_DATA_GRAPHIC';
export const ZOOM_IN = 'ZOOM_IN';
export const ZOOM_OUT = 'ZOOM_OUT';
export const LENGTH_DATA = 'LENGTH_DATA';
export const UPDATE_TYPE_GRAPHIC = 'UPDATE_TYPE_GRAPHIC';
export const UPDATE_CURRENT_DATE = 'UPDATE_CURRENT_DATE';
export const SET_DATA_FLAG = 'SET_DATA_FLAG';
export const REBOOT_STATES = 'REBOOT_STATES';
export const SET_DATA_NULL = 'SET_DATA_NULL';
export const SET_APPLIANCES = 'SET_APPLIANCES';
export const SET_TIMESTAMP_TAGS = 'SET_TIMESTAMP_TAGS';
export const SET_FLAG_EVENT_TAG = 'SET_FLAG_EVENT_TAG';
export const SET_ENABLE_POPUP_TAG = 'SET_ENABLE_POPUP_TAG';
export const SET_ENABLE_TAGS = 'SET_ENABLE_TAGS';
export const SET_SUCCESS_INSERT_TAG = 'SET_SUCCESS_INSERT_TAG';
export const SET_SUCCESS_INSERT_APPLIANCE = 'SET_SUCCESS_INSERT_APPLIANCE';
export const DELETE_ACTIVE_DEVICES = 'DELETE_ACTIVE_DEVICES';
export const SET_APPLIANCES_INQUIRY = 'SET_APPLIANCES_INQUIRY';
export const SET_BASE_APPLIANCES = 'SET_BASE_APPLIANCES';
export const SET_BRANDS_APPLIANCES = 'SET_BRANDS_APPLIANCES';
export const SET_TOTAL_APPLIANCES_INQUIRY = 'SET_TOTAL_APPLIANCES_INQUIRY';
export const SET_LOADING_APPLIANCES_INQUIRY = 'SET_LOADING_APPLIANCES_INQUIRY';
export const SET_FLAG_INQUIRY = 'SET_FLAG_INQUIRY';
export const SET_FLAG_INQUIRY_CREATE = 'SET_FLAG_INQUIRY_CREATE';
export const SET_DEVICE_TAG_EDIT = 'SET_DEVICE_TAG_EDIT';