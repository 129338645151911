import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as homeActions from '../../store/actions/Home';
import * as notificationsActions from '../../store/actions/Notifications';

import { Loader, Table, Input, InputGroup, Tooltip, Whisper, DateRangePicker, SelectPicker, CheckPicker } from 'rsuite';

import { Transition } from 'react-transition-group';

import Options from '../shared/Options';

import { getAnalytics, logEvent } from "firebase/analytics";

import amplitude from 'amplitude-js';

import { format } from 'date-fns';
import esLocale from "date-fns/locale/es";
import enLocale from 'date-fns/locale/en-US';

const analytics = getAnalytics();

const { Column, HeaderCell, Cell } = Table;
const { afterToday } = DateRangePicker;

const lengthMenu = [
    {
        value: 20,
        label: 20
    },
    {
        value: 50,
        label: 50
    },
    {
        value: 100,
        label: 100
    }
];

const ImageCell = ({ rowData, dataKey, ...props }) => {

    let icon;
    let color;

    switch (rowData[dataKey]) {
        case 2:
            icon = <i className="fas fa-exclamation-triangle txt-12 txt-yellow ml-3"></i>;
            color = "yellow";
            break;
        case 3:
            icon = <i className="fas fa-times-circle txt-12 txt-red ml-3"></i>;
            color = "red";
            break;
        case 4:
            icon = <i className="fas fa-check-circle txt-12 txt-green ml-3"></i>;
            color = "green";
            break;
        default:
            icon = <i className="fas fa-info-circle txt-12 txt-blue ml-3"></i>;
            color = "blue";
            break;
    }

    return (
        <Cell {...props} style={{ padding: 0 }}>
            <div className="position-absolute row-start align-items-center h-100">
                <div className={`bg-${color} h-100`} style={{ width: 5, marginBottom: 3 }}></div>
                {icon}
            </div>
        </Cell>);

};

const Notifications = (props) => {

    const types = [
        {
            value: 2,
            label: props.language.option_notifications_type_advertising
        },
        {
            value: 3,
            label: props.language.option_notifications_type_alert
        },
        {
            value: 4,
            label: props.language.option_notifications_type_achievement
        },
        {
            value: 0,
            label: props.language.option_notifications_type_information
        }
    ];

    const initFilterData = {
        search_value: "",
        from: new Date(2020, 0, 1),
        to: new Date(),
        locations: "",
        type: null
    }

    const locations = props.locations;

    let availableData = props.availableData;

    let totalData = props.totalData;

    const [showFilters, setShowFilters] = useState(false);
    const [filterData, setFilterData] = useState(initFilterData);

    const [activePage, setActivePage] = useState(1);
    const [pageLength, setPageLength] = useState(20);
    const [totalPages, setTotalPages] = useState(0);

    const setActiveData = props.setActiveData;
    const setAvailableData = props.setAvailableData;
    const loadNotificationsAsync = props.loadNotificationsAsync;
    const loadLocationsAsync = props.loadLocationsAsync;

    let locale = props.language.language_locale == "en" ? enLocale : esLocale;

    useEffect(() => {
        if (!totalData.length) { loadNotificationsAsync(); }
        else {
            setTotalPages(availableData.length);
            setActiveData(availableData.slice(((activePage - 1) * pageLength), activePage * pageLength));
        }

        if (!locations.length)
            loadLocationsAsync();

    }, [loadNotificationsAsync, loadLocationsAsync, setActiveData, setTotalPages, activePage, pageLength, availableData, totalData, locations]);

    useEffect(() => {

        if (totalData.length) {

            let data = totalData;
            const search = filterData.search_value.toLowerCase();

            data = data.filter(option =>
            ((Date.parse(filterData.from) <= Date.parse(option.date) && (Date.parse(filterData.to) + 86400000) >= Date.parse(option.date))
                && (filterData.locations.includes(option.id_project) || !filterData.locations.length)
                && (filterData.type === option.type || (filterData.type === 0 && option.type !== 2 && option.type !== 3) || filterData.type === null)
                && (option.project.toLowerCase().includes(search) || option.value.toLowerCase().includes(search))
            ));

            setActivePage(1);
            setAvailableData(data);
        }
    }, [setActivePage, setAvailableData, filterData, totalData]);

    useEffect(() => {
        logEvent(analytics, "notifications", {
            id_user: props.user.id_user,
            name: `${props.user.name} ${props.user.lastname}`
        });

        amplitude.getInstance().logEvent('Notifications Screen Viewed', {
            number_of_unread: props.unreadNotificacions.length
        });

    }, []);

    return (
        <div id="notifications" className="column-start align-items-start px-3 py-2 h-100 w-100">
            <div className="row-between align-items-center flex-wrap-reverse w-100 pb-2">
                <div className="row-start align-items-center flex-wrap col-md-8 col-12 p-0">
                    <div className="row-start align-items-center col-md-6 col-12 my-md-0 my-2 p-0">
                        <div className="txt-20 txt-bold txt-dark-blue w-auto">{props.language.title_notifications}</div>
                    </div>
                    <div className="row-start align-items-center col-md-6 col-12 p-0">
                        <Whisper
                            delay={1000}
                            placement="bottom"
                            trigger="focus"
                            speaker={<Tooltip>{props.language.tooltip_shared_search}</Tooltip>}>
                            <InputGroup
                                inside>
                                <Input
                                    placeholder={`${props.language.title_shared_search}`}
                                    onPressEnter={e => setFilterData({ ...filterData, search_value: e.target.value })} />
                                <InputGroup.Addon>
                                    <i className="fas fa-search txt-10 txt-light-blue"></i>
                                </InputGroup.Addon>
                            </InputGroup>
                        </Whisper>
                    </div>
                </div>
                <div className="d-block d-md-flex justify-content-end align-items-center col-md-4 col-12 p-0">
                    <Whisper
                        placement="bottom"
                        trigger="click"
                        speaker={<Tooltip>{props.language.tooltip_notifications_info}</Tooltip>}>
                        <i className="fas fa-info-circle d-none d-md-block txt-12 txt-light-blue txt-hover-blue mx-2 c-pointer"></i>
                    </Whisper>
                    <Options />
                </div>
            </div>
            <div className="position-relative column-start flex-grow-1 w-100 bg-white rounded shadow overflow-hidden">
                <Transition
                    in={props.loading}
                    timeout={100}>
                    {state => (
                        <div className={`trans-fade trans-fade-${state} position-absolute bg-white w-100 h-100 top-0 left-0 zindex-1`}>
                            <div className="column-center align-items-center w-100 h-100">
                                <Loader center size="md" content={props.language.loading_message} />
                            </div>
                        </div>
                    )}
                </Transition>
                {!props.activeData.length && !props.loading ?
                    <div className={`position-absolute column-center align-items-center left-0 bg-white border-top w-100 p-3 zindex-1 empty-message-${!showFilters ? "complete" : "filtered"}`}>
                        <div className="row-center align-items-center w-100">
                            <i className="fas fa-bell txt-20 txt-light-blue" />
                            <div className="txt-20 txt-bold txt-light-blue w-auto mx-3">{props.language.title_notifications_empty}</div>
                        </div>
                    </div> : null}
                <div className="table-data-container flex-grow-1 w-100">
                    <div className="position-relative d-flex flex-column h-100" style={{ minWidth: 1200 }}>
                        <div className="table-header position-absolute top-0 row-start align-items-center zindex-1 py-3 w-100" style={{ paddingRight: 20 }}>
                            <div className="text-center" style={{ width: 60, paddingLeft: 10, paddingRight: 10 }}>
                                <Whisper
                                    delay={500}
                                    placement="top"
                                    trigger="hover"
                                    speaker={<Tooltip>{props.language.tooltip_shared_filter}</Tooltip>}>
                                    <i className="fas fa-filter txt-10 txt-light-blue txt-hover-blue c-pointer"
                                        onClick={() => setShowFilters(!showFilters)} />
                                </Whisper>
                            </div>
                            <div className="text-left" style={{ width: 130, paddingLeft: 10, paddingRight: 10 }}>
                                <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_date}</div>
                            </div>
                            <div className="text-left" style={{ width: 130, paddingLeft: 10, paddingRight: 10 }}>
                                <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_hour}</div>
                            </div>
                            <div className="text-left" style={{ width: 250, paddingLeft: 10, paddingRight: 10 }}>
                                <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_notificacions_project}</div>
                            </div>
                            <div className="text-left flex-grow-1" style={{ paddingLeft: 10, paddingRight: 10 }}>
                                <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_notificacions_notification}</div>
                            </div>
                        </div>
                        <div className={`table-filter position-absolute row-start align-items-end zindex-1 pb-3 w-100 ${!showFilters ? "d-none" : null}`} style={{ paddingRight: 20 }}>
                            <div className="text-left" style={{ marginLeft: 60, width: 260, paddingLeft: 10, paddingRight: 10 }}>
                                <DateRangePicker
                                    className="w-100"
                                    showOneCalendar
                                    format={props.user.hide_invoices? "MM-DD-YYYY" : "DD-MM-YYYY"}
                                    value={[filterData.from, filterData.to]}
                                    locale={props.dateLocale}
                                    disabledDate={afterToday()}
                                    onChange={value => setFilterData({ ...filterData, from: value[0], to: value[1] })}
                                    onClean={() => setFilterData({ ...filterData, from: initFilterData.from, to: initFilterData.to })}
                                />
                            </div>
                            <div className="text-left " style={{ width: 250, paddingLeft: 10, paddingRight: 10 }}>
                                <CheckPicker
                                    className="w-100"
                                    searchable={false}
                                    placeholder={props.language.placeholder_notifications_filter_table_all_locations}
                                    value={filterData.locations}
                                    labelKey={"name"}
                                    valueKey={"id_project"}
                                    onChange={value => setFilterData({ ...filterData, locations: value })}
                                    data={locations}
                                />
                            </div>
                            <div className="text-left " style={{ width: 280, paddingLeft: 10, paddingRight: 10 }}>
                                <SelectPicker
                                    className="w-100"
                                    searchable={false}
                                    placeholder={props.language.placeholder_notifications_filter_table_all_types}
                                    value={filterData.type}
                                    data={types}
                                    onChange={value => setFilterData({ ...filterData, type: value })}
                                />
                            </div>
                        </div>
                        <div className={`position-relative flex-grow-1 table-vertical-scroll border-top table-content-${!showFilters ? "complete" : "filtered"}`}>
                            <Table
                                autoHeight
                                wordWrap
                                data={props.activeData}
                                hover={false}
                                showHeader={false}>
                                <Column width={60} >
                                    <HeaderCell>type</HeaderCell>
                                    <ImageCell dataKey="type" />
                                </Column>
                                <Column width={130} verticalAlign="middle">
                                    <HeaderCell>date</HeaderCell>
                                    <Cell dataKey="date">
                                        {rowData => (
                                            <div className="txt-10 txt-dark-blue txt-regular">{
                                                format(new Date (rowData.date), props.user.hide_invoices? "MMM dd yyyy" : "dd MMM yyyy", { locale: locale })
                                            }
                                            </div>)}
                                    </Cell>
                                </Column>
                                <Column width={130} verticalAlign="middle">
                                    <HeaderCell>hour</HeaderCell>
                                    <Cell dataKey="date">
                                        {rowData => (
                                            <div className="txt-10 txt-dark-blue txt-regular">{new Date(rowData.date)
                                                .toLocaleTimeString(props.language.format_date_string,
                                                    {
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        hour12: true
                                                    })}
                                            </div>)}
                                    </Cell>
                                </Column>
                                <Column width={250} verticalAlign="middle">
                                    <HeaderCell>project</HeaderCell>
                                    <Cell dataKey="project">
                                        {rowData => (
                                            <div style={{ width: 250 }}>
                                                <div className="txt-10 txt-dark-blue txt-semibold">{rowData.project}</div>
                                            </div>)}
                                    </Cell>
                                </Column>
                                <Column flexGrow={1} verticalAlign="middle">
                                    <HeaderCell>value</HeaderCell>
                                    <Cell dataKey="value" >
                                        {rowData => (
                                            <div className="column-start align-items-center">
                                                <div className="txt-10 txt-dark-blue txt-bold text-wrap">{rowData.title}</div>
                                                <div className="txt-10 txt-dark-blue txt-regular text-wrap">{rowData.value}</div>
                                            </div>
                                        )}
                                    </Cell>
                                </Column>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="border-top">
                    <Table.Pagination
                        lengthMenu={lengthMenu}
                        activePage={activePage}
                        displayLength={pageLength}
                        total={totalPages}
                        renderLengthMenu={picker => picker}
                        onChangeLength={value => {
                            const maxActivePage = Math.ceil(props.availableData.length / value);
                            const page = activePage <= maxActivePage ? activePage : maxActivePage;

                            setPageLength(value);
                            setActivePage(page);
                        }}
                        onChangePage={value => setActivePage(value)}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    totalData: state.notifications.totalData,
    availableData: state.notifications.availableData,
    activeData: state.notifications.activeData,
    locations: state.home.locations,
    language: state.shared.language,
    loading: state.notifications.loading,
    unreadNotificacions: state.notifications.popupData
})

const mapDispatchToProps = dispatch => ({
    loadNotificationsAsync: () => dispatch(notificationsActions.loadNotificationsAsync()),
    setAvailableData: payload => dispatch(notificationsActions.setAvailableData(payload)),
    setActiveData: payload => dispatch(notificationsActions.setActiveData(payload)),
    loadLocationsAsync: () => dispatch(homeActions.loadLocationsAsync()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)

