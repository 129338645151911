//import { color } from '../../style/_colors';
//import {colors} from '../../style/style.scss';
import * as creators from '../creators/Game';

const initialState = {
    colorsTrophy: ["yellow","light-blue","bronze"],
    games: [],
    newAchievements: [],
    medals: [],
    showMedals: false,
    profile: null,
    positions: [],
    previousPage: null,
    nextPage: null,
    loadingNext: false,
    loadingPrevious: false,
    loadingInitial: false,
    loadingInitialChallenge: false,
    showRanking: false,
    rankingUri: '',
    rankingPosition: null,
    rankingTypePlace: 0, //0: city 1:country
    rankingTypeTime: 0, //0: monthly 1:historic,
    gamesPcType: 0, //0:actual 1:historic,
    gamesPcChallenges: [],
    gamesPcNextPage: null
}

export const gameReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case creators.SET_PROFILE:
            return { ...state, profile: payload };
        case creators.SET_GAMES:
            return { ...state, games: payload };
        case creators.SET_NEW_ACHIEVEMENTS:
            return { ...state, newAchievements: payload };
        case creators.SET_MEDALS:
            return { ...state, medals: payload };
        case creators.SET_SHOW_MEDALS:
            return { ...state, showMedals: payload };
        case creators.SET_SHOW_RANKING:
            return { ...state, showRanking: payload };
        case creators.SET_RANKING:
            return {
                ...state,
                positions: payload.results,
                previousPage: payload.previous,
                nextPage: payload.next,
                rankingPosition: payload.results.find(x => x.active_user).position
            };
        case creators.SET_RANKING_URI:
            return { ...state, rankingUri: payload };
        case creators.SET_RANKING_TYPE_PLACE:
            return { ...state, rankingTypePlace: payload };
        case creators.SET_RANKING_TYPE_TIME:
            return { ...state, rankingTypeTime: payload };
        case creators.SET_PREVIOUS:
            return {
                ...state,
                positions: [
                    ...state.positions.slice(0, 3),
                    ...payload.results,
                    ...state.positions.slice(3)
                ],
                previousPage: payload.previous
            }
        case creators.SET_NEXT:
            return {
                ...state,
                positions: [
                    ...state.positions,
                    ...payload.results
                ],
                nextPage: payload.next
            }
        case creators.SET_LOADING_PREVIOUS:
            return { ...state, loadingPrevious: payload }
        case creators.SET_LOADING_NEXT:
            return { ...state, loadingNext: payload }
        case creators.SET_LOADING_INITIAL:
            return { ...state, loadingInitial: payload }
        case creators.SET_LOADING_INITIAL_CHALLENGE:
            return { ...state, loadingInitialChallenge: payload }
        case creators.SET_GAMES_PC_TYPE:
            return { ...state, gamesPcType: payload };
        case creators.SET_GAMES_PC_CHALLENGES:
            return { ...state, gamesPcChallenges: payload };
        case creators.SET_GAMES_PC_NEXT_PAGE:
            return { ...state, gamesPcNextPage: payload };
        default:
            return state;
    }

}