const lang = {
    title_shared_search: "Buscar",
    title_shared_notifications_clear: "Limpiar",
    title_shared_notifications_check: "Entendido",
    title_shared_notifications_empty: "No hay notificaciones nuevas.",
    title_notifications: "Notificaciones",
    title_shared_user_salutation: "Hola,",
    title_shared_user_type_consumer: "Consumidor",
    title_shared_user_type_prosumer: "Prosumidor",
    title_shared_user_type_generator: "Generador",
    title_shared_user_info: "Tu información",
    title_shared_user_save: "Guardar",
    title_shared_user_signout: "Cerrar sesión",
    title_invoices: "Facturas",
    title_invoices_popup_pays: "Factura",
    title_invoices_popup_gateway: "Pago seguro desde cuenta de ahorros o corriente vía",
    title_invoices_state_pay: "Pagar",
    title_invoices_state_paid: "Pagada",
    title_invoices_state_cancelled: "Cancelada",
    title_paid_state_approved: "Gracias, tu pago ha sido aprobado.",
    title_paid_state_declined: "Lo sentimos, tu pago ha sido rechazado.",
    title_paid_state_pending: "Tu pago aún esta pendiente.",
    title_paid_name: "Nombre",
    title_paid_email: "Correo electrónico",
    title_paid_document: "Documento de identidad",
    title_paid_description: "Descripción",
    title_paid_total: "Total",
    title_paid_reference: "Referencia",
    title_paid_print: "Imprimir",
    title_reports: "Reportes",
    title_rules: "Reglas",
    title_notifications_empty: "No hay notificaciónes.",
    title_invoices_empty: "No hay facturas.",
    title_reports_empty: "No hay reportes.",
    title_rules_empty: "No hay reglas.",
    title_home_project_info: "Información del proyecto",
    title_home_project_save: "Guardar",
    label_home_project_acronym: "Acrónimo",
    label_home_project_name: "Nombre",
    label_home_project_date: "Fecha de instalación",
    label_home_project_address: "Dirección",
    label_home_project_average_consumption: "Consumo promedio",
    label_home_project_cost_kwh: "Costo por kWh",
    label_home_project_installed_power: "Potencia instalada",
    label_home_project_theoretical_energy: "Energía teórica",
    label_home_project_panels_quantity: "Cantidad de paneles",
    label_home_project_panel_area: "Área de paneles",
    label_home_project_panel_efficiency: "Eficiencia del panel",
    placeholder_home_project_address: "Ej. Carrera 79 # 22 - 10",
    placeholder_home_project_average_consumption: "Ej. 800",
    placeholder_home_project_costo_kwh: "Ej. 450",
    alert_info_project_address: "Debes ingresar una dirección válida.",
    alert_info_project_average_consumption: "Debes ingresar un consumo promedio válido.",
    alert_info_project_costo_kwh: "Debes ingresar un costo por kWh válido.",
    title_home_locations: "Ubicaciones",
    title_home_hourly: "Hora",
    title_home_daily: "Día",
    title_home_weekly: "Semana",
    title_home_monthly: "Mes",
    title_home_select_location: "Elige una ubicación.",
    title_home_select_graph: "Elige una gráfica.",
    title_home_card_no_inovices: "No hay facturas",
    title_home_card_inovice: "Factura",
    title_home_card_inovices: "Facturas",
    title_home_card_more: "más",
    title_home_card_less: "menos",
    title_home_card_expires: "Vencimiento",
    title_home_card_to_pay: "Pagar",
    title_home_card_to_go: "Ver facturas",
    title_home_card_saves: "Has ahorrado",
    title_home_card_ad: "Pero podrías ahorrar más con NEU",
    title_home_card_change_to_neu: "¡Cámbiate a NEU!",
    title_home_card_activity: "Tu actividad equivale",
    title_home_card_trees: "Árboles sembrados",
    title_home_card_co2: "Tons de CO2 reducidas",
    title_home_card_solar: "Consumo solar",
    title_menu_home: "Resumen",
    title_menu_locations: "Ubicaciones",
    title_menu_indexes: "Indicadores",
    title_menu_invoices: "Facturas",
    title_menu_reports: "Reportes",
    title_menu_notifications: "Notificaciones",
    title_menu_rules: "Reglas",
    placeholder_home_select_graphs: "Gráficas",
    title_home_popup_addresses: "Smart meters",
    title_home_popup_addresses_check_all: "Seleccionar todos",
    title_home_popup_addresses_empty: "No hay smart meters disponibles.",
    title_home_popup_addresses_ok: "Guardar",
    title_home_popup_export: "Exportar gráfica",
    title_home_popup_button_export: "Exportar",
    title_home_popup_graph_empty: "Selecciona una gráfica.",
    title_locations_search_projects: "Buscar proyecto",
    title_locations_loading_info: "Cargando",
    title_locations_total: "Total",
    title_locations_avg: "Promedio",
    option_home_graph_active_energy: "Consumo",
    option_home_graph_reactive_enegy_inductive: "Reactiva inductiva",
    option_home_graph_reactive_enegy_capacitive: "Reactiva capacitiva",
    option_home_graph_reactive_enegy_excess: "Reactiva en exceso",
    option_home_graph_injection: "Excedentes",
    option_home_graph_transactions: "Transacciones",
    option_home_graph_current: "Corriente",
    option_home_graph_voltage: "Voltaje",
    option_home_graph_frequency: "Frecuencia",
    option_home_graph_generation: "Generación",
    option_home_graph_generation_specific: 'Generación especifica',
    option_home_graph_current_ac: 'Corriente AC',
    option_home_graph_current_dc: 'Corriente DC',
    option_home_graph_voltage_ac: 'Tensión AC',
    option_home_graph_voltage_dc: 'Tensión DC',
    option_home_graph_active_pwr: 'Potencia activa',
    option_home_graph_apparent_pwr: 'Potencia aparente',
    option_home_graph_pwr_factor: 'Factor de potencia',
    option_home_graph_inverter_temperature: 'Temperatura del inversor',
    option_home_graph_heatsink_temperature: 'Temperatura del disipador',
    option_home_graph_irradiance: 'Irradiancia',
    option_home_graph_panel_temperature: 'Temperatura del panel',
    option_home_graph_environmet_temperature: 'Temperatura ambiente',
    option_home_graph_humidity: 'Humedad',
    option_home_graph_wind_speed: 'Velocidad del viento',
    option_home_graph_wind_direction: 'Dirección del viento',
    option_home_datepicker_sunday: "Do",
    option_home_datepicker_monday: "Lu",
    option_home_datepicker_tuesday: "Ma",
    option_home_datepicker_wednesday: "Mi",
    option_home_datepicker_thursday: "Ju",
    option_home_datepicker_friday: "Vi",
    option_home_datepicker_saturday: "Sa",
    option_home_datepicker_ok: "Cargar",
    option_home_datepicker_today: "Hoy",
    option_home_datepicker_yesterday: "Ayer",
    option_home_datepicker_last_7_Days: "Últimos 7 días",
    tooltip_home_dashboard_size: "Expandir / Colapsar",
    tooltip_home_dashboard_addresses_filter: "Filtrar Smart meters",
    tooltip_home_dashboard_invoices: "Exportar",
    tooltip_home_card_invoice_pending: "Pendiente",
    tooltip_home_card_invoice_paid: "Pagado",
    tooltip_home_card_invoice_consumer: "Tu factura de energía del mes actual. Puedes comparar tu consumo respecto al mes anterior y proyectar un ahorro para fin de mes.",
    tooltip_home_card_invoice_generator: "Tu ahorro monetario por tener generación solar. Se calcula a partir de la energía generada y la tarifa. Recuerda que puedes editar el costo por kWh en la información de cada proyecto.",
    tooltip_home_card_environment: "La energía solar que consumes a través de NEU tiene un impacto positivo en el medio ambiente. Puedes ver cuantos árboles se sembraron por este consumo o incluso cómo ayudaste a reducir emisiones de CO2.",
    tooltip_home_card_gauge: "Energía solar que consumiste en un determinado periodo de tiempo.",
    tooltip_notifications_info: "Es el historial de las notificaciones enviadas por tu aplicación para recordarte el ahorro generado o alertarte sobre algún evento inusual. Acá también verás el resultado de las reglas creadas.",
    tooltip_invoices_info: "El detalle de tus facturas vigentes e históricas.",
    tooltip_reports_info: "Aquí puedes ver los reportes que has creado. Adicionalmente selecciona las opciones que desees para generar un nuevo reporte.",
    tooltip_rules_info: "Crea tus propias reglas para generar notificaciones personalizadas por ti para optimizar tu consumo de energía.",
    tooltip_shared_search: "Presiona (Enter) para buscar.",
    tooltip_shared_download: "Descargar",
    tooltip_overdue_invoice: "Factura vencida",
    tooltip_delete: "Eliminar",
    tooltip_shared_filter: "Filtrar",
    tooltip_menu_pending: "Pendiente",
    placeholder_invoices_popup_name: "Nombres",
    placeholder_invoices_popup_name_example: "Ej. Luis Enrique",
    placeholder_invoices_popup_lastname: "Apellidos",
    placeholder_invoices_popup_lastname_example: "Ej. Cortes López",
    placeholder_invoices_popup_email: "Correo eléctronico",
    placeholder_invoices_popup_email_example: "Ej. lcortes@neu.com.co",
    placeholder_invoices_popup_phone: "Teléfono",
    placeholder_invoices_popup_phone_example: "Ej. 3013865253",
    placeholder_invoices_popup_document_type: "Tipo de documento",
    placeholder_invoices_popup_document: "Documento de identidad",
    placeholder_invoices_popup_document_example: "Ej. 1037658620",
    placeholder_invoices_popup_person_type: "Tipo de persona",
    placeholder_invoices_popup_bank: "Entidad bancaria",
    placeholder_invoices_popup_bank_select: "Selecciona tu banco...",
    placeholder_invoices_popup_invoice: "Total",
    placeholder_shared_user_document: "Documento de identidad",
    placeholder_shared_user_name: "Nombres",
    placeholder_shared_user_name_example: "Ej. Luis Enrique",
    placeholder_shared_user_lastname: "Apellidos",
    placeholder_shared_user_lastname_example: "Ej. Cortes López",
    placeholder_shared_user_email: "Correo eléctronico",
    placeholder_shared_user_email_example: "Ej. lcortes@neu.com.co",
    placeholder_shared_user_phone: "Teléfono",
    placeholder_shared_user_phone_example: "Ej. 3013865253",
    placeholder_shared_user_phone_two: "Teléfono alternativo",
    placeholder_shared_user_phone_two_example: "Ej. 3040625",
    placeholder_shared_user_address: "Dirección",
    placeholder_shared_user_address_example: "Ej. Calle 27 S # 100 - 14",
    placeholder_shared_user_address_two: "Dirección alternativa",
    placeholder_shared_user_address_two_example: "Ej. Calle 13 S # 120 - 45",
    alert_invoices_popup_name: "Debes ingresar un nombre válido.",
    alert_invoices_popup_lastname: "Debes ingresar un apellido válido.",
    alert_invoices_popup_email: "Debes ingresar un correo eléctronico válido.",
    alert_invoices_popup_phone: "Debes ingresar un teléfono válido.",
    alert_invoices_popup_id_document: "Debes ingresar un documento de identidad válido.",
    alert_invoices_popup_financial_institution_code: "Debes ingresar una entidad bancaria válida.",
    alert_invoices_popup_terms: "Debes aceptar los términos y condiciones.",
    alert_user_popup_name: "Debes ingresar un nombre válido.",
    alert_user_popup_lastname: "Debes ingresar un apellido válido.",
    alert_user_popup_email: "Debes ingresar un correo eléctronico válido.",
    alert_user_popup_phone: "Debes ingresar un teléfono válido.",
    alert_user_popup_address: "Debes ingresar una dirección válido.",
    placeholder_invoices_filter_table_code: "Código",
    placeholder_invoices_filter_table_description: "Descripción",
    placeholder_invoices_filter_table_value: "Valor total",
    placeholder_invoices_filter_table_all_states: "Todos los estados",
    alert_shared_error: "¡Ups!, Ha ocurrido un error, porfavor vuelve a intentarlo.",
    tooltip_reports_create_report: "Crear nuevo reporte",
    title_reports_popup_create_report: "Crear reporte",
    title_reports_popup_button_generate: "Generar",
    title_reports_button_error: "Error",
    title_reports_button_pending: "Pendiente",
    title_reports_button_download: "Descargar",
    label_reports_popup_name: "Nombre del informe",
    label_reports_popup_addresses: "Ubicaciones",
    label_reports_popup_graphs: "Gráficas",
    label_reports_popup_date_range: "Rango de fechas",
    label_reports_popup_interval: "Intervalo",
    label_reports_popup_format: "Formato",
    label_shared_popup_select_interval_hourly: "Horario",
    label_shared_popup_select_interval_daily: "Diario",
    label_shared_popup_select_interval_weekly: "Semanal",
    label_shared_popup_select_interval_monthly: "Mensual",
    placeholder_reports_popup_name: "Ej. Informe mensual",
    placeholder_reports_popup_addresses: "Seleccione las ubicaciones...",
    placeholder_reports_popup_graphs: "Seleccione las gráficas...",
    alert_reports_popup_report_name: "Debes ingresar un nombre para tu informe.",
    alert_reports_popup_addresses: "Debes seleccionar por lo menos un Smart Meter.",
    alert_reports_popup_graphs: "Debes seleccionar por lo menos una gráfica.",
    alert_reports_popup_interval: "Debes seleccionar un intervalo válido.",
    alert_reports_popup_format_type: "Debes seleccionar un formato válido.",
    placeholder_reports_filter_table_report_name:"Nombre del informe",
    placeholder_reports_filter_table_all_formats:"Todos los formatos",
    placeholder_reports_filter_table_all_states:"Todos los estados",
    label_rules_popup_location: "Ubicación",
    label_rules_popup_properties: "Propiedad",
    label_rules_popup_interval: "Intervalo",
    label_rules_popup_rule_names: "Regla",
    label_rules_popup_value: "Valor",
    label_rules_popup_available_days: "Días disponibles",
    placeholder_rules_popup_value: "Ej. 500",
    placeholder_rules_popup_location: "Seleccione una ubicación...",
    placeholder_rules_popup_properties: "Seleccione una propiedad...",
    tooltip_rules_create_rule: "Crear nueva regla",
    title_rules_popup_create_rule: "Crear regla",
    title_rules_popup_button_create: "Crear",
    option_table_rules_day_monday: "L",
    option_table_rules_day_tuesday: "M",
    option_table_rules_day_wednesday: "M",
    option_table_rules_day_thursday: "J",
    option_table_rules_day_friday: "V",
    option_table_rules_day_saturday: "S",
    option_table_rules_day_sunday: "D",
    label_rules_select_rule_name_greater: "Mayor que",
    label_rules_select_rule_name_less: "Menor que",
    label_rules_select_rule_name_equal: "Igual que",
    alert_rules_popup_location: "Debes seleccionar una ubicación válida.",
    alert_rules_popup_property: "Debes seleccionar una propiedad válida.",
    alert_rules_popup_rule_name: "Debes seleccionar una regla válida.",
    alert_rules_popup_value: "Debes ingresar un valor válido.",
    alert_rules_popup_available_days: "Debes seleccionar por lo menos un día de la semana.",
    alert_error_address_zero: "Revisa la dirección ingresada, puede estar incompleta.",
    header_table_date: "Fecha",
    header_table_hour: "Hora",
    header_table_notificacions_project: "Ubicación",
    header_table_notificacions_notification: "Notificación",
    placeholder_notifications_filter_table_all_locations: "Todos los proyectos",
    placeholder_notifications_filter_table_all_types: "Todos los tipos",
    option_notifications_type_alert: "Alerta",
    option_notifications_type_information: "Información",
    option_notifications_type_advertising: "Advertencia",
    header_table_invoices_code: "Código",
    header_table_invoices_date: "Fecha",
    header_table_invoices_description: "Descripción",
    header_table_invoices_total_value: "Valor total",
    header_table_state: "Estado",
    header_table_location: "Ubicación",
    header_table_reports_report: "Informe",
    header_table_reports_file_name: "Nombre del archivo",
    header_table_rules_property: "Propiedad",
    header_table_rules_interval: "Intervalo",
    header_table_rules_rule: "Regla",
    header_table_rules_value: "Valor",
    header_table_rules_available: "Disponible",
    option_filter_table_rules_day_monday: "Lunes",
    option_filter_table_rules_day_tuesday: "Martes",
    option_filter_table_rules_day_wednesday: "Miércoles",
    option_filter_table_rules_day_thursday: "Jueves",
    option_filter_table_rules_day_friday: "Viernes",
    option_filter_table_rules_day_saturday: "Sábado",
    option_filter_table_rules_day_sunday: "Domingo",
    placeholder_rules_filter_table_all_locations: "Todas las ubicaciones",
    placeholder_rules_filter_table_all_properties: "Todas las propiedades",
    placeholder_rules_filter_table_all_intervals: "Todos",
    placeholder_rules_filter_table_all_rule_names: "Todas",
    placeholder_rules_filter_table_value: "Valor",
    placeholder_rules_filter_table_all_days: "Todas los días",
    format_date_string: 'es-ES',
    loading_message: "Cargando...",
    thousandSeparator: ".",
    decimalSeparator: ",",
    title_login: "Reinventamos el futuro de la energía",
    label_login_input_data: "Escribe tu teléfono o email registrado",
    placeholder_login_input_data: "Teléfono o email",
    label_login_input_code: "Por favor, escribe el código de verificación que te enviamos a:",
    placeholder_login_input_code: "Código de verificación",
    login_resend_code: "Reenviar código",
    login_resending_code: "Espera #seg seg para reenviar",
    login_want_to_join: "¿Quieres unirte?",
    login_click_here: "Haz click aquí",
    alert_login_user_not_found: "Aún no estas registrado.",
    alert_login_invalid_code: "El código es inválido.",
    button_neugets_want_more: "¡Quiero más!",
    text_neugets_share_code_modal_title: "¡Comparte y gana!",
    text_neugets_share_code_modal_body: "Comparte este código con tus amigos, cuando ellos se registren e ingresen este código ambos ganan NEUgets, genial ¿No? ",
    text_neugets_share_code_title: "Comparte y gana con NEU",
    text_neugets_share_code_message: "Hola\n¿Te quieres ganar un super descuento en tu próxima factura de energía?\n\n¡Te invito a que conozcas a NEU Energy! El único proveedor de energía eléctrica en Colombia que te permite visualizar tu consumo en tiempo real desde tu celular 📊📱 y reducir tu factura de energía con notificaciones de eficiencia energética.⚡\n\nRegístrate con mi código de referido: \n\n#code#\n\nAmbos participaremos para obtener descuentos en futuras facturas de energía eléctrica.🧾⚡\n\nRegístrate en: \n\nhttps://www.neu.com.co/registro",
    text_share_user_salutation_text: "Crea tu perfil y cuéntanos un poco más quien eres…",
    tooltip_neugets_copy_code: "Código copiado",
}

export default lang;