import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Modal, Slider, Input } from 'rsuite';

import * as sharedActions from '../../store/actions/Shared';

const Score = (props) => {

    let graphs = props.graphs;

    const [graph, setGraph] = useState(null);

    useEffect(() => {
        if (graphs.length && !graph && props.graphScore) {
            let currentGraph = graphs.find(g => g.id_graph === props.graphScore.id_graph)
            if (currentGraph)
                setGraph(currentGraph.label)
        }
    }, [graphs]);


    return (
        <Modal
            size="xs"
            show={props.graphScore !== null && graph !== null}
            onHide={() => props.setGraphScore(null)}>
            <Modal.Header>
                <div className="row-end align-items-center h-100 w-100">
                    <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.title_graph_score}</div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="column-center align-items-center p-3">
                    {props.graphScore !== null && graph ?
                        <div className="txt-12 txt-semibold txt-dark-blue text-wrap text-center">{`${props.language.label_graph_score} ${graph}?`}</div> : null}
                    <div className="my-4 px-3 w-100">
                        <div className="my-4">
                            <div className="w-100">
                                <Slider defaultValue={4} min={1} max={5} step={1} graduated progress onChange={value => props.setGraphScoreValue(value)} />
                            </div>
                            <div className="row-between align-items-center w-100">
                                <div className="col-6 px-0 py-3">
                                    <div className="txt-10 txt-semibold txt-light-blue text-wrap text-left">{props.language.label_graph_score_not_usefull}</div>
                                </div>
                                <div className="col-6 px-0 py-3">
                                    <div className="txt-10 txt-semibold txt-light-blue text-wrap text-right">{props.language.label_graph_score_too_usefull}</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-100">
                            <Input
                                rows={4}
                                maxLength={144}
                                componentClass="textarea"
                                placeholder={props.language.label_graph_score_suggestion}
                                onChange={value => props.setGraphScoreReason(value)} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row-end align-items-center h-100 w-100">
                    <button style={{ width: 150 }} className="txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 mr-4 w-auto bg-blue" onClick={() => {

                        let graphScore = props.graphScore.score !== 0 ? props.graphScore : { ...props.graphScore, score: 4 };

                        props.updateGraphScoreAsync(graphScore);

                    }}>{props.language.title_support_popup_send}</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    language: state.shared.language,
    graphScore: state.shared.graphScore,
    graphs: state.home.graphs,
})

const mapDispatchToProps = dispatch => ({
    updateGraphScoreAsync: payload => dispatch(sharedActions.updateGraphScoreAsync(payload)),
    setGraphScore: payload => dispatch(sharedActions.setGraphScore(payload)),
    setGraphScoreValue: payload => dispatch(sharedActions.setGraphScoreValue(payload)),
    setGraphScoreReason: payload => dispatch(sharedActions.setGraphScoreReason(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Score);