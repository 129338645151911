const current = window.location.hostname === "localhost" ?
    `${window.location.protocol}//${window.location.host}/api/` :
    `${window.location.protocol}//${window.location.hostname}/api/`;

const SETData = async (url, method, params, endpoint = current, enableToken = false) => {

    let token = localStorage.getItem('token');  
    const headers =   enableToken ? {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',   
                                    }:
                                    {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`
                                    }
    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        headers: headers,
        body: JSON.stringify(params)
    });

    if (response.ok) {

        const body = await response.text().then(response);

        if (body !== "") { return JSON.parse(body); } else { return null; }
        
    } else {
        return null;
    }

}

const SETFile = async (url, method, params, endpoint = current) => {

    let token = localStorage.getItem('token');

    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: params
    });

    if (response.ok) {

        const body = await response.text().then(response);

        if (body !== "") { return JSON.parse(body); } else { return null; }
        
    } else {
        return null;
    }

}

const GETFile = async (url, method, params, endpoint = current) => {

    let token = localStorage.getItem('token');

    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(params)
    });

    if (response.ok) {
        return response;
    } else {
        return null;
    }

}

const GETData = async (url, method, endpoint = current, games = false) => {

    let token = localStorage.getItem('token');

    const headers_games = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    const response = await fetch(`${endpoint}${url}`, {
        method: method,
        mode: "cors",
        headers: games ? headers_games : new Headers({
            "Access-Control-Allow-Origin": "*",            
            'Authorization': `Bearer ${token}`
        })
    });
    if (response.ok) {

        const body = await response.text().then(response);

        if (body !== "") { return JSON.parse(body); } else { return null; }

    } else { return null; }

};

export { SETData, SETFile, GETData, GETFile };