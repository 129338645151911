import * as creators from '../creators/Shared';

const initialState = {
    language: {},
    graphScore: null,
    showMenu: false,
    showBlackScreen: false,
    showPopupShare: false,
    showPopupSupport: false,
    cities: [],
    showNpsSurvey: false,
};

export const sharedReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_LANGUAGE:
            return { ...state, language: payload };
        case creators.SET_MENU:
            return { ...state, showMenu: payload };
        case creators.SET_BLACK_SCREEN:
            return { ...state, showBlackScreen: payload };
        case creators.SET_POPUP_SHARE:
            return { ...state, showPopupShare: payload };
        case creators.SET_POPUP_SUPPORT:
            return { ...state, showPopupSupport: payload };
        case creators.SET_CITIES:
            return { ...state, cities: payload };
        case creators.SET_GRAPH_SCORE:
            return { ...state, graphScore: payload };
        case creators.SET_GRAPH_SCORE_VALUE:
            return { ...state, graphScore: { ...state.graphScore, score: payload } };
        case creators.SET_GRAPH_SCORE_REASON:
            return { ...state, graphScore: { ...state.graphScore, reason: payload } };
        case creators.SET_SHOW_NPS_SURVEY:
            return { ...state, showNpsSurvey: payload };
        default:
            return state;
    }
}