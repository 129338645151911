
import Store from '../store/Store';
import * as neuplusActions from '../store/actions/NEUPlus';
import { format, parse } from 'date-fns';
import esLocale from "date-fns/locale/es";
import enLocale from 'date-fns/locale/en-US';
import ptBR from 'date-fns/locale/pt-BR';

const axios = require('axios');

const api = 'https://4sh6kh1whb.execute-api.us-east-2.amazonaws.com/PDN/get-data-streaming'; 

//const api = 'https://krkcjk0iph.execute-api.us-east-2.amazonaws.com/developing/getdata';

class DataHandler {

  constructor() {

    this.record = [];
    this.squenceNumber = "";
    this.currentDate = "";

  }

  async loadData(deviceID) {
    try {

      const data = { "request": "loadData", "devicesID": deviceID };
      
      this.record=await axios.post(api, data);

      this.squenceNumber = this.record.data.SequenceNumber;

      this.currentDate = this.record.data.currentDate;

    }
    catch (err) {  };
  }


  async getData(deviceID) {
    try {

      const data = {
        "request": "getData",
        "devicesID": deviceID,
        "SequenceNumber": this.squenceNumber
      };


      this.record = await axios.post(api, data);

      if (this.record.data.body.length > 2) {

        this.squenceNumber = this.record.data.SequenceNumber;

        this.currentDate = this.record.data.currentDate;
        
      }
    }
    catch (err) { };
  }
}

let dataHandler = new DataHandler();
let deviceID_ini = []

const GETData = async () => {
  
    let globalState = Store.getState();
    let states = mapState(globalState);
    let dispatch = mapDispatch(Store.dispatch);

    if (states.activeDevices.length !== 0){

        let deviceID = await states.activeDevices;

        if (JSON.stringify(deviceID_ini) !== JSON.stringify(deviceID) ) {
            await dispatch.rebootStates();
            await dispatch.setGraphLoading(true); 
            dataHandler.record = [];
            dataHandler.squenceNumber = "";
            dataHandler.response = "";
            dataHandler.currentDate = "";

            try{
              await dataHandler.loadData(deviceID);

              if (dataHandler.record.data.body.length > 2){
                await dispatch.updateDataGraphic(dataHandler.record.data.body);
                await dispatch.setDataNull(false);
              }
              else{
                await dispatch.setDataNull(true);
              }
            }
            catch(err){}

            dispatch.setGraphLoading(false); 
             
        }

        deviceID_ini = await deviceID;
        
        if (!states.dataNull){
            try{
              await dataHandler.getData(deviceID);
        
              if (dataHandler.record.data.body.length > 2) {
                  await dispatch.updateDataGraphic(dataHandler.record.data.body);
              }
            }
            catch(err){}
      
            await dispatch.lengthData(900);
            await dispatch.setDataGraphic();
      
      
            if (dataHandler.currentDate !== ""){
              const locale = states.language_locale == "en" ? enLocale : esLocale;
              const currentDate = parse(dataHandler.currentDate, "EEEE dd MMM yyyy", new Date(), { locale: esLocale });
              const dateFormated = format(currentDate, !states.user.hide_invoices? "EEEE, dd MMM yyyy" : "EEEE, MMM dd yyyy", { locale: locale })
              await dispatch.updateDate(dateFormated);
              }
      
            dataHandler.record.data.body = [];
        }
      }
    
    if (states.activeLocations.length === 0){
        dispatch.deleteActiveDevices();
        deviceID_ini = []
    }
 
}

const mapState = state =>{
    return{
        activeLocations: state.neuPlus.activeLocations,
        activeDevices: state.neuPlus.activeDevices,
        devices: state.neuPlus.devices,
        dataNull: state.neuPlus.dataNull,
        language_locale: state.shared.language.language_locale,
        user: state.login.user,
    }
}

const mapDispatch = dispatch => ({
    updateDataGraphic: payload => dispatch(neuplusActions.updateDataGraphic(payload)),
    setDataGraphic: () => dispatch(neuplusActions.setDataGraphic()),
    lengthData: payload => dispatch(neuplusActions.lengthData(payload)),
    updateDate: payload => dispatch(neuplusActions.updateDate(payload)),
    setGraphLoading: payload => dispatch(neuplusActions.setGraphLoading(payload)),
    setDataFlag: payload => dispatch(neuplusActions.setDataFlag(payload)),
    rebootStates: () => dispatch(neuplusActions.rebootStates()),
    setDataNull: payload => dispatch(neuplusActions.setDataNull(payload)),
    deleteActiveDevices: () => dispatch(neuplusActions.deleteActiveDevices())
  })


export {GETData};

