import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import * as homeActions from '../../../store/actions/Home';

import { Tooltip, Whisper } from 'rsuite';

import GaugeChart from 'react-gauge-chart';

import ContentLoader from "react-content-loader";

const initLoading = <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
    <ContentLoader
        seed={2}
        foregroundColor="#4d4d4d"
        backgroundColor="#373737"
        style={{ width: "100%", height: "100%" }}>
        <rect x="15" y="22" width="100" height="10" rx="2" ry="2"></rect>
        <circle cx="calc(100% - 25px)" cy="28" r="10"></circle>
        <rect x="120" y="100" width="100" height="30" rx="5" ry="5"></rect>
        <rect x="110" y="172" width="120" height="10" rx="2" ry="2"></rect>
    </ContentLoader>
</div>;

const Gauge = (props) => {

    let content = null;

    if (!props.sustainabilityLoading) {

        if (props.totalGeneration !== null &&
            props.sustaintability !== null) {
            content = <Fragment>
                <div className="row-between align-items-center w-100">
                    <div className="txt-10 txt-bold txt-white">{props.language.title_home_card_solar}</div>
                    <Whisper
                        placement="bottom"
                        trigger="click"
                        speaker={<Tooltip>{props.language.tooltip_home_card_gauge}</Tooltip>}>
                        <i className="fas fa-info-circle txt-12 txt-white c-pointer"></i>
                    </Whisper>
                </div>
                <GaugeChart
                    className="txt-semibold txt-white"
                    nrOfLevels={30}
                    colors={['#00B4FF', '#A1C52A']}
                    needleColor={'#474747'}
                    needleBaseColor={'#474747'}
                    percent={props.sustaintability < 1 ? props.sustaintability : 1}
                />
                <div className="row-center align-items-center w-100">
                    <i className="fas fa-solar-panel txt-10 txt-white c-pointer mx-2"></i>
                    <div className="txt-10 txt-semibold txt-white text-center w-auto">{props.totalGeneration} kWh</div>
                </div>
            </Fragment>;
        }

    } else { content = initLoading; }

    return (
        <Fragment>
        {!props.user.hide_invoices && props.totalGeneration <= 0.01 ?
        <div className="position-relative column-between align-items-center h-100 w-100 bg-black rounded shadow p-3">
            {content}
        </div> : null}
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    totalGeneration: state.home.totalGeneration,
    sustaintability: state.home.sustaintability,
    sustainabilityLoading: state.home.sustainabilityLoading,
    user: state.login.user,
})

export default connect(mapStateToProps)(Gauge)
