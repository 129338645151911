import * as creators from '../creators/Login';

const initialState = {
    step: 0,
    loading: false,
    userLoading: false,
    resending: false,
    counter: 60,
    codeTries: 0,
    user: null
}

export const loginReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case creators.SET_STEP:
            return { ...state, step: payload };
        case creators.SET_LOADING:
            return { ...state, loading: payload };
        case creators.SET_USER_LOADING:
            return { ...state, userLoading: payload };
        case creators.SET_RESENDING:
            return { ...state, resending: payload };
        case creators.SET_USER:
            return { ...state, user: payload };
        case creators.SET_COUNTER:
            return { ...state, counter: payload };
        case creators.SET_CODE_TRIES:
            return { ...state, codeTries: payload };
        default:
            return state;
    }

}