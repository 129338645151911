import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as neuplusActions from '../../store/actions/NEUPlus'

import { Loader, Whisper, Tooltip, SelectPicker,Modal, Checkbox, CheckboxGroup, Input, InputGroup, CheckPicker, Alert, Pagination, InputNumber} from 'rsuite';

import { Transition } from 'react-transition-group';


const SelectQuantity = (props) => {
    const [quantity, setQuantity] = useState(props.defaultValue);
    useEffect(() => {
        props.onChange(quantity);
    }, [quantity, setQuantity]);
    return (
        <div className='align-items-center'>
            <div className='w-100 text-center fas fa-angle-up txt-14 c-pointer' onClick={() => setQuantity(quantity + 1)}/>
            <Input className='text-center' readOnly value={quantity}/>
            <div className='w-100 text-center mt-1 fas fa-angle-down txt-14 c-pointer' onClick={() => quantity - 1 < 1 ? setQuantity(1) : setQuantity(quantity - 1)}/>
        </div>
    )
}

let brandsAppliancesCreate = [];
let baseNameAppliancesCreate = [];

const Inquiry = (props) => {

    let flagInquiry = props.flagInquiry;

    const [showInquirySelectModal, setShowInquirySelectModal] = useState(false);
    const [showInquiryCreateModal, setShowInquiryCreateModal] = useState(false);
    const [showInquiryModifyModal, setShowInquiryModifyModal] = useState(false);
    const [showInquirySpecificationsModal, setShowInquirySpecificationsModal] = useState(false);
    const [showConfirmModal, setConfirmModal] = useState(false);

    let devices = props.locations.map(value => value.dissagregators).flat();

    const filterAppliancesInit = {
        search : "",
        id_base : [],
        id_brand : []
    }

    const specificationsInit = {
        active_power: null,
        reactive_power: null,
        monthly_cons: null,
        monthly_reactive_cons: null,
        weight: null,
        height: null,
        width: null,
        depth: null,
    }

    const newApplianceInquiryInit = {
        id_base: null,
        id_brand: null,
        brand: null,
        base: null,
        reference: null,
        quantity: 1, 
        specifications: specificationsInit,
        custom_name: null,
        module: props.module,
    }

    
    const rowsPerPage = 20;
    const [activePage, setActivePage] = useState(1);
    const [appliancesSelectedInquiry, setAppliancesSelectedInquiry] = useState([]);
    const [idAppliancesSelectedInquiry, setIdAppliancesSelectedInquiry] = useState([]);
    const [filterAppliancesInquiry, setFilterAppliancesInquiry] = useState(filterAppliancesInit);
    const [filterAppliancesInquiryEdit, setFilterAppliancesInquiryEdit] = useState(filterAppliancesInit);
    const [newApplianceInquiry, setNewApplianceInquiry] = useState(newApplianceInquiryInit);
    const [idApplianceDeviceInquiry, setIdApplianceDeviceInquiry] = useState(0);
    const [specifications, setSpecifications] = useState(specificationsInit);
    const [applianceToModify, setApplianceToModify] = useState(null);
    const [flagNoAppliances, setFlagNoAppliances] = useState(false);
    const [flagInquiryClosed, setFlagInquiryClosed] = useState(false);

    useEffect(() => {
        const id_device = devices[0].id_device;
        props.setDeviceTagEdit(id_device);
    }, [])

    useEffect(()=>{
        if (props.activeDevices.length){
            setFilterAppliancesInquiry(filterAppliancesInit);
            props.loadAppliances();
        }
    }, [props.activeDevices]);


    useEffect(()=>{
        //if ((props.activeDevices.length && !props.appliances.length && !flagInquiryClosed) || props.flagInquiryCreate){
        if (props.flagInquiryCreate){
            setFlagNoAppliances(true);
            setShowInquirySelectModal(true);
            if(!props.flagInquiryCreate){
                Alert.info(props.language.inquiry_alert_info_no_appliances, 10000);
            }
        }
    }, [props.appliances, props.flagInquiryCreate]);
    

    useEffect(() => {
        if(showInquirySelectModal){
            props.getInquiryAppliances({page:activePage, filter: filterAppliancesInquiry, module: props.module});
        }
        if(flagInquiry){
            props.getInquiryAppliancesEdit({page:activePage, filter: filterAppliancesInquiryEdit, module: props.module});
        }
        if((showInquirySelectModal || flagInquiry) && !props.base_appliances.length){
            props.getBaseAppliances();
        }
        if((showInquirySelectModal || flagInquiry) && !props.brands_appliances.length){
            props.getBrandsAppliances();
        }
    },[activePage, setActivePage, setFilterAppliancesInquiry, filterAppliancesInquiry, showInquirySelectModal, setShowInquirySelectModal, filterAppliancesInquiryEdit, setFilterAppliancesInquiryEdit, flagInquiry, props.deviceTagEdit]);

    useEffect(() => {
        setActivePage(1);
    },[setFilterAppliancesInquiry, filterAppliancesInquiry]);

    useEffect(() => {
        if(props.brands_appliances.length){
            brandsAppliancesCreate = props.brands_appliances.slice();
            brandsAppliancesCreate.unshift({id_brand: 0, name: props.language.inquiry_select_modal_another_brand_appliance});
        }
        if(props.base_appliances.length){
            baseNameAppliancesCreate = props.base_appliances.slice();
            baseNameAppliancesCreate.unshift({id_base_appliance: 0, name: props.language.inquiry_select_modal_another_type_appliance});
        }
    }, [props.brands_appliances, props.base_appliances]);

    return(
        <Fragment>
            <Modal
                size='xs'
                show={showInquirySelectModal}
                onHide={()=>{
                    setShowInquirySelectModal(false);
                    setActivePage(1);
                    if(!flagNoAppliances){
                        props.setFlagInquiry(true)
                    }
                    setFlagNoAppliances(false);
                    setFlagInquiryClosed(true);
                    props.setFlagInquiryCreate(false);
                    }}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.inquiry_header_select_modal}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-100'>
                        <Transition
                            in={props.loadingAppliancesInquiry}
                            timeout={100}>
                            {state => (
                                <div className={`trans-fade trans-fade-${state} position-absolute bg-white w-100 h-100 top-0 left-0 zindex-2`}>
                                    <div className="column-center align-items-center w-100 h-100">
                                        <Loader center size="md" content={props.language.loading_message} />
                                    </div>
                                </div>
                            )}
                        </Transition>
                        <div id="body-inquiry-appliances" className='row-start align-items-center px-1 my-3 w-100'>
                            <div className="col">
                                <InputGroup
                                    inside>
                                    <Input
                                        defaultValue={filterAppliancesInquiry.search}
                                        placeholder={`${props.language.title_shared_search}`}
                                        onPressEnter={e => setFilterAppliancesInquiry({ ...filterAppliancesInquiry, search: e.target.value.toLowerCase() })} 
                                    />
                                        <InputGroup.Addon>
                                            <i className="fas fa-search txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                </InputGroup>
                            </div>
                            <div className='col-auto pl-0'>
                                <Whisper
                                    container={document.getElementById("body-inquiry-appliances")}
                                    delay={500}
                                    placement="left"
                                    trigger="hover"
                                    speaker={<Tooltip className='mt-3'>{props.language.inquiry_tooltip_create_new_appliance}</Tooltip>}>
                                    <button
                                        id="reports-add-report"
                                        className="d-flex justify-content-center align-items-center border-0 rounded-circle p-2 bg-blue mx-auto"
                                        onClick={() => {
                                            setShowInquiryCreateModal(true);
                                        }}>
                                        <i className="fas fa-plus txt-12 txt-white"></i>
                                    </button>
                                </Whisper>
                            </div>
                        </div>
                        <div className='row-between align-items-center mt-3 border-bottom'>
                            <div className='col-md-auto w-50 pl-3 pr-1 mb-3'>
                                <CheckPicker
                                    style={{width: 180}}
                                    defaultValue={filterAppliancesInquiry.id_base}
                                    data={props.base_appliances}
                                    labelKey="name"
                                    valueKey="id_base_appliance"
                                    size="md"
                                    searchable={true}
                                    placeholder={props.language.inquiry_select_modal_placeholder_filter_by_type}
                                    onChange={value => { setFilterAppliancesInquiry({ ...filterAppliancesInquiry, id_base: value }) }}
                                />
                            </div>
                            <div className='col-md-auto w-50 pr-3 pl-1 mb-3'>
                                <CheckPicker
                                    style={{width: 180}}
                                    defaultValue={filterAppliancesInquiry.id_brand}
                                    data={props.brands_appliances}
                                    labelKey="name"
                                    valueKey="id_brand"
                                    size="md"
                                    searchable={true}
                                    placeholder={props.language.inquiry_select_modal_placeholder_filter_by_brand}
                                    onChange={value => { setFilterAppliancesInquiry({ ...filterAppliancesInquiry, id_brand: value }) }}
                                />
                            </div>
                        </div>
                        <div style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: window.innerHeight * 0.5}}>
                            {props.appliancesInquiry.length?
                            <Fragment>
                                <div className='w-100 px-2'>
                                    <CheckboxGroup 
                                    value={idAppliancesSelectedInquiry}
                                    defaultValue={[]}
                                    onChange={value => {
                                        let appliances =[];
                                        value.forEach(element =>{
                                            let flagAppliance = appliancesSelectedInquiry.filter(item => item.id_appliance == element);
                                            if (flagAppliance.length){
                                                appliances.push(flagAppliance[0]);
                                            }
                                            else{
                                                appliances.push({id_appliance:element, quantity:1, custom_name:null});
                                            }
                                        });
                                        setAppliancesSelectedInquiry(appliances);
                                        setIdAppliancesSelectedInquiry(value);
                                    }}>
                                        {props.appliancesInquiry.map(element=>(
                                            <div className='row w-100 align-items-center my-3 mx-0' key={element.id_appliance}> 
                                                <div className='col-auto px-0'>
                                                    {!element.id_device?
                                                    <Checkbox value={element.id_appliance}/> : <div style={{width: 36}} className='fas fa-check txt-12 text-center txt-green2'/>}
                                                </div>
                                                <div className='col-auto pl-0 pr-2 align-items-center'>
                                                {element.image?
                                                    <img src={process.env.REACT_APP_NEU_PLUS_INQUIRY_IMAGES_URL + element.image} 
                                                        width={80} height={80} style={{borderRadius: 20}} alt={element.name}></img>:
                                                    <div style={{width:80}} className='fas fa-box-open txt-light-blue my-4 text-center txt-30'/>}
                                                </div>
                                                <div className='col-6 pl-0 pr-2 align-items-center'>
                                                    <div className='txt-12 txt-bold txt-dark-blue text-wrap' style={{minHeight: 30}}>
                                                        {element.name}
                                                    </div>
                                                    {element.custom_name?
                                                        <div className='txt-10 txt-bold txt-light-blue text-wrap'>
                                                            {element.custom_name}
                                                        </div>
                                                    : null}
                                                    {appliancesSelectedInquiry.filter(value => value.id_appliance === element.id_appliance).length?
                                                        <div className='my-1'>
                                                            <Input 
                                                                placeholder={props.language.inquiry_placeholder_custom_name}
                                                                defaultValue = {appliancesSelectedInquiry.filter(value => value.id_appliance === element.id_appliance)[0].custom_name}
                                                                onChange={item =>{
                                                                    let aliasChanged = appliancesSelectedInquiry;
                                                                    let index = aliasChanged.findIndex(appliance => appliance.id_appliance === element.id_appliance);
                                                                    aliasChanged[index].custom_name = item;
                                                                    setAppliancesSelectedInquiry(aliasChanged);
                                                                }}
                                                                size="sm"/>
                                                        </div> 
                                                    : null}
                                                </div>
                                                <div className='col-auto px-0' style={{maxWidth: 50}}>
                                                    {appliancesSelectedInquiry.filter(value => value.id_appliance === element.id_appliance).length?
                                                        <SelectQuantity
                                                            defaultValue={appliancesSelectedInquiry.filter(value => value.id_appliance === element.id_appliance)[0].quantity}
                                                            onChange={item => {
                                                                        let quantityChanged = appliancesSelectedInquiry;
                                                                        let index = quantityChanged.findIndex(appliance => appliance.id_appliance === element.id_appliance);
                                                                        quantityChanged[index].quantity = item;
                                                                        setAppliancesSelectedInquiry(quantityChanged);
                                                            }}
                                                            />
                                                    : null}
                                                </div>
                                            </div>
                                        ))}
                                    </CheckboxGroup>
                                </div>
                                <div className='row-end align-items-center my-3'>
                                    <div className='w-50 pl-3 txt-11 txt-semibold txt-light-blue'>
                                        {props.language.inquiry_pagination_total_appliances}: {props.totalAppliancesInquiry}
                                    </div>
                                    <div className='w-100 row-end'>
                                        <Pagination 
                                                size="xs"
                                                total={props.totalAppliancesInquiry} 
                                                pages={Math.ceil(props.totalAppliancesInquiry/rowsPerPage)}
                                                maxButtons={3}
                                                boundaryLinks={true}
                                                ellipsis={true}
                                                prev={true}
                                                next={true}
                                                activePage={activePage}
                                                onSelect={page => setActivePage(page)}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                            :
                            <div className='w-100 my-3'>
                                <div className='text-left txt-14 txt-semibold txt-light-blue my-5 text-wrap pl-4 pr-1 pb-2'>
                                    {props.language.inquiry_select_modal_no_results}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {!props.loadingAppliancesInquiry?
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            className={`w-auto txt-12 txt-semibold txt-white border-0 rounded-pill 
                                        px-4 py-2 bg-${appliancesSelectedInquiry.length || !props.appliancesInquiry.length? "blue" : "light-blue"} ${!flagNoAppliances? "" : "mr-4"}`}
                            onClick={async () => {
                                if(appliancesSelectedInquiry.length){
                                    appliancesSelectedInquiry.map(async item => {
                                        await props.insertApplianceInquiry({appliance: item, module: props.module});
                                    });
                                    Alert.success(props.language.inquiry_alert_insert_appliances);
                                    await props.getInquiryAppliances({page:activePage, filter: filterAppliancesInquiry, module: props.module});
                                    setIdAppliancesSelectedInquiry([]);
                                    setAppliancesSelectedInquiry([]);
                                }
                                if(!props.appliancesInquiry.length){
                                    setShowInquiryCreateModal(true);
                                }
                            }}>{props.appliancesInquiry.length? props.language.inquiry_select_modal_save_appliances : props.language.inquiry_select_modal_create_appliances}</button>
                        {!flagNoAppliances?
                        <button 
                            className="row-start align-items-center txt-12 txt-semibold txt-light-blue bg-white w-auto 
                                        border-0 py-2 px-4"
                            onClick={()=>{
                                setShowInquirySelectModal(false);
                                props.setFlagInquiry(true);
                                setActivePage(1);
                            }}>
                            {props.language.inquiry_select_modal_return}
                        </button>: null}
                    </div>: null}
                </Modal.Footer>
            </Modal>


            <Modal
                size='xs'
                show={showInquiryCreateModal}
                onEnter = {() => setShowInquirySelectModal(false)}
                onHide={() => {
                    setShowInquiryCreateModal(false); 
                    setShowInquirySelectModal(true);
                    setNewApplianceInquiry(newApplianceInquiryInit)}}>
                <Modal.Header> 
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-3">{props.language.inquiry_header_create_modal}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                        <div className='align-items-center my-3'>
                            <div className='row align-items-center mx-4 mb-3'>
                                <div className="txt-light-blue txt-12 txt-semibold mb-2">{props.language.inquiry_create_modal_tittle_type_appliance}</div>
                                <div className='col px-0'>
                                    <SelectPicker
                                        value={newApplianceInquiry.id_base}
                                        style={{width: 170}}
                                        data={baseNameAppliancesCreate}
                                        labelKey="name"
                                        valueKey="id_base_appliance"
                                        className="w-100"
                                        searchable={true}
                                        placeholder={props.language.inquiry_create_modal_placeholder_type_appliance}
                                        onChange={value => { setNewApplianceInquiry({ ...newApplianceInquiry, id_base: value })  }}
                                    />
                                </div>
                                <div className='col px-0 ml-2'>
                                    <Input
                                        placeholder={props.language.inquiry_create_modal_placeholder_input_type_appliance}
                                        maxLength={20}
                                        disabled={newApplianceInquiry.id_base !== 0}
                                        onChange={value => { setNewApplianceInquiry({ ...newApplianceInquiry, base: value }) }} />
                                </div>
                            </div>
                            <div className='row align-items-center mx-4 mb-3'>
                            <div className="txt-light-blue txt-12 txt-semibold mb-2">{props.language.inquiry_create_modal_tittle_brand_appliance}</div>
                                <div className='col px-0'>
                                    <SelectPicker
                                        value={newApplianceInquiry.id_brand}
                                        style={{width: 170}}
                                        data={brandsAppliancesCreate}
                                        labelKey="name"
                                        valueKey="id_brand"
                                        className="w-100"
                                        searchable={true}
                                        placeholder={props.language.inquiry_create_modal_placeholder_brand_appliance}
                                        onChange={value => { setNewApplianceInquiry({ ...newApplianceInquiry, id_brand: value }) }}
                                    />
                                </div>
                                <div className='col px-0 ml-2'>
                                    <Input
                                        value={newApplianceInquiry.brand}
                                        placeholder={props.language.inquiry_create_modal_placeholder_input_brand_appliance}
                                        maxLength={20}
                                        disabled={newApplianceInquiry.id_brand !== 0}
                                        onChange={value => { setNewApplianceInquiry({ ...newApplianceInquiry, brand: value }) }} />
                                </div>
                            </div>
                            <div className='row mx-4 mb-2'>
                                <div className="col-8 px-0 text-left txt-light-blue txt-12 txt-semibold">{props.language.inquiry_create_modal_tittle_reference_appliance}</div>
                                <div className="col px-0 text-right txt-light-blue txt-12 txt-semibold">{props.language.inquiry_create_modal_tittle_quantity_appliance}</div>
                            </div>
                            <div className='row align-items-center mx-4 mb-3'>
                                <div className='col pl-0 pr-2'>
                                    <Input
                                        value={newApplianceInquiry.reference? newApplianceInquiry.reference : ""}
                                        placeholder={props.language.inquiry_create_modal_placeholder_input_reference_appliance}
                                        maxLength={20}
                                        onChange={value => { setNewApplianceInquiry({ ...newApplianceInquiry, reference: value }) }} />
                                </div>
                                <div className='col px-0' style={{ maxWidth: 60 }}>
                                    <InputNumber 
                                        className='rs-input-number-inquiry'
                                        min={1}
                                        defaultValue='1'
                                        onChange={value => { setNewApplianceInquiry({ ...newApplianceInquiry, quantity: parseInt(value) }) }}/>
                                </div>
                            </div>
                            <div className='w-100 px-4 mb-3'>
                                <div className="text-left text-wrap txt-light-blue txt-12 txt-semibold mb-2">{props.language.inquiry_tittle_custom_name}</div>
                                <Input 
                                    value={newApplianceInquiry.custom_name? newApplianceInquiry.custom_name : ""}
                                    maxLength={20}
                                    placeholder={props.language.inquiry_placeholder_custom_name}
                                    onChange={value => { setNewApplianceInquiry({ ...newApplianceInquiry, custom_name: value }) }}
                                    size="md"/>
                            </div>
                            <div className='row align-items-center mx-4 mb-4'>
                                <div className='col pl-0 pr-2 txt-12 text-wrap txt-semibold text-center txt-light-blue'>{props.language.inquiry_create_modal_banner_aditional_data_appliance}</div>
                                <div className='col-md-auto px-0' style={{width: 60}}>
                                    <Whisper
                                        delay={500}
                                        placement="left"
                                        trigger="hover"
                                        speaker={<Tooltip className='mt-3'>{props.language.neuplus_main_speaker_new_device}</Tooltip>}>
                                        <button
                                            id="reports-add-report"
                                            className="d-flex justify-content-center align-items-center border-0 rounded-circle p-2 bg-blue mx-auto"
                                            onClick={() => {
                                                setShowInquirySpecificationsModal(true);
                                            }}>
                                            <i className="fas fa-plus txt-12 txt-white"></i>
                                        </button>
                                    </Whisper>
                                </div>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`row-start align-items-center txt-12 txt-semibold txt-white w-auto rounded-pill px-4 
                                        py-2 bg-${((newApplianceInquiry.id_brand === 0 && newApplianceInquiry.brand) || (newApplianceInquiry.id_brand !== 0 && newApplianceInquiry.id_brand)) &&
                                            ((newApplianceInquiry.id_base === 0 && newApplianceInquiry.base) || (newApplianceInquiry.id_base !== 0 && newApplianceInquiry.id_base))? "blue" : "light-blue"}
                                            ${props.flagInquiryCreate? "mr-4" : ""}`}
                            onClick={async() => {
                                if(((newApplianceInquiry.id_brand === 0 && newApplianceInquiry.brand) || (newApplianceInquiry.id_brand !== 0 && newApplianceInquiry.id_brand)) &&
                                    ((newApplianceInquiry.id_base === 0 && newApplianceInquiry.base) || (newApplianceInquiry.id_base !== 0 && newApplianceInquiry.id_base))){
                                    await props.insertNewApplianceInquiry(newApplianceInquiry);
                                    setNewApplianceInquiry(newApplianceInquiryInit);
                                }
                            }}>
                            <div className={"txt-12 mx-1"}>{props.language.inquiry_select_create_save_appliance}</div>
                        </button>
                        {!props.flagInquiryCreate?
                        <button
                            className="row-start align-items-center txt-12 txt-semibold txt-light-blue bg-white w-auto 
                                        border-0 py-2 px-4"
                            onClick={() => {
                                setShowInquiryCreateModal(false); 
                                setShowInquirySelectModal(true);
                                setNewApplianceInquiry(newApplianceInquiryInit);
                            }}>{props.language.inquiry_select_create_return}</button> : null}
                    </div>
                </Modal.Footer>
            </Modal>



            <Modal
                size='xs'
                show={props.flagInquiry}
                onHide={()=>{
                    props.setFlagInquiry(false);
                    setActivePage(1)}}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.inquiry_header_associated_appliances_modal}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-100'>
                        <Transition
                            in={props.loadingAppliancesInquiry}
                            timeout={100}>
                            {state => (
                                <div className={`trans-fade trans-fade-${state} position-absolute bg-white w-100 h-100 top-0 left-0 zindex-2`}>
                                    <div className="column-center align-items-center w-100 h-100">
                                        <Loader center size="md" content={props.language.loading_message} />
                                    </div>
                                </div>
                            )}
                        </Transition>
                        <div className='row-start align-items-center px-1 py-3 w-100 border-bottom'>
                            <div className={`col ${props.module === "tags"? "pr-1" : ""}`}>
                                <InputGroup
                                    inside>
                                    <Input
                                        defaultValue={filterAppliancesInquiryEdit.search}
                                        placeholder={`${props.language.title_shared_search}`}
                                        onPressEnter={e => setFilterAppliancesInquiryEdit({ ...filterAppliancesInquiryEdit, search: e.target.value.toLowerCase() })} 
                                    />
                                        <InputGroup.Addon>
                                            <i className="fas fa-search txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                </InputGroup>
                            </div>
                            {props.module === "tags"?
                            <div className='col pl-1'>
                                <SelectPicker
                                        style={{width: 170}}
                                        data={devices}
                                        labelKey="address_identifier"
                                        cleanable={false}
                                        valueKey="id_device"
                                        className="w-100"
                                        searchable={false}
                                        placeholder={devices[0].address_identifier}
                                        onChange={async value => { 
                                            await props.setDeviceTagEdit(value);
                                        }}
                                />
                            </div>: null}
                        </div>
                        <div style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: window.innerHeight * 0.5}}>
                            {props.appliancesInquiry.length?
                                <Fragment>
                                    <div className='w-100 px-2'>
                                        {props.appliancesInquiry.map(element=>(
                                            <div className='row w-100 align-items-center my-3 mx-0' key={element.id_appliance}> 
                                                <div className='col-auto pl-3 pr-2 align-items-center'>
                                                    {element.image?
                                                    <img src={process.env.REACT_APP_NEU_PLUS_INQUIRY_IMAGES_URL + element.image} 
                                                        width={80} height={80} style={{borderRadius: 20}} alt={element.name}></img>:
                                                    <div style={{width:80}} className='fas fa-box-open txt-light-blue my-4 text-center txt-30'/>}
                                                </div>
                                                <div className='col-6 pl-0 pr-2 align-items-center'>
                                                    <div className='txt-12 txt-bold txt-dark-blue text-wrap' style={{minHeight: 30}}>
                                                        {element.name}
                                                    </div>
                                                    {element.custom_name?
                                                        <div className='txt-10 txt-bold txt-light-blue text-wrap'>
                                                            {element.custom_name}
                                                        </div>
                                                    : null}
                                                </div>
                                                <div className='col px-0'>
                                                    <i className='fas fa-pen txt-12 txt-light-blue txt-hover-blue c-pointer'
                                                        onClick={()=>{
                                                            setApplianceToModify(element);
                                                            setShowInquiryModifyModal(true);
                                                        }}/>
                                                </div>
                                                <div className='col px-0'>
                                                    <i className='fas fa-trash txt-12 txt-light-blue txt-hover-blue c-pointer'
                                                        onClick={()=>{
                                                            setIdApplianceDeviceInquiry(element.id_appliance_device);
                                                            setConfirmModal(true);
                                                        }}/>
                                                </div>
                                                <div className='col-auto px-0' style={{maxWidth: 50}}>
                                                    {appliancesSelectedInquiry.filter(value => value.id_appliance === element.id_appliance).length?
                                                        <SelectQuantity
                                                            defaultValue={appliancesSelectedInquiry.filter(value => value.id_appliance === element.id_appliance)[0].quantity}
                                                            onChange={item => {
                                                                        let quantityChanged = appliancesSelectedInquiry;
                                                                        let index = quantityChanged.findIndex(appliance => appliance.id_appliance === element.id_appliance);
                                                                        quantityChanged[index].quantity = item;
                                                                        setAppliancesSelectedInquiry(quantityChanged);
                                                            }}
                                                            />
                                                    : null}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='row-end align-items-center my-3'>
                                        <div className='w-50 pl-3 txt-11 txt-semibold txt-light-blue'>
                                            {props.language.inquiry_pagination_total_appliances}: {props.totalAppliancesInquiry}
                                        </div>
                                        <div className='w-100 row-end'>
                                            <Pagination 
                                                size="xs"
                                                total={props.totalAppliancesInquiry} 
                                                pages={Math.ceil(props.totalAppliancesInquiry/rowsPerPage)}
                                                maxButtons={3}
                                                boundaryLinks={true}
                                                ellipsis={true}
                                                prev={true}
                                                next={true}
                                                activePage={activePage}
                                                onSelect={page => setActivePage(page)}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            :
                            <div className='w-100 my-3'>
                                <div className='text-center txt-20 txt-semibold txt-light-blue my-5 text-wrap pl-4 pr-1 pb-2'>
                                    {props.language.inquiry_no_appliances}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {!props.loadingAppliancesInquiry?
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            className={`w-auto txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 mr-4 bg-blue`}
                            onClick={() => {
                                props.setFlagInquiry(false);
                                setActivePage(1);
                                setShowInquirySelectModal(true)}}>
                            {props.language.inquiry_associated_modal_new_appliance}</button>
                    </div>: null}
                </Modal.Footer>
            </Modal>

            <Modal
                size='xs'
                show={showConfirmModal}
                onHide={()=>setConfirmModal(false)}
                >
                <Modal.Body>
                    <div className='row-center align-items-center w-100 my-4 px-2 txt-12'>
                        {props.language.modal_confirm_delete_tag_body}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button 
                            className={`row-start align-items-center txt-12 txt-semibold txt-white w-auto rounded-pill px-4 py-2 bg-blue`}
                            onClick={async()=>{
                                await props.deleteInquiryAppliance(idApplianceDeviceInquiry);
                                setConfirmModal(false);
                                await props.getInquiryAppliancesEdit({page:activePage, filter: filterAppliancesInquiryEdit, module: props.module});
                        }}>
                            {props.language.modal_confirm_delete_tag_agree}
                        </button>
                        <button 
                            className="row-start align-items-center txt-12 txt-semibold txt-light-blue bg-white w-auto 
                                        border-0 py-2 px-4"
                            onClick={()=>setConfirmModal(false)}>
                            {props.language.modal_confirm_delete_tag_cancel}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>


            <Modal
                size='xs'
                show={showInquiryModifyModal}
                onHide={()=>setShowInquiryModifyModal(false)}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.inquiry_header_edit_appliances_modal}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-100'>
                        {applianceToModify ?
                        <div style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: window.innerHeight * 0.6}}>
                            <div className='row-start w-100 align-items-center my-3 px-3'> 
                                <div className='col-auto pl-0 pr-2 align-items-center'>
                                    {applianceToModify.image?
                                        <img src={process.env.REACT_APP_NEU_PLUS_INQUIRY_IMAGES_URL + applianceToModify.image} 
                                            width={80} height={80} style={{borderRadius: 20}} alt={applianceToModify.name}></img>:
                                        <div style={{width:80}} className='fas fa-box-open txt-light-blue my-4 text-center txt-30'/>}
                                </div>
                                <div className='col-6 px-2 mr-4 txt-12 txt-bold txt-dark-blue text-wrap'>
                                    {applianceToModify.name}
                                </div>
                                <div className='col-auto px-0' style={{maxWidth: 50}}>
                                        <SelectQuantity
                                            defaultValue={applianceToModify.quantity}
                                            onChange={item => {
                                                setApplianceToModify({ ...applianceToModify, quantity: parseInt(item) })
                                            }}
                                            />
                                </div>
                            </div>
                            <div className={`w-100 px-4 ${applianceToModify.id_verify? "": "mb-4"}`}>
                                <div className="text-left text-wrap txt-light-blue txt-12 txt-semibold mb-2">{props.language.inquiry_tittle_custom_name}</div>
                                <Input 
                                    placeholder={props.language.inquiry_placeholder_custom_name}
                                    defaultValue = {applianceToModify.custom_name}
                                    onChange={item =>{
                                        setApplianceToModify({ ...applianceToModify, custom_name: item })
                                        }}
                                        size="md"/>
                            </div>
                            {applianceToModify.id_verify?
                            <Fragment>
                                <div className='row align-items-center mx-4 mb-2 mt-4'>
                                    <div className="col pl-0 pr-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_active_power}</div>
                                    <div className="col pr-0 pl-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_reactive_power}</div>
                                </div>
                                <div className='row align-items-center mx-4 mb-4'>
                                    <div className='col pl-0 pr-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix=" kW" defaultValue={applianceToModify.active_power}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, active_power:  value ? parseFloat(value) : null })}/>
                                    </div>
                                    <div className='col pr-0 pl-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix=" kW" defaultValue={applianceToModify.reactive_power}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, reactive_power:  value ? parseFloat(value) : null })}/>
                                    </div>
                                </div>
                                <div className='row align-items-center mx-4 mb-2'>
                                    <div className="col pl-0 pr-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_monthly_cons}</div>
                                    <div className="col pr-0 pl-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_monthly_cons_reactive}</div>
                                </div>
                                <div className='row align-items-center mx-4 mb-4'>
                                    <div className='col pl-0 pr-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix="kWh" defaultValue={applianceToModify.monthly_cons}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, monthly_cons:  value ? parseFloat(value) : null })}/>
                                    </div>
                                    <div className='col pr-0 pl-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix="kWh" defaultValue={applianceToModify.monthly_reactive_cons}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, monthly_reactive_cons:  value ? parseFloat(value) : null })}/>
                                    </div>
                                </div>
                                <div className='row align-items-center mx-4 mb-2'>
                                    <div className="col pl-0 pr-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_weight}</div>
                                    <div className="col pr-0 pl-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_height}</div>
                                </div>
                                <div className='row align-items-center mx-4 mb-4'>
                                    <div className='col pl-0 pr-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix=" kg" defaultValue={applianceToModify.weight}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, weight:  value ? parseFloat(value) : null })}/>
                                    </div>
                                    <div className='col pr-0 pl-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix=" cm" defaultValue={applianceToModify.height}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, height:  value ? parseFloat(value) : null })}/>
                                    </div>
                                </div>
                                <div className='row align-items-center mx-4 mb-2'>
                                    <div className="col pl-0 pr-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_width}</div>
                                    <div className="col pr-0 pl-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_depth}</div>
                                </div>
                                <div className='row align-items-center mx-4 mb-4'>
                                    <div className='col pl-0 pr-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix=" cm" defaultValue={applianceToModify.width}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, width: value ? parseFloat(value) : null })}/>
                                    </div>
                                    <div className='col pr-0 pl-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix=" cm" defaultValue={applianceToModify.depth}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, depth:  value ? parseFloat(value) : null })}/>
                                    </div>
                                </div>
                            </Fragment> :  null}
                        </div>
                        : null}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`row-start align-items-center txt-12 txt-semibold txt-white w-auto rounded-pill px-4 
                                        py-2 bg-blue`}
                            onClick={async() => {
                                await props.updateApplianceInquiry(applianceToModify);
                                setShowInquiryModifyModal(false); 
                                await props.getInquiryAppliancesEdit({page:activePage, filter: filterAppliancesInquiryEdit, module: props.module});
                            }}>
                            <div className={"txt-12 mx-1"}>{props.language.inquiry_edit_save_appliance}</div>
                        </button>
                        <button
                            className="row-start align-items-center txt-12 txt-semibold txt-light-blue bg-white w-auto 
                                        border-0 py-2 px-4"
                            onClick={() => {
                                setShowInquiryModifyModal(false); 
                            }}>{props.language.inquiry_edit_cancel}</button>
                    </div>
                </Modal.Footer>
            </Modal>


            <Modal
                size='xs'
                show={showInquirySpecificationsModal}
                onHide={()=>{
                    setShowInquirySpecificationsModal(false);
                    setSpecifications(specificationsInit);}}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.inquiry_header_specifications_modal}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-100'>
                            <div className='row align-items-center mx-4 mb-2 mt-4'>
                                <div className="col pl-0 pr-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_active_power}</div>
                                <div className="col pr-0 pl-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_reactive_power}</div>
                            </div>
                            <div className='row align-items-center mx-4 mb-4'>
                                <div className='col pl-0 pr-2'>
                                    <InputNumber min={0} className='rs-input-number-edit' postfix=" kW"
                                        onChange={value => setSpecifications({ ...specifications, active_power:  value ? parseFloat(value) : null })}/>
                                </div>
                                <div className='col pr-0 pl-2'>
                                    <InputNumber min={0} className='rs-input-number-edit' postfix=" kW"
                                        onChange={value => setSpecifications({ ...specifications, reactive_power:  value ? parseFloat(value) : null })}/>
                                </div>
                            </div>
                            <div className='row align-items-center mx-4 mb-2'>
                                <div className="col pl-0 pr-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_monthly_cons}</div>
                                <div className="col pr-0 pl-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_monthly_cons_reactive}</div>
                            </div>
                            <div className='row align-items-center mx-4 mb-4'>
                                <div className='col pl-0 pr-2'>
                                    <InputNumber min={0} className='rs-input-number-edit' postfix="kWh"
                                        onChange={value => setSpecifications({ ...specifications, monthly_cons:  value ? parseFloat(value) : null })}/>
                                </div>
                                <div className='col pr-0 pl-2'>
                                    <InputNumber min={0} className='rs-input-number-edit' postfix="kWh"
                                        onChange={value => setSpecifications({ ...specifications, monthly_reactive_cons:  value ? parseFloat(value) : null })}/>
                                </div>
                            </div>
                            <div className='row align-items-center mx-4 mb-2'>
                                <div className="col pl-0 pr-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_weight}</div>
                                <div className="col pr-0 pl-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_height}</div>
                            </div>
                            <div className='row align-items-center mx-4 mb-4'>
                                <div className='col pl-0 pr-2'>
                                    <InputNumber min={0} className='rs-input-number-edit' postfix=" kg"
                                        onChange={value => setSpecifications({ ...specifications, weight:  value ? parseFloat(value) : null })}/>
                                </div>
                                <div className='col pr-0 pl-2'>
                                    <InputNumber min={0} className='rs-input-number-edit' postfix=" cm"
                                        onChange={value => setSpecifications({ ...specifications, height:  value ? parseFloat(value) : null })}/>
                                </div>
                            </div>
                            <div className='row align-items-center mx-4 mb-2'>
                                <div className="col pl-0 pr-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_width}</div>
                                <div className="col pr-0 pl-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_depth}</div>
                            </div>
                            <div className='row align-items-center mx-4 mb-4 pb-4'>
                                <div className='col pl-0 pr-2'>
                                    <InputNumber min={0} className='rs-input-number-edit' postfix=" cm"
                                        onChange={value => setSpecifications({ ...specifications, width: value ? parseFloat(value) : null })}/>
                                </div>
                                <div className='col pr-0 pl-2'>
                                    <InputNumber min={0} className='rs-input-number-edit' postfix=" cm"
                                        onChange={value => setSpecifications({ ...specifications, depth:  value ? parseFloat(value) : null })}/>
                                </div>
                            </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`row-start align-items-center txt-12 txt-semibold txt-white w-auto rounded-pill px-4 
                                        py-2 bg-blue`}
                            onClick={async() => {
                                setNewApplianceInquiry({...newApplianceInquiry, specifications : specifications});
                                setShowInquirySpecificationsModal(false);
                            }}>
                            <div className={"txt-12 mx-1"}>{props.language.inquiry_specifications_modal_confirm}</div>
                        </button>
                        <button
                            className="row-start align-items-center txt-12 txt-semibold txt-light-blue bg-white w-auto 
                                        border-0 py-2 px-4"
                            onClick={() => {
                                setShowInquirySpecificationsModal(false); 
                                setSpecifications(specificationsInit);
                            }}>{props.language.inquiry_specifications_modal_cancel}</button>
                    </div>
                </Modal.Footer>
            </Modal>

        </Fragment>
    )
}

                                    


const mapStateToProps = state => ({
    user: state.login.user,
    language: state.shared.language,
    locations: state.neuPlus.locations,
    activeLocations: state.neuPlus.activeLocations,
    devices: state.neuPlus.devices,
    activeDevices: state.neuPlus.activeDevices,
    appliancesInquiry: state.neuPlus.appliancesInquiry,
    base_appliances: state.neuPlus.base_appliances,
    brands_appliances: state.neuPlus.brands_appliances,
    totalAppliancesInquiry: state.neuPlus.totalAppliancesInquiry,
    loadingAppliancesInquiry: state.neuPlus.loadingAppliancesInquiry,
    flagInquiry: state.neuPlus.flagInquiry,
    flagInquiryCreate: state.neuPlus.flagInquiryCreate,
    appliances: state.neuPlus.appliances,
    deviceTagEdit: state.neuPlus.deviceTagEdit,
})

const mapDispatchToProps = dispatch => ({
    getInquiryAppliances: payload => dispatch(neuplusActions.getInquiryAppliances(payload)),
    getInquiryAppliancesEdit: payload => dispatch(neuplusActions.getInquiryAppliancesEdit(payload)),
    getBaseAppliances: () => dispatch(neuplusActions.getBaseAppliances()),
    getBrandsAppliances: () => dispatch(neuplusActions.getBrandsAppliances()),
    insertNewApplianceInquiry: payload => dispatch(neuplusActions.insertNewApplianceInquiry(payload)),
    insertApplianceInquiry: payload => dispatch(neuplusActions.insertApplianceInquiry(payload)),
    deleteInquiryAppliance: payload => dispatch(neuplusActions.deleteInquiryAppliance(payload)),
    updateApplianceInquiry: payload => dispatch(neuplusActions.updateApplianceInquiry(payload)),
    setFlagInquiry: payload => dispatch(neuplusActions.setFlagInquiry(payload)),
    setFlagInquiryCreate: payload => dispatch(neuplusActions.setFlagInquiryCreate(payload)),
    loadAppliances: () => dispatch(neuplusActions.loadAppliances()),
    setDeviceTagEdit: payload => dispatch(neuplusActions.setDeviceTagEdit(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Inquiry)