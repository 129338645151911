import React, { useState } from 'react';
import { connect } from 'react-redux';

import * as sharedActions from '../../store/actions/Shared';

import { Transition } from 'react-transition-group';

import logo from '../../imgs/logo-neu-white.svg';
import neugetsLogo from '../../imgs/neugets-logo.svg';
import neugetsBackground from '../../imgs/neugets-background.png';

import { getAnalytics, logEvent } from "firebase/analytics";

import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const Neugets = (props) => {

    const [showCard, setShowCard] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    const fullName = props.user.name + ' ' + props.user.lastname;
    const neugets = props.user.neugets;
    const ranking = props.user.ranking;

    return (
        <div id="neugets-container"
            className="position-relative mx-md-2 ml-md-0 ml-2 c-pointer"
            onClick={() => {
                setShowCard(true);
                props.setBlackScreen(true);                
                amplitude.getInstance().logEvent('Home NEUgets Button Tapped');     
            }}
            onMouseLeave={() => {
                setShowCard(false);
                props.setBlackScreen(false);
            }}>
            <Transition
                in={showCard}
                timeout={100}
                onEntered={() => {setShowInfo(true); amplitude.getInstance().logEvent('NEUgets Viewed');}}
                onExited={() => setShowInfo(false)}>
                {state => (
                    <div className={`position-absolute overflow-hidden top-0 right-0 trans-neugets trans-neugets-${state} ${showCard ? "zindex-3" : "zindex-1"}`}>
                        {!showInfo ?
                            <div className="row-start align-items-center bg-black rounded-pill p-1 w-100 h-100">
                                <div className="col-3 p-0">
                                    <div id="neugets-logo-small" className="row-center align-items-center rounded-circle p-1">
                                        <img src={neugetsLogo} alt="neugets-logo" className="m-1 w-100 h-100"></img>
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="txt-10 txt-white txt-semibold text-center c-pointer">{neugets}</div>
                                </div>
                            </div> :
                            <div className="position-relative bg-black w-100 h-100 rounded shadow">
                                <div className="position-absolute text-right w-100 h-100 top-0 right-0">
                                    <img src={neugetsBackground} alt="neugets-background" className="h-100"></img>
                                </div>
                                <div className="position-absolute column-between align-items-start w-100 h-100 top-0 right-0 p-3">
                                    <div className="row-between align-items-center w-100">
                                        <div className="txt-10 txt-semibold txt-white w-auto">{fullName}</div>
                                        <img src={logo} className="ml-2" alt="neu-logo" style={{ width: 50 }}></img>
                                    </div>
                                    <div className="w-100">
                                        <div className="row-start align-items-start">
                                            <div id="neugets-logo-big" className="row-center align-items-center rounded-circle mt-1 p-1">
                                                <img src={neugetsLogo} alt="neugets-logo" className="m-2 w-100 h-100"></img>
                                            </div>
                                            <div className="txt-20 txt-bold txt-white ml-2 w-auto">{neugets}</div>
                                        </div>
                                        <div className="txt-10 txt-bold txt-white ml-5">Neugets</div>
                                    </div>
                                    <div className="row-between align-items-center w-100">
                                        <div className="column-start align-items-start">
                                            <div className="txt-12 txt-bold txt-light-blue">Ranking</div>
                                            <div className="txt-14 txt-bold txt-green">{ranking}</div>
                                        </div>
                                        <button className="txt-12 txt-semibold txt-reactive-green2 border-0 rounded-pill px-4 py-2 w-auto bg-reactive-green"
                                            onClick={() => {

                                                logEvent(analytics, "click_home_neugets", {
                                                    id_user: props.user.id_user,
                                                    name: `${props.user.name} ${props.user.lastname}`
                                                });

                                                amplitude.getInstance().logEvent('I want more NEUgets');
            
                                                props.setPopupShare(true);

                                            }}>{props.language.button_neugets_want_more}</button>
                                    </div>
                                </div>
                            </div>}
                    </div>
                )}
            </Transition>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    language: state.shared.language
})

const mapDispatchToProps = dispatch => ({
    setBlackScreen: payload => dispatch(sharedActions.setBlackScreen(payload)),
    setPopupShare: payload => dispatch(sharedActions.setPopupShare(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Neugets)
