import React, { useState } from 'react';
import { connect } from 'react-redux';

import * as sharedActions from '../../store/actions/Shared';

import { SelectPicker, Input, Modal } from 'rsuite';

import amplitude from 'amplitude-js';

const Support = (props) => {

    const categories = [
        {
            value: "comercial",
            label: props.language.option_support_comercial
        },
        {
            value: "financial",
            label: props.language.option_support_financial
        },
        {
            value: "tech",
            label: props.language.option_support_tech
        },
        {
            value: "operations",
            label: props.language.option_support_operations
        },
        {
            value: "others",
            label: props.language.option_support_others
        }
    ];

    const initSupport = {
        comment: '',
        category: categories[2].value
    }

    const [support, setSupport] = useState(initSupport);

    return (
        <Modal
            size="sm"
            show={props.showPopupSupport}
            onEnter={() => setSupport(initSupport)}
            onHide={() => {props.setPopupSupport(false); amplitude.getInstance().logEvent('Suggestions Closed');}}>
            <Modal.Header>
                <div className="row-end align-items-center h-100 w-100">
                    <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.title_menu_support}</div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column p-3">
                    <div className="col-12 my-2">
                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_support_type}</div>
                        <SelectPicker
                            className="w-100"
                            searchable={false}
                            cleanable={false}
                            data={categories}
                            defaultValue={support.category}
                            onChange={value => setSupport({ ...support, category: value })}
                        />
                    </div>
                    <div className="col-12 my-2">
                        <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_support_comment}</div>
                        <Input
                            rows={5}
                            maxLength={255}
                            componentClass="textarea"
                            placeholder={props.language.placeholder_support_comment_example}
                            onChange={value => setSupport({ ...support, comment: value })} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row-end align-items-center h-100 w-100">
                    <button
                        style={{ width: 150 }}
                        className={`txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 w-auto mr-4 bg-${support.comment !== "" ? "blue" : "light-blue"}`}
                        onClick={() => {
                            if (support.comment !== "") {
                                amplitude.getInstance().logEvent('Suggestions Send');
                                props.setSupportAsync(support);
                                props.setPopupSupport(false);
                            }
                        }}>
                        {props.language.title_support_popup_send}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    showPopupSupport: state.shared.showPopupSupport
})

const mapDispatchToProps = dispatch => ({
    setPopupSupport: payload => dispatch(sharedActions.setPopupSupport(payload)),
    setSupportAsync: payload => dispatch(sharedActions.setSupportAsync(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Support);