import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Challenges from './powerChallenge/Challenges';

import { Rate } from 'rsuite';

const GameCard = (props) =>{

    return(
        <Fragment>
            <div className='row-between aling-items-center w-100 bg-green rounded p-3'>
                <div className='column-start align-items-center'>
                    <div className='txt-bold txt-14 text-wrap' style={{color:props.game.prefix_name_color}}>{props.game.prefix_name} <span style={{color:props.game.suffix_name_color}}>{props.game.suffix_name}</span></div>
                    <div className='txt-regular txt-8 txt-white text-wrap py-2'>{props.game.description}</div>
                </div>
                <img style={{ width: 115, height: 110 }} src={props.game.image} alt="Power Challeger"></img>
            </div>
            <i className="py-2 w-100 fas fa-chevron-down txt-18 txt-green text-center"/>
            <Challenges survey={props.game.survey} id_game={props.game.id_game}/>
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => ({
    user: state.login.user,
    game: ownProps.game
})

export default connect(mapStateToProps)(GameCard)