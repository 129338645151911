import * as creators from '../creators/Notifications';

const initialState = {
    totalData: [],
    availableData: [],
    activeData: [],
    popupData: [],
    loading: true,
    popupLoading: true
}

export const notificacionsReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case creators.SET_NOTIFICATIONS_DATA:
            return { ...state, totalData: payload };
        case creators.SET_NOTIFICATIONS_AVAILABLE_DATA:
            return { ...state, availableData: payload };
        case creators.SET_NOTIFICATIONS_ACTIVE_DATA:
            return { ...state, activeData: payload };
        case creators.SET_NOTIFICATIONS_POPUP_DATA:
            return { ...state, popupData: payload };
        case creators.SET_NOTIFICATIONS_POPUP_LOADING:
            return { ...state, popupLoading: payload };
        case creators.SET_NOTIFICATIONS_LOADING:
            return { ...state, loading: payload };
        default:
            return state;
    }

}