import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Loader, Drawer } from 'rsuite';

import { Transition } from 'react-transition-group';

import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';

import { format, parse } from 'date-fns';
import * as tagsActions from '../../../store/actions/Tags';

import esLocale from "date-fns/locale/es";
import enLocale from 'date-fns/locale/en-US';

import amplitude from 'amplitude-js';

const color = [
    "#A1C52A",
    "#6DB639",
    "#CAD515",
    "#ADD081",
    "#27AA58",
    "#619630",
    "#1C7131",
    "#2F5123",
    "#184E24",
    "#202D19",
    "#31B08A",
    "#009C75",
    "#007866",
    "#006F83",
    "#006F83",
    "#42ACE2",
    "#2599A5",
    "#006DB1",
    "#026577",
    "#20335F"
];

const fill = [
    {
        match: '*',
        id: 'gradient'
    }];

const defs = [
    linearGradientDef('gradient', [
        { offset: 0, color: 'inherit' },
        { offset: 100, color: 'inherit', opacity: 0 }
    ])
];

const SetZoom = (action, value, user) => {

    const change = action ? 10 : -10;

    const result = value + change;

    amplitude.getInstance().logEvent('Report Zoomed', {
        zoom: result
    });

    if (result >= 10 && result <= 80) {
        return result;
    } else {
        return value;
    }

}

const DashboardGraph = (props) => {

    const [zoom, setZoom] = useState(20);

    const [dateNeuPlus, setDateNeuPlus] = useState("");

    const [valueNeuPlus, setValueNeuPlus] = useState(0);

    let graph;

    let hasData = props.graphData.length;

    const [enableDrawer, setEnableDrawer] = useState(false);

    const [locationAppliance, setLocationAppliance] = useState(false);

    let total = 0;

    let typeGraphDissagregator = [
        {id_graph: 1, type: 'cons'},
        {id_graph: 5, type: 'r_cons'}
    ];

    let [typeVariable, setTypeVariable]= useState(typeGraphDissagregator[0].type);

    let [unitVariable, setUnitVariable]= useState("");

    let locale = props.language.language_locale == "en" ? enLocale : esLocale;

    if (props.graphType === "linear") {
        graph = <ResponsiveLine
            data={props.graphData}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
            margin={{ top: 60, right: 40, bottom: 120, left: 40 }}
            curve="catmullRom"
            colors={color}
            defs={defs}
            fill={fill}
            areaOpacity={0.8}
            enableArea={true}
            enablePoints={false}
            enableGridX={false}
            enableGridY={false}
            useMesh={true}
            animate={false}
            axisTop={null}
            axisRight={null}
            //enableSlices="x"
            onClick={async value => { 
                try{
                    setLocationAppliance(value.serieId);
                    const acronym = value.serieId.split(' - ')[0];
                    const type = value.serieId.split(' - ')[1];
                    const device = props.activeLocations.filter(element => element.acronym === acronym);
                    let locations = device.map(element => element.dissagregators.map(value => value.id_device))[0];
                    const graphSelected = props.graphs.filter(value => value.label === type)[0];
                    const typeVariableDissagregator = typeGraphDissagregator.filter(value => value.id_graph === graphSelected.id_graph);
                    setUnitVariable(graphSelected.unit);
                    setValueNeuPlus(value.data.y);
                    if (locations.length && typeVariableDissagregator.length){
                        setTypeVariable(typeVariableDissagregator[0].type);
                        const time = parse(value.data.x, 'HH:mm dd/MM/yy', new Date(), { locale: locale });
                        const timeStamp = format(time, "yyyy-MM-dd'T'HH:mm:ss");
                        setDateNeuPlus(format(time, "dd MMMM, yyyy HH:mm", { locale: locale }));
                        let payload = {
                            from: timeStamp,
                            type: props.graphInterval,
                            devices: locations.toString()
                        }
                        await props.loadPredictionTagsAsync(payload);
                        setEnableDrawer(true);}
                }
                catch(err){console.error(err)}
            }}
            tooltip={({ point }) => (
                <div className="column-between align-items-center bg-white p-2 shadow" style={{ borderRadius: 6 }}>
                    <div className="row-start align-items-center w-100 mr-1 ml-auto">
                        <div style={{ background: point.serieColor, height: '12px', width: '12px' }} />
                        <div className="row-between align-items-center txt-dark-blue txt-semibold txt-10">
                            <div className="mx-2">{`${point.serieId}`}</div>
                            <div>{`${point.data.unit === 'COP' ? '$' : ''} ${point.data.y} ${point.data.unit}`}</div>
                        </div>
                    </div>
                </div>
            )}
                
            // sliceTooltip={({ slice }) => (
            //     <div className="column-between align-items-start bg-white p-2 shadow">
            //         {slice.points.map((point) => (
            //             <div key={point.id} className="row-start align-items-center w-100">
            //                 <div style={{ background: point.serieColor, height: '12px', width: '12px' }} />
            //                 <div className="row-between align-items-center txt-dark-blue txt-semibold txt-10">
            //                     <div className="mx-2">{`${point.serieId}`}</div>
            //                     <div>{`${point.data.unit === 'COP' ? '$' : ''} ${point.data.y} ${point.data.unit}`}</div>
            //                 </div>
            //             </div>
            //         ))}
            //     </div>
            // )}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                renderTick: (data => {
                    let text = <g transform={`translate(${data.x},${data.y})`} style={{ opacity: 1 }}>
                        <text
                            className="txt-8 txt-semibold"
                            alignmentBaseline={data.textBaseline}
                            textAnchor={data.textAnchor}
                            transform={`translate(${data.textX},${data.textY}) rotate(${data.rotate})`}>
                            {data.value}
                        </text>
                    </g>
                    text = data.tickIndex % 2 === 0 ? text : null;
                    return (text);
                })
            }}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 90,
                renderTick: (data => {
                    let text = <g transform={`translate(${data.x},${data.y})`} style={{ opacity: 1 }}>
                        <text
                            className="txt-8 txt-semibold"
                            alignmentBaseline={data.textBaseline}
                            textAnchor={data.textAnchor}
                            transform={`translate(${data.textX},${data.textY}) rotate(${data.rotate})`}>
                            {props.user.hide_invoices && props.graphInterval !== 3? format(parse(data.value, 'HH:mm dd/MM/yy', new Date(), { locale: locale }), "HH:mm MM/dd/yy", { locale: locale }) : data.value}
                        </text>
                    </g>
                    const mod = zoom === 10 ? 4 : 2;
                    text = zoom <= 30 ? data.tickIndex % mod === 0 ? text : null : text;
                    return (text);
                })
            }}
            legends={[
                {
                    anchor: 'bottom-left',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 110,
                    itemsSpacing: 100,
                    itemDirection: 'left-to-right',
                    itemWidth: 100,
                    itemHeight: 12,
                    itemOpacity: 1,
                    symbolSize: 10,
                    symbolShape: 'square',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}>
        </ResponsiveLine>;

    } else if (props.graphType === "bars") {

        let keys = [];
        let values = [];

        props.graphData.forEach(project => {

            keys.push(project.id);

            project.data.forEach((value, i) => {
                values[i] = {
                    ...values[i],
                    date: value.x,
                    [project.id]: value.y,
                    unit: value.unit
                }
            });

        });
        graph = <ResponsiveBar
            data={values}
            indexBy="date"
            keys={keys}
            groupMode="grouped"
            margin={{ top: 60, right: 40, bottom: 100, left: 40 }}
            indexScale={{ type: 'band', round: false }}
            colors={color}
            borderRadius={2}
            enableLabel={false}
            enableGridX={false}
            enableGridY={false}
            animate={false}
            axisTop={null}
            axisRight={null}
            onClick={async value => { 
                try{
                    setLocationAppliance(value.id);
                    const acronym = value.id.split(' - ')[0];
                    const type = value.id.split(' - ')[1];
                    const device = props.activeLocations.filter(element => element.acronym === acronym);
                    let locations = device.map(element => element.dissagregators.map(value => value.id_device))[0];
                    const graphSelected = props.graphs.filter(value => value.label === type)[0];
                    const typeVariableDissagregator = typeGraphDissagregator.filter(value => value.id_graph === graphSelected.id_graph);
                    setUnitVariable(graphSelected.unit);
                    setValueNeuPlus(value.value);
                    if (locations.length && typeVariableDissagregator.length){
                        setTypeVariable(typeVariableDissagregator[0].type);
                        let timeStamp;
                        let payload;
                        if(props.graphInterval === 3){
                            timeStamp = format(new Date(props.graphDateFrom), "yyyy-MM-dd");
                            setDateNeuPlus(format(new Date(props.graphDateFrom), "dd MMMM, yyyy", { locale: locale }));
                            const to = format(new Date(props.graphDateTo), "yyyy-MM-dd");
                            payload = {
                                from: timeStamp,
                                type: props.graphInterval,
                                devices: locations.toString(),
                                to: to
                            }
                        }
                        else{
                            const date = value.data.date.split("/");
                            timeStamp = "20"+ date[2]+"-"+date[1]+"-"+date[0]+"T00:00:00";
                            setDateNeuPlus(format(new Date(timeStamp), "dd MMMM, yyyy", { locale: locale }));
                            payload = {
                                from: timeStamp,
                                type: props.graphInterval,
                                devices: locations.toString()
                            }
                        }
                        await props.loadPredictionTagsAsync(payload);
                        setEnableDrawer(true); }
                }
                catch(err){console.error(err)}
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                renderTick: (data => {
                    let text = <g transform={`translate(${data.x},${data.y})`} style={{ opacity: 1 }}>
                        <text
                            className="txt-8 txt-semibold"
                            alignmentBaseline={data.textBaseline}
                            textAnchor={data.textAnchor}
                            transform={`translate(${data.textX},${data.textY}) rotate(${data.rotate})`}>
                            {data.value}
                        </text>
                    </g>
                    text = data.tickIndex % 2 === 0 ? text : null;
                    return (text);
                })
            }}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 90,
                renderTick: (data => {
                    let text =
                        <g transform={`translate(${data.x},${data.y})`}
                            style={{ opacity: 1 }}>
                            <text
                                className="txt-8 txt-semibold"
                                alignmentBaseline={data.textBaseline}
                                textAnchor={data.textAnchor}
                                transform={`translate(${data.textX},${data.textY}) rotate(${data.rotate})`}>
                                {props.user.hide_invoices && props.graphInterval !== 3? format(parse(data.value, 'dd/MM/yy', new Date(), { locale: locale }), "MM/dd/yy", { locale: locale }) : data.value}
                            </text>
                        </g>
                    const mod = zoom === 10 ? 4 : 2;
                    text = props.graphData.length <= 10 ? text : zoom <= 30 ? data.tickIndex % mod === 0 ? text : null : text;
                    return (text);
                })
            }}
            tooltip={(point) => {
                return (
                    <div className="row-start align-items-center">
                        <div style={{ background: point.color, height: '12px', width: '12px' }} />
                        <div className="txt-dark-blue txt-semibold txt-10 ml-2">{`${point.id}: ${point.data.unit === 'COP' ? "$" : ""} ${point.value} ${point.data.unit}`}</div>
                    </div>
                )
            }}
            legends={[
                {
                    anchor: 'bottom-left',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 80,
                    itemsSpacing: 100,
                    itemDirection: 'left-to-right',
                    itemWidth: 100,
                    itemHeight: 12,
                    itemOpacity: 1,
                    symbolSize: 10,
                    symbolShape: 'square',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}>
        </ResponsiveBar>

    }
    if(props.predictions.length){
        props.predictions.forEach(element => total += element[typeVariable]);
    }
    return (
        <Fragment>
            <Drawer 
                size="xs"
                show={enableDrawer} 
                onHide={() => setEnableDrawer(false)}>
                <Drawer.Header>
                    <div className="row-center align-items-center h-100 w-100">
                        <div className="txt-16 txt-bold txt-dark-blue mx-4">NEU+</div>
                    </div>
                </Drawer.Header>
                <Drawer.Body>
                    <Transition
                        in={props.loadingPredictions}
                        timeout={100}>
                        {state => (
                            <div className={`trans-fade trans-fade-${state} position-absolute bg-white w-100 h-100 top-0 left-0 zindex-2`}>
                                <div className="column-center align-items-center w-100 h-100">
                                    <Loader center size="md" content={props.language.loading_message} />
                                </div>
                            </div>
                        )}
                    </Transition>
                    <div className="d-flex justify-content-end w-100">
                        <div className="txt-12 txt-light-blue mx-3 my-2">{dateNeuPlus}</div>
                    </div>
                    {props.predictions.length?
                    props.predictions.map((element, index) => 
                        <div className='w-auto mx-3' key={element.id_appliance_device}>
                            <div className={`d-flex justify-content-between w-100 ${index !== 0 ? "border-top":""}`}>
                                <div className="txt-12 txt-bold txt-dark-blue mt-2 mb-1 py-2">{element.name}</div>
                                <div className="txt-12 txt-dark-blue mt-2 mb-1 bg-blue rounded-pill py-2 px-4 w-auto">{(element[typeVariable]*100/valueNeuPlus).toFixed(2)}%</div>
                            </div>
                            <div className="d-flex justify-content-start w-100">
                                <div className="txt-12 txt-light-blue my-1">{locationAppliance}</div>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <div className="txt-9 txt-dark-blue w-100 mt-1 mb-2">{props.language.neuplus_accuracy_prediction} {element.accuracy}%</div>
                                <div className="txt-12 txt-bold txt-dark-blue d-flex justify-content-end mt-1 mb-2">{element[typeVariable].toFixed(2)} {unitVariable}</div>
                            </div>
                        </div>)
                    : null}
                    <div className='w-auto mx-3'>
                        <div className={`d-flex justify-content-between w-100 ${props.predictions.length? "border-top":""}`}>
                            <div className="txt-12 txt-bold txt-dark-blue mt-2 mb-1 py-2">{props.language.neuplus_predictions_unknown}</div>
                            <div className="txt-12 txt-light-blue mt-2 mb-1 bg-clear-gray rounded-pill py-2 px-4 w-auto">{valueNeuPlus - total > 0 ? ((valueNeuPlus - total)*100/valueNeuPlus).toFixed(2) : 0}%</div>
                        </div>
                        <div className="d-flex justify-content-start w-100">
                            <div className="txt-12 txt-light-blue my-1">{locationAppliance}</div>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <div className="txt-10 txt-dark-blue w-100 my-1">{props.language.neuplus_accuracy_prediction} 100%</div>
                            <div className="txt-12 txt-bold txt-dark-blue d-flex justify-content-end my-1">{valueNeuPlus - total > 0 ? (valueNeuPlus - total).toFixed(2) : 0} {unitVariable}</div>
                        </div>
                    </div>
                </Drawer.Body>
            </Drawer>
            <div className="row-between align-items-center flex-grow-1 w-100">
                <div className="position-relative row-center align-items-center overflow-hidden h-100 w-100">
                    {hasData ?
                        <Fragment>
                            <div className="position-absolute overflow-auto h-100 w-100">
                                <div id="home-graph-container" className="h-100 overflow-hidden"
                                    style={{ width: props.graphData[0].data.length * zoom }}>
                                    {graph}
                                </div>
                            </div>
                            <div className="position-absolute column-center align-items-center right-0 top-0">
                                <i className="fa fa-search-plus home-graph-zoom txt-12 txt-white bg-black c-pointer p-2 m-2" onClick={() => setZoom(SetZoom(true, zoom, props.user))}></i>
                                <i className="fa fa-search-minus home-graph-zoom txt-12 txt-white bg-black c-pointer p-2 m-2" onClick={() => setZoom(SetZoom(false, zoom, props.user))}></i>
                            </div>
                        </Fragment> :
                        !props.activeLocations.length ?
                            <div className="row-center align-items-center w-100">
                                <i className="fas fa-plus-circle txt-20 txt-light-blue" />
                                <div className="txt-20 txt-bold txt-light-blue mx-3 w-auto">{props.language.title_home_select_location}</div>
                            </div> : !props.activeGraphs.length ?
                                <div className="row-center align-items-center w-100">
                                    <i className="fas fa-poll txt-20 txt-light-blue" />
                                    <div className="txt-20 txt-bold txt-light-blue mx-3 w-auto">{props.language.title_home_select_graph}</div>
                                </div> : null}
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    language: state.shared.language,
    activeGraphs: state.home.activeGraphs,
    activeLocations: state.home.activeLocations,
    graphData: state.home.graphData,
    graphType: state.home.graphType,
    graphInterval: state.home.graphInterval,
    graphDateFrom: state.home.graphDateFrom,
    graphDateTo: state.home.graphDateTo,
    predictions: state.tags.predictions,
    graphs: state.home.graphs,
    loadingPredictions: state.tags.loadingPredictions
})

const mapDispatchToProps = dispatch => ({
    loadPredictionTagsAsync: payload => dispatch(tagsActions.loadPredictionTagsAsync(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardGraph)
