import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as homeActions from '../../../store/actions/Home';

import { Loader, Drawer, Input, InputGroup, Alert } from 'rsuite';

import { SETFile } from '../../../services/WebServices';

import { Fragment } from 'react';

const isValidProject = project => {

    if (project.address === null || project.address === "") {
        return false;
    }

    if (project.average_consumption === null || project.average_consumption === "") {
        return false;
    }

    if (project.cost_kwh === null || project.cost_kwh === "") {
        return false;
    }

    return true;

}

const showError = (project, props) => {

    if (project.address === null || project.address === "") {
        Alert.error(props.language.alert_info_project_address);
        return;
    }

    if (project.average_consumption === null || project.average_consumption === "") {
        Alert.error(props.language.alert_info_project_average_consumption);
        return;
    }

    if (project.cost_kwh === null || project.cost_kwh === "") {
        Alert.error(props.language.alert_info_project_costo_kwh);
        return;
    }

}

const setProjectImage = (locations, setLocations, activeLocations, setActiveLocationsInfo, project, setProject, setIsLoading, e) => {

    const file = e.target.files[0];

    const data = new FormData();

    const metadata = {
        id: project.id_project,
        folder: "locations/photos"
    }

    data.append("file", file);
    data.append("metadata", JSON.stringify(metadata));

    setIsLoading(true);

    SETFile("projects/image", "PUT", data)
        .then(result => {

            const index = locations
                .findIndex(location => location.id_project === project.id_project);

            locations[index].image = result.name;
            setLocations([...locations]);

            if (activeLocations.length) {

                const locationsTemp = activeLocations;

                setActiveLocationsInfo(activeLocations.filter(x => x.id_project !== project.id_project));
                setActiveLocationsInfo([...locationsTemp]);

            }

            setIsLoading(false);
            setProject({ ...project, image: result.name });
        });

}

const LocationInfo = (props) => {

    const infoLocation = props.infoLocation;

    const [isLoading, setIsLoading] = useState(false);

    const [project, setProject] = useState(infoLocation);

    useEffect(() => setProject(infoLocation), [infoLocation]);

    return (
        <Fragment>
            {Object.entries(project).length !== 0 ?
                <Drawer
                    size="xs"
                    show={true}
                    onHide={() => props.setInfoLocation({})}>
                    <Drawer.Header>
                        <div className="row-center align-items-center h-100 w-100">
                            <div className="txt-16 txt-bold txt-dark-blue mx-4">{project.name ?? ""}</div>
                        </div>
                    </Drawer.Header>
                    <Drawer.Body>
                        <div className="p-4">
                            <div className="column-center align-items-center w-100">
                                <div className="position-relative row-center align-items-center">
                                    <div id="shared-profile-image" className={`position-relative overflow-hidden row-center align-items-center border-0 rounded-circle ${project.image === null ? "bg-blue" : null}`}>
                                        {isLoading ?
                                            <div className="black-screen position-absolute row-center align-items-center w-100 h-100 zindex-1">
                                                <Loader inverse />
                                            </div> : null}
                                        {project.image === null ?
                                            <i className="fas fa-building txt-30 txt-dark-blue3"></i> :
                                            <img className="home-location-img position-absolute w-100 h-100" src={`${process.env.REACT_APP_IMAGES_URL}/locations/photos/${project.id_project}/150x150/${project.image}`} alt="project-profile" />
                                        }
                                    </div>
                                    <label id="shared-profile-image-edit" htmlFor="location-img" className="position-absolute row-center align-items-center bg-clear-gray right-0 bottom-0 zindex-1 rounded-circle c-pointer">
                                        <i className="fas fa-pen txt-10 txt-light-blue"></i>
                                    </label>
                                    <input type="file" id="location-img" name="location-img" accept="image/*" className="d-none" onChange={e => setProjectImage(props.locations, props.setLocations, props.activeLocations, props.setActiveLocationsInfo, project, setProject, setIsLoading, e)}></input>
                                </div>
                            </div>
                            <div className="txt-16 txt-bold txt-dark-blue my-4">{props.language.title_home_project_info}</div>
                            <div className="my-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_home_project_acronym}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-align-justify txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input disabled value={project.acronym ?? ""} />
                                </InputGroup>
                            </div>
                            <div className="my-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_home_project_name}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-align-justify txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input disabled value={project.name ?? ""} />
                                </InputGroup>
                            </div>
                            <div className="my-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_home_project_date}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="far fa-calendar-alt txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input disabled value={new Date(project.date)
                                        .toLocaleDateString(props.language.format_date_string,
                                            {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            }) ?? ""} />
                                </InputGroup>
                            </div>
                            <div className="my-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_home_project_address}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-map-marker-alt txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.placeholder_home_project_address}
                                        value={project.address ?? ""}
                                        onChange={value => setProject({ ...project, address: value })} />
                                </InputGroup>
                            </div>
                            <div className="my-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_home_project_average_consumption}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-plug txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.placeholder_home_project_average_consumption}
                                        type='number'
                                        value={project.average_consumption ?? ""}
                                        onChange={value => setProject({ ...project, average_consumption: value })} />
                                    <InputGroup.Addon><div className="txt-10 txt-light-blue">kWh</div></InputGroup.Addon>
                                </InputGroup>
                            </div>
                            <div className="my-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_home_project_costo_kwh}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon><div className="txt-10 txt-light-blue">$</div></InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.placeholder_home_project_cost_kwh}
                                        type='number'
                                        value={project.cost_kwh ?? ""}
                                        onChange={value => setProject({ ...project, cost_kwh: value })} />
                                </InputGroup>
                            </div>
                            <div className={`${!project.generator ? "d-none" : null}`}>
                                <div className="my-2">
                                    <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_home_project_installed_power}</div>
                                    <InputGroup
                                        inside >
                                        <InputGroup.Addon>
                                            <i className="fas fa-bolt txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input disabled value={project.installed_power ?? ""} />
                                        <InputGroup.Addon><div className="txt-10 txt-light-blue">kWp</div></InputGroup.Addon>
                                    </InputGroup>
                                </div>
                                <div className="my-2">
                                    <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_home_project_theoretical_energy}</div>
                                    <InputGroup
                                        inside >
                                        <InputGroup.Addon>
                                            <i className="fas fa-bolt txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input disabled value={project.theoretical_energy ?? ""} />
                                        <InputGroup.Addon><div className="txt-10 txt-light-blue">kWh</div></InputGroup.Addon>
                                    </InputGroup>
                                </div>
                                <div className="my-2">
                                    <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_home_project_panels_quantity}</div>
                                    <InputGroup
                                        inside >
                                        <InputGroup.Addon>
                                            <i className="fas fa-solar-panel txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input disabled value={project.panels_quantity ?? ""} />
                                    </InputGroup>
                                </div>
                                <div className="my-2">
                                    <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_home_project_panel_area}</div>
                                    <InputGroup
                                        inside >
                                        <InputGroup.Addon>
                                            <i className="fas fa-solar-panel txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input disabled value={project.panel_area ?? ""} />
                                        <InputGroup.Addon><div className="txt-10 txt-light-blue">m2</div></InputGroup.Addon>
                                    </InputGroup>
                                </div>
                                <div className="my-2">
                                    <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_home_project_panel_efficiency}</div>
                                    <InputGroup
                                        inside >
                                        <InputGroup.Addon>
                                            <i className="fas fa-solar-panel txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input disabled value={project.panel_efficiency ?? ""} />
                                        <InputGroup.Addon><div className="txt-10 txt-light-blue">%</div></InputGroup.Addon>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </Drawer.Body>
                    <Drawer.Footer>
                        <div className="row-end align-items-center h-100 w-100">
                            <button
                                style={{ width: 150 }}
                                className={`row-center align-items-center txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 mx-3 bg-${isValidProject(project) ? "blue" : "light-blue"}`}
                                onClick={() => {
                                    if (isValidProject(project)) {
                                        props.updateInfoLocationAsync(project);
                                    } else {
                                        showError(project, props);
                                    }
                                }}
                            >{props.infoLocationLoading ? <Loader inverse /> : props.language.title_home_project_save}</button>
                        </div>
                    </Drawer.Footer>
                </Drawer>
                : null}
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    infoLocation: state.home.infoLocation,
    infoLocationLoading: state.home.infoLocationLoading,
    locations: state.home.locations,
    activeLocations: state.home.activeLocations
})

const mapDispatchToProps = dispatch => ({
    setInfoLocation: payload => dispatch(homeActions.setInfoLocation(payload)),
    setLocations: payload => dispatch(homeActions.setLocations(payload)),
    setActiveLocationsInfo: payload => dispatch(homeActions.setActiveLocationsInfo(payload)),
    updateInfoLocationAsync: payload => dispatch(homeActions.updateInfoLocationAsync(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LocationInfo)
