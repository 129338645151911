import React from 'react';
import { connect } from 'react-redux';

import * as sharedActions from '../../store/actions/Shared';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faWhatsapp, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import filePDF from '../../files/TyC+PLAN+DE+REFERIDOS+.pdf'

import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton
} from "react-share";

import { Modal, Whisper, Tooltip } from 'rsuite';

import { Fragment } from 'react';

import { getAnalytics, logEvent } from "firebase/analytics";

import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const Icon = ({ iconName, iconColor }) => {
    return (
        <div className="row-center align-items-center rounded-circle p-2 bg-clear-gray my-3" style={{ width: 50, height: 50 }}>
            <FontAwesomeIcon icon={iconName} color={iconColor} style={{ width: 25, height: 25 }} />
        </div>)
}

const Share = (props) => {    

    const codeMessage = props.language.text_neugets_share_code_message !== undefined ?
        props.language.text_neugets_share_code_message.replace("#code#", props.user.ref_code) : null;
     
    return (
        <Fragment>
            <Modal
                size="sm"
                show={props.showPopupShare}
                onShow={()=> amplitude.getInstance().logEvent('Referidos')}
                onHide={() => {
                    props.setPopupShare(false);
                }}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.text_neugets_share_code_modal_title}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="column-center align-items-center p-3">
                        <div className="txt-12 txt-semibold txt-dark-blue text-wrap text-center">{props.language.text_neugets_share_code_modal_body}</div>
                        <div className="txt-12 txt-semibold txt-dark-blue text-wrap text-center">{props.language.text_neugets_share_code_modal_tyc} <a href={filePDF} download={'TyC+PLAN+DE+REFERIDOS+.pdf'}>{props.language.text_neugets_share_code_modal_tyc_link}</a></div>
                        <div className="row-center align-items-center flex-wrap my-md-5 my-3">
                            <div className="text-center col-md-3 col-6">
                                <FacebookShareButton
                                    url={"www.neu.com.co"}
                                    quote={codeMessage}
                                    hashtag={"#NEUEnergy"}>
                                    <Icon
                                        iconName={faFacebookF}
                                        iconColor="#3b5998"
                                    />
                                </FacebookShareButton>
                            </div>
                            <div className="text-center col-md-3 col-6">
                                <TwitterShareButton
                                    title={codeMessage}
                                    via={"NEUEnergy"}
                                    hashtags={[
                                        "NEUEnergy",
                                        "YoCuidoMiPlaneta"
                                    ]}
                                    related={[
                                        "NeuEnergy",
                                        "ErcoEnergia"
                                    ]}
                                    url={"www.neu.com.co"}>
                                    <Icon
                                        iconName={faTwitter}
                                        iconColor="#00aced"
                                    />
                                </TwitterShareButton>
                            </div>
                            <div className="text-center col-md-3 col-6">
                                <WhatsappShareButton
                                    title={codeMessage}
                                    url={"www.neu.com.co"}>
                                    <Icon
                                        iconName={faWhatsapp}
                                        iconColor="#25D366"
                                    />
                                </WhatsappShareButton>
                            </div>
                            <div className="text-center col-md-3 col-6">
                                <EmailShareButton
                                    subject={props.language.text_neugets_share_code_title}
                                    body={codeMessage}
                                    url={"www.neu.com.co"}>
                                    <Icon
                                        iconName={faEnvelope}
                                        iconColor="#7f7f7f"
                                    />
                                </EmailShareButton>
                            </div>
                        </div>
                        <div id="neugets-container-code" className="row-between align-items-center bg-clear-gray rounded px-3 py-2 mb-3">
                            <div className="txt-14 txt-light-blue txt-semibold ">{props.user.ref_code}</div>
                            <Whisper
                                delay={200}
                                placement="bottom"
                                trigger="click"
                                speaker={<Tooltip>{props.language.tooltip_neugets_copy_code}</Tooltip>}>
                                <FontAwesomeIcon icon={faClone} className="txt-14 txt-light-blue txt-hover-blue c-pointer"
                                    onClick={() => navigator.clipboard.writeText(props.user.ref_code)}
                                />
                            </Whisper>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <i className="fas fa-share-alt txt-12 txt-light-blue txt-hover-blue mx-2 c-pointer"
                onClick={() => {

                    logEvent(analytics, "click_share_code", {
                        user: {
                            id_user: props.user.id_user,
                            name: `${props.user.name} ${props.user.lastname}`
                        }
                    });

                    amplitude.getInstance().logEvent('Home Share Button Tapped'); 

                    props.setPopupShare(true);
                    
                    }}></i>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    language: state.shared.language,
    showPopupShare: state.shared.showPopupShare
})

const mapDispatchToProps = dispatch => ({
    setPopupShare: payload => dispatch(sharedActions.setPopupShare(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Share)
