import * as creators from '../creators/Reports';

const initialState = {
    totalData: [],
    availableData: [],
    activeData: [],
    loading: true,
    addresses: []
}

export const reportsReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case creators.SET_REPORTS_DATA:
            return { ...state, totalData: payload };
        case creators.SET_REPORTS_AVAILABLE_DATA:
            return { ...state, availableData: payload };
        case creators.SET_REPORTS_ACTIVE_DATA:
            return { ...state, activeData: payload };
        case creators.SET_REPORTS_LOADING:
            return { ...state, loading: payload };
        case creators.SET_REPORTS_ADDRESSES:

            let addresses = payload.flatMap(({ name, acronym, addresses, consumer, generator }) =>
                addresses.flatMap(address =>
                    ({
                        'group': name,
                        'value': [address.id_address, name, acronym, consumer, generator, address.id_type],
                        'label': address.address_identifier
                    })));

            return { ...state, addresses: addresses };
        default:
            return state;
    }

}