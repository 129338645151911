import * as creators from '../creators/Users';

const initialState = {
    totalData: [],
    availableData: [],
    activeData: [],
    loading: true,
    userProject: []
}

export const usersReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case creators.SET_USERS_DATA:
            return { ...state, totalData: payload };
        case creators.SET_USERS_AVAILABLE_DATA:
            return { ...state, availableData: payload };
        case creators.SET_USERS_ACTIVE_DATA:
            return { ...state, activeData: payload };
        case creators.SET_USERS_LOADING:
            return { ...state, loading: payload };
         case creators.SET_USERS_BY_PROJECT:
            return { ...state, userProject: payload };
        default:
            return state;
    }

}