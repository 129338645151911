import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import * as homeActions from '../../../store/actions/Home';
import * as invoicesActions from '../../../store/actions/Invoices';

import { Tooltip, Whisper, Loader } from 'rsuite';

import CurrencyFormat from 'react-currency-format';

import { add, format } from 'date-fns';

import ContentLoader from "react-content-loader";

import Pay from '../../invoices/Pay';

import { Link } from 'react-router-dom';

import { getAnalytics, logEvent } from "firebase/analytics";

import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const initLoadingConsumer = <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
    <ContentLoader
        seed={2}
        foregroundColor="#b3d054"
        backgroundColor="#aaca3f"
        style={{ width: "100%", height: "100%" }}>
        <rect x="15" y="22" width="150" height="10" rx="2" ry="2"></rect>
        <circle cx="calc(100% - 25px)" cy="28" r="10"></circle>
        <rect x="15" y="70" width="150" height="30" rx="5" ry="5"></rect>
        <rect x="15" y="110" width="200" height="10" rx="2" ry="2"></rect>
        <rect x="15" y="152" width="80" height="10" rx="2" ry="2"></rect>
        <rect x="15" y="172" width="80" height="10" rx="2" ry="2"></rect>
    </ContentLoader>
</div>;

const initLoadingGenerator = <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
    <ContentLoader
        seed={2}
        foregroundColor="#b3d054"
        backgroundColor="#aaca3f"
        style={{ width: "100%", height: "100%" }}>
        <rect x="15" y="22" width="150" height="10" rx="2" ry="2"></rect>
        <circle cx="calc(100% - 25px)" cy="28" r="10"></circle>
        <rect x="110" y="85" width="120" height="30" rx="5" ry="5"></rect>
        <rect x="60" y="175" width="220" height="10" rx="2" ry="2"></rect>
    </ContentLoader>
</div>;

const Invoice = (props) => {

    const user = props.user;
    const loadInvoiceAsync = props.loadInvoiceAsync;

    useEffect(() => { if (user.type === "CONSUMER" || user.type === "PROSUMER") { loadInvoiceAsync() } }, [user, loadInvoiceAsync]);

    let content = null;

    switch (user.type) {
        case "CONSUMER":
        case "PROSUMER":

            if (!props.invoicesLoading) {

                let currentValue = 0;
                let lastValue = 0;
                let diffValue = 0;
                let invoiceTitle = props.language.title_home_card_no_inovices;
                let expirationDate = "--";
                let hasPendingPayments = true;
                let pendingPayments = [];

                if (props.invoices.length) {
                    if (props.invoices) {

                        let currentInvoices = props.invoices[0].invoices;

                        currentInvoices
                            .forEach(invoice => currentValue += invoice.payment_value);

                        invoiceTitle = currentInvoices.length === 1 ?
                            `${props.language.title_home_card_inovice} NFE${currentInvoices[0].payment_reference}` :
                            `${currentInvoices.length} ${props.language.title_home_card_inovices}`;

                        hasPendingPayments = !currentInvoices
                            .every(invoice => invoice.state === 1);

                        pendingPayments = currentInvoices
                            .filter(invoice => invoice.state === 0);

                        expirationDate = format(add(
                            new Date(props.invoices[0].year,
                                props.invoices[0].month,
                                20), { months: 1 }), "dd-MM-yyyy");

                    }

                    if (props.invoices.lenght === 2) {
                        props.invoices[1].invoices
                            .forEach(invoice => lastValue += invoice.payment_value);
                    }
                }

                diffValue = currentValue - lastValue;

                content = <Fragment>
                    <div className="row-between align-items-center w-100">
                        <div className="row-start align-items-center">
                            <Whisper
                                delay={1000}
                                placement="right"
                                trigger="hover"
                                speaker={<Tooltip>{hasPendingPayments ? props.language.tooltip_home_card_invoice_pending : props.language.tooltip_home_card_invoice_paid}</Tooltip>}>
                                <i className={`far fa-${hasPendingPayments ? "clock" : "check-circle"} txt-12 txt-white c-pointer`}></i>
                            </Whisper>
                            <div className="txt-10 txt-bold txt-white mx-2">{invoiceTitle}</div>
                        </div>
                        <Whisper
                            placement="bottom"
                            trigger="click"
                            speaker={<Tooltip>{props.language.tooltip_home_card_invoice_consumer}</Tooltip>}>
                            <i className="fas fa-info-circle txt-12 txt-white c-pointer"></i>
                        </Whisper>
                    </div>
                    <div className="column-center align-items-center w-100">
                        <CurrencyFormat
                            className="txt-20 txt-bold txt-white"
                            value={currentValue}
                            prefix="$"
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={1}
                            fixedDecimalScale={true} />
                        <div className="row-start align-items-center w-100">
                            <i className={`fas fa-caret-${diffValue > 0 ? "up" : "down"} txt-12 txt-${diffValue > 0 ? "red" : "green3"} c-pointer`}></i>
                            <CurrencyFormat
                                className="txt-10 txt-semibold txt-white mx-2"
                                value={diffValue}
                                prefix={`$`}
                                suffix={` ${diffValue > 0 ? props.language.title_home_card_more : props.language.title_home_card_less} vs. ${props.language.title_home_card_prev_month}`}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={1}
                                fixedDecimalScale={true} />
                        </div>
                    </div>
                    <div className="row-between align-items-center w-100">
                        <div className="column-center align-items-center">
                            <div className="txt-10 txt-bold txt-white">{props.language.title_home_card_expires}</div>
                            <div className="txt-10 txt-semibold txt-white">{expirationDate}</div>
                        </div>
                        {pendingPayments.length === 1 ?
                            <button
                                style={{ width: 150 }}
                                className="row-center align-items-center txt-12 txt-semibold txt-reactive-green2 border-0 rounded-pill px-4 py-2 bg-reactive-green"
                                onClick={() => {

                                    logEvent(analytics, "click_home_pay", {
                                        id_user: props.user.id_user,
                                        user_name: `${props.user.name} ${props.user.lastname}`
                                    });                      

                                    if (!props.payLoading) {
                                        if (props.banks.length) { props.setInvoicesToPay([pendingPayments[0]]) }
                                        else { props.loadPayDataAsync([pendingPayments[0]]) }
                                    }
                                }}>{!props.payLoading ? props.language.title_home_card_to_pay : <Loader inverse />}</button> :
                            <Link to="/invoices"
                                style={{ width: 150 }}  
                                onClick={()=>{

                                    amplitude.getInstance().logEvent('Home Billing Button Tapped');
                                }}                              
                                className="row-center align-items-center txt-12 txt-semibold txt-reactive-green2 border-0 rounded-pill px-4 py-2 bg-reactive-green c-pointer" >{props.language.title_home_card_to_go}</Link>
                        }
                    </div>
                </Fragment >

            } else { content = initLoadingConsumer }

            break;
        case "GENERATOR":

            if (props.totalValueGeneration !== null && !props.sustainabilityLoading) {
                content = <Fragment>
                    <div className="row-between align-items-center w-100">
                        <div className="row-start align-items-center">
                            <div className="txt-10 txt-bold txt-white">{props.language.title_home_card_saves}</div>
                        </div>
                        <Whisper
                            placement="bottom"
                            trigger="click"
                            speaker={<Tooltip>{props.language.tooltip_home_card_invoice_generator}</Tooltip>}>
                            <i className="fas fa-info-circle txt-12 txt-white c-pointer"></i>
                        </Whisper>
                    </div>
                    <div className="column-center align-items-center w-100">
                        <CurrencyFormat
                            className="txt-20 txt-bold txt-white text-center"
                            value={props.totalValueGeneration}
                            prefix="$"
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={1}
                            fixedDecimalScale={true} />
                    </div>
                    <div className="row-center align-items-center w-100">
                        <div className="txt-10 txt-semibold txt-white text-center">{props.language.text_card_saving_generator}</div>
                    </div>
                    {/* <div className="row-center align-items-center w-100">
                        <div className="txt-10 txt-semibold txt-white text-center">{props.language.title_home_card_ad}</div>
                    </div>
                    <div className="row-center align-items-center w-100">
                        <a href="https://www.neu.com.co/registro" className="row-center align-items-center txt-12 txt-semibold txt-reactive-green2 border-0 rounded-pill px-4 py-2 w-auto bg-reactive-green c-pointer">{props.language.title_home_card_change_to_neu}</a>
                    </div> */}
                </Fragment>
            } else { content = initLoadingGenerator }

            break;
        default:
            break;
    }

    return (
        <Fragment>
            <Pay />
            <div className="position-relative column-between align-items-center h-100 w-100 bg-green rounded shadow p-3">
                {content}
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    banks: state.invoices.banks,
    permalink: state.invoices.permalink,
    invoices: state.home.invoices,
    language: state.shared.language,
    totalValueGeneration: state.home.totalValueGeneration,
    invoicesLoading: state.home.invoicesLoading,
    payLoading: state.home.payLoading,
    sustainabilityLoading: state.home.sustainabilityLoading
})

const mapDispatchToProps = dispatch => ({
    loadInvoiceAsync: () => dispatch(homeActions.loadInvoiceAsync()),
    loadPayDataAsync: payload => dispatch(homeActions.loadPayDataAsync(payload)),
    setInvoicesToPay: payload => dispatch(invoicesActions.setInvoicesToPay(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoice)
