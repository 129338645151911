import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';

import * as neuplusActions from '../../store/actions/NEUPlus';
import * as homeActions from '../../store/actions/Home';
import * as tagsActions from '../../store/actions/Tags'

import { Loader, Table, Input, InputGroup, Tooltip, Whisper, DateRangePicker, InputNumber, CheckPicker, Modal} from 'rsuite';

import { Transition } from 'react-transition-group';

import { format } from 'date-fns';

import Options from '../shared/Options';
import Inquiry from '../neuplus/Inquiry';

import { ResponsiveBar } from '@nivo/bar';

import { getAnalytics, logEvent } from "firebase/analytics";
import ModalFooter from 'rsuite/lib/Modal/ModalFooter';

import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const { Column, HeaderCell, Cell } = Table;
const { afterToday } = DateRangePicker;

const color = [
    "#A1C52A",
    "#6DB639",
    "#CAD515",
    "#ADD081",
    "#27AA58",
    "#619630",
    "#1C7131",
    "#2F5123",
    "#184E24",
    "#202D19",
    "#31B08A",
    "#009C75",
    "#007866",
    "#006F83",
    "#006F83",
    "#42ACE2",
    "#2599A5",
    "#006DB1",
    "#026577",
    "#20335F"
];

const lengthMenu = [
    {
        value: 20,
        label: 20
    },
    {
        value: 50,
        label: 50
    },
    {
        value: 100,
        label: 100
    }
];


const SelectQuantity = (props) => {
    const [quantity, setQuantity] = useState(props.defaultValue);
    useEffect(() => {
        props.onChange(quantity);
    }, [quantity, setQuantity]);
    return (
        <div className='align-items-center'>
            <div className='w-100 text-center fas fa-angle-up txt-14 c-pointer' onClick={() => setQuantity(quantity + 1)}/>
            <Input className='text-center' readOnly value={quantity}/>
            <div className='w-100 text-center mt-1 fas fa-angle-down txt-14 c-pointer' onClick={() => quantity - 1 < 1 ? setQuantity(1) : setQuantity(quantity - 1)}/>
        </div>
    )
}

const formatData = (data) => {
    try{
        if (data < 0.001) {
            return (String((data * 1000000).toFixed(3)) + " mWh");
        }
        else {
            return (String((data * 1000).toFixed(3)) + " Wh");
        }
    }
    catch (err){
        console.log(err);
    }
}


const Tags = (props) => {

    const initFilterData = {
        search_value: "",
        from: new Date(2020, 0, 1),
        to: new Date(),
        locations: "",
        appliances: ""
    }

    const initFilterDataRecords = {
        from: new Date(2021, 6, 0),
        to: new Date(),
    }

    const locations = props.locations;

    let availableData = props.availableData;

    let totalData = props.totalData;

    let records = props.records;

    const [showFilters, setShowFilters] = useState(false);
    const [filterData, setFilterData] = useState(initFilterData);

    const [showFiltersRecords, setShowFiltersRecords] = useState(false);
    const [filterDataRecords, setFilterDataRecords] = useState(initFilterDataRecords);

    const [recordsFiltered, setRecordsFiltered] = useState(records);
    const [recordsAvailable, setRecordsAvailable] = useState(records);
    const [hasDataGraph, setHasDataGraph] = useState([]);

    const [applianceToModify, setApplianceToModify] = useState(null);

    const [activePage, setActivePage] = useState(1);
    const [pageLength, setPageLength] = useState(20);
    const [totalPages, setTotalPages] = useState(0);

    const [activePageRecords, setActivePageRecords] = useState(1);
    const [pageLengthRecords, setPageLengthRecords] = useState(20);

    const [showRecordsModal, setRecordsModal] = useState(false);
    const [applianceName, setApplianceName] = useState("");
    const [idApplianceDevice, setIdApplianceDevice] = useState(0);
    const [idUserLabel, setIdUserLabel] = useState(0);

    const [hasDataModal, setHasDataModal] = useState(false);
    const [showInquiryModifyModal, setShowInquiryModifyModal] = useState(false);

    const [showConfirmModal, setConfirmModal] = useState(false);

    const setActiveData = props.setActiveData;
    const setAvailableData = props.setAvailableData;
    const loadAppliancesTagsAsync = props.loadAppliancesTagsAsync;
    const loadLocationsAsync = props.loadLocationsAsync;

    const loadAppliances = async () =>{
        if (!totalData.length) { await props.loadAppliancesTagsAsync() }
        else {
            setTotalPages(availableData.length);
            setActiveData(availableData.slice(((activePage - 1) * pageLength), activePage * pageLength));
        }

        if (!locations.length)
            loadLocationsAsync();
    }

    useEffect(()=>{
        if(!props.flagInquiry && !showInquiryModifyModal){
            props.loadAppliancesTagsAsync();
        }
    },[props.flagInquiry, setShowInquiryModifyModal, showInquiryModifyModal])


    useEffect(() => {
        loadAppliances();
    }, [loadAppliancesTagsAsync, setActiveData, setTotalPages, activePage, pageLength, availableData, totalData]);

    useEffect(() => {
        if (recordsFiltered.length){
            setHasDataModal(true);
            setRecordsAvailable(recordsFiltered.slice(((activePageRecords - 1) * pageLengthRecords), activePageRecords * pageLengthRecords));
        }
    }, [props.loadRecordsApplianceTags, activePageRecords, pageLengthRecords, records, recordsFiltered]);
    
    useEffect(() => {
        if (totalData.length) {
            let data = totalData;
            const search = filterData.search_value.toLowerCase();
            data = data.filter(option =>
            ((filterData.locations.includes(option.id_project) || !filterData.locations.length)
                && (option.location.toLowerCase().includes(search) || option.name.toLowerCase().includes(search))
                && (filterData.appliances.includes(option.id_appliance_device) || !filterData.appliances.length)
            ));
            setActivePage(1);
            setAvailableData(data);
        }
    }, [setActivePage, setAvailableData, filterData, totalData]);

    useEffect(()=>{
        if(records.length){
            let data = records;
            data = data.filter(option =>
                ((Date.parse(filterDataRecords.from) <= Date.parse(option.initial_time_stamp) && (Date.parse(filterDataRecords.to) + 86400000) >= Date.parse(option.initial_time_stamp))));
            data = data.map((element, index) => ({...element, tag: "Tag "+String(index+1), xx: element.active_power, yy: element.reactive_power}));
            data = JSON.stringify(data).replace(/xx/g, props.language.module_tags_label_active_power).replace(/yy/g, props.language.module_tags_label_reactive_power);
            data = JSON.parse(data);
            setRecordsFiltered(data);
            let dataGraph = data.filter(value => value.active_power !== null && value.reactive_power);
            setHasDataGraph(dataGraph);
        }
        else{
            setRecordsFiltered([]);
        }
    },[records, props.loadRecordsApplianceTags, props.setRecords, filterDataRecords, setFilterDataRecords])


    return (
        <Fragment>
            <Modal
                size='xs'
                show={showInquiryModifyModal}
                onHide={()=>setShowInquiryModifyModal(false)}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.inquiry_header_edit_appliances_modal}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-100'>
                        {applianceToModify ?
                        <div style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: window.innerHeight * 0.6}}>
                            <div className='row-start w-100 align-items-center my-3 px-3'> 
                                <div className='col-auto pl-0 pr-2 align-items-center'>
                                    {applianceToModify.image?
                                        <img src={process.env.REACT_APP_NEU_PLUS_INQUIRY_IMAGES_URL + applianceToModify.image} 
                                            width={80} height={80} style={{borderRadius: 20}} alt={applianceToModify.name}></img>:
                                        <div style={{width:80}} className='fas fa-box-open txt-light-blue my-4 text-center txt-30'/>}
                                </div>
                                <div className='col-6 px-2 mr-4 txt-12 txt-bold txt-dark-blue text-wrap'>
                                    {applianceToModify.name}
                                </div>
                                <div className='col-auto px-0' style={{maxWidth: 50}}>
                                        <SelectQuantity
                                            defaultValue={applianceToModify.quantity}
                                            onChange={item => {
                                                setApplianceToModify({ ...applianceToModify, quantity: parseInt(item) });
                                                amplitude.getInstance().logEvent('Edit Tag Started', {
                                                    quantity: parseInt(item)
                                                });                                                
                                            }}
                                            />
                                </div>
                            </div>
                            <div className={`w-100 px-4 ${applianceToModify.id_verify? "": "mb-4"}`}>
                                <div className="text-left text-wrap txt-light-blue txt-12 txt-semibold mb-2">{props.language.inquiry_tittle_custom_name}</div>
                                <Input 
                                    placeholder={props.language.inquiry_placeholder_custom_name}
                                    defaultValue = {applianceToModify.custom_name}
                                    onChange={item =>{
                                        setApplianceToModify({ ...applianceToModify, custom_name: item });
                                        amplitude.getInstance().logEvent('Edit Tag Started', {
                                            custom_name: item
                                        });
                                    }}
                                    size="md"/>
                            </div>
                            {applianceToModify.id_verify?
                            <Fragment>
                                <div className='row align-items-center mx-4 mb-2 mt-4'>
                                    <div className="col pl-0 pr-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_active_power}</div>
                                    <div className="col pr-0 pl-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_reactive_power}</div>
                                </div>
                                <div className='row align-items-center mx-4 mb-4'>
                                    <div className='col pl-0 pr-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix=" kW" defaultValue={applianceToModify.active_power}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, active_power:  value ? parseFloat(value) : null })}/>
                                    </div>
                                    <div className='col pr-0 pl-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix=" kW" defaultValue={applianceToModify.reactive_power}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, reactive_power:  value ? parseFloat(value) : null })}/>
                                    </div>
                                </div>
                                <div className='row align-items-center mx-4 mb-2'>
                                    <div className="col pl-0 pr-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_monthly_cons}</div>
                                    <div className="col pr-0 pl-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_monthly_cons_reactive}</div>
                                </div>
                                <div className='row align-items-center mx-4 mb-4'>
                                    <div className='col pl-0 pr-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix="kWh" defaultValue={applianceToModify.monthly_cons}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, monthly_cons:  value ? parseFloat(value) : null })}/>
                                    </div>
                                    <div className='col pr-0 pl-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix="kWh" defaultValue={applianceToModify.monthly_reactive_cons}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, monthly_reactive_cons:  value ? parseFloat(value) : null })}/>
                                    </div>
                                </div>
                                <div className='row align-items-center mx-4 mb-2'>
                                    <div className="col pl-0 pr-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_weight}</div>
                                    <div className="col pr-0 pl-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_height}</div>
                                </div>
                                <div className='row align-items-center mx-4 mb-4'>
                                    <div className='col pl-0 pr-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix=" kg" defaultValue={applianceToModify.weight}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, weight:  value ? parseFloat(value) : null })}/>
                                    </div>
                                    <div className='col pr-0 pl-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix=" cm" defaultValue={applianceToModify.height}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, height:  value ? parseFloat(value) : null })}/>
                                    </div>
                                </div>
                                <div className='row align-items-center mx-4 mb-2'>
                                    <div className="col pl-0 pr-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_width}</div>
                                    <div className="col pr-0 pl-2 text-left text-wrap txt-light-blue txt-12 txt-semibold">{props.language.inquiry_edit_tittle_depth}</div>
                                </div>
                                <div className='row align-items-center mx-4 mb-4'>
                                    <div className='col pl-0 pr-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix=" cm" defaultValue={applianceToModify.width}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, width: value ? parseFloat(value) : null })}/>
                                    </div>
                                    <div className='col pr-0 pl-2'>
                                        <InputNumber min={0} className='rs-input-number-edit' postfix=" cm" defaultValue={applianceToModify.depth}
                                            onChange={value => setApplianceToModify({ ...applianceToModify, depth:  value ? parseFloat(value) : null })}/>
                                    </div>
                                </div>
                            </Fragment> :  null}
                        </div>
                        : null}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`row-start align-items-center txt-12 txt-semibold txt-white w-auto rounded-pill px-4 
                                        py-2 bg-blue`}
                            onClick={async() => {
                                await props.updateApplianceInquiry(applianceToModify);
                                setShowInquiryModifyModal(false); 
                                amplitude.getInstance().logEvent('Edit tag finished');
                            }}>
                            <div className={"txt-12 mx-1"}>{props.language.inquiry_edit_save_appliance}</div>
                        </button>
                        <button
                            className="row-start align-items-center txt-12 txt-semibold txt-light-blue bg-white w-auto 
                                        border-0 py-2 px-4"
                            onClick={() => {
                                setShowInquiryModifyModal(false); 
                                amplitude.getInstance().logEvent('Edit Tag Started');
                            }}>{props.language.inquiry_edit_cancel}</button>
                    </div>
                </Modal.Footer>
            </Modal>


            <Modal
                size='xs'
                show={showConfirmModal}
                onHide={()=>{
                    setConfirmModal(false);
                    }}
                >
                <Modal.Body>
                    <div className='row-center align-items-center w-100 my-4 px-2 txt-12'>
                        {props.language.modal_confirm_delete_tag_body}
                    </div>
                </Modal.Body>
                <ModalFooter>
                    <div className="row-end align-items-center h-100 w-100">
                        <button 
                            className={`row-start align-items-center txt-12 txt-semibold txt-white w-auto rounded-pill px-4 py-2 bg-blue`}
                            onClick={async()=>{                                
                                await props.deleteEventLabel(idUserLabel);
                                amplitude.getInstance().logEvent('Delete Tag', {
                                    id_user_label: idUserLabel
                                });
                                setConfirmModal(false);
                                await props.loadRecordsApplianceTags(idApplianceDevice);
                                const index = recordsAvailable.findIndex(item => item.id_user_label === idUserLabel);
                                if (index !== -1){
                                    recordsAvailable.splice(index, 1);
                                }
                        }}>
                            {props.language.modal_confirm_delete_tag_agree}
                        </button>
                        <button 
                            className="row-start align-items-center txt-12 txt-semibold txt-light-blue bg-white w-auto 
                                        border-0 py-2 px-4"
                            onClick={()=>setConfirmModal(false)}>
                            {props.language.modal_confirm_delete_tag_cancel}
                        </button>
                    </div>
                </ModalFooter>
            </Modal>


            <Modal
                size="tags"
                overflow={true}
                show={showRecordsModal && hasDataModal}
                onHide={() => {
                    setRecordsModal(false);
                    setHasDataModal(false);
                    setFilterDataRecords({ ...filterDataRecords, from: initFilterDataRecords.from, to: initFilterDataRecords.to })}}>
                <Modal.Body>
                    <div className="w-100 p-3" style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: window.innerHeight * 0.9}}>
                        <div className="row-end align-items-center h-100 w-100">
                            <div className="txt-18 txt-bold txt-dark-blue text-left mx-2">{applianceName}</div>
                        </div>
                        <Transition
                            in={props.loadingRecords}
                            timeout={100}>
                            {state => (
                                <div className={`trans-fade trans-fade-${state} position-absolute bg-white w-100 h-100 top-0 left-0 zindex-2`}>
                                    <div className="column-center align-items-center w-100 h-100">
                                        <Loader center size="md" content={props.language.loading_message} />
                                    </div>
                                </div>
                            )}
                        </Transition>
                        {!(recordsFiltered.length && hasDataGraph.length) ? null :
                            <div className="w-100 overflow-auto">
                                <div className='neuplus-tags-graph align-items-center' style={{ height: 350, width: 900 }}>
                                        <ResponsiveBar
                                            data={recordsAvailable}
                                            indexBy="tag"
                                            keys={[props.language.module_tags_label_active_power, props.language.module_tags_label_reactive_power]}
                                            groupMode="grouped"
                                            margin={{ top: 20, right: 40, bottom: 55, left: 80 }}
                                            indexScale={{ type: 'band', round: false }}
                                            colors={color}
                                            borderRadius={2}
                                            enableLabel={false}
                                            enableGridX={false}
                                            enableGridY={true}
                                            animate={false}
                                            axisTop={null}
                                            axisRight={null}
                                            theme={{
                                                fontSize: 12,
                                                fontFamily: "semi-bold"
                                            }}
                                            axisLeft={{
                                                orient: 'left',
                                                tickSize: 18,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: props.language.graph_tag_records_graph_ignition_power,
                                                legendPosition: 'middle',
                                                legendOffset: -60,
                                                renderTick: (data => {
                                                    let text = <g transform={`translate(${data.x},${data.y})`} style={{ opacity: 1 }}>
                                                        <text
                                                            className="txt-8 txt-semibold"
                                                            alignmentBaseline={data.textBaseline}
                                                            textAnchor={data.textAnchor}
                                                            transform={`translate(${data.textX},${data.textY}) rotate(${data.rotate})`}>
                                                            {data.value}
                                                        </text>
                                                    </g>
                                                    text = data.tickIndex % 2 === 0 ? text : null;
                                                    return (text);
                                                })
                                            }}
                                            legends={[
                                                {
                                                    anchor: 'bottom-left',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateX: 0,
                                                    translateY: 50,
                                                    itemsSpacing: 100,
                                                    itemDirection: 'left-to-right',
                                                    itemWidth: 100,
                                                    itemHeight: 12,
                                                    itemOpacity: 1,
                                                    symbolSize: 10,
                                                    symbolShape: 'square',
                                                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            tooltip={(point) => {
                                                return (
                                                    <div className="row-start align-items-center">
                                                        <div style={{ background: point.color, height: '12px', width: '12px' }} />
                                                        <div className="txt-dark-blue txt-semibold txt-10 ml-2">{`${point.id}: ${point.value.toFixed(3)} ${point.id === props.language.module_tags_label_active_power ? "W" : "VAr"} `}</div>
                                                    </div>
                                                )
                                            }}
                                            >
                                        </ResponsiveBar>
                                    </div>
                                </div>
                            }
                            {recordsFiltered.length ?
                            <Fragment>
                                <div id="date-range-container" className="table-data-container flex-grow-1 w-100">
                                    <div className="position-relative d-flex flex-column h-100" style={{ minWidth: 1000 }}>
                                        <div className="table-header position-absolute top-0 row-start align-items-center zindex-1 py-3 w-100" style={{ paddingRight: 20 }}>
                                            <div className="text-center" style={{ width: 50, paddingLeft: 10, paddingRight: 10 }}>
                                                <Whisper
                                                    delay={500}
                                                    placement="top"
                                                    trigger="hover"
                                                    speaker={<Tooltip>{props.language.tooltip_shared_filter}</Tooltip>}>
                                                    <i className="fas fa-filter txt-10 txt-light-blue txt-hover-blue c-pointer"
                                                        onClick={() => setShowFiltersRecords(!showFiltersRecords)} />
                                                </Whisper>
                                            </div>
                                            <div className="text-left" style={{ width: 120, paddingLeft: 30, paddingRight: 10 }}>
                                                <div className="txt-10 txt-dark-blue txt-bold">{props.language.header_table_records_tag}</div>
                                            </div>
                                            <div className="text-left" style={{ width: 180, paddingLeft: 30, paddingRight: 10 }}>
                                                <div className="txt-10 txt-dark-blue txt-bold">{props.language.header_table_records_date}</div>
                                            </div>
                                            <div className="text-left" style={{ width: 165, paddingLeft: 10, paddingRight: 10 }}>
                                                <div className="txt-10 txt-dark-blue txt-bold">{props.language.header_table_records_active_cons}</div>
                                            </div>
                                            <div className="text-left" style={{ width: 165, paddingLeft: 10, paddingRight: 10 }}>
                                                <div className="txt-10 txt-dark-blue txt-bold">{props.language.header_table_records_active_power}</div>
                                            </div>
                                            <div className="text-left " style={{ width: 165, paddingLeft: 10, paddingRight: 10 }}>
                                                <div className="txt-10 txt-dark-blue txt-bold">{props.language.header_table_records_reactive_cons}</div>
                                            </div>
                                            <div className="text-left " style={{ width: 165, paddingLeft: 10, paddingRight: 10 }}>
                                                <div className="txt-10 txt-dark-blue txt-bold">{props.language.header_table_records_reactive_power}</div>
                                            </div>
                                            <div className="text-left flex-grow-1" style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <div className="txt-10 txt-dark-blue txt-bold">{props.language.header_table_records_duration}</div>
                                            </div>
                                        </div>
                                        <div className={`table-filter position-absolute row-start align-items-end zindex-1 pb-3 w-100 ${!showFiltersRecords ? "d-none" : null}`} style={{ paddingRight: 20 }}>
                                            <div className="text-left" style={{ marginLeft: 60, width: 260, paddingLeft: 10, paddingRight: 10 }}>
                                                <DateRangePicker
                                                    container={document.getElementById("date-range-container")}
                                                    className="w-100"
                                                    showOneCalendar
                                                    format="DD-MM-YYYY"
                                                    value={[filterDataRecords.from, filterDataRecords.to]}
                                                    locale={props.dateLocale}
                                                    disabledDate={afterToday()}
                                                    onChange={value => setFilterDataRecords({ ...filterDataRecords, from: value[0], to: value[1] })}
                                                    onClean={() => setFilterDataRecords({ ...filterDataRecords, from: initFilterDataRecords.from, to: initFilterDataRecords.to })}
                                                />
                                            </div>
                                        </div>
                                        <div className={`position-relative flex-grow-1 table-content-${!showFiltersRecords ? "complete" : "filtered"}`}>
                                            <Table
                                                height={46 * recordsFiltered.length}
                                                wordWrap
                                                data={recordsAvailable}
                                                hover={false}
                                                showHeader={false}>
                                                <Column width={50} verticalAlign="middle">
                                                    <HeaderCell>sign_name</HeaderCell>
                                                    <Cell>
                                                        {rowData => (
                                                            <div className='fas fa-trash txt-10 txt-light-blue txt-hover-blue c-pointer ml-2'
                                                                onClick={()=>{
                                                                    setIdUserLabel(rowData.id_user_label);
                                                                    setConfirmModal(true);                                                                    
                                                                }}
                                                            />
                                                        )}
                                                    </Cell>
                                                </Column>
                                                <Column width={120} verticalAlign="middle" className="pl-3">
                                                    <HeaderCell>Tag</HeaderCell>
                                                    <Cell>
                                                        {rowData => (
                                                            <div>
                                                                <div className="txt-8 txt-dark-blue txt-bold text-wrap">
                                                                    {rowData.tag}
                                                                </div>
                                                            </div>)}
                                                    </Cell>
                                                </Column>
                                                <Column width={180} verticalAlign="middle" className="pl-3">
                                                    <HeaderCell>Date</HeaderCell>
                                                    <Cell>
                                                        {rowData => (
                                                            <div>
                                                                <div className="txt-8 txt-dark-blue txt-bold text-wrap">
                                                                    {format(new Date(rowData.initial_time_stamp),"yyyy/MM/dd HH:mm:ss")}
                                                                </div>
                                                            </div>)}
                                                    </Cell>
                                                </Column>
                                                <Column width={165} verticalAlign="middle">
                                                    <HeaderCell >active_cons</HeaderCell>
                                                    <Cell>
                                                        {rowData => (
                                                            <div>
                                                                <div className="txt-8 txt-dark-blue txt-regular text-wrap">
                                                                    { rowData.final_time_stamp == null ? "-" : rowData.active_cons == null ? props.language.procesing_module_tags : formatData(rowData.active_cons) }
                                                                </div>
                                                            </div>)}
                                                    </Cell>
                                                </Column>
                                                <Column width={165} verticalAlign="middle">
                                                    <HeaderCell>active_power</HeaderCell>
                                                    <Cell>
                                                        {rowData => (
                                                            <div>
                                                                <div className="txt-8 txt-dark-blue txt-regular text-wrap">{rowData.active_power == null ? props.language.procesing_module_tags : String(rowData.active_power.toFixed(3))+" W"}
                                                                </div>
                                                            </div>)}
                                                    </Cell>
                                                </Column>
                                                <Column width={165} verticalAlign="middle">
                                                    <HeaderCell>reactive_cons</HeaderCell>
                                                    <Cell>
                                                        {rowData => (
                                                            <div className="txt-8 txt-dark-blue txt-regular text-wrap">{
                                                                rowData.final_time_stamp == null ? "-" : rowData.reactive_cons == null ? props.language.procesing_module_tags : String((rowData.reactive_cons*1000).toFixed(3))+" VArh"
                                                                }
                                                            </div>)}
                                                    </Cell>
                                                </Column>
                                                <Column width={165} verticalAlign="middle">
                                                    <HeaderCell>reactive_power</HeaderCell>
                                                    <Cell>
                                                        {rowData => (
                                                            <div className="txt-8 txt-dark-blue txt-regular text-wrap">{
                                                                rowData.reactive_power == null ? props.language.procesing_module_tags : String(rowData.reactive_power.toFixed(3)) + " VAr"
                                                                }
                                                            </div>)}
                                                    </Cell>
                                                </Column>
                                                <Column flexGrow={1} verticalAlign="middle">
                                                    <HeaderCell>Duration</HeaderCell>
                                                    <Cell>
                                                        {rowData => (
                                                            <div className="txt-8 txt-dark-blue txt-bold text-wrap flex-grow-1">
                                                                {rowData.final_time_stamp == null ? props.language.table_tag_records_time_instant : 
                                                                String((Date.parse(rowData.final_time_stamp) - Date.parse(rowData.initial_time_stamp))/1000)+" s"}
                                                            </div>)}
                                                    </Cell>
                                                </Column>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                                <div className="border-top">
                                    <Table.Pagination
                                        lengthMenu={lengthMenu}
                                        activePage={activePageRecords}
                                        displayLength={pageLengthRecords}
                                        total={recordsFiltered.length}
                                        renderLengthMenu={picker => picker}
                                        onChangeLength={value => {
                                            const maxActivePage = Math.ceil(records.length / value);
                                            const page = activePageRecords <= maxActivePage ? activePageRecords : maxActivePage;

                                            setPageLengthRecords(value);
                                            setActivePageRecords(page);
                                        }}
                                        onChangePage={value => setActivePageRecords(value)}
                                    />
                                </div>
                            </Fragment>:
                            <div className={"row-center align-items-center w-100 txt-18 txt-light-blue"} style={{ height: 300 }}>{props.language.module_tags_no_records}</div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            
            {props.locations.length ?
                <Inquiry module="tags"/>
            : null}

            <div id="neu-plus-tags" className="column-start align-items-start px-3 py-2 h-100 w-100">
                <div className="row-between align-items-center flex-wrap-reverse w-100 pb-2">
                    <div className="row-start align-items-center flex-wrap col-md-8 col-12 p-0">
                        <div className="row-start align-items-center col-md-6 col-12 my-md-0 my-2 p-0">
                            <div className='row-start align-items-center'>
                                <div className="d-flex flex-row txt-20 txt-bold txt-dark-blue text-wrap">{props.language.title_tags}</div>
                                <div className='d-flex flex-row px-3'>
                                    <Whisper
                                        delay={500}
                                        placement="right"
                                        trigger="hover"
                                        speaker={<Tooltip>{props.language.inquiry_tooltip_edit_appliance}</Tooltip>}>
                                        <button
                                            id="reports-add-report"
                                            className="d-flex justify-content-center align-items-center border-0 rounded-circle p-2 bg-blue mx-auto"
                                            onClick={() => {
                                                props.setFlagInquiry(true);
                                                amplitude.getInstance().logEvent('Edit Tag Started');
                                            }}>
                                            <i className="fas fa-pen txt-12 txt-white"></i>
                                        </button>
                                    </Whisper>
                                </div>
                            </div>
                        </div>
                        <div className="row-start align-items-center col-md-6 col-12 p-0">
                            <Whisper
                                delay={1000}
                                placement="bottom"
                                trigger="focus"
                                speaker={<Tooltip>{props.language.tooltip_shared_search}</Tooltip>}>
                                <InputGroup
                                    inside>
                                    <Input
                                        placeholder={`${props.language.title_shared_search}`}
                                        onPressEnter={e => {
                                            setFilterData({ ...filterData, search_value: e.target.value });
                                            amplitude.getInstance().logEvent('Edit Tag Started', {
                                                search_value: e.target.value
                                            });
                                        }} />
                                    <InputGroup.Addon>
                                        <i className="fas fa-search txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                </InputGroup>
                            </Whisper>
                        </div>
                    </div>
                    <div className="d-block d-md-flex justify-content-end align-items-center col-md-4 col-12 p-0">
                        <Whisper
                            placement="bottom"
                            trigger="click"
                            speaker={<Tooltip>{props.language.tooltip_tags_info}</Tooltip>}>
                            <i className="fas fa-info-circle d-none d-md-block txt-12 txt-light-blue txt-hover-blue mx-2 c-pointer"></i>
                        </Whisper>
                        <Options />
                    </div>
                </div>
                <div className="position-relative column-start flex-grow-1 w-100 bg-white rounded shadow overflow-hidden">
                    <Transition
                        in={props.loading}
                        timeout={100}>
                        {state => (
                            <div className={`trans-fade trans-fade-${state} position-absolute bg-white w-100 h-100 top-0 left-0 zindex-2`}>
                                <div className="column-center align-items-center w-100 h-100">
                                    <Loader center size="md" content={props.language.loading_message} />
                                </div>
                            </div>
                        )}
                    </Transition>
                    {!props.activeData.length && !props.loading ?
                        <div className={`position-absolute column-center align-items-center left-0 bg-white border-top w-100 p-3 zindex-1 empty-message-${!showFilters ? "complete" : "filtered"}`}>
                            <div className="row-center align-items-center w-100">
                                <i className="fas fa-tag txt-20 txt-light-blue" />
                                <div className="txt-20 txt-bold txt-light-blue w-auto mx-3">{props.language.module_tags_no_devices}</div>
                            </div>
                        </div> : null}
                    <div className="table-data-container flex-grow-1 w-100">
                        <div className="position-relative d-flex flex-column h-100" style={{ minWidth: 1200 }}>
                            <div className="table-header position-absolute top-0 row-start align-items-center zindex-1 py-3 w-100" style={{ paddingRight: 20 }}>
                                <div className="text-center" style={{ width: 60, paddingLeft: 10, paddingRight: 10 }}>
                                    <Whisper
                                        delay={500}
                                        placement="top"
                                        trigger="hover"
                                        speaker={<Tooltip>{props.language.tooltip_shared_filter}</Tooltip>}>
                                        <i className="fas fa-filter txt-10 txt-light-blue txt-hover-blue c-pointer"
                                            onClick={() => setShowFilters(!showFilters)} />
                                    </Whisper>
                                </div>
                                <div className="text-left" style={{ width: 260, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_tags_name_device}</div>
                                </div>
                                <div className="text-left" style={{ width: 260, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_tags_location}</div>
                                </div>
                                <div className="text-left" style={{ width: 220, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_tags_average_duration}</div>
                                </div>
                                <div className="text-left" style={{ width: 220, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_tags_average_consumption}</div>
                                </div>
                                <div className="text-center" style={{ width: 125, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_tags_details}</div>
                                </div>
                            </div>
                            <div className={`table-filter position-absolute row-start align-items-end zindex-1 pb-3 w-100 ${!showFilters ? "d-none" : null}`} style={{ paddingRight: 20 }}>
                                <div className="text-left " style={{ width: 280, marginLeft: 60, paddingRight: 10 }}>
                                    <CheckPicker
                                        className="w-100"
                                        searchable={false}
                                        placeholder={props.language.placeholder_tags_filter_table_all_appliances}
                                        value={filterData.appliances}
                                        labelKey={"name"}
                                        valueKey={"id_appliance_device"}
                                        groupBy={"base"}
                                        onChange={value => {
                                            setFilterData({ ...filterData, appliances: value });
                                            amplitude.getInstance().logEvent('Tag filtered', {
                                                appliances: value
                                            });
                                        }}
                                        data={totalData}
                                    />
                                </div>
                                <div className="text-left " style={{ width: 280, paddingLeft: 10, paddingRight: 10 }}>
                                    <CheckPicker
                                        className="w-100"
                                        searchable={false}
                                        placeholder={props.language.placeholder_notifications_filter_table_all_locations}
                                        value={filterData.locations}
                                        labelKey={"name"}
                                        valueKey={"id_project"}
                                        onChange={value => {
                                            setFilterData({ ...filterData, locations: value }); 
                                            amplitude.getInstance().logEvent('Tag filtered', {
                                                location: value
                                            });                                            
                                        }}
                                        data={locations}
                                    />
                                </div>
                            </div>
                            {props.activeData.length?
                            <div className={`position-relative flex-grow-1 table-vertical-scroll border-top table-content-${!showFilters ? "complete" : "filtered"}`}>
                                <Table
                                    autoHeight
                                    wordWrap
                                    data={props.activeData}
                                    hover={false}
                                    showHeader={false}>
                                    <Column width={60} verticalAlign="middle" className='text-center'>
                                        <HeaderCell>sign_name</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <div className="fas fa-pen txt-12 txt-light-blue txt-hover-blue c-pointer" 
                                                onClick= {() => {
                                                    setApplianceToModify(rowData);
                                                    setShowInquiryModifyModal(true);
                                                    amplitude.getInstance().logEvent('Edit Tag Started');
                                                }} />
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={260} verticalAlign="middle">
                                        <HeaderCell>base_name</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <div className="txt-10 txt-dark-blue txt-bold text-wrap">{
                                                    rowData.custom_name? rowData.custom_name : rowData.name
                                                    }
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={260} verticalAlign="middle">
                                        <HeaderCell>location</HeaderCell>
                                        <Cell dataKey="location">
                                            {rowData => (
                                                <div className="txt-10 txt-dark-blue txt-regular text-wrap">{
                                                    rowData.location
                                                    }
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={220} verticalAlign="middle">
                                        <HeaderCell>duration</HeaderCell>
                                        <Cell >
                                            {rowData => (
                                                <div>
                                                    <div className="txt-10 txt-dark-blue txt-semibold">{rowData.duration !== null ? String(rowData.duration.toFixed(2)) + " s" : props.language.table_tag_records_time_instant}</div>
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={220} verticalAlign="middle">
                                        <HeaderCell>consumption</HeaderCell>
                                        <Cell >
                                            {rowData => (
                                                <div>
                                                    <div className="txt-10 txt-dark-blue txt-semibold">{rowData.duration === null ? "-" : rowData.active_cons !== null ? formatData(rowData.active_cons) : props.language.procesing_module_tags}</div>
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    
                                    <Column width={125} verticalAlign="middle">
                                        <HeaderCell>details</HeaderCell>
                                        <Cell >
                                            {rowData => (
                                                <div>
                                                    <div className="btn-tags-view-more row-center align-items-center txt-10 txt-white txt-semibold c-pointer bg-blue rounded-pill w-100 text-center py-2 px-3"
                                                                    onClick={async()=>{
                                                                        setIdApplianceDevice(rowData.id_appliance_device);
                                                                        await props.loadRecordsApplianceTags(rowData.id_appliance_device);
                                                                        setApplianceName(rowData.name);
                                                                        setRecordsModal(true);
                                                                    }}
                                                    >{!hasDataModal && showRecordsModal && rowData.id_appliance_device === idApplianceDevice? <Loader inverse /> : props.language.see_more_module_tags}</div>
                                                </div>)}
                                        </Cell>
                                    </Column>
                                </Table>
                            </div>: null}
                        </div>
                    </div>
                    <div className="border-top">
                        <Table.Pagination
                            lengthMenu={lengthMenu}
                            activePage={activePage}
                            displayLength={pageLength}
                            total={totalPages}
                            renderLengthMenu={picker => picker}
                            onChangeLength={value => {
                                const maxActivePage = Math.ceil(props.availableData.length / value);
                                const page = activePage <= maxActivePage ? activePage : maxActivePage;

                                setPageLength(value);
                                setActivePage(page);
                            }}
                            onChangePage={value => setActivePage(value)}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    totalData: state.tags.totalData,
    availableData: state.tags.availableData,
    activeData: state.tags.activeData,
    locations: state.neuPlus.locations,
    language: state.shared.language,
    loading: state.tags.loading,
    records: state.tags.records,
    loadingRecords: state.tags.loadingRecords,
    flagInquiry: state.neuPlus.flagInquiry,
})

const mapDispatchToProps = dispatch => ({
    setAvailableData: payload => dispatch(tagsActions.setAvailableData(payload)),
    setActiveData: payload => dispatch(tagsActions.setActiveData(payload)),
    loadLocationsAsync: () => dispatch(homeActions.loadLocationsAsync()),
    loadAppliancesTagsAsync: () => dispatch(tagsActions.loadAppliancesTagsAsync()),
    loadRecordsApplianceTags: payload => dispatch(tagsActions.loadRecordsApplianceTags(payload)),
    setRecords: payload => dispatch(tagsActions.setRecords(payload)),
    deleteEventLabel: payload => dispatch(tagsActions.deleteEventLabel(payload)),
    setLoading: payload => dispatch(tagsActions.setLoading(payload)),
    setFlagInquiry: payload => dispatch(neuplusActions.setFlagInquiry(payload)),
    updateApplianceInquiry: payload => dispatch(neuplusActions.updateApplianceInquiry(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Tags)

