import React from 'react';

const Medal = ({scale = 1, iconSize, icon, color, iconColor, won}) =>{
        return(
               <svg height={80*scale} width={80*scale}>                                            
                    <g id="one">
                        <polygon points='40 13, 80 13, 110 40, 110 80, 80 110, 40 110, 13 80, 13 40' fill={ won ? color : "#ACB7C0" } transform={`scale(${0.73*scale})`}/>   
                        <polygon className='game-medal-shadow' points='55 28, 95 28, 125 55, 125 95, 95 125, 55 125, 28 95, 28 55' fill={ won ? color : "#ACB7C0" } transform={`scale(${0.59*scale})`}/>   
                        <foreignObject width={80*scale} height={80*scale}>
                            <div className='w-100 h-100 row-center align-items-center' style={{paddingTop:12*scale, paddingLeft:10*scale}}>                                        
                                <i className={`fas fa-${won? icon : "lock"} txt-white txt-${iconSize}`} color={iconColor}/>
                            </div>                 
                        </foreignObject>   
                    </g>                                            	
                </svg>
        )
    }

    export default Medal;