export const SET_LOCATION_MAP = 'SET_LOCATION_MAP';
export const SET_ACTIVE_LOCATIONS_MAP = 'SET_ACTIVE_LOCATIONS_MAP';
export const DELETE_ACTIVE_LOCATIONS_MAP = 'DELETE_ACTIVE_LOCATIONS_MAP';
export const DELETE_ACTIVE_GRAPH_MAP = 'DELETE_ACTIVE_GRAPH_MAP';
export const DELETE_GRAPH_DATA_MAP = 'DELETE_GRAPH_DATA_MAP';
export const SET_LOCATIONS_POSITIONS_MAP = 'SET_LOCATIONS_POSITIONS_MAP';
export const SET_GRAPH_ACTIVE_MAP = 'SET_GRAPH_ACTIVE_MAP';
export const SET_GRAPH_DATA_MAP = 'SET_GRAPH_DATA_MAP';
export const SET_GRAPH_LOADING_MAP = 'SET_GRAPH_LOADING_MAP';
export const SET_GRAPH_TYPE_MAP = 'SET_GRAPH_TYPE_MAP';
export const SET_GRAPH_OPTIONS_MAP = 'SET_GRAPH_OPTIONS_MAP';