import React, { useState,useCallback } from 'react';
import { connect } from 'react-redux';
import {useHistory} from 'react-router-dom';

import * as notificationsActions from '../../store/actions/Notifications';
import * as sharedActions from '../../store/actions/Shared';

import { slideOutLeft } from 'react-animations';

import Radium, { StyleRoot } from 'radium';

const styles = {
    slideOutLeft: {
        animation: 'x 0.3s',
        animationName: Radium.keyframes(slideOutLeft, 'slideOutLeft')
    }
}

const Notification = (props) => {

    let icon;
    let color;

    const history = useHistory();

    const redierect = useCallback(() => history.push('/notifications'), [history]);

    const [isShown, setVisualization] = useState(true);

    switch (props.notification.type) {
        case 2:
            icon = <i className="fas fa-exclamation-triangle txt-12 txt-yellow ml-3"></i>;
            color = "yellow";
            break;
        case 3:
            icon = <i className="fas fa-times-circle txt-12 txt-red ml-3"></i>;
            color = "red";
            break;
        default:
            icon = <i className="fas fa-info-circle txt-12 txt-blue ml-3"></i>;
            color = "blue";
            break;
    }

    return (
        <div onClick={() => {

            props.setBlackScreen(false);
            props.whisper.current.close();

            redierect();
        }}>
            <div className="position-relative">
                <StyleRoot>
                    <div className="position-relative column-start align-items-start bg-white zindex-1 py-3"
                        style={!isShown ? styles.slideOutLeft : null} >
                        <div className="position-absolute row-start align-items-center h-100 top-0">
                            <div className={`bg-${color} h-100`} style={{ width: 5 }}></div>
                        </div>
                        <div className="column-start align-items-end w-100">
                            <div className="row-start align-items-center w-100">
                                {icon}
                                <div className="shared-notifications mx-3 txt-10 txt-dark-blue txt-regular text-wrap c-pointer w-100" title={props.notification.value}>{props.notification.value}</div>
                            </div>
                            <div className="row-start align-items-center mt-3 c-pointer"
                                onClick={e => {

                                    e.stopPropagation();

                                    setVisualization(false);

                                    props.updateActiveNotificationAsync(props.notification);

                                }}>
                                <i className="fas fa-check-circle txt-12 txt-blue mr-2 c-pointer"></i>
                                <div className="d-flex justify-content-end w-100 mr-3 txt-blue txt-semi-bold txt-10 c-pointer">{props.language.title_shared_notifications_check}</div>
                            </div>
                        </div>
                    </div>
                </StyleRoot>
                <div className="position-absolute top-0 left-0 h-100 w-100 bg-clear-gray"></div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    language: state.shared.language,
    notification: ownProps.notification,
    whisper: ownProps.whisper
})

const mapDispatchToProps = dispatch => ({
    updateActiveNotificationAsync: payload => dispatch(notificationsActions.updateActiveNotificationAsync(payload)),
    setBlackScreen: payload => dispatch(sharedActions.setBlackScreen(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
