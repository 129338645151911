import * as invoices from '../creators/Invoices';
import { GETData, GETFile } from '../../services/WebServices';

export const setData = payload => {
    return {
        type: invoices.SET_INVOICES_DATA,
        payload
    }
}

export const setAvailableData = payload => {
    return {
        type: invoices.SET_INVOICES_AVAILABLE_DATA,
        payload
    }
}

export const setActiveData = payload => {
    return {
        type: invoices.SET_INVOICES_ACTIVE_DATA,
        payload
    }
}

export const setInvoicesToPay = payload => {
    return {
        type: invoices.SET_INVOICES_TO_PAY,
        payload
    }
}

export const setLoading = payload => {
    return {
        type: invoices.SET_INVOICES_LOADING,
        payload
    }
}

export const setPermalink = payload => {
    return {
        type: invoices.SET_INVOICES_PERMALINK,
        payload
    }
}

export const setBanks = payload => {
    return {
        type: invoices.SET_INVOICES_BANKS,
        payload
    }
}

export const loadInvoicesAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        dispatch(setLoading(true));

        GETData(`invoices/${userId}`, "GET")
            .then(response => {
                if (response !== null) {
                    dispatch(setData(response));
                    dispatch(setAvailableData(response));
                }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoading(false)));

    }
}

export const loadPayDataAsync = (btn, payload) => {
    return (dispatch, getState) => {

        let invoices = getState().invoices.activeData;

        const index = invoices
            .findIndex(invoice => invoice.id_bill === btn);

        invoices[index].downloading = true;

        dispatch(setActiveData([...invoices]));

        GETData("payvalida/banks", "GET")
            .then(response => {

                if (response !== null) {
                    invoices[index].downloading = false;

                    dispatch(setActiveData([...invoices]));
                    dispatch(setBanks(response));
                    dispatch(setInvoicesToPay(payload));
                }
            })
            .catch(response => console.error(response));

    }
}

export const downloadInvoiceAsync = (payload) => {
    return (dispatch, getState) => {

        let invoices = getState().invoices.activeData;

        const index = invoices
            .findIndex(invoice => invoice.id_bill === payload.id_bill);

        invoices[index].downloading_file = true;

        dispatch(setActiveData([...invoices]));

        let metadata = {
            id: payload.id_user,
            folder: "utility-bills",
            name: `bills/${payload.id_period}/${payload.file_name}`
        }

        GETFile("files", "PUT", metadata)
            .then((result) => {

                if (result !== null) {

                    const reader = result.body.getReader();

                    return new ReadableStream({

                        start(controller) {

                            return pump();

                            async function pump() {

                                const { done, value } = await reader
                                    .read();
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            }
                        }
                    });

                }

            })
            .then(stream => new Response(stream, { headers: { "Content-Type": `application/${payload.file_name.split('.').pop()}` } }))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {

                const link = document.createElement("a");

                link.href = url;
                link.download = payload.file_name;
                link.click();

            })
            .catch(response => console.error(response))
            .finally(() => {
                invoices[index].downloading_file = false;
                dispatch(setActiveData([...invoices]));
            });
    }
}