export const SET_GAMES = 'SET_GAMES';
export const SET_NEW_ACHIEVEMENTS = 'SET_NEW_ACHIEVEMENTS';
export const SET_MEDALS = 'SET_MEDALS';
export const SET_SHOW_MEDALS = 'SET_SHOW_MEDALS';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_RANKING = 'SET_RANKING';
export const SET_SHOW_RANKING = 'SET_SHOW_RANKING';
export const SET_PREVIOUS = 'SET_PREVIOUS';  // View more in ranking
export const SET_NEXT = 'SET_NEXT';  // View more in ranking
export const SET_LOADING_NEXT = 'SET_LOADING_NEXT';
export const SET_LOADING_PREVIOUS = 'SET_LOADING_PREVIOUS';
export const SET_LOADING_INITIAL = 'SET_LOADING_INITIAL';
export const SET_LOADING_INITIAL_CHALLENGE = 'SET_LOADING_INITIAL_CHALLENGE';
export const SET_RANKING_URI = 'SET_RANKING_URI';
export const SET_RANKING_TYPE_PLACE = 'SET_RANKING_TYPE_PLACE';
export const SET_RANKING_TYPE_TIME = 'SET_RANKING_TYPE_TIME';

//Power Challenge
export const SET_GAMES_PC_TYPE = 'SET_GAMES_PC_TYPE';
export const SET_GAMES_PC_CHALLENGES = 'SET_GAMES_PC_CHALLENGES';
export const SET_GAMES_PC_NEXT_PAGE = 'SET_GAMES_PC_NEXT_PAGE';