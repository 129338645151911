import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { SETData } from '../../services/WebServices';
import * as invoicesActions from '../../store/actions/Invoices';

import { Loader, Input, InputGroup, SelectPicker, Modal, Alert, InputNumber } from 'rsuite';

import CurrencyFormat from 'react-currency-format';

import payvalidaLogo from '../../imgs/payvalida-logo.png';
import neugetsLogo from '../../imgs/neugets-logo.svg';

import amplitude from 'amplitude-js';

const userLegalTypes = [
    {
        label: "Cédula de ciudadanía",
        value: "CC"
    },
    {
        label: "NIT",
        value: "NIT",
    },
    {
        label: "Cédula de extranjería",
        value: "CE"
    }
]

const userTypes = [
    {
        label: "Natural",
        value: "N"
    },
    {
        label: "Jurídica",
        value: "J",
    }
]

const isValidateEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const isValidateString = val => {
    return /^[ñíóáéú a-zA-Z ]+$/.test(val);
}

const isValidUser = user => {

    if (user.name === null || user.name === "" || !isValidateString(user.name)) {
        return false;
    }

    if (!isValidateEmail(user.email)) {
        return false;
    }

    if (user.phone === null || user.phone === "") {
        return false;
    }

    if (user.address === null || user.address === "") {
        return false;
    }

    if (user.id_document === null || user.id_document === "") {
        return false;
    }

    if (user.financial_institution_code === null) {
        return false;
    }

    if (user.user_type === null) {
        return false;
    }

    if (user.terms === false) {
        return false;
    }

    return true;

}

const showError = (user, props) => {

    if (user.name === null || user.name === "" || !isValidateString(user.name)) {
        Alert.error(props.language.alert_invoices_popup_name);
        return;
    }

    if (user.address === null || user.address === "") {
        Alert.error(props.language.alert_invoices_popup_address);
        return;
    }

    if (!isValidateEmail(user.email)) {
        Alert.error(props.language.alert_invoices_popup_email);
        return;
    }

    if (user.phone === null || user.phone === "") {
        Alert.error(props.language.alert_invoices_popup_phone);
        return;
    }

    if (user.id_document === null || user.id_document === "") {
        Alert.error(props.language.alert_invoices_popup_id_document);
        return;
    }

    if (user.financial_institution_code === null) {
        Alert.error(props.language.alert_invoices_popup_financial_institution_code);
        return;
    }

    if (user.terms === false) {
        Alert.error(props.language.alert_invoices_popup_terms);
        return;
    }

}

const setPay = (user, invoices, language, setLoading) => {

    user.id_bills = invoices.map(i => i.id_bill);

    setLoading(true);

    SETData(`payvalida/pse/transaction/`, "POST", user)
         .then(response => {
             if (response !== null) {

                 window.location.assign(response.url_bank);

             } else {
                 Alert.error(language.alert_shared_error);
             }
         })
        .catch(() => Alert.error(language.alert_shared_error))
        .finally(() => setLoading(false));

}

const Pay = (props) => {

    let invoicesToPay = props.invoicesToPay;
    let neugets = props.user.neugets;

    const initUser = {
        id_user: props.user.id_user,
        name: `${props.user.name} ${props.user.lastname}`,
        email: props.user.email,
        phone: props.user.phone,
        address: props.user.address,
        id_document: props.user.id_document,
        financial_institution_code: null,
        user_legal_id_type: userLegalTypes[0].value,
        user_type: null,
        terms: true,
        neugets: 0
    }

    const neugetsToCOP = 1000;

    const [user, setUser] = useState(initUser);

    const [isLoading, setLoading] = useState(false);
    const [isEnable, setEnable] = useState(false);
    const [modalInfo, setModalInfo] = useState({});

    const [maxNeugets, setMaxNeugets] = useState()

    useEffect(() => {
        if (invoicesToPay.length) {
            setEnable(isValidUser(user));
            setModalInfo({
                title: invoicesToPay.reduce((a, { prefix_reference, payment_reference }, index) => a + (index ? ' - ' : '') + prefix_reference + payment_reference, ''),
                payment_value: invoicesToPay.reduce((a, { payment_value }) => a + payment_value, 0),
            });
        }
    }, [setEnable, setModalInfo, user, invoicesToPay, setMaxNeugets]);

    useEffect(() => {
        setMaxNeugets(Math.min(neugets, modalInfo.payment_value / neugetsToCOP))
    }, [neugets, modalInfo, setMaxNeugets]);

    return (
        <Modal
            size="sm"
            show={invoicesToPay.length !== 0}
            onHide={() => {props.setInvoicesToPay([]); amplitude.getInstance().logEvent('Billing Payment Stopped');}}>
            <Modal.Header>
                <div className="row-end align-items-center h-100 w-100">
                    <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.title_invoices_popup_pays} {modalInfo.title}</div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column p-3">
                    <div className="row-center align-items-center flex-wrap my-2">
                        <div className="col-md-6 col-12">
                            <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_invoices_popup_name}</div>
                            <InputGroup
                                inside >
                                <InputGroup.Addon>
                                    <i className="fas fa-align-justify txt-10 txt-light-blue"></i>
                                </InputGroup.Addon>
                                <Input
                                    value={user.name ?? ""}
                                    placeholder={`${props.language.placeholder_invoices_popup_name_example}`}
                                    onChange={value => setUser({ ...user, name: value })} />
                            </InputGroup>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_invoices_popup_email}</div>
                            <InputGroup
                                inside >
                                <InputGroup.Addon>
                                    <i className="fas fa-envelope txt-10 txt-light-blue"></i>
                                </InputGroup.Addon>
                                <Input
                                    value={user.email ?? ""}
                                    type={"email"}
                                    placeholder={`${props.language.placeholder_invoices_popup_email_example}`}
                                    onChange={value => setUser({ ...user, email: value })} />
                            </InputGroup>
                        </div>
                    </div>
                    <div className="row-center align-items-center flex-wrap my-2">
                        <div className="col-md-6 col-12 mt-md-0 mt-2">
                            <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_invoices_popup_phone}</div>
                            <InputGroup
                                inside >
                                <InputGroup.Addon>
                                    <i className="fas fa-mobile txt-10 txt-light-blue"></i>
                                </InputGroup.Addon>
                                <Input
                                    value={user.phone ?? ""}
                                    type={"number"}
                                    placeholder={`${props.language.placeholder_invoices_popup_phone_example}`}
                                    onChange={value => setUser({ ...user, phone: value })} />
                            </InputGroup>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_shared_user_address}</div>
                            <InputGroup
                                inside >
                                <InputGroup.Addon>
                                    <i className="fas fa-map-marker-alt txt-10 txt-light-blue"></i>
                                </InputGroup.Addon>
                                <Input
                                    value={user.address ?? ""}
                                    placeholder={`${props.language.placeholder_shared_user_address_example}`}
                                    onChange={value => setUser({ ...user, address: value })} />
                            </InputGroup>
                        </div>
                    </div>
                    <div className="row-center align-items-center flex-wrap my-2">
                        <div className="col-md-6 col-12">
                            <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_invoices_popup_person_type}</div>
                            <SelectPicker
                                className="w-100"
                                placeholder={props.language.placeholder_invoices_popup_type_user_select}
                                searchable={false}
                                cleanable={false}
                                value={user.user_type}
                                data={userTypes}
                                onChange={value => setUser({ ...user, user_type: value })}
                            />
                        </div>
                        <div className="col-md-6 col-12 mt-md-0 mt-2">
                            <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_invoices_popup_bank}</div>
                            <SelectPicker
                                className="w-100"
                                searchable={false}
                                cleanable={false}
                                value={user.financial_institution_code}
                                data={props.banks}
                                placeholder={props.language.placeholder_invoices_popup_bank_select}
                                valueKey={"bank_code"}
                                labelKey={"bank_name"}
                                onChange={value => setUser({ ...user, financial_institution_code: value })}
                            />
                        </div>
                    </div>
                    {user.user_type?
                    <div className="row-center align-items-center flex-wrap my-2">
                        <div className="col-md-6 col-12">
                            <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.placeholder_invoices_popup_document_type}</div>
                            <SelectPicker
                                className="w-100"
                                searchable={false}
                                cleanable={false}
                                value={user.user_type === userTypes[1].value ? userLegalTypes[1].value : userLegalTypes[0].value}
                                data={userLegalTypes}
                                onChange={value => setUser({ ...user, user_legal_id_type: value })}
                            />
                        </div>
                        <div className="col-md-6 col-12 mt-md-0 mt-2">
                            <div className="txt-light-blue txt-10 txt-semibold mb-2">{user.user_type === userTypes[1].value ? "NIT" : props.language.placeholder_invoices_popup_document}</div>
                            <InputGroup
                                inside >
                                <InputGroup.Addon>
                                    <i className="fas fa-id-card txt-10 txt-light-blue"></i>
                                </InputGroup.Addon>
                                <Input
                                    defaultValue={user.user_type === userTypes[1].value ? "" : user.id_document}
                                    placeholder={`${props.language.placeholder_invoices_popup_document_example}`}
                                    onChange={value => setUser({ ...user, id_document: value })} />
                            </InputGroup>
                        </div>
                    </div>: null}
                    <div className="row-between align-items-center flex-wrap bg-clear-gray p-2 my-2 mx-3 rounded">
                        <div className="col-6">
                            <div className="txt-bold txt-14 txt-dark-blue">{props.language.placeholder_invoices_popup_invoice}</div>
                        </div>
                        <div className="d-flex col-6">
                            <CurrencyFormat
                                className="txt-bold txt-14 txt-dark-blue text-right"
                                value={modalInfo.payment_value - user.neugets * neugetsToCOP}
                                prefix="$"
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={1}
                                fixedDecimalScale={true} />
                        </div>
                    </div>
                    <div className="row-between align-items-center flex-wrap bg-black p-2 my-2 mx-3 rounded">
                        <div className="col-6 p-0 row-start align-items-center">
                            <div id="neugets-logo-small" className="row-center align-items-center rounded-circle p-1">
                                <img src={neugetsLogo} alt="neugets-logo" className="m-1 w-100 h-100"></img>
                            </div>
                            <div className="txt-semibold txt-12 txt-white pl-3">{props.language.placeholder_invoices_popup_neugets}</div>
                        </div>
                        <div className="d-flex col-6 justify-content-end">
                            <InputNumber
                                className="input-neugets"
                                size="xs"
                                scrollable
                                min={0}
                                max={maxNeugets}
                                value={user.neugets}
                                onChange={value => value <= maxNeugets && setUser({ ...user, neugets: value })}
                            />
                        </div>
                    </div>
                    {/* <div className="row-start align-items-center">
                        <div className="col-md-1 col-2 p-0">
                            <Checkbox
                                checked={user.terms}
                                onChange={(_value, checked) => setUser({ ...user, terms: checked })}
                            />
                        </div>
                        <div className="col-md-11 col-10 p-0">
                            <div className="txt-regular txt-8 txt-dark-blue" style={{ whiteSpace: "normal" }}>Acepto haber leído los <a href={props.permalink}>términos y condiciones y la política de privacidad</a> para hacer esta compra.</div>
                        </div>
                    </div> */}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row-between align-items-center h-100 w-100">
                    <div className="column-center align-items-start ml-4">
                        <div className="d-none d-md-inline-block txt-semibold txt-8 txt-light-blue text-left">{props.language.title_invoices_popup_gateway}</div>
                        <img height={30} src={payvalidaLogo} alt="payvalida-logo"></img>
                    </div>
                    <button
                        style={{ width: 150 }}
                        className={`row-center align-items-center txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 mr-4 bg-${isEnable ? "blue" : "light-blue"}`}
                        onClick={() => {
                            if (!isLoading && isEnable) { 
                                setPay(user, props.invoicesToPay, props.language, setLoading); 
                                amplitude.getInstance().logEvent('Billing Payment Finished', user);
                            }
                            else if (!isEnable) { showError(user, props); }
                        }}>{!isLoading ? props.language.title_invoices_state_pay : <Loader inverse />}</button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    banks: state.invoices.banks,
    language: state.shared.language,
    permalink: state.invoices.permalink,
    invoicesToPay: state.invoices.invoicesToPay
})

const mapDispatchToProps = dispatch => ({
    setInvoicesToPay: payload => dispatch(invoicesActions.setInvoicesToPay(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Pay)
