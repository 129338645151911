import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';

import { startOfMonth } from 'date-fns';

import * as reportsActions from '../../store/actions/Reports';

import { setDisbaledGraphsValues } from '../../services/GraphsHandler';

import { Loader, Table, Input, InputGroup, Tooltip, Whisper, Alert, Checkbox } from 'rsuite';
import { Modal } from 'rsuite';
import { CheckPicker } from 'rsuite';
import { DateRangePicker } from 'rsuite';
import { SelectPicker } from 'rsuite';

import { Transition } from 'react-transition-group';

import Options from '../shared/Options';

import { getAnalytics, logEvent } from "firebase/analytics";

import { format } from 'date-fns';
import esLocale from "date-fns/locale/es";
import enLocale from 'date-fns/locale/en-US';

const analytics = getAnalytics();

const { Column, HeaderCell, Cell } = Table;
const { afterToday } = DateRangePicker;

const lengthMenu = [
    {
        value: 20,
        label: 20
    },
    {
        value: 50,
        label: 50
    },
    {
        value: 100,
        label: 100
    }
];

const format_types = [
    {
        value: 'CSV',
        label: 'CSV'
    },
    {
        value: 'PDF',
        label: 'PDF'
    }
];

const showError = (report, props) => {

    if (report.report_name === null || report.report_name === "") {
        Alert.error(props.language.alert_reports_popup_report_name);
        return;
    }

    if (!report.addresses.length) {
        Alert.error(props.language.alert_reports_popup_addresses);
        return;
    }
    if (!report.graphs.length) {
        Alert.error(props.language.alert_reports_popup_graphs);
        return;
    }
    if (report.interval === null) {
        Alert.error(props.language.alert_reports_popup_interval);
        return;
    }
    if (report.format_type === null) {
        Alert.error(props.language.alert_reports_popup_format_type);
        return;
    }

}

const ImageCell = ({ rowData, dataKey, language, downloadReport, ...props }) => {

    let button;

    switch (rowData[dataKey]) {
        case 0:
            button = <button className="btn-state txt-12 txt-dark-blue2 rounded-pill px-3 py-2 bg-light-blue2 c-default">{language.title_reports_button_pending}</button>;
            break;
        case 1:
            button = <button className="btn-state row-center align-items-center txt-12 txt-semibold txt-white rounded-pill px-3 py-2 bg-blue"
                onClick={() => downloadReport(rowData)}>{rowData.downloading ? <Loader inverse /> : language.title_reports_button_download}</button>;
            break;
        default:
            button = <button className="btn-state txt-12 txt-semibold txt-red rounded-pill px-4 py-2 bg-clear-red c-default">{language.title_reports_button_error}</button>;
            break;
    }

    return (
        <Cell {...props} style={{ padding: 0 }}>
            <div className="row-center align-items-center h-100">
                {button}
            </div>
        </Cell>);

};

const Reports = (props) => {

    const intervals = [
        {
            value: 0,
            label: props.language.label_shared_popup_select_interval_hourly
        },
        {
            value: 1,
            label: props.language.label_shared_popup_select_interval_daily
        },
        {
            value: 2,
            label: props.language.label_shared_popup_select_interval_weekly
        },
        {
            value: 3,
            label: props.language.label_shared_popup_select_interval_monthly
        }
    ];

    const states = [
        {
            value: 1,
            label: props.language.title_reports_button_download
        },
        {
            value: 0,
            label: props.language.title_reports_button_pending
        },
        {
            value: 2,
            label: props.language.title_reports_button_error
        }
    ];

    const initReportForm = {
        report_name: '',
        addresses: [],
        graphs: [],
        from: startOfMonth(new Date()),
        to: new Date(),
        interval: intervals[0].value,
        format_type: format_types[0].value
    }

    const initFilterData = {
        search_value: "",
        from: new Date(2020, 0, 1),
        to: new Date(),
        report_name: "",
        format: null,
        state: null
    }

    let availableData = props.availableData;
    let totalData = props.totalData;

    const [disbaledGraphs, setDisbaledGraphs] = useState([]);
    const [reportForm, setReportForm] = useState(initReportForm);
    const [filterData, setFilterData] = useState(initFilterData);

    const [activePage, setActivePage] = useState(1);
    const [pageLength, setPageLength] = useState(20);
    const [totalPages, setTotalPages] = useState(0);

    const [showReportsModal, setReportsModal] = useState(false);
    const [showFilters, setShowFilters] = useState(false);

    const setActiveData = props.setActiveData;
    const setAvailableData = props.setAvailableData;
    const loadReportsAsync = props.loadReportsAsync;
    const loadAddressesAsync = props.loadAddressesAsync;

    let isValid = !Object.values(reportForm).some(x => x.length === 0);

    let locale = props.language.language_locale == "en" ? enLocale : esLocale;

    let allAddresses = props.addresses.map(item => item.value);

    useEffect(() => {
        loadReportsAsync();
    }, [loadReportsAsync]);

    useEffect(() => {

        let data = totalData;
        const search = filterData.search_value.toLowerCase();

        data = data.filter(option =>
        ((Date.parse(filterData.from) <= Date.parse(option.date) && (Date.parse(filterData.to) + 86400000) >= Date.parse(option.date))
            && (filterData.report_name === option.report_name || !filterData.report_name.length)
            && (String(option.file).toUpperCase().includes(filterData.format) || filterData.format === null)
            && (filterData.state === option.status || filterData.state === null)
            && (option.report_name.toLowerCase().includes(search) || String(option.file).toLowerCase().includes(search) || search === "")
        ));

        setActivePage(1);
        setAvailableData(data);

    }, [setActivePage, setAvailableData, filterData, totalData]);

    useEffect(() => {
        setTotalPages(availableData.length);
        setActiveData(availableData.slice((activePage - 1) * pageLength, activePage * pageLength));
    }, [loadReportsAsync, setActiveData, setTotalPages, activePage, pageLength, availableData]);

    useEffect(() => logEvent(analytics, "reports", {
        id_user: props.user.id_user,
        name: `${props.user.name} ${props.user.lastname}`
    }), []);

    return (
        <Fragment>
            <Modal
                size="sm"
                show={showReportsModal}
                onHide={() => {
                    setReportsModal(false);
                    setReportForm(initReportForm);
                }}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.title_reports_popup_create_report}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column p-3">
                        <div className="row-center align-items-center flex-wrap my-2">
                            <div className="col-md-6 col-12">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_reports_popup_name}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-clipboard txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.placeholder_reports_popup_name}
                                        maxLength={30}
                                        onChange={value => setReportForm({ ...reportForm, report_name: value })} />
                                </InputGroup>
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_reports_popup_addresses}</div>
                                <CheckPicker
                                    className="w-100"
                                    groupBy="group"
                                    searchable={false}
                                    value={reportForm.addresses}
                                    placeholder={props.language.placeholder_reports_popup_addresses}
                                    data={props.addresses}
                                    renderExtraFooter={() => (
                                        <div className='my-2'>
                                          <Checkbox
                                            inline
                                            onChange={(value, checked) => {
                                                setReportForm({ ...reportForm, addresses: checked ? allAddresses : [] });
                                              }}
                                          >
                                            {props.language.title_home_popup_addresses_check_all}
                                          </Checkbox>
                                        </div>)}
                                    onChange={value => setReportForm({ ...reportForm, addresses: value })}
                                />
                            </div>
                        </div>
                        <div className="row-center align-items-center flex-wrap my-2">
                            <div className="col-md-6 col-12">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_reports_popup_graphs}</div>
                                <CheckPicker
                                    className="w-100"
                                    groupBy="group"
                                    searchable={false}
                                    placeholder={props.language.placeholder_reports_popup_graphs}
                                    data={props.graphs}
                                    disabledItemValues={disbaledGraphs}
                                    onChange={value => {

                                        setDisbaledGraphs(setDisbaledGraphsValues(value, props.graphs, props.graphsRelations));

                                        setReportForm({ ...reportForm, graphs: value });
                                    }}
                                />
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_reports_popup_date_range}</div>
                                <DateRangePicker
                                    className="w-100"
                                    showOneCalendar
                                    format={props.user.hide_invoices? "MM-DD-YYYY" : "DD-MM-YYYY"}
                                    defaultValue={[reportForm.from, reportForm.to]}
                                    locale={props.dateLocale}
                                    disabledDate={afterToday()}
                                    cleanable={false}
                                    onChange={value => setReportForm({ ...reportForm, from: value[0], to: value[1] })}
                                />
                            </div>
                        </div>
                        <div className="row-center align-items-center flex-wrap my-2">
                            <div className="col-md-6 col-12">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_reports_popup_interval}</div>
                                <SelectPicker
                                    className="w-100"
                                    defaultValue={reportForm.interval}
                                    searchable={false}
                                    cleanable={false}
                                    data={intervals}
                                    onChange={value => setReportForm({ ...reportForm, interval: value })}
                                />
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_reports_popup_format}</div>
                                <SelectPicker
                                    className="w-100"
                                    defaultValue={reportForm.format_type}
                                    searchable={false}
                                    cleanable={false}
                                    data={format_types}
                                    onChange={value => setReportForm({ ...reportForm, format_type: value })}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 mr-4 bg-${isValid ? "blue" : "light-blue"}`}
                            onClick={() => {
                                if (isValid) {
                                    setFilterData(initFilterData);
                                    props.createReportAsync(reportForm);
                                    setReportsModal(false);
                                    setShowFilters(false);
                                    setReportForm(initReportForm);
                                    document.getElementById("input-search").value = "";
                                    document.getElementById("input-name").value = "";
                                } else {
                                    showError(reportForm, props);
                                }
                            }}>
                            {props.language.title_reports_popup_button_generate}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <div id="reports" className="column-start align-items-start px-3 py-2 h-100 w-100">
                <div className="row-between align-items-center flex-wrap-reverse w-100 pb-2">
                    <div className="row-start align-items-center flex-wrap col-md-8 col-12 p-0">
                        <div className="row-start align-items-center col-md-6 col-12 my-md-0 my-2 p-0">
                            <div className="txt-20 txt-bold txt-dark-blue w-auto">{props.language.title_reports}</div>
                            <Whisper
                                delay={500}
                                placement="right"
                                trigger="hover"
                                speaker={<Tooltip>{props.language.tooltip_reports_create_report}</Tooltip>}>
                                <button
                                    id="reports-add-report"
                                    className="d-flex justify-content-center align-items-center border-0 rounded-circle p-2 bg-blue ml-3 mr-2"
                                    onClick={() => {

                                        logEvent(analytics, "click_reports_add", {
                                            id_user: props.user.id_user,
                                            name: `${props.user.name} ${props.user.lastname}`
                                        });

                                        if (!props.addresses.length) { loadAddressesAsync(); }

                                        setReportsModal(true);

                                    }}>
                                    <i className="fas fa-plus txt-12 txt-white"></i>
                                </button>
                            </Whisper>
                        </div>
                        <div className="row-start align-items-center col-md-6 col-12 p-0">
                            <Whisper
                                delay={1000}
                                placement="bottom"
                                trigger="focus"
                                speaker={<Tooltip>{props.language.tooltip_shared_search}</Tooltip>}>
                                <InputGroup
                                    inside>
                                    <Input
                                        id="input-search"
                                        placeholder={`${props.language.title_shared_search}`}
                                        onPressEnter={e => setFilterData({ ...filterData, search_value: e.target.value })} />
                                    <InputGroup.Addon>
                                        <i className="fas fa-search txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                </InputGroup>
                            </Whisper>
                        </div>
                    </div>
                    <div className="d-block d-md-flex justify-content-end align-items-center col-md-4 col-12 p-0">
                        <Whisper
                            placement="bottom"
                            trigger="click"
                            speaker={<Tooltip>{props.language.tooltip_reports_info}</Tooltip>}>
                            <i className="fas fa-info-circle d-none d-md-block txt-12 txt-light-blue txt-hover-blue mx-2 c-pointer"></i>
                        </Whisper>
                        <Options />
                    </div>
                </div>
                <div className="position-relative column-start flex-grow-1 w-100 bg-white rounded shadow overflow-hidden">
                    <Transition
                        in={props.loading}
                        timeout={100}>
                        {state => (
                            <div className={`trans-fade trans-fade-${state} position-absolute bg-white w-100 h-100 top-0 left-0 zindex-1`}>
                                <div className="column-center align-items-center w-100 h-100">
                                    <Loader center size="md" content={props.language.loading_message} />
                                </div>
                            </div>
                        )}
                    </Transition>
                    {!props.activeData.length && !props.loading ?
                        <div className={`position-absolute column-center align-items-center left-0 bg-white border-top w-100 p-3 zindex-1 empty-message-${!showFilters ? "complete" : "filtered"}`}>
                            <div className="row-center align-items-center w-100">
                                <i className="fas fa-clipboard txt-20 txt-light-blue" />
                                <div className="txt-20 txt-bold txt-light-blue w-auto mx-3">{props.language.title_reports_empty}</div>
                            </div>
                        </div> : null}
                    <div className="table-data-container flex-grow-1 w-100">
                        <div className="position-relative d-flex flex-column h-100" style={{ minWidth: 1200 }}>
                            <div className="table-header position-absolute top-0 row-start align-items-center zindex-1 py-3 w-100" style={{ paddingRight: 20 }}>
                                <div className="text-center" style={{ width: 60, paddingLeft: 10, paddingRight: 10 }}>
                                    <Whisper
                                        delay={500}
                                        placement="top"
                                        trigger="hover"
                                        speaker={<Tooltip>{props.language.tooltip_shared_filter}</Tooltip>}>
                                        <i className="fas fa-filter txt-10 txt-light-blue txt-hover-blue c-pointer"
                                            onClick={() => setShowFilters(!showFilters)} />
                                    </Whisper>
                                </div>
                                <div className="text-left" style={{ width: 170, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_date}</div>
                                </div>
                                <div className="text-left" style={{ width: 140, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_hour}</div>
                                </div>
                                <div className="text-left " style={{ width: 300, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_reports_report}</div>
                                </div>
                                <div className="text-left " style={{ width: 280, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_reports_file_name}</div>
                                </div>
                                <div className="text-center flex-grow-1 " style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_state}</div>
                                </div>
                            </div>
                            <div className={`table-filter position-absolute row-start align-items-end zindex-1 pb-3 w-100 ${!showFilters ? "d-none" : null}`} style={{ paddingRight: 20 }}>
                                <div className="text-left" style={{ marginLeft: 60, width: 310, paddingLeft: 10, paddingRight: 10 }}>
                                    <DateRangePicker
                                        className="w-100"
                                        showOneCalendar
                                        format={props.user.hide_invoices? "MM-DD-YYYY" : "DD-MM-YYYY"}
                                        value={[filterData.from, filterData.to]}
                                        locale={props.dateLocale}
                                        disabledDate={afterToday()}
                                        onChange={value => setFilterData({ ...filterData, from: value[0], to: value[1] })}
                                        onClean={() => setFilterData({ ...filterData, from: initFilterData.from, to: initFilterData.to })}
                                    />
                                </div>
                                <div className="text-left " style={{ width: 300, paddingLeft: 10, paddingRight: 10 }}>
                                    <InputGroup
                                        inside>
                                        <InputGroup.Addon>
                                            <i className="fas fa-search txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input
                                            id="input-name"
                                            placeholder={props.language.placeholder_reports_filter_table_report_name}
                                            onPressEnter={e => setFilterData({ ...filterData, report_name: e.target.value })} />
                                    </InputGroup>
                                </div>
                                <div className="text-left " style={{ width: 280, paddingLeft: 10, paddingRight: 10 }}>
                                    <SelectPicker
                                        className="w-100"
                                        searchable={false}
                                        placeholder={props.language.placeholder_reports_filter_table_all_formats}
                                        value={filterData.format}
                                        data={format_types}
                                        onChange={value => setFilterData({ ...filterData, format: value })}
                                    />
                                </div>
                                <div className="text-left flex-grow-1 " style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <SelectPicker
                                        className="w-100"
                                        searchable={false}
                                        placeholder={props.language.placeholder_reports_filter_table_all_states}
                                        value={filterData.state}
                                        data={states}
                                        onChange={value => setFilterData({ ...filterData, state: value })}
                                    />
                                </div>
                            </div>
                            <div className={`position-relative flex-grow-1 table-vertical-scroll border-top table-content-${!showFilters ? "complete" : "filtered"}`}>
                                <Table
                                    autoHeight
                                    wordWrap
                                    data={props.activeData}
                                    hover={false}
                                    showHeader={false}>
                                    <Column width={60} verticalAlign="middle">
                                        <HeaderCell>trash</HeaderCell>
                                        <Cell dataKey="trash">
                                            {rowData => (
                                                rowData.deleting ? <Loader className="ml-3" /> :
                                                    <Whisper
                                                        placement="top"
                                                        trigger="hover"
                                                        speaker={<Tooltip>{props.language.tooltip_delete}</Tooltip>}>
                                                        <i className="fas fa-trash txt-12 txt-hover-blue txt-light-blue ml-3 c-pointer"
                                                            onClick={(() => props.deleteReportAsync(rowData.id_report))}
                                                        />
                                                    </Whisper>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={170} verticalAlign="middle">
                                        <HeaderCell>date</HeaderCell>
                                        <Cell dataKey="date">
                                            {rowData => (<div className="txt-10 txt-dark-blue txt-regular">{
                                                format(new Date (rowData.date), props.user.hide_invoices? "MMM dd yyyy" : "dd MMM yyyy", { locale: locale })
                                            }
                                            </div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={140} verticalAlign="middle">
                                        <HeaderCell>hour</HeaderCell>
                                        <Cell dataKey="date">
                                            {rowData => (
                                                <div className="txt-10 txt-dark-blue txt-regular">{new Date(rowData.date)
                                                    .toLocaleTimeString(props.language.format_date_string,
                                                        {
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            hour12: true
                                                        })}
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={300} verticalAlign="middle">
                                        <HeaderCell>report_name</HeaderCell>
                                        <Cell dataKey="report_name">
                                            {rowData => (
                                                <div style={{ width: 260 }}>
                                                    <div className="txt-10 txt-dark-blue txt-semibold">{rowData.report_name}</div>
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={280} verticalAlign="middle">
                                        <HeaderCell>file_name</HeaderCell>
                                        <Cell dataKey="file_name">
                                            {rowData => (
                                                <div style={{ width: 260 }}>
                                                    <div className="txt-10 txt-dark-blue txt-semibold">{rowData.file}</div>
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={1} verticalAlign="middle">
                                        <HeaderCell>state</HeaderCell>
                                        <ImageCell
                                            dataKey="status"
                                            language={props.language}
                                            downloadReport={props.downloadReportAsync} />
                                    </Column>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className={`border-top ${!props.activeData.length ? "d-none" : null}`}>
                        <Table.Pagination
                            lengthMenu={lengthMenu}
                            activePage={activePage}
                            displayLength={pageLength}
                            total={totalPages}
                            renderLengthMenu={picker => picker}
                            onChangeLength={value => {
                                const maxActivePage = Math.ceil(props.availableData.length / value);
                                const page = activePage <= maxActivePage ? activePage : maxActivePage;

                                setPageLength(value);
                                setActivePage(page);
                            }}
                            onChangePage={value => { setActivePage(value) }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    totalData: state.reports.totalData,
    availableData: state.reports.availableData,
    activeData: state.reports.activeData,
    loading: state.reports.loading,
    graphs: state.home.graphs,
    graphsRelations: state.home.graphsRelations,
    addresses: state.reports.addresses,
    language: state.shared.language,
    dateLocale: state.home.dateLocale
})

const mapDispatchToProps = dispatch => ({
    loadReportsAsync: () => dispatch(reportsActions.loadReportsAsync()),
    loadAddressesAsync: () => dispatch(reportsActions.loadAddressesAsync()),
    setAvailableData: payload => dispatch(reportsActions.setAvailableData(payload)),
    setActiveData: payload => dispatch(reportsActions.setActiveData(payload)),
    downloadReportAsync: payload => dispatch(reportsActions.downloadReportAsync(payload)),
    deleteReportAsync: payload => dispatch(reportsActions.deleteReportAsync(payload)),
    createReportAsync: payload => dispatch(reportsActions.createReportAsync(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Reports)

