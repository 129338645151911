import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { Loader, Modal, Progress} from 'rsuite';

import * as gameActions from '../../../../store/actions/Game';
import moment from 'moment';

import GraphChallenge from './GraphChallenge';

const typeButton = (props, setModal, setIndex) => {

    let buttonFisrt, buttonSecond;

    const handleChoseChallenge = (e) => {
        e.stopPropagation();

        const index = props.challenges.findIndex(c => c.state !== 0);

        if (index >= 0) {
            setIndex(index);
            setModal(true);
        } else {
            setIndex(null);
            props.choseChallenge(props.challenge, null, props.survey);            
        }

    }

    switch (props.challenge.state) {
        case 0:

            buttonFisrt =
            <div className="row-end mt-2">
                    <div className="bg-blue text-white w-auto px-3 py-2 rounded text-center c-pointer"  
                        onClick={async (e) => {
                        handleChoseChallenge(e);
                    }}>{props.language.title_btn_game_power_challenge_choose}</div>
            </div>                

            buttonSecond =
                <div className="row-end mt-2">
                    <div className="bg-blue text-white w-auto px-3 py-2 rounded text-center c-pointer"  
                        onClick={async (e) => {
                        handleChoseChallenge(e);
                    }}>{props.language.title_btn_game_power_challenge_choose}</div>
                </div>

            break;
        case 1:

            buttonFisrt =
                <div className="row-end mt-2">
                    <div className="bg-dark-gray txt-dark-blue w-auto px-3 py-2 rounded text-center c-default">{props.language.title_btn_game_power_challenge_progress}</div>
                </div>

            buttonSecond =
                <div className="row-end mt-2">
                    <div className="bg-dark-green txt-green w-auto px-3 py-2 rounded text-center c-default">{props.language.title_btn_game_power_challenge_winning}</div>
                </div>              

            break;
        case 2:

            buttonFisrt =
                <div className="row-end mt-2">
                    <div className="bg-dark-gray txt-dark-blue w-auto px-3 py-2 rounded text-center">{props.language.title_btn_game_power_challenge_progress}</div>
                </div>                 

            buttonSecond =
                <div className="row-end mt-2">
                    <div className="bg-dark-red txt-red w-auto px-3 py-2 rounded text-center">{props.language.title_btn_game_power_challenge_losing}</div>
                </div> 
                
            break;
        case 3:

            buttonFisrt =
                <div className="row-end mt-2">
                    <div className="bg-dark-gray txt-dark-blue w-auto px-3 py-2 rounded text-center">{props.language.title_btn_game_power_challenge_progress}</div>
                </div> 

            buttonSecond =
                <div className="row-end mt-2">
                    <div className="bg-dark-gray txt-dark-blue w-auto px-3 py-2 rounded text-center">{props.language.title_btn_game_power_challenge_progress}</div>
                </div> 

            break;
        case 4:

            buttonFisrt =
                <div className="row-end mt-2">
                    <div className="bg-green txt-white w-auto px-3 py-2 rounded text-center">{props.language.title_btn_game_power_challenge_won}</div>
                </div>    

            buttonSecond =
                <div className="row-end mt-2">
                    <div className="bg-green txt-white w-auto px-3 py-2 rounded text-center">{props.language.title_btn_game_power_challenge_won}</div>
                </div>                

            break;
        case 5:

            buttonFisrt =
                <div className="row-end mt-2">
                    <div className="bg-red txt-white w-auto px-3 py-2 rounded text-center">{props.language.title_btn_game_power_challenge_lost}</div>
                </div>                

            buttonSecond =
                <div className="row-end mt-2">
                    <div className="bg-red txt-white w-auto px-3 py-2 rounded text-center">{props.language.title_btn_game_power_challenge_lost}</div>
                </div> 

            break;
        case 6:

            buttonFisrt =
                <div className="row-end mt-2">
                    <div className="bg-blue txt-white w-auto px-3 py-2 rounded text-center">
                        <Loader center size="md" content={props.language.loading_message} className="txt-white"/>
                    </div>                    
                </div>                

            buttonSecond =
                <div className="row-end mt-2">
                    <div className="bg-blue txt-white w-auto px-3 py-2 rounded text-center">
                        <Loader center size="md" content={props.language.loading_message} className="txt-white"/>
                    </div>                    
                </div>    

            break;
        default:
            break;
    }

    return { buttonFisrt, buttonSecond };

}

const ModalConfirmation = ({ props, visible, setVisible, index }) => {

    return (
        <Modal
            size='xs'
            show={visible} 
            onHide={() => setVisible(!visible)}>
                <div className='column-center align-items-center p-4 bg-black' style={{borderRadius: 'inherit'}}>
                    <div className='txt-bold txt-12 txt-white'>{props.language.title_game_pc_change_challenge}</div>
                    <div className='txt-semibold txt-10 txt-white my-3 text-wrap'>{props.language.text_game_pc_change_challenge}</div>
                    <div className='row-end align-items-center w-100'>
                        <div className='bg-blue row-center rounded-pill align-items-center py-2 px-3'
                            onClick={() => {
                                props.choseChallenge(props.challenge, index, props.survey);
                                setVisible(false);                                    
                            }}>
                            <p className='txt-white txt-center txt-semibold txt-12'>{props.language.text_game_pc_button_change_challenge}</p>
                        </div>
                    </div>
                </div>           
        </Modal>);
}

const BubbleInfo = (props) =>{

    const date = props.type ? props.challenge.final_timestamp : props.challenge.initial_timestamp;

    return(
        <div className='column-start align-items-center c-pointer w-100'>
            <div className='row-between align-items-center w-100 p-0'>
                <div className='py-1 px-3 bg-blue rounded'>
                    <div className="txt-regular txt-8 txt-blue2 text-center c-pointer">{props.challenge.points/100} NEUgets</div>
                </div>
                <div className="row-end align-items-center ">
                    <i className="fas fa-stopwatch txt-white txt-12"></i>  
                    <div className='ml-2 txt-semibold txt-light-blue c-pointer'>{moment(date).format("DD MMM YYYY HH:mm")}</div>                                     
                </div>
            </div>
            <div className='txt-bold txt-12 txt-white py-2 c-pointer'>{props.challenge.title}</div>
            <div className='txt-semibold txt-8 txt-white text-wrap c-pointer'>{props.challenge.description}</div>
        </div>
    )
}

const BubbleChallenge = (props) =>{     

        let challenge = props.challenge;

        const statesNoGraph = [0, 6];

        const [index, setIndex] = useState(null);

        const [modalVisible, setModalVisible] = useState(false);
        const [modalConfirmationVisible, setModalConfirmationVisible] = useState(false);

        let { buttonFisrt, buttonSecond } = typeButton(props, setModalConfirmationVisible, setIndex);

        return(
            <Fragment>
                <ModalConfirmation
                    props={props}
                    visible={modalConfirmationVisible}
                    setVisible={setModalConfirmationVisible}
                    index={index} />
                <Modal
                    size='xs'
                    show={modalVisible}
                    onHide={() => setModalVisible(false)}> 
                    <div className='column-start align-items-center bg-black p-4' style={{borderRadius: 'inherit'}}>
                        <BubbleInfo challenge={props.challenge} type={props.type}/>
                        {!statesNoGraph.includes(challenge.state) ?
                            <div className='p-3 bg-dark-blue rounded mt-3 w-100'>
                                {!props.type ?
                                    <div className='row-start align-items-center c-pointer mb-3' 
                                        onClick={() => {props.refreshChallenge(props.challenge.id_user_challenge);}}>
                                        <i className="fas fa-sync-alt txt-14 txt-white"/>
                                    </div> : null}                        
                                {GraphChallenge(props.challenge)}
                            </div> : null
                        }
                        <div className='row-end align-items-center mt-3 w-100'>
                            {buttonSecond}
                        </div>
                    </div>                    
                </Modal>
                <div className='column-between bg-dark-blue rounded my-3 p-3 c-pointer' 
                    onClick={() => {
                        if (!props.type && props.challenge.id_user_challenge) props.refreshChallenge(props.challenge.id_user_challenge);
                        setModalVisible(true);
                    }}>
                    <BubbleInfo challenge={challenge} type={props.type} />
                    <div className='row-end align-items-center mt-2 w-100'>
                        {challenge.progress != null &&
                            <Progress.Line percent={challenge.progress * 100} strokeColor="#A1C52A" strokeWidth={6} className='txt-white txt-regular txt-10 text-right' style={{width: 180}}/>                                    
                        }
                    </div>
                    <div className='row-end align-items-center mt-2 w-100'>
                        {buttonFisrt}
                    </div>
                </div>
            </Fragment>
            
)
}

const mapStateToProps = (state, ownProps) => ({
    // user: state.login.user,
    language: state.shared.language,
    challenges: state.game.gamesPcChallenges,
    challenge: ownProps.challenge,
    type: ownProps.type,
    //survey: ownProps.survey
})

const mapDispatchToProps = dispatch => ({
    choseChallenge: (challenge, index, survey) => dispatch(gameActions.gamesPcChoseChallengeAsync(challenge, index, survey)),
    refreshChallenge: payload => dispatch(gameActions.gamesPcRefreshChallengeAsync(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(BubbleChallenge)