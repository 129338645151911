import * as home from '../creators/Home';
import * as invoicesActions from './Invoices';
import * as neuplusActions from './NEUPlus';

import { getUnixTime, startOfMonth } from 'date-fns';

import { GETData, SETData, GETFile } from '../../services/WebServices';

import amplitude from 'amplitude-js';

export const setActiveLocation = payload => {
    return {
        type: home.SET_ACTIVE_LOCATION,
        payload
    }
}

export const deleteActiveLocation = payload => {
    return {
        type: home.DELETE_ACTIVE_LOCATION,
        payload
    }
}

export const setLocations = payload => {
    return {
        type: home.SET_LOCATIONS,
        payload
    }
}

export const setActiveLocationsInfo = payload => {
    return {
        type: home.SET_ACTIVE_LOCATIONS_INFO,
        payload
    }
}

export const setAddressesActive = payload => {
    return {
        type: home.SET_ACTIVE_ADDRESSES,
        payload
    }
}

export const setCardsActive = payload => {
    return {
        type: home.SET_CARDS_ACTIVE,
        payload
    }
}

export const setGraphOptions = payload => {
    return {
        type: home.SET_GRAPH_OPTIONS,
        payload
    }
}

export const setGraphDates = payload => {
    return {
        type: home.SET_GRAPH_DATES,
        payload
    }
}

export const setGraphInterval = payload => {
    return {
        type: home.SET_GRAPH_INTERVAL,
        payload
    }
}

export const setGraphActive = payload => {
    return {
        type: home.SET_GRAPH_ACTIVE,
        payload
    }
}

export const setGraphData = payload => {
    return {
        type: home.SET_GRAPH_DATA,
        payload
    }
}

export const setGraphType = payload => {
    return {
        type: home.SET_GRAPH_TYPE,
        payload
    }
}

export const setInvoices = payload => {
    return {
        type: home.SET_INVOICES,
        payload
    }
}

export const setSustainability = payload => {
    return {
        type: home.SET_SUSTAINABILITY,
        payload
    }
}

export const setInvoicesLoading = payload => {
    return {
        type: home.SET_INVOICES_LOADING,
        payload
    }
}

export const setPayLoading = payload => {
    return {
        type: home.SET_PAY_LOADING,
        payload
    }
}

export const setSustainabilityLoading = payload => {
    return {
        type: home.SET_SUSTAINABILITY_LOADING,
        payload
    }
}

export const setGraphLoading = payload => {
    return {
        type: home.SET_GRAPH_LOADING,
        payload
    }
}

export const setExportLoading = payload => {
    return {
        type: home.SET_EXPORT_LOADING,
        payload
    }
}


export const setInfoLocation = payload => {
    return {
        type: home.SET_INFO_LOCATION,
        payload
    }
}

export const setInfoLocationLoading = payload => {
    return {
        type: home.SET_INFO_LOCATION_LOADING,
        payload
    }
}

export const setIsCommercialized = payload => {
    return {
        type: home.SET_IS_COMMERCIALIZED,
        payload
    }
}

export const setSavingIndexValues = payload => {
    return {
        type: home.SET_SAVING_INDEX_VALUES,
        payload
    }
}

export const setDateLocale = payload => {
    return {
        type: home.SET_DATE_LOCALE,
        payload
    }
}

export const loadLocationsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        const activeLocations = getState().home.activeLocations
            .map(location => location.id_project);

        GETData(`projects/${userId}`, "GET")
            .then(response => {
                if (response !== null) {

                    const isCommercialized = !response.every(location => location.addresses.every(address => address.service_type === 7));

                    dispatch(setLocations(response));
                    dispatch(neuplusActions.setLocations(response.filter(x => x.dissagregators.length)));
                    dispatch(setIsCommercialized(isCommercialized));
                    dispatch(setCardsActive(isCommercialized));

                    if (response.length) {
                        if (!activeLocations.includes(response[0].id_project)) dispatch(setActiveLocation(response[0]));
                        dispatch(loadSustaintabilityAsync());
                    };

                    //GETData(`data/saving/${userId}`, "GET")
                    //    .then(res => {
                    //        if (res !== null) {
                    //            dispatch(setSavingIndexValues(res));
                    //            dispatch(setCardsActive(isCommercialized));
                    //        }
                    //    })
                    //    .catch(response => console.error(response));
                }
            })
            .catch(response => console.error(response));
    }
}

export const loadGraphOptionsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;
        const language = getState().shared.language.language_locale;

        GETData(`graphs/${language}/${userId}`, "GET")
            .then(response => {
                if (response !== null) { dispatch(setGraphOptions(response)); }
            })
            .catch(response => console.error(response));

    }
}

export const loadGraphAsync = () => {
    return (dispatch, getState) => {

        let inteval;
        let graphType;

        const from = getUnixTime(getState().home.graphDateFrom);
        const to = getUnixTime(getState().home.graphDateTo);

        const graphs = getState().home.graphs;

        switch (getState().home.graphInterval) {
            case 0:
                graphType = "linear";
                inteval = "HOURS";
                break;
            case 1:
                graphType = "bars";
                inteval = "DAYS";
                break;
            case 2:
                graphType = "bars";
                inteval = "WEEK";
                break;
            case 3:
                graphType = "bars";
                inteval = "MONTH";
                break;
            default:
                break;
        }

        const graphsOptions = getState().home.activeGraphs
            .map(function (graph) {
                const graphOption = graphs.find(x => x.value === graph);
                return {
                    'graph_type': `${graph}_${inteval}`,
                    'label': graphOption.label,
                    'unit': graphOption.unit
                }
            });

        const addresses = getState().home.activeAddresses
            .map(address => address.id_address);

        const data = {
            id_addresses: addresses,
            graphs_options: graphsOptions
        }


        dispatch(setGraphLoading(true));
        dispatch(setGraphData([]));

        SETData(`graphs/${from}/${to}`, "POST", data)
            .then(response => {
                if (response !== null) { dispatch(setGraphData(response));}
            })
            .catch(response => console.error(response))
            .finally(() => {
                dispatch(setGraphType(graphType));
                dispatch(setGraphLoading(false));
            });

    }
}

export const loadInvoiceAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        GETData(`data/invoice/${userId}`, "GET")
            .then(response => {
                if (response !== null) { dispatch(setInvoices(response)); }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setInvoicesLoading(false)));

    }
}

export const loadPayDataAsync = (payload) => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        let promises = [];

        dispatch(setPayLoading(true));

        GETData("payvalida/banks", "GET")
            .then(response => {
                if (response !== null) {
                    dispatch(setPayLoading(false));
                    dispatch(invoicesActions.setBanks(response));
                    dispatch(invoicesActions.setInvoicesToPay(payload));
                }
            })
            .catch(response => console.error(response));

    }
}

export const loadSustaintabilityAsync = () => {
    return (dispatch, getState) => {

        dispatch(setSustainabilityLoading(true))

        let arg = getState().login.user.id_user;
        let from = getUnixTime(startOfMonth(new Date(2018, 1, 1)));
        let to = getUnixTime(new Date());

        const activeLocations = getState().home.activeLocations;

        if (activeLocations.length) {
            arg = `projects/${activeLocations.map(location => location.id_project)}`;
            from = getUnixTime(getState().home.graphDateFrom);
            to = getUnixTime(getState().home.graphDateTo);
        }

        GETData(`data/sustainability/${arg}/${from}/${to}`, "GET")
            .then(response => {                               
                if (response !== null) { dispatch(setSustainability(response)) }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setSustainabilityLoading(false)));
    }
}

export const exportGraphAsync = (payload) => {
    return (dispatch, getState) => {

        let inteval;
        let graphType;

        const from = getUnixTime(getState().home.graphDateFrom);
        const to = getUnixTime(getState().home.graphDateTo);

        const graphs = getState().home.graphs

        switch (getState().home.graphInterval) {
            case 0:
                graphType = "LINEAR";
                inteval = "HOURS";
                break;
            case 1:
                graphType = "BARS";
                inteval = "DAYS";
                break;
            case 2:
                graphType = "BARS";
                inteval = "WEEK";
                break;
            case 3:
                graphType = "BARS";
                inteval = "MONTH";
                break;
            default:
                break;
        }

        const graphsOptions = getState().home.activeGraphs
            .map(function (graph) {
                const graphOption = graphs.find(x => x.value === graph);
                return {
                    'graph_type': `${graph}_${inteval}`,
                    'label': graphOption.label,
                    'unit': graphOption.unit
                }
            });

        const idAddresses = getState().home.activeAddresses
            .map(address => address.id_address);

        const typeAddresses = getState().home.activeAddresses
            .map(address => address.id_type);

        const data = {
            id_user: getState().login.user.id_user,
            report_name: payload.report_name,
            id_addresses: idAddresses,
            projects: getState().home.activeLocations,
            index_type: 'SUM',
            graphs_options: graphsOptions,
            graph_type: graphType,
            Format_type: payload.format_type,
            id_types: typeAddresses,
            language: getState().shared.language.language_locale
        };

        dispatch(setExportLoading(true));

        amplitude.getInstance().logEvent('Report Downloaded In Home', {
            graphs: graphsOptions.map(g => g.graph_type).join(', ')
        });

        SETData(`reports/${from}/${to}`, "POST", data)
            .then(response => {
                if (response != null) {

                    const metadata = {
                        id: getState().login.user.id_user,
                        folder: "reports",
                        name: response.filename
                    }

                    GETFile("files", "PUT", metadata)
                        .then((result) => {

                            if (result !== null) {

                                const reader = result.body.getReader();

                                return new ReadableStream({

                                    start(controller) {

                                        return pump();

                                        async function pump() {

                                            const { done, value } = await reader
                                                .read();
                                            if (done) {
                                                controller.close();
                                                return;
                                            }
                                            controller.enqueue(value);
                                            return pump();
                                        }
                                    }
                                });

                            }

                        })
                        .then(stream => new Response(stream, { headers: { "Content-Type": `application/${response.filename.split('.').pop()}` } }))
                        .then(response => response.blob())
                        .then(blob => URL.createObjectURL(blob))
                        .then(url => {

                            const link = document.createElement("a");

                            link.href = url;
                            link.download = response.filename;
                            link.click();

                        })
                }
            })
            .catch(response => { console.error(response) })
            .finally(() => dispatch(setExportLoading(false)));
    }
}

export const updateInfoLocationAsync = (payload) => {
    return (dispatch, getState) => {

        let locations = getState().home.locations;

        const index = locations
            .findIndex(location => location.id_project === payload.id_project);

        locations[index] = payload;

        dispatch(setInfoLocationLoading(true));

        SETData("projects", "PUT", payload)
            .then(() => {
                dispatch(setLocations([...locations]));
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setInfoLocationLoading(false)));

    }
}


