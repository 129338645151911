import * as locations from '../creators/Locations';

import { getUnixTime } from 'date-fns';

import { GETData, SETData } from '../../services/WebServices';

export const setActiveLocationsMap = payload => {
    return {
        type: locations.SET_ACTIVE_LOCATIONS_MAP,
        payload
    }
}

export const deleteActiveLocationMap = payload => {
    return {
        type: locations.DELETE_ACTIVE_LOCATIONS_MAP,
        payload
    }
}

export const deleteActiveGraphMap = payload => {
    return {
        type: locations.DELETE_ACTIVE_GRAPH_MAP,
        payload
    }
}

export const deleteGraphDataMap = payload => {
    return {
        type: locations.DELETE_GRAPH_DATA_MAP,
        payload
    }
}

export const setLocationMap = payload => {
    return {
        type: locations.SET_LOCATION_MAP,
        payload
    }
}

export const setGraphActiveMap = payload => {
    return {
        type: locations.SET_GRAPH_ACTIVE_MAP,
        payload
    }
}

export const setGraphDataMap = payload => {
    return {
        type: locations.SET_GRAPH_DATA_MAP,
        payload
    }
}

export const setGraphTypeMap = payload => {
    return {
        type: locations.SET_GRAPH_TYPE_MAP,
        payload
    }
}

export const setGraphLoadingMap = payload => {
    return {
        type: locations.SET_GRAPH_LOADING_MAP,
        payload
    }
}

export const setGraphOptionsMap = payload => {
    return {
        type: locations.SET_GRAPH_OPTIONS_MAP,
        payload
    }
}

export const loadGraphOptionsAsyncMap = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        GETData(`graphs/es/${userId}`, "GET")
            .then(response => {
                if (response !== null) { dispatch(setGraphOptionsMap(response)); }
            })
            .catch(response => console.error(response));

    }
}

const changeState = (acronym, newState, array) => {
    return array.map(obj => {
        if(obj.acronym === acronym){
            return {loading: newState, acronym}
        }else{
            return obj;
        }
    })
}

export const loadGraphAsyncUnitMap = payload => {
    return (dispatch, getState) => {
        const auxLoading = []
        let activeGraph = null;
        getState().locations.activeGraphsMap.forEach(active => { 
            if(payload === active.acronym) {
                activeGraph = active;
                auxLoading.push({acronym: active.acronym, loading: true})
            }
        })

        dispatch(setGraphLoadingMap(auxLoading));
        
        const graphDataAux = getState().locations.graphDataMap;
        
        let graphType = "linear";
        let inteval = "HOURS";

        const from = getUnixTime(activeGraph.graphDateFromMap);
        const to = getUnixTime(activeGraph.graphDateToMap);

        const graphs = getState().home.graphs;

        const graphOption = graphs.find(x => x.value === activeGraph.value);
        const graphsOptions = [{
            'graph_type': `${activeGraph.value}_${inteval}`,
            'label': graphOption.label,
            'unit': graphOption.unit
        }]

        const addresses = activeGraph.activeAddresses
            .map(address => address.id_address);

        const data = {
            id_addresses: addresses,
            graphs_options: graphsOptions
        }

        SETData(`graphs/${from}/${to}`, "POST", data)
            .then(response => {
                if (response !== null) {
                    const index = graphDataAux.findIndex(graphData => graphData.acronym === activeGraph.acronym);
                    index > -1 ? graphDataAux[index] = {response, acronym: activeGraph.acronym} : graphDataAux.push({response, acronym: activeGraph.acronym});
                    dispatch(setGraphDataMap(graphDataAux)); }
            })
            .catch(response => console.error(response))
            .finally(() => {
                dispatch(setGraphLoadingMap(changeState(activeGraph.acronym, false, getState().locations.graphLoadingMap)));
                dispatch(setGraphTypeMap(graphType));
            });
    }
}

