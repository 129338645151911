import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as homeActions from '../../store/actions/Home';
import * as sharedActions from '../../store/actions/Shared';

import { Dropdown, Tooltip, Whisper } from 'rsuite';

import { Transition } from 'react-transition-group';

import Saving from './cards/Saving';
import Invoice from './cards/Invoice';
import Environment from './cards/Environment';
import Gauge from './cards/Gauge';
import Dashboard from './dashboard/Dashboard';
import Location from './location/Location';
import LocationInfo from './location/LocationInfo';
import Options from '../shared/Options';

import { getAnalytics, logEvent } from "firebase/analytics";

import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const Home = (props) => {

    let language = props.language;

    const loadLocationsAsync = props.loadLocationsAsync;
    const loadGraphOptionsAsync = props.loadGraphOptionsAsync;

    useEffect(() => {
        loadLocationsAsync();
    }, [loadLocationsAsync]);

    useEffect(() => {
        if (language != null)
            loadGraphOptionsAsync();
    }, [loadGraphOptionsAsync, language]);

    useEffect(() => {
        logEvent(analytics, "home", {
            id_user: props.user.id_user,
            user_name: `${props.user.name} ${props.user.lastname}`
        });

        amplitude.getInstance().logEvent('Main Dashboard Viewed');
        
    }, []);

    const handleDeleteLocation = (project) => {
        props.deleteActiveLocation(project);
        props.loadSustaintabilityAsync();
        if (props.activeGraphs.length && props.activeAddresses.length) { props.loadGraphAsync() }
    }

    return (
        <div id="home" className="d-flex flex-md-row flex-column-reverse justify-content-start align-items-start w-100">
            <LocationInfo />
            <Transition
                in={props.cardsActive && props.isCommercialized}
                timeout={150}>
                {state => (
                    <div id="home-cards-container" className={`d-flex flex-md-column flex-row flex-none justify-content-start align-items-center col-md-auto col-12 pl-md-3 pr-md-0 py-2 trans-home-cards trans-home-cards-${state}`}>
                        {/*{props.savingIndexValues.length ?*/}
                        {/*    <div className="home-card-saving flex-none pr-md-0 pr-3 py-2">*/}
                        {/*        <Saving />*/}
                        {/*    </div> : null*/}
                        {/*}*/}
                        {!props.user.hide_invoices ||  props.invoices.length || props.user.type ==="GENERATOR" ?
                        <div className="home-card flex-none pr-md-0 pr-3 py-2">
                            <Invoice />
                        </div>: null
                        }
                        <div className="home-card flex-none pr-md-0 pr-3 py-2">
                            <Environment />
                        </div>
                        <div className="home-card flex-none pr-md-0 pr-3 py-2">
                            <Gauge />
                        </div>
                    </div>)}
            </Transition>
            <div className="column-start flex-grow-1 h-100 col-md-auto col-12 px-md-3 pt-2 pb-md-2 pb-0">
                <div className="row-between align-items-center flex-wrap-reverse">
                    <div className="row-start align-items-center flex-wrap col-md-8 col-12 p-0 mt-3 mt-md-0">
                        <div id="home-locations-title" className="txt-20 txt-bold txt-dark-blue text-center text-md-left">{language.title_home_locations}</div>
                        <Dropdown
                            className="home-locations-dropdown"
                            onOpen={() => props.setBlackScreen(true)}
                            onClose={() => props.setBlackScreen(false)}
                            renderTitle={() => (
                                <button id="home-add-location" className={`row-center align-items-center border-0 rounded-circle p-2 ml-md-3 ml-0 mr-2 bg-${props.locations.length ? "blue" : "light-blue"}`}>
                                    <i className="fas fa-plus txt-12 txt-white"></i>
                                </button>)}>
                            {props.locations.map(location => {
                                let index = props.activeLocations
                                    .findIndex(_project => _project.id_project === location.id_project);
                                return <Dropdown.Item
                                    key={location.id_project}>
                                    <Whisper
                                        delay={1000}
                                        placement="right"
                                        trigger="hover"
                                        speaker={<Tooltip>{`${location.acronym} - ${location.name}`}</Tooltip>}>
                                        <div className="home-add-location-option row-start align-items-center">
                                            <div className="row-start align-items-center col-10 p-0"
                                                onClick={() => {

                                                    logEvent(analytics, "click_home_location", {
                                                        id_user: props.user.id_user,
                                                        user_name: `${props.user.name} ${props.user.lastname}`,
                                                        id_location: location.id_project,
                                                        location_name: location.name
                                                    });

                                                    if (index < 0) {
                                                        props.setActiveLocation(location);
                                                        props.loadSustaintabilityAsync();                                                                                                       
                                                        if (props.activeGraphs.length && location.addresses.length) { props.loadGraphAsync() }
                                                    }
                                                }}>
                                                <div className="text-center col-2 p-0">
                                                    <div className={`home-add-location-option-img position-relative overflow-hidden column-center align-items-center rounded-circle c-pointer ${location.image === null ? "bg-blue" : null}`}>
                                                        {location.image === null ?
                                                            <i className="fas fa-building txt-12 txt-dark-blue3"></i> :
                                                            <img className="home-location-img position-absolute w-100 h-100" src={`${process.env.REACT_APP_IMAGES_URL}/locations/photos/${location.id_project}/50x50/${location.image}`} alt="location" />}
                                                    </div>
                                                </div>
                                                <div className="col-10 p-0">
                                                    <div className={`txt-semibold txt-10 txt-${index < 0 ? "dark-blue" : "light-blue"} c-pointer`}> {`${location.acronym} - ${location.name}`}</div>
                                                </div>
                                            </div>
                                            <div className="text-center col-2 p-0">
                                                <i className="fas fa-info-circle txt-12 txt-light-blue txt-hover-blue"
                                                    onClick={() => props.setInfoLocation(location)}></i>
                                            </div>
                                        </div>
                                    </Whisper>
                                </Dropdown.Item>
                            })}
                        </Dropdown>
                        <div className="overflow-hidden row-start">
                            {props.activeLocations.map(location => (
                                <Location
                                    key={location.id_project}
                                    project={location}
                                    handleDeleteLocation={handleDeleteLocation}></Location>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-4 col-12 p-0">
                        <Options />
                    </div>
                </div>
                <div className="flex-grow-1 py-2">
                    <Dashboard />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    language: state.shared.language,
    locations: state.home.locations,
    activeGraphs: state.home.activeGraphs,
    activeAddresses: state.home.activeAddresses,
    activeLocations: state.home.activeLocations,
    cardsActive: state.home.cardsActive,
    isCommercialized: state.home.isCommercialized,
    savingIndexValues: state.home.savingIndexValues,
    invoices: state.home.invoices,
})

const mapDispatchToProps = dispatch => ({
    loadGraphAsync: () => dispatch(homeActions.loadGraphAsync()),
    loadGraphOptionsAsync: () => dispatch(homeActions.loadGraphOptionsAsync()),
    loadLocationsAsync: () => dispatch(homeActions.loadLocationsAsync()),
    loadSustaintabilityAsync: () => dispatch(homeActions.loadSustaintabilityAsync()),
    setActiveLocation: payload => dispatch(homeActions.setActiveLocation(payload)),
    deleteActiveLocation: payload => dispatch(homeActions.deleteActiveLocation(payload)),
    setInfoLocation: payload => dispatch(homeActions.setInfoLocation(payload)),
    setBlackScreen: payload => dispatch(sharedActions.setBlackScreen(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)

