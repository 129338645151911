import * as creators from '../creators/Locations';
import update from "immutability-helper";

const initialState = {
    locationMap: null,
    activeLocationsMap: [],
    locationsPositionsMap: [],
    addressesMap: [],
    activeAddressesMap: [],
    activeGraphsMap: [],
    graphDataMap: [],
    graphsMap: [],
    disabledGraphsExtraMap: ['ACTIVE_ENERGY_COST'],
    graphIntervalMap: 0,
    graphTypeMap: 'linear',
    graphLoadingMap: [],
}

const locationsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_LOCATION_MAP:
            return {
                ...state,
                locationMap: payload,
                addressesMap: payload.addresses,
                activeAddressesMap: payload.addresses };
        case creators.SET_ACTIVE_LOCATIONS_MAP:
            return update(state, {
                activeLocationsMap: { $push: [payload] }
            });
        case creators.DELETE_ACTIVE_LOCATIONS_MAP:
            let index = state.activeLocationsMap
                .findIndex(Location => Location.id_project === payload.id_project);
            return update(state, {
                activeLocationsMap: { $splice: [[index, 1]] },
            });
        case creators.DELETE_ACTIVE_GRAPH_MAP:
            let indexGraph = state.activeGraphsMap
                .findIndex(Location => Location.acronym === payload.acronym);
            return update(state, {
                activeGraphsMap: { $splice: [[indexGraph, 1]] },
            });
        case creators.DELETE_GRAPH_DATA_MAP:
            let indexGraphData = state.graphDataMap
                .findIndex(Location => Location.acronym === payload.acronym);
            return update(state, {
                graphDataMap : { $splice: [[indexGraphData, 1]] },
            });
        case creators.SET_LOCATIONS_POSITIONS_MAP:
            return { ...state, locationsPositionsMap: payload };
        case creators.SET_GRAPH_ACTIVE_MAP:
            return { ...state, activeGraphsMap: payload };
        case creators.SET_GRAPH_DATA_MAP:
            return { ...state, graphDataMap: payload };
        case creators.SET_GRAPH_TYPE_MAP:
            return { ...state, graphTypeMap: payload };
        case creators.SET_GRAPH_LOADING_MAP:
            return { ...state, graphLoadingMap: payload };
        case creators.SET_GRAPH_OPTIONS_MAP:
            return {
                ...state,
                graphsMap: payload.options,
            };
        default:
            return state;
    }
}

export default locationsReducer;