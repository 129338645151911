import * as creators from '../creators/Tags';

const initialState = {
    totalData: [],
    availableData: [],
    activeData: [],
    loading: true,
    records: [],
    predictions: [],
    loadingRecords: false,
    loadingPredictions: false
};

const tagsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_TAGS_DATA:
            return { ...state, totalData: payload };
        case creators.SET_TAGS_AVAILABLE_DATA:
            return { ...state, availableData: payload };
        case creators.SET_TAGS_ACTIVE_DATA:
            return { ...state, activeData: payload };
        case creators.SET_TAGS_LOADING:
            return { ...state, loading: payload };
        case creators.SET_TAGS_LOADING_RECORDS:
            return { ...state, loadingRecords: payload };
        case creators.SET_TAGS_LOADING_PREDICTIONS:
            return { ...state, loadingPredictions: payload };
        case creators.SET_TAGS_RECORDS:
            return { ...state, records: payload };
        case creators.SET_TAGS_PREDICTIONS:
            return { ...state, predictions: payload };
        default:
            return state;
    }
}

export default tagsReducer;