import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import * as sharedActions from '../../../store/actions/Shared';

import queryString from 'query-string';

import ReactToPrint from 'react-to-print';

import PaidStatus from './PaidStatus';

import { GETData } from '../../../services/WebServices';

const Paid = (props) => {

    let language = props.language;

    const componentRef = useRef();

    const [transaction, setTransaction] = useState(null);

    useEffect(() => {

        let url = props.location.search;
        let params = queryString.parse(url);

        GETData(`payvalida/transaction/${params.order}/${params.ticket}`, 'GET')
            .then((result) => { if (result !== null) { setTransaction(result); } });

    }, [props, setTransaction]);

    useEffect(() => !Object.entries(language).length && props.loadLanguageAsync(), [language]);

    return (
        <section className="column-center align-items-center p-4 vh-100 w-100 bg-clear-gray">
            <div className="position-relative column-start align-items-center flex-grow-1 col-12 col-md-6 bg-white rounded shadow overflow-hidden">
                <PaidStatus
                    ref={componentRef}
                    transaction={transaction}
                    language={language}
                />
                {transaction != null && Object.entries(language).length ?
                    <div className="row-center align-items-center flex-wrap my-2">
                        <ReactToPrint
                            trigger={() => <button style={{ width: 150 }} className="row-center align-items-center txt-12 txt-semibold txt-white border-0 my-2 rounded-pill px-4 py-2 mx-2 bg-blue c-pointer">{props.language.title_paid_print}</button>}
                            content={() => componentRef.current}
                        />
                        <a href='https://app.neu.com.co/home' style={{ width: 150 }} className="row-center align-items-center txt-12 txt-semibold txt-white border-0 my-2 rounded-pill px-4 py-2 mx-2 bg-light-blue c-pointer">{props.language.title_paid_back}</a>
                    </div> : null
                }
            </div> 
        </section>
    )
}

const mapStateToProps = (state) => ({
    language: state.shared.language
})

const mapDispatchToProps = dispatch => ({
    loadLanguageAsync: () => dispatch(sharedActions.loadLanguageAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(Paid);