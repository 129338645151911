import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as neuplusActions from '../../store/actions/NEUPlus'
import * as homeActions from '../../store/actions/Home';
import * as sharedActions from '../../store/actions/Shared';

import { Loader, Whisper, Tooltip, SelectPicker, Dropdown, Modal, Checkbox, CheckboxGroup, Input, InputGroup, CheckPicker, Alert } from 'rsuite';

import { Transition } from 'react-transition-group';

import neuplus_device from '../../imgs/logo-neu-plus.png'

import Options from '../shared/Options';
import Graph from './Graph';
import Period from '../../services/NEUPLusDataStreamServices'
import Location from '../home/location/Location';
import LocationInfo from '../home/location/LocationInfo';
import Inquiry from './Inquiry';

import { getAnalytics, logEvent } from "firebase/analytics";
import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const graphs = (label1, label2) => ([
    {
        value: 1,
        label: label1
    },
    {
        value: 2,
        label: label2
    }
])

const graphsAll = (label1, label2, language) => ([
    {
        value: 1,
        label: label1
    },
    {
        value: 2,
        label: label2
    },
    {
        value: 3,
        label: language.option_home_graph_pwr_factor + " 1"
    },
    {
        value: 4,
        label: language.option_home_graph_voltage + " 1"
    },
    {
        value: 5,
        label: language.option_home_graph_current + " 1"
    },
    {
        value: 6,
        label: language.option_home_graph_pwr_factor + " 2"
    },
    {
        value: 7,
        label: language.option_home_graph_voltage + " 2"
    },
    {
        value: 8,
        label: language.option_home_graph_current + " 2"
    }
])

const graphsAll_2 = (label1, label2, language) => ([
    {
        value: 1,
        label: label1
    },
    {
        value: 2,
        label: label2
    },
    {
        value: 3,
        label: language.option_home_graph_pwr_factor + " 1"
    },
    {
        value: 4,
        label: language.option_home_graph_voltage + " 1"
    },
    {
        value: 5,
        label: language.option_home_graph_current + " 1"
    },
    {
        value: 6,
        label: language.option_home_graph_pwr_factor + " 2"
    },
    {
        value: 7,
        label: language.option_home_graph_voltage + " 2"
    },
    {
        value: 8,
        label: language.option_home_graph_current + " 2"
    },
    {
        value: 9,
        label: language.option_home_graph_pwr_factor + " 3"
    },
    {
        value: 10,
        label: language.option_home_graph_voltage + " 3"
    },
    {
        value: 11,
        label: language.option_home_graph_current + " 3"
    },
])


const NEUPlus = (props) => {

    let totalDevicesIds = props.devices
        .map(device => device.id_device);

    let activeDevicesIds = props.activeDevices
        .map(device => device.id_device);

    const [activeDevicesModal, setActiveDevicesModal] = useState([]);
    const [showDevicesModal, setDevicesModal] = useState(false);

    const [appliancesModal, setSelectedAppliancesModal] = useState([]);
    const [showAppliancesModal, setAppliancesModal] = useState(false);

    const handleDeleteLocation = (project) => props.deleteActiveLocation(project);

    const [flagAddDevice, setFlagAddDevice] = useState(false);
    const [newDevice, setNewDevice] = useState("");
    const [locations_appliance, setLocationsAppliance] = useState([]);

    useEffect(() => {
        props.setFlagEventTag(true);
        props.setEnablePopupTag(false);
        setSelectedAppliancesModal([]);
        setAppliancesModal(false);
        props.typeGraphic(1);
    }, [props.activeDevices])

    useEffect(()=>{
        if(props.locations.length && !props.activeLocations.length){
            props.setActiveLocation(props.locations[0]);
        }
    },[])

    useEffect(() => logEvent(analytics, "neuplus", {
        id_user: props.user.id_user,
        name: `${props.user.name} ${props.user.lastname}`
    }), []);

    return (

        <Fragment>
            <Modal
                size="xs"
                show={showDevicesModal}
                onEnter={() => setActiveDevicesModal(activeDevicesIds) }
                onHide={() => setDevicesModal(false)}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.title_home_popup_addresses}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {props.devices.length ?
                        <div id="home-check-address-container" className="overflow-auto w-100 p-3">
                            <Checkbox
                                indeterminate={!totalDevicesIds.every(id => activeDevicesModal.includes(id))}
                                checked={totalDevicesIds.every(id => activeDevicesModal.includes(id))}
                                onChange={(_value, checked) => {
                                    checked ? setActiveDevicesModal(totalDevicesIds) : setActiveDevicesModal([])
                                }}>{props.language.title_home_popup_addresses_check_all}</Checkbox>
                            <CheckboxGroup
                                value={activeDevicesModal}
                                onChange={value => setActiveDevicesModal(value)}>
                                {props.devices.map(device => (
                                    <Checkbox
                                        key={device.id_device}
                                        value={device.id_device}>{device.address_identifier}</Checkbox>
                                ))}
                            </CheckboxGroup>
                        </div> :
                        <div className="txt-semibold txt-12 txt-light-blue text-center py-3">{props.language.title_home_popup_addresses_empty}</div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 mr-4 bg-${activeDevicesModal.length ? "blue" : "light-blue"}`}
                            onClick={() => {
                                if (activeDevicesModal.length) {
                                    setDevicesModal(false);
                                    props.setActiveDevices(activeDevicesModal);
                                }
                            }}>{props.language.title_home_popup_addresses_ok}</button>
                    </div>
                </Modal.Footer>
            </Modal>


            <Modal
                size="xs"
                show={showAppliancesModal}
                onEnter={async () => { await props.loadAppliances() }}
                onHide={() => {
                    setAppliancesModal(false);
                    setSelectedAppliancesModal([])
                }}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.neuplus_main_header_new_tag}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div id="home-check-address-container" className="row-start align-items-center overflow-hidden w-100 py-4 px-4">
                        <div className="col pl-0 pr-2">
                            <CheckPicker
                                id="neuplus_checkpicker_tags"
                                data={props.appliances}
                                labelKey="name"
                                valueKey="id_appliance_device"
                                groupBy="acronym"
                                size="md"
                                searchable={true}
                                placeholder={props.language.neuplus_main_checkpicker_placeholder}
                                onChange={value => { setSelectedAppliancesModal(value) }}
                                onEnter={async () => await props.loadAppliances()}
                            />
                        </div>
                        <div className="col-md-auto px-0">
                            <Whisper
                                container={document.getElementById("home-check-address-container")}
                                delay={500}
                                placement="left"
                                trigger="hover"
                                speaker={<Tooltip>{props.language.inquiry_tooltip_edit_appliance}</Tooltip>}>
                                <button
                                    id="reports-add-report"
                                    className="d-flex justify-content-center align-items-center border-0 rounded-circle p-2 bg-blue mx-auto"
                                    onClick={() => {
                                        props.setFlagInquiry(true);
                                    }}>
                                    <i className="fas fa-pen txt-12 txt-white"></i>
                                </button>
                            </Whisper>
                        </div>
                        <div className="col-md-auto pr-0 pl-2">
                            <Whisper
                                container={document.getElementById("home-check-address-container")}
                                delay={500}
                                placement="left"
                                trigger="hover"
                                speaker={<Tooltip>{props.language.inquiry_tooltip_add_new_appliance}</Tooltip>}>
                                <button
                                    id="reports-add-report"
                                    className="d-flex justify-content-center align-items-center border-0 rounded-circle p-2 bg-blue mx-auto"
                                    onClick={() => {
                                        props.setFlagInquiryCreate(true);
                                    }}>
                                    <i className="fas fa-plus txt-12 txt-white"></i>
                                </button>
                            </Whisper>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`row-start align-items-center txt-12 txt-semibold txt-white w-auto rounded-pill px-4 py-2 bg-${appliancesModal.length && props.timeStampTags.length ? "blue" : "light-blue"}`}
                            onClick={() => {
                                if (!appliancesModal.length) {
                                    Alert.error(props.language.neuplus_main_error_appliance_no_device);
                                }
                                if (!props.timeStampTags.length) {
                                    Alert.error(props.language.neuplus_main_error_appliance_no_timestamp);
                                }
                                if (appliancesModal.length && props.timeStampTags.length) {
                                    setAppliancesModal(false);
                                    appliancesModal.map(async id_appliance => await props.insertLabelEvent(id_appliance));
                                    props.setFlagEventTag(true);
                                    props.setEnablePopupTag(false);
                                    setSelectedAppliancesModal([]);
                                    Alert.success(props.language.neuplus_main_success_appliance);
                                }
                            }}>
                            <div className={"txt-12 mx-1"}>{props.language.neuplus_main_footer_new_tag_create}</div>
                        </button>
                        <button
                            className="row-start align-items-center txt-12 txt-semibold txt-light-blue bg-white w-auto 
                                        border-0 py-2 px-4"
                            onClick={() => {
                                props.setFlagEventTag(true);
                                props.setEnablePopupTag(false);
                                setSelectedAppliancesModal([]);
                                setAppliancesModal(false);
                            }}>
                            {props.language.neuplus_main_footer_new_tag_cancel}</button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/*<Modal*/}
            {/*    size="xs"*/}
            {/*    show={false}*/}
            {/*    onHide={() => {*/}
            {/*        setFlagAddDevice(false);*/}
            {/*        setNewDevice("");*/}
            {/*        setLocationsAppliance([]);*/}
            {/*    }}>*/}
            {/*    <Modal.Header>*/}
            {/*        <div className="row-end align-items-center h-100 w-100">*/}
            {/*            <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.neuplus_main_header_new_device}</div>*/}
            {/*        </div>*/}
            {/*    </Modal.Header>*/}
            {/*    <Modal.Body>*/}
            {/*        <div className="column-start align-items-start px-4 py-4 h-100 w-100">*/}
            {/*            <div className="txt-light-blue txt-12 txt-semibold mb-2">{props.language.neuplus_main_body_new_device_name}</div>*/}
            {/*            <InputGroup*/}
            {/*                inside >*/}
            {/*                <InputGroup.Addon>*/}
            {/*                    <i className="fas fa-align-justify txt-10 txt-light-blue"></i>*/}
            {/*                </InputGroup.Addon>*/}
            {/*                <Input*/}
            {/*                    placeholder={props.language.neuplus_main_body_new_device_example}*/}
            {/*                    maxLength={20}*/}
            {/*                    onChange={value => setNewDevice(value)} />*/}
            {/*            </InputGroup>*/}
            {/*        </div>*/}
            {/*    </Modal.Body>*/}
            {/*    <Modal.Footer>*/}
            {/*        <div className="row-end align-items-center h-100 w-100">*/}
            {/*            <button*/}
            {/*                style={{ width: 150 }}*/}
            {/*                className={`txt-12 txt-semibold txt-white border-0 rounded-pill */}
            {/*                            px-4 py-2 mr-4 bg-${newDevice.length ? "blue" : "light-blue"}`}*/}
            {/*                onClick={async () => {*/}
            {/*                    if (newDevice.length) {*/}
            {/*                        await props.insertAppliance({ newDevice: newDevice });*/}
            {/*                        setFlagAddDevice(false);*/}
            {/*                        setNewDevice("");*/}
            {/*                        setLocationsAppliance([]);*/}
            {/*                    }*/}
            {/*                    else {*/}
            {/*                        if (!newDevice.length) {*/}
            {/*                            Alert.error(props.language.neuplus_main_error_new_device);*/}
            {/*                        }*/}
            {/*                        else {*/}
            {/*                            Alert.error(props.language.neuplus_main_error_new_device_no_project);*/}
            {/*                        }*/}
            {/*                    }*/}
            {/*                }}>{props.language.neuplus_main_footer_new_device}</button>*/}
            {/*        </div>*/}
            {/*    </Modal.Footer>*/}
            {/*</Modal>*/}
            
            {props.locations.length ?
                <Inquiry module="neuplus"/> 
            : null}

            <div id="neuplus" className="column-start align-items-start flex-grow-1 px-3 py-2 h-100 w-100" >
                <LocationInfo />
                <div className="row-between align-items-center flex-wrap-reverse w-100 pb-2" >
                    <div className="row-start align-items-center flex-wrap col-md-8 col-12 p-0 mt-3 mt-md-0">
                        <div id="neuplus-title" className="row-md-start row-center align-items-center">
                            <div className="w-auto txt-20 txt-bold txt-dark-blue">NEU</div>
                            <i className="fas fa-plus txt-14 txt-dark-blue ml-2"></i>
                        </div>
                        <Dropdown
                            disabled={!props.locations.length}
                            onOpen={() => props.setBlackScreen(true)}
                            onClose={() => props.setBlackScreen(false)}
                            renderTitle={() => (
                                <button id="neuplus-add-location" className={`row-center align-items-center border-0 rounded-circle p-2 ml-md-3 ml-0 mr-2 bg-${props.locations.length ? "blue" : "light-blue c-default"}`}
                                    onClick={() => amplitude.getInstance().logEvent('Select location')}>
                                    <i className="fas fa-plus txt-12 txt-white"></i>
                                </button>)}>
                            {props.locations.map(location => {
                                let index = props.activeLocations
                                    .findIndex(_project => _project.id_project === location.id_project);
                                return <Dropdown.Item
                                    key={location.id_project}>
                                    <Whisper
                                        delay={1000}
                                        placement="right"
                                        trigger="hover"
                                        speaker={<Tooltip>{`${location.acronym} - ${location.name}`}</Tooltip>}>
                                        <div className="neuplus-add-location-option row-start align-items-center">
                                            <div className="row-start align-items-center col-10 p-0"
                                                onClick={() => {

                                                    logEvent(analytics, "click_neuplus_location", {
                                                        id_user: props.user.id_user,
                                                        name: `${props.user.name} ${props.user.lastname}`,
                                                        id_location: location.id_project,
                                                        location_name: location.name
                                                    });

                                                    if (index < 0 && !props.enablePopUpTag) {
                                                        props.setActiveLocation(location)
                                                    }
                                                    if(props.enablePopUpTag){
                                                        Alert.error(props.language.alert_multiple_devices_tags_2);
                                                    }

                                                }}>
                                                <div className="text-center col-2 p-0">
                                                    <div className={`neuplus-add-location-option-img position-relative overflow-hidden column-center align-items-center rounded-circle c-pointer ${location.image === null ? "bg-blue" : null}`}>
                                                        {location.image === null ?
                                                            <i className="fas fa-building txt-12 txt-dark-blue3"></i> :
                                                            <img className="neuplus-location-img position-absolute w-100 h-100" src={`${process.env.REACT_APP_IMAGES_URL}/locations/photos/${location.id_project}/50x50/${location.image}`} alt="location" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-10 p-0">
                                                    <div className={`txt-semibold txt-10 txt-${index < 0 ? "dark-blue" : "light-blue"} c-pointer`}> {`${location.acronym} - ${location.name}`}</div>
                                                </div>
                                            </div>
                                            <div className="text-center col-2 p-0">
                                                <i className="fas fa-info-circle txt-12 txt-light-blue txt-hover-blue"
                                                    onClick={() => props.setInfoLocation(location)}></i>
                                            </div>
                                        </div>
                                    </Whisper>
                                </Dropdown.Item>
                            })}
                        </Dropdown>
                        <div className="overflow-hidden row-start">
                            {props.activeLocations.map(location => (
                                <Location
                                    key={location.id_project}
                                    project={location}
                                    handleDeleteLocation={handleDeleteLocation}></Location>
                            ))}
                        </div>
                    </div>
                    <div className="d-block d-md-flex justify-content-end align-items-center col-md-4 col-12 p-0">
                        <Whisper
                            placement="bottom"
                            trigger="click"
                            speaker={<Tooltip>{props.language.tooltip_neuplus_info}</Tooltip>}>
                            <i className="fas fa-info-circle d-none d-md-block txt-12 txt-light-blue txt-hover-blue mx-2 c-pointer"></i>
                        </Whisper>
                        <Options />
                    </div>
                </div>
                <div className="flex-grow-1 py-2 w-100">
                    <div id="neuplus-graph" className={`position-relative overflow-hidden column-start ${!props.locations.length ? "" : "bg-white rounded shadow"} p-3`}>
                        {!props.locations.length ?
                            <div className="position-absolute row align-items-center left-50 top-0 bg-clear-gray w-100 h-100 zindex-1">
                                <img src={neuplus_device} id="neuplus-banner" alt="neuplus-banner"></img>
                                <div className="column-start col-md-4 col-sm-6 align-items-center align-items-md-start px-3 w-100">
                                    <div className="txt-20 txt-bold txt-dark-blue w-auto text-wrap text-center text-md-left">{props.language.neuplus_banner_tittle}</div>
                                    <div className="txt-12 txt-bold txt-light-blue semi-bold w-auto text-wrap py-2 text-center text-md-left">{props.language.neuplus_banner_body}</div>
                                    <div className="txt-12 txt-bold txt-light-blue semi-bold w-auto text-wrap py-2 text-center text-md-left">{props.language.neuplus_banner_final_invitation}</div>
                                    <div className="d-inline-flex mt-3 w-auto semi-bold text-center text-md-left text-wrap txt-12 txt-semibold bg-blue txt-white rounded-pill px-4 py-2 c-pointer"
                                        onClick={() => window.open('https://signin.neu.com.co/saas/es')}> {props.language.neuplus_banner_button_text}</div>
                                </div>
                            </div> :
                            <Fragment>
                                <Transition
                                    in={props.graphLoading}
                                    timeout={100}>
                                    {state => (
                                        <div className={`trans-fade trans-fade-${state} position-absolute bg-white w-100 h-100 top-0 left-0 zindex-2`}>
                                            <div className="column-center align-items-center w-100 h-100">
                                                <Loader center size="md" content={props.language.loading_message} />
                                            </div>
                                        </div>
                                    )}
                                </Transition>
                                {!props.graphLoading ?
                                    <Fragment>
                                        <div className="row-start flex-wrap align-items-center w-100">
                                            <div className="row-start align-items-center col-md-auto col-12 p-0 zindex-2">
                                                <Whisper
                                                    container={document.getElementById("container-tooltip-filter")}
                                                    delay={1000}
                                                    placement="right"
                                                    trigger="hover"
                                                    speaker={<Tooltip id="neuplus-tooltip" className="zindex-5">{props.language.tooltip_home_dashboard_addresses_filter}</Tooltip>}>
                                                    <i className="fas fa-filter txt-12 txt-light-blue txt-hover-blue text-center c-pointer mr-3" onClick={() => setDevicesModal(true)}></i>
                                                </Whisper>
                                                <div id="container-tooltip-filter" className="zindex-5" />
                                                <div id="neuplus-graph-picker" className="zindex-3">
                                                    <SelectPicker
                                                        className="w-100"
                                                        data={props.activeDevices.length && props.enableAllVariables === 3 ?
                                                            graphsAll_2(props.language.neuplus_typeGraphic_Active, props.language.neuplus_typeGraphic_Reactive, props.language) :
                                                            props.activeDevices.length && props.enableAllVariables === 2 ?
                                                            graphsAll(props.language.neuplus_typeGraphic_Active, props.language.neuplus_typeGraphic_Reactive, props.language) :
                                                            graphs(props.language.neuplus_typeGraphic_Active, props.language.neuplus_typeGraphic_Reactive)}
                                                        size="lg"
                                                        appearance="subtle"
                                                        searchable={false}
                                                        cleanable={false}
                                                        placeholder={props.activeLocations.length === 0 ?
                                                            props.language.placeholder_neuplus_select_graphs :
                                                            graphsAll_2(props.language.neuplus_typeGraphic_Active, props.language.neuplus_typeGraphic_Reactive, props.language).filter(x => x.value === props.graphicType)[0].label }
                                                        onSelect={(value) => { props.typeGraphic(value); amplitude.getInstance().logEvent('Reactive Energy Selected'); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`position-absolute top-0 left-0 column-center align-items-center w-100 py-4 mt-5 mt-md-0  ${(props.dataNull) && "d-none"}`}>
                                            <div className="txt-12 txt-bold txt-light-blue text-center">{props.currentDate}</div>
                                        </div>
                                        <Period />
                                        <div className="position-absolute overflow-hidden h-100 w-100">
                                            <div id="neuplus-graph-container" className="h-100 overflow-hidden">
                                                <Graph />
                                            </div>
                                        </div>
                                        <div className={`position-absolute bottom-0 left-0 row-center align-items-center w-100 zindex-3 pb-2 ${(!props.activeLocations.length || props.graphLoading || props.dataNull) && "d-none"}`}>
                                            <div id="neuplus-buttons-graph" className="row-start align-items-center bg-blue rounded-pill py-3 px-4 w-auto">
                                                <i className={`fas fa-stop txt-white txt-hover-blue2 c-pointer`} onClick={() => {

                                                    logEvent(analytics, "click_neuplus_stop", {
                                                        id_user: props.user.id_user,
                                                        name: `${props.user.name} ${props.user.lastname}`
                                                    });

                                                    amplitude.getInstance().logEvent('Stream Stopped');

                                                    props.lengthData("ALL");

                                                }}></i>
                                                <i id="neuplus_button_play" className={`fas fa-play txt-hover-blue2 c-pointer ${props.run ? "txt-blue2" : "txt-white"}`} onClick={() => {

                                                    logEvent(analytics, "click_neuplus_play", {
                                                        id_user: props.user.id_user,
                                                        name: `${props.user.name} ${props.user.lastname}`
                                                    });

                                                    amplitude.getInstance().logEvent('Stream Played');

                                                    props.runGraphic();

                                                }}></i>
                                                <i className={`fas fa-pause txt-hover-blue2 c-pointer ${!props.run ? "txt-blue2" : "txt-white"}`} onClick={() => {

                                                    logEvent(analytics, "click_neuplus_pause", {
                                                        id_user: props.user.id_user,
                                                        name: `${props.user.name} ${props.user.lastname}`
                                                    });

                                                    amplitude.getInstance().logEvent('Stream paused');

                                                    props.pauseGraphic();

                                                }}></i>
                                            </div>
                                            <div id="neuplus-buttons-graph" className={`row-start align-items-center rounded-pill py-3 px-4 c-pointer w-auto ${props.enablePopUpTag ? "bg-red txt-white" : "bg-reactive-green txt-reactive-green2"}`}
                                                onClick={() => {
                                                    if (props.enablePopUpTag) {

                                                        logEvent(analytics, "click_neuplus_stop_tag", {
                                                            id_user: props.user.id_user,
                                                            name: `${props.user.name} ${props.user.lastname}`
                                                        });

                                                        setAppliancesModal(true);
                                                    }
                                                    else {

                                                        logEvent(analytics, "click_neuplus_start_tag", {
                                                            id_user: props.user.id_user,
                                                            name: `${props.user.name} ${props.user.lastname}`
                                                        });

                                                        if(props.activeDevices.length <= 1){
                                                            props.setEnablePopupTag(true);
                                                        }
                                                        else{
                                                            setDevicesModal(true);
                                                            Alert.error(props.language.alert_multiple_devices_tags);
                                                        }
                                                    }
                                                }}>
                                                <i className={`fas fa-tags mx-1`}></i>
                                                <div className={"mx-1"}>{props.enablePopUpTag ? props.language.neuplus_main_button_finish_tag : props.language.neuplus_main_button_start_tag}</div>
                                            </div>
                                        </div>
                                    </Fragment> : null}
                            </Fragment>}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.login.user,
    language: state.shared.language,
    locations: state.neuPlus.locations,
    activeLocations: state.neuPlus.activeLocations,
    devices: state.neuPlus.devices,
    activeDevices: state.neuPlus.activeDevices,
    dataFlag: state.neuPlus.dataFlag,
    graphLoading: state.neuPlus.graphLoading,
    currentDate: state.neuPlus.currentDate,
    run: state.neuPlus.run,
    typeToolTip: state.neuPlus.typeToolTip,
    dataNull: state.neuPlus.dataNull,
    graphicType: state.neuPlus.graphicType,
    appliances: state.neuPlus.appliances,
    enablePopUpTag: state.neuPlus.enablePopUpTag,
    timeStampTags: state.neuPlus.timeStampTags,
    dataNoModification: state.neuPlus.dataNoModification,
    enableAllVariables: state.neuPlus.enableAllVariables,
})

const mapDispatchToProps = dispatch => ({
    runGraphic: () => dispatch(neuplusActions.runGraphic()),
    pauseGraphic: () => dispatch(neuplusActions.pauseGraphic()),
    typeGraphic: payload => dispatch(neuplusActions.typeGraphic(payload)),
    lengthData: payload => dispatch(neuplusActions.lengthData(payload)),
    setInfoLocation: payload => dispatch(homeActions.setInfoLocation(payload)),
    setBlackScreen: payload => dispatch(sharedActions.setBlackScreen(payload)),
    setActiveLocation: payload => dispatch(neuplusActions.setActiveLocation(payload)),
    setActiveDevices: payload => dispatch(neuplusActions.setActiveDevices(payload)),
    deleteActiveLocation: payload => dispatch(neuplusActions.deleteActiveLocation(payload)),
    loadAppliances: () => dispatch(neuplusActions.loadAppliances()),
    insertLabelEvent: payload => dispatch(neuplusActions.insertLabelEvent(payload)),
    setFlagEventTag: payload => dispatch(neuplusActions.setFlagEventTag(payload)),
    setEnablePopupTag: payload => dispatch(neuplusActions.setEnablePopupTag(payload)),
    insertAppliance: payload => dispatch(neuplusActions.insertAppliance(payload)),
    setFlagInquiry: payload => dispatch(neuplusActions.setFlagInquiry(payload)),
    setFlagInquiryCreate: payload => dispatch(neuplusActions.setFlagInquiryCreate(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NEUPlus)

