import React from 'react';
import { connect } from 'react-redux';

import { Loader } from 'rsuite';

const LoaderMore = (props) => {
    return (
        props.loading ?
        <div className='position-relative py-4'>
            <Loader center size="xs" content={props.language.loading_message} className="txt-white"/>
        </div> :
        <div className='txt-semibold text-center txt-12 txt-white c-pointer py-3' 
            onClick={() => props.fun(props.val)}>Ver mas...</div>);
}

const mapStateToProps = (state, ownProps) => ({
    language: state.shared.language,
    loading: ownProps.loading,
    fun: ownProps.fun,
    val: ownProps.val
})

export default connect(mapStateToProps)(LoaderMore)