import React, { Fragment } from 'react';

import { connect } from 'react-redux';

const DashboardIndexes = (props) => {

    let total;
    let min;
    let max;
    let avg;

    let content = null;

    let hasData = props.graphData.length && props.activeGraphs.length === 1 ? true : false;

    if (hasData) {

        let graph = props.graphs
            .filter(graph => graph.value === props.activeGraphs[0])[0];

        let array = props.graphData
            .map(value => value.data
                .filter(value => value.y > 0)
                .map(value => value.y))
            .reduce((a, b) => a.concat(b), []);

        switch (graph.type) {
            case "SUM":

                if (array.length) {
                    total = array.reduce((prev, curr) => curr += prev).toFixed(2);
                    max = Math.max(...array).toFixed(2);
                    avg = (total / array.length).toFixed(2);
                } else {
                    total = max = avg = 0;
                }

                content = <Fragment>
                    <div className="col-4">
                        <div className="txt-12 txt-bold txt-light-blue">{props.language.title_home_dashboard_indexes_total}</div>
                        <div className="row-start align-items-center txt-dark-blue txt-bold">
                            {graph.value === 'ACTIVE_ENERGY_COST' ?
                                <div className="txt-14 mr-1">$</div> :
                                null
                            }
                            <div className="txt-12">{total} {graph.unit}</div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="txt-12 txt-bold txt-light-blue">{props.language.title_home_dashboard_indexes_max}</div>
                        <div className="row-start align-items-center txt-dark-blue txt-bold">
                            {graph.value === 'ACTIVE_ENERGY_COST' ?
                                <div className="txt-14 mr-1">$</div> :
                                null
                            }
                            <div className="txt-12">{max} {graph.unit}</div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="txt-12 txt-bold txt-light-blue">{props.language.title_home_dashboard_indexes_avg}</div>
                        <div className="row-start align-items-center txt-dark-blue txt-bold">
                            {graph.value === 'ACTIVE_ENERGY_COST' ?
                                <div className="txt-14 mr-1">$</div> :
                                null
                            }
                            <div className="txt-12">{avg} {graph.unit}</div>
                        </div>
                    </div>
                </Fragment>;

                break;
            case "AVG":

                if (array.length) {
                    total = array.reduce((prev, curr) => curr += prev).toFixed(2);
                    min = Math.min(...array).toFixed(2);
                    max = Math.max(...array).toFixed(2);
                    avg = (total / array.length).toFixed(2);
                } else {
                    min = max = avg = 0;
                }

                content = <Fragment>
                    <div className="col-4">
                        <div className="txt-12 txt-bold txt-light-blue">{props.language.title_home_dashboard_indexes_min}</div>
                        <div className="row-start align-items-center txt-dark-blue txt-bold">
                            {graph.value === 'ACTIVE_ENERGY_COST' ?
                                <div className="txt-14 mr-1">$</div> :
                                null
                            }
                            <div className="txt-12">{min} {graph.unit}</div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="txt-12 txt-bold txt-light-blue">{props.language.title_home_dashboard_indexes_max}</div>
                        <div className="row-start align-items-center txt-dark-blue txt-bold">
                            {graph.value === 'ACTIVE_ENERGY_COST' ?
                                <div className="txt-14 mr-1">$</div> :
                                null
                            }
                            <div className="txt-12">{max} {graph.unit}</div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="txt-12 txt-bold txt-light-blue">{props.language.title_home_dashboard_indexes_avg}</div>
                        <div className="row-start align-items-center txt-dark-blue txt-bold">
                            {graph.value === 'ACTIVE_ENERGY_COST' ?
                                <div className="txt-14 mr-1">$</div> :
                                null
                            }
                            <div className="txt-12">{avg} {graph.unit}</div>
                        </div>
                    </div>
                </Fragment>;

                break;
            default:
                break;
        }

    }

    return (
        <div className="row-start align-items-center w-100">
            {content}
        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    graphData: state.home.graphData,
    graphs: state.home.graphs,
    activeGraphs: state.home.activeGraphs
})

export default connect(mapStateToProps)(DashboardIndexes)
