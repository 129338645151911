import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Tooltip, Whisper } from 'rsuite';

import ContentLoader from "react-content-loader";

const initLoading = <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
    <ContentLoader
        seed={2}
        foregroundColor="#89c65e"
        backgroundColor="#7abf4a"
        style={{ width: "100%", height: "100%" }}>
        <rect x="15" y="22" width="150" height="10" rx="2" ry="2"></rect>
        <circle cx="calc(100% - 25px)" cy="28" r="10"></circle>
        <circle cx="45" cy="85" r="15"></circle>
        <circle cx="45" cy="145" r="15"></circle>
        <rect x="90" y="60" width="100" height="30" rx="5" ry="5"></rect>
        <rect x="90" y="100" width="130" height="10" rx="2" ry="2"></rect>
        <rect x="90" y="120" width="100" height="30" rx="5" ry="5"></rect>
        <rect x="90" y="160" width="130" height="10" rx="2" ry="2"></rect>
    </ContentLoader>
</div>;

const Environment = (props) => {

    let content = null;

    if (props.planted_trees !== null && props.co2_emission !== null && !props.sustainabilityLoading ) {
        content = <Fragment>
            <div className="row-between align-items-center w-100">
                <div className="txt-10 txt-bold txt-white">{props.language.title_home_card_activity}</div>
                <Whisper
                    placement="bottom"
                    trigger="click"
                    speaker={<Tooltip>{props.language.tooltip_home_card_environment}</Tooltip>}>
                    <i className="fas fa-info-circle txt-12 txt-white c-pointer"></i>
                </Whisper>
            </div>
            {props.equivalencies !== null ?
            <div className="column-center align-items-start flex-grow-1">
                <div className="row-start align-items-center w-100">
                    <i className="fas fa-tree txt-22 txt-green3 col-3"></i>
                    <div className="column-start align-items-start col-9">
                        <div className="txt-20 txt-bold txt-white">{(props.planted_trees).toFixed(2)}</div>
                        <div className="txt-10 txt-semibold txt-white">{props.language.title_home_card_trees}</div>
                    </div>
                </div>
                <div className="row-start align-items-center w-100">
                    <i className="fas fa-cloud-meatball txt-22 txt-clear-gray col-3"></i>
                    <div className="column-start align-items-start col-9">
                        <div className="txt-20 txt-bold txt-white">{(props.co2_emission).toFixed(2)}</div>
                        <div className="txt-10 txt-semibold txt-white">{props.language.title_home_card_co2}</div>
                    </div>
                </div>
            </div>: null 
            }
        </Fragment>
    } else { content = initLoading }


if (props.equivalencies) console.log("desde la tarjeta", props.equivalencies)
    return (
        <div className="position-relative column-between align-items-start h-100 w-100 bg-green2 rounded shadow p-3">
            {content}
        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    totalGeneration: state.home.totalGeneration,
    sustainabilityLoading: state.home.sustainabilityLoading,
    planted_trees: state.home.planted_trees_total,
    co2_emission: state.home.co2_emission_total,
})

export default connect(mapStateToProps)(Environment)
