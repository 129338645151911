import * as rules from '../creators/Rules';
import { GETData, SETData } from '../../services/WebServices';

import { Alert } from 'rsuite';

export const setData = payload => {
    return {
        type: rules.SET_RULES_DATA,
        payload
    }
}

export const setAvailableData = payload => {
    return {
        type: rules.SET_RULES_AVAILABLE_DATA,
        payload
    }
}

export const setActiveData = payload => {
    return {
        type: rules.SET_RULES_ACTIVE_DATA,
        payload
    }
}

export const setLoading = payload => {
    return {
        type: rules.SET_RULES_LOADING,
        payload
    }
}

export const setUserData = payload => {
    return {
        type: rules.SET_RULES_USER_DATA,
        payload
    }
}

export const loadRulesAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;
        const language = getState().shared.language.language_locale;        

        dispatch(setLoading(true));

        GETData(`rules/${userId}/?language=${language}`, "GET")
            .then(response => {
                if (response !== null) { 
                    dispatch(setData(response));                                  
                }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoading(false)));

    }
}

export const loadRulesUser = (payload) => {
    return (dispatch) => {        

        GETData(`rules/users/?id_alarm=${payload}`, "GET")
            .then(response => {
                if (response !== null) {                     
                    dispatch(setUserData(response));                
                }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoading(false)));

    }
}

export const createRuleAsync = (payload) => {
    return (dispatch, getState) => {

        const locations = getState().home.locations;
        const graphs = getState().home.graphs;
        const rules = getState().rules.totalData;

        const newRule = {
            id_project: payload.location,
            project: locations.find(location => location.id_project === payload.location).name,
            id_graph: payload.graph,
            property_name: graphs.find(graph => graph.id_graph === payload.graph).label,
            interval_code: payload.interval,
            customizable_days: payload.customizable_days,
            rule_name: payload.rule_name,
            value: parseFloat(payload.value),
            monday: payload.monday,
            tuesday: payload.tuesday,
            wednesday: payload.wednesday,
            thursday: payload.thursday,
            friday: payload.friday,
            saturday: payload.saturday,
            sunday: payload.sunday,
            users: payload.users.toString()
        }

        dispatch(setLoading(true));

        SETData("rules", "POST", newRule)
            .then(({ alarmId }) => {

                newRule.id_alarm = alarmId;
                dispatch(setData([newRule, ...rules]))
            })
            .catch(response => { console.error(response) })
            .finally(() => dispatch(setLoading(false)));
    }
}

export const setDaysRulesAsync = (payload) => {
    return (dispatch, getState) => {

        let rules = getState().rules.availableData;

        const index = rules
            .findIndex(rule => rule.id_alarm === payload.id_alarm);

        rules[index] = payload;

        SETData("rules", "PUT", payload)
            .then(() => dispatch(setAvailableData([...rules])))
            .catch(response => { console.error(response) });
    }
}

export const deleteRuleAsync = (payload) => {
    return (dispatch, getState) => {

        let rules = getState().rules.availableData;

        const index = rules
            .findIndex(rule => rule.id_alarm === payload);

        rules[index].deleting = true;

        dispatch(setAvailableData([...rules]));

        GETData(`rules/${payload}`, "DELETE")
            .then(() => {

                rules = getState().rules.totalData
                    .filter(rule => rule.id_alarm !== payload);

                dispatch(setData(rules));
            })
            .catch(response => console.error(response))
    }
}

export const updateRuleAsync = (payload) => {
    return (dispatch, getState) => {
        
        let language = getState().shared.language;             
        let rules = getState().rules.availableData;  
        const locations = getState().home.locations;
        const graphs = getState().home.graphs;     

        const newRule = {
            id_alarm: payload.id_alarm,
            id_project: payload.location,
            project: locations.find(location => location.id_project === payload.location).name,
            id_graph: payload.graph,
            property_name: graphs.find(graph => graph.id_graph === payload.graph).label,
            interval_code: payload.interval,
            customizable_days: payload.customizable_days,
            rule_name: payload.rule_name,
            value: parseFloat(payload.value),
            monday: payload.monday,
            tuesday: payload.tuesday,
            wednesday: payload.wednesday,
            thursday: payload.thursday,
            friday: payload.friday,
            saturday: payload.saturday,
            sunday: payload.sunday,
            users: payload.users.toString()
        };

        const index = rules
            .findIndex(rule => rule.id_alarm === payload.id_alarm);

        rules[index] = newRule;

        SETData("rules/data/update", "PUT", newRule)
            .then(() => {
                dispatch(setAvailableData([...rules]));
                Alert.success(language.alert_rule_success_update, 4000);
            })
            .catch(response => {
                console.error("error actualización", response)
                Alert.error(language.alert_rule_error_update);
            });
    }
}
