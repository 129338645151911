export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_ACTIVE_LOCATION = 'SET_ACTIVE_LOCATION';
export const SET_ACTIVE_LOCATIONS_INFO = 'SET_ACTIVE_LOCATIONS_INFO';
export const DELETE_ACTIVE_LOCATION = 'DELETE_ACTIVE_LOCATION';
export const SET_ACTIVE_ADDRESSES = 'SET_ACTIVE_ADDRESSES';
export const SET_CARDS_ACTIVE = 'SET_CARDS_ACTIVE';
export const SET_GRAPH_OPTIONS = 'SET_GRAPH_OPTIONS';
export const SET_GRAPH_DATES = 'SET_GRAPH_DATES';
export const SET_GRAPH_INTERVAL = 'SET_GRAPH_INTERVAL';
export const SET_GRAPH_ACTIVE = 'SET_GRAPH_ACTIVE';
export const SET_GRAPH_DATA = 'SET_GRAPH_DATA';
export const SET_GRAPH_TYPE = 'SET_GRAPH_TYPE';
export const SET_INVOICES = 'SET_INVOICES';
export const SET_SUSTAINABILITY = 'SET_SUSTAINABILITY';
export const SET_PAY_LOADING = 'SET_PAY_LOADING';
export const SET_INVOICES_LOADING = 'SET_INVOICES_LOADING';
export const SET_SUSTAINABILITY_LOADING = 'SET_SUSTAINABILITY_LOADING';
export const SET_GRAPH_LOADING = 'SET_GRAPH_LOADING';
export const SET_EXPORT_LOADING = 'SET_EXPORT_LOADING';
export const SET_INFO_LOCATION = 'SET_INFO_LOCATION';
export const SET_INFO_LOCATION_LOADING = 'SET_INFO_LOCATION_LOADING';
export const SET_IS_COMMERCIALIZED = 'SET_IS_COMMERCIALIZED';
export const SET_SAVING_INDEX_VALUES = 'SET_SAVING_INDEX_VALUES';
export const SET_DATE_LOCALE = 'SET_DATE_LOCALE';
