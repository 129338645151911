import React from 'react';
import { connect } from 'react-redux';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import CurrencyFormat from 'react-currency-format';

import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';

import ContentLoader from "react-content-loader";

const initLoading = <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
    <ContentLoader
        seed={2}
        foregroundColor="#272C364D"
        backgroundColor="#272C361A"
        style={{ width: "100%", height: "100%" }}>
        <rect x="50" y="25" width="190" height="20" rx="4" ry="4"></rect>
        <rect x="50" y="55" width="120" height="25" rx="5" ry="5"></rect>
        <rect x="50" y="90" width="140" height="20" rx="4" ry="4"></rect>
        <rect x="calc(50% - 50px)" y="130" width="100" height="13" rx="4" ry="4"></rect>
    </ContentLoader>
</div>;

const responsive = {
    all: {
        breakpoint: { max: 4000, min: 0 },
        items: 1,
        slidesToSlide: 1,
    }
};

const RenderItem = ({ saving, language }) => {

    const locale = language.language_locale === 'es' ? esLocale : enLocale;

    return (
        <div className="column-start align-items-center w-100 h-100 px-5 pt-2">
            {saving.type ?
                < div className="row-start txt-bold txt-12 m-0">
                    <div className="txt-white mr-1">{ language.title_saving_index_tariff }</div>
                    <div className="txt-reactive-green">{ saving.title }</div>
                </div> :
                <div className="row-start txt-bold txt-12 m-0">
                    <div className="txt-white mr-1">{language.title_saving_index_prefix}</div>
                    <div className="txt-reactive-green">{language.title_saving_index_saved}</div>
                    <div className="txt-white ml-1">{language.title_saving_index_suffix}</div>
                </div>
            }
            <CurrencyFormat
                className="txt-bold txt-18 txt-white my-md-2 my-4"
                value={saving.value}
                prefix="$"
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={1}
                fixedDecimalScale={true} />
            <div className="txt-semibold txt-12 txt-light-blue2">{saving.type ? format(new Date(2021, saving.param - 1, 1), "MMMM", { locale: locale}) : language[saving.title]}</div>
        </div>
    )
}

const CustomDot = ({ onClick, ...rest }) => {

    const { active } = rest;

    return (
        <div
            className={`doc-carousel rounded-pill mx-1 mb-1 c-pointer ${active && "active"}`}
            onClick={() => onClick()} />
    )
}

const CustomLeftArrow = ({ onClick }) => {
    return (
        <div className="carousel-btn mx-4 row-center align-items-center c-pointer"
            onClick={() => onClick()}>
            <i className="fas fa-chevron-left txt-white txt-12 c-pointer" />
        </div>
    );
};

const CustomRightArrow = ({ onClick }) => {
    return (
        <div className="carousel-btn mx-4 right-0 row-center align-items-center c-pointer"
            onClick={() => onClick()}>
            <i className="fas fa-chevron-right txt-white txt-12 c-pointer" />
        </div>
    );
};

const Saving = (props) => {

    return (
        <div className="position-relative h-100 w-100 bg-black rounded shadow py-3 overflow-hidden">
            <div className="position-absolute w-100 h-100 saving-card-blur top-0 left-0" />
            {props.savingIndex.length ?
                <Carousel
                    draggable
                    swipeable
                    infinite
                    autoPlay={props.savingIndex.length > 1}
                    autoPlaySpeed={10000}
                    showDots={true}
                    customDot={<CustomDot />}
                    customLeftArrow={<CustomLeftArrow />}
                    customRightArrow={<CustomRightArrow />}
                    containerClass="h-100"
                    responsive={responsive}>
                    {props.savingIndex.map((saving, i) => <RenderItem key={i} saving={saving} language={props.language} />)}
                </Carousel> : initLoading
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    savingIndex: state.home.savingIndexValues
})

export default connect(mapStateToProps)(Saving)
