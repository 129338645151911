import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import * as sharedActions from '../../store/actions/Shared';

import { Transition } from 'react-transition-group';

import logo from '../../imgs/logo-erco.svg';
import neuplus_plus from '../../imgs/neuplus_plus.svg';

import MenuItem from './MenuItem';
import Support from '../shared/Support';

import { getAnalytics, logEvent } from "firebase/analytics";

import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const Menu = (props) => {
    return (
        <Fragment>
            <Support />
            <Transition
                in={props.showMenu}
                timeout={150}>
                {state => (
                    <Fragment>
                        <Transition
                            in={props.showMenu}
                            timeout={100}>
                            {state => (
                                <div className={`position-fixed left-0 top-0 w-100 vh-100 zindex-2 trans-fade trans-fade-${state}`} onClick={() => props.setMenu(false)}>
                                    <div className="black-screen w-100 h-100"></div>
                                </div>)}
                        </Transition>
                        <aside
                            id="menu"
                            className={`position-fixed column-start align-items-start overflow-hidden left-0 top-0 h-100 bg-black zindex-4 trans-menu trans-menu-${state}`}
                            onMouseEnter={() => props.setMenu(true)}
                            onMouseLeave={() => props.setMenu(false)}>
                            <div className="w-100 row-center align-items-center my-4" style={{ height: 35 }}>
                                <div className={`position-relative row-start align-items-center overflow-hidden h-100 trans-menu-logo trans-menu-logo-${state}`}>
                                    <img src={logo} className="position-absolute" alt="logo-neu-energy" style={{ width: 120 }} />
                                </div>
                            </div>
                            <MenuItem
                                icon="home"
                                text={props.language.title_menu_home}
                                onClick={() => props.setMenu(false)}
                                path="/home"
                                enable={true} />
                            <MenuItem
                                icon="bell"
                                text={props.language.title_menu_notifications}
                                onClick={() => props.setMenu(false)}
                                path="/notifications"
                                enable={true} />
                            <MenuItem
                                icon="pencil-ruler"
                                text={props.language.title_menu_rules}
                                onClick={() => props.setMenu(false)}
                                path="/rules"
                                enable={true} />
                            <MenuItem
                                icon="address-book"
                                text={props.language.title_menu_userAccess}
                                onClick={() => { props.setMenu(false); amplitude.getInstance().logEvent('Users screen viewed'); }}
                                path="/users"
                                enable={true} />
                            {/* <MenuItem
                                icon="map-marker-alt"
                                text={props.language.title_menu_locations}
                                onClick={() => {props.setMenu(false); amplitude.getInstance().logEvent('Maps Screen Viewed');}}
                                path="/locations"
                                enable={true} />
                            <MenuItem
                                icon="file-invoice-dollar"
                                text={props.language.title_menu_invoices}
                                onClick={() => {props.setMenu(false); amplitude.getInstance().logEvent('Billing Screen Viewed');}}
                                path="/invoices"
                                enable={true} />
                            <MenuItem
                                icon="clipboard"
                                text={props.language.title_menu_reports}
                                onClick={() => props.setMenu(false)}
                                path="/reports"
                                enable={true} />
                            <MenuItem
                                icon="tag"
                                text={props.language.title_menu_tags}
                                onClick={() => {props.setMenu(false); amplitude.getInstance().logEvent('Tags Screen Viewed');}}
                                path="/tags"
                                enable={true} />
                            <MenuItem
                                img={neuplus_plus}
                                text={props.language.title_menu_neuplus}
                                onClick={() => {props.setMenu(false); amplitude.getInstance().logEvent('NEU+ Screen Viewed');}}
                                path="/neuplus"
                                enable={true} />
                            <div className={"row-start align-items-center c-pointer my-3 mx-4 txt-white"}
                                onClick={() => {

                                    logEvent(analytics, "support", {                            
                                        id_user: props.user.id_user,
                                        user_name: `${props.user.name} ${props.user.lastname}`
                                    });

                                    amplitude.getInstance().logEvent('Suggestions Opened');

                                    props.setPopupSupport(true);
                                    
                                }}>
                                <div className="meun-icon d-flex row-center align-items-center">
                                    <i className={"fas fa-question-circle txt-12 c-pointer"}></i>
                                </div>
                                <div className="meun-text d-flex row-start align-items-center">
                                    <div className="txt-regular txt-12 ml-4 c-pointer">{props.language.title_menu_support}</div>
                                </div>
                            </div> */}
                        </aside>
                    </Fragment>
                )}
            </Transition>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    language: state.shared.language,
    showMenu: state.shared.showMenu
})

const mapDispatchToProps = dispatch => ({
    setMenu: payload => dispatch(sharedActions.setMenu(payload)),
    setPopupSupport: payload => dispatch(sharedActions.setPopupSupport(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
