import React from 'react';

import * as shared from '../creators/Shared';

import * as homeActions from '../actions/Home';

import { Notification, Alert } from 'rsuite';
import { GETData, SETData } from '../../services/WebServices';

import * as firebase from "firebase/app";
import { getMessaging, onMessage, isSupported } from "firebase/messaging";
import { getRemoteConfig, fetchAndActivate, getAll } from "firebase/remote-config";

const firebaseConfig = {
    apiKey: "AIzaSyDEgzgYtr7ysd9cI_l0thdqtWJ8vyXhtC8",
    authDomain: "neu-app.firebaseapp.com",
    databaseURL: "https://neu-app.firebaseio.com",
    projectId: "neu-app",
    storageBucket: "neu-app.appspot.com",
    messagingSenderId: "83269931061",
    appId: "1:83269931061:web:6171c3ff67d3baa26c617c",
    measurementId: "G-PK2BE79747"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const remoteConfig = getRemoteConfig();

isSupported().then(support => {
    if (support) {
        const messaging = getMessaging(firebaseApp);

        onMessage(messaging, (payload) => {
            Notification.open(
                {
                    title: payload.notification.title,
                    duration: 20000,
                    placement: "bottomEnd",
                    description: (<div className="txt-dark-blue txt-10 txt-semibold text-wrap">{payload.notification.body}</div>)
                }
            )
        });
    }
})

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

remoteConfig.defaultConfig = {
    title_shared_search: "Buscar",
    title_shared_notifications_clear: "Limpiar",
    title_shared_notifications_check: "Entendido",
    title_shared_notifications_empty: "No hay notificaciones nuevas.",
    title_notifications: "Notificaciones",
    title_shared_user_salutation: "Hola,",
    title_shared_user_type_consumer: "Consumidor",
    title_shared_user_type_prosumer: "Prosumidor",
    title_shared_user_type_generator: "Generador",
    title_shared_user_info: "Tu información",
    title_shared_user_save: "Guardar",
    title_shared_user_signout: "Cerrar sesión",
    title_invoices: "Facturas",
    title_invoices_popup_pays: "Factura",
    title_invoices_popup_gateway: "Pago seguro desde cuenta de ahorros o corriente vía",
    title_invoices_state_pay: "Pagar",
    title_invoices_state_paid: "Pagada",
    title_invoices_state_cancelled: "Cancelada",
    title_paid_state_approved: "Gracias, tu pago ha sido aprobado.",
    title_paid_state_declined: "Lo sentimos, tu pago ha sido rechazado.",
    title_paid_state_pending: "Tu pago aún esta pendiente.",
    title_paid_name: "Nombre",
    title_paid_email: "Correo electrónico",
    title_paid_document: "Documento de identidad",
    title_paid_description: "Descripción",
    title_paid_total: "Total",
    title_paid_reference: "Referencia",
    title_paid_date: "Fecha",
    title_paid_print: "Imprimir",
    title_paid_back: "Regresar",
    title_reports: "Reportes",
    title_rules: "Reglas",
    title_notifications_empty: "No hay notificaciónes.",
    title_invoices_empty: "No hay facturas.",
    title_reports_empty: "No hay reportes.",
    title_rules_empty: "No hay reglas.",
    title_home_project_info: "Información del proyecto",
    title_home_project_save: "Guardar",
    label_home_project_acronym: "Acrónimo",
    label_home_project_name: "Nombre",
    label_home_project_date: "Fecha de instalación",
    label_home_project_address: "Dirección",
    label_home_project_average_consumption: "Consumo promedio",
    label_home_project_cost_kwh: "Costo por kWh",
    label_home_project_installed_power: "Potencia instalada",
    label_home_project_theoretical_energy: "Energía teórica",
    label_home_project_panels_quantity: "Cantidad de paneles",
    label_home_project_panel_area: "Área de paneles",
    label_home_project_panel_efficiency: "Eficiencia del panel",
    placeholder_home_project_address: "Ej. Carrera 79 # 22 - 10",
    placeholder_home_project_average_consumption: "Ej. 800",
    placeholder_home_project_costo_kwh: "Ej. 450",
    alert_info_project_address: "Debes ingresar una dirección válida.",
    alert_info_project_average_consumption: "Debes ingresar un consumo promedio válido.",
    alert_info_project_costo_kwh: "Debes ingresar un costo por kWh válido.",
    title_home_locations: "Ubicaciones",
    title_home_hourly: "Hora",
    title_home_daily: "Día",
    title_home_weekly: "Semana",
    title_home_monthly: "Mes",
    title_home_select_location: "Elige una ubicación.",
    title_home_select_graph: "Elige una gráfica.",
    title_home_card_no_inovices: "No hay facturas",
    title_home_card_inovice: "Factura",
    title_home_card_inovices: "Facturas",
    title_home_card_more: "más",
    title_home_card_less: "menos",
    title_home_card_expires: "Vencimiento",
    title_home_card_to_pay: "Pagar",
    title_home_card_to_go: "Ver facturas",
    title_home_card_saves: "Haz ahorrado",
    title_home_card_ad: "Pero podrías ahorrar más con NEU",
    title_home_card_change_to_neu: "¡Cámbiate a NEU!",
    title_home_card_activity: "Tu actividad equivale",
    title_home_card_trees: "Árboles sembrados",
    title_home_card_co2: "Tons de CO2 reducidas",
    title_home_card_solar: "Consumo solar",
    title_menu_home: "Resumen",
    title_menu_locations: "Ubicaciones",
    title_menu_indexes: "Indicadores",
    title_menu_invoices: "Facturas",
    title_menu_reports: "Reportes",
    title_menu_notifications: "Notificaciones",
    title_menu_rules: "Reglas",
    title_menu_support: "Sugerencias",
    placeholder_home_select_graphs: "Gráficas",
    title_home_popup_addresses: "Smart meters",
    title_home_popup_addresses_check_all: "Seleccionar todos",
    title_home_popup_addresses_empty: "No hay smart meters disponibles.",
    title_home_popup_addresses_ok: "Guardar",
    title_home_popup_export: "Exportar gráfica",
    title_home_popup_button_export: "Exportar",
    title_home_popup_graph_empty: "Selecciona una gráfica.",
    title_support_popup_send: "Enviar",
    title_locations_search_projects: "Buscar proyecto",
    title_locations_loading_info: "Cargando",
    title_locations_total: "Total",
    title_locations_avg: "Promedio",
    title_graph_score: "Cuéntanos tu experiencia",
    label_graph_score: "¿Qué te pareció la información encontrada en la gráfica de",
    label_graph_score_not_usefull: "Poco útil",
    label_graph_score_too_usefull: "Muy útil",
    label_graph_score_suggestion: "¿Cómo podría mejorar? (opcional).",
    option_home_graph_active_energy: "Consumo",
    option_home_graph_reactive_enegy_inductive: "Reactiva inductiva",
    option_home_graph_reactive_enegy_capacitive: "Reactiva capacitiva",
    option_home_graph_reactive_enegy_excess: "Reactiva en exceso",
    option_home_graph_injection: "Excedentes",
    option_home_graph_transactions: "Transacciones",
    option_home_graph_current: "Corriente",
    option_home_graph_voltage: "Voltaje",
    option_home_graph_frequency: "Frecuencia",
    option_home_graph_generation: "Generación",
    option_home_graph_generation_specific: 'Generación especifica',
    option_home_graph_current_ac: 'Corriente AC',
    option_home_graph_current_dc: 'Corriente DC',
    option_home_graph_voltage_ac: 'Tensión AC',
    option_home_graph_voltage_dc: 'Tensión DC',
    option_home_graph_active_pwr: 'Potencia activa',
    option_home_graph_apparent_pwr: 'Potencia aparente',
    option_home_graph_pwr_factor: 'Factor de potencia',
    option_home_graph_inverter_temperature: 'Temperatura del inversor',
    option_home_graph_heatsink_temperature: 'Temperatura del disipador',
    option_home_graph_irradiance: 'Irradiancia',
    option_home_graph_panel_temperature: 'Temperatura del panel',
    option_home_graph_environmet_temperature: 'Temperatura ambiente',
    option_home_graph_humidity: 'Humedad',
    option_home_graph_wind_speed: 'Velocidad del viento',
    option_home_graph_wind_direction: 'Dirección del viento',
    option_home_datepicker_sunday: "Do",
    option_home_datepicker_monday: "Lu",
    option_home_datepicker_tuesday: "Ma",
    option_home_datepicker_wednesday: "Mi",
    option_home_datepicker_thursday: "Ju",
    option_home_datepicker_friday: "Vi",
    option_home_datepicker_saturday: "Sa",
    option_home_datepicker_ok: "Cargar",
    option_home_datepicker_today: "Hoy",
    option_home_datepicker_yesterday: "Ayer",
    option_home_datepicker_last_7_Days: "Últimos 7 días",
    option_support_comercial: "¿Tienes una sugerencia comercial?",
    option_support_financial: "¿Tienes problemas o mejoras con tus pagos?",
    option_support_operations: "¿Tienes problemas o mejoras con tu energía?",
    option_support_tech: "¿Tienes problemas o mejoras con nuestra web?",
    option_support_others: "Otro tipo",
    tooltip_home_dashboard_size: "Expandir / Colapsar",
    tooltip_home_dashboard_addresses_filter: "Filtrar Smart meters",
    tooltip_home_dashboard_invoices: "Exportar",
    tooltip_home_card_invoice_pending: "Pendiente",
    tooltip_home_card_invoice_paid: "Pagado",
    tooltip_home_card_invoice_consumer: "Tu factura de energía del mes actual. Puedes comparar tu consumo respecto al mes anterior y proyectar un ahorro para fin de mes.",
    tooltip_home_card_invoice_generator: "Tu ahorro monetario por tener generación solar. Se calcula a partir de la energía generada y la tarifa. Recuerda que puedes editar el costo por kWh en la información de cada proyecto.",
    tooltip_home_card_environment: "La energía solar que consumes a través de NEU tiene un impacto positivo en el medio ambiente. Puedes ver cuantos árboles se sembraron por este consumo o incluso cómo ayudaste a reducir emisiones de CO2.",
    tooltip_home_card_gauge: "Energía solar que consumiste en un determinado periodo de tiempo.",
    tooltip_notifications_info: "Es el historial de las notificaciones enviadas por tu aplicación para recordarte el ahorro generado o alertarte sobre algún evento inusual. Acá también verás el resultado de las reglas creadas.",
    tooltip_invoices_info: "El detalle de tus facturas vigentes e históricas.",
    tooltip_reports_info: "Aquí puedes ver los reportes que has creado. Adicionalmente selecciona las opciones que desees para generar un nuevo reporte.",
    tooltip_rules_info: "Crea tus propias reglas para generar notificaciones personalizadas por ti para optimizar tu consumo de energía.",
    tooltip_shared_search: "Presiona (Enter) para buscar.",
    tooltip_shared_download: "Descargar",
    tooltip_overdue_invoice: "Factura vencida",
    tooltip_delete: "Eliminar",
    tooltip_shared_filter: "Filtrar",
    tooltip_menu_pending: "Pendiente",
    placeholder_invoices_popup_name: "Nombres",
    placeholder_invoices_popup_name_example: "Ej. Luis Enrique",
    placeholder_invoices_popup_lastname: "Apellidos",
    placeholder_invoices_popup_lastname_example: "Ej. Cortes López",
    placeholder_invoices_popup_email: "Correo eléctronico",
    placeholder_invoices_popup_email_example: "Ej. lcortes@neu.com.co",
    placeholder_invoices_popup_phone: "Teléfono",
    placeholder_invoices_popup_phone_example: "Ej. 3013865253",
    placeholder_invoices_popup_document_type: "Tipo de documento",
    placeholder_invoices_popup_document: "Documento de identidad",
    placeholder_invoices_popup_document_example: "Ej. 1037658620",
    placeholder_invoices_popup_person_type: "Tipo de persona",
    placeholder_invoices_popup_bank: "Entidad bancaria",
    placeholder_invoices_popup_bank_select: "Selecciona tu banco...",
    placeholder_invoices_popup_invoice: "Total",
    placeholder_invoices_popup_neugets: "Pagar con NEUgets",
    placeholder_shared_user_document: "Documento de identidad",
    placeholder_shared_user_name: "Nombres",
    placeholder_shared_user_name_example: "Ej. Luis Enrique",
    placeholder_shared_user_lastname: "Apellidos",
    placeholder_shared_user_lastname_example: "Ej. Cortes López",
    placeholder_shared_user_email: "Correo eléctronico",
    placeholder_shared_user_email_example: "Ej. lcortes@neu.com.co",
    placeholder_shared_user_phone: "Teléfono",
    placeholder_shared_user_phone_example: "Ej. 3013865253",
    placeholder_shared_user_phone_two: "Teléfono alternativo",
    placeholder_shared_user_phone_two_example: "Ej. 3040625",
    placeholder_shared_user_address: "Dirección",
    placeholder_shared_user_address_example: "Ej. Calle 27 S # 100 - 14",
    placeholder_shared_user_address_two: "Dirección alternativa",
    placeholder_shared_user_address_two_example: "Ej. Calle 13 S # 120 - 45",
    placeholder_support_type: "Tipo de mejora o sugerencia",
    placeholder_support_comment: "Comentario (Max 255 cará.)",
    placeholder_support_comment_example: "No somos perfectos, lo sabemos, pero queremos ofrecerte un servicio increíble y gracias a tus comentarios somos cada vez somos mejores.",
    alert_invoices_popup_name: "Debes ingresar un nombre válido.",
    alert_invoices_popup_lastname: "Debes ingresar un apellido válido.",
    alert_invoices_popup_email: "Debes ingresar un correo eléctronico válido.",
    alert_invoices_popup_phone: "Debes ingresar un teléfono válido.",
    alert_invoices_popup_address: "Debes ingresar una dirección válida.",
    alert_invoices_popup_id_document: "Debes ingresar un documento de identidad válido.",
    alert_invoices_popup_financial_institution_code: "Debes ingresar una entidad bancaria válida.",
    alert_invoices_popup_terms: "Debes aceptar los términos y condiciones.",
    alert_user_popup_name: "Debes ingresar un nombre válido.",
    alert_user_popup_lastname: "Debes ingresar un apellido válido.",
    alert_user_popup_email: "Debes ingresar un correo eléctronico válido.",
    alert_user_popup_phone: "Debes ingresar un teléfono válido.",
    alert_user_popup_address: "Debes ingresar una dirección válido.",
    alert_support_popup_thanks: "¡Muchas gracias!, nuestro equipo te contactará cuanto antes.",
    placeholder_invoices_filter_table_code: "Código",
    placeholder_invoices_filter_table_description: "Descripción",
    placeholder_invoices_filter_table_value: "Valor total",
    placeholder_invoices_filter_table_all_states: "Todos los estados",
    placeholder_invoices_filter_table_all_locations: "Todas las ubicaciones",
    alert_shared_error: "¡Ups!, Ha ocurrido un error, porfavor vuelve a intentarlo.",
    tooltip_reports_create_report: "Crear nuevo reporte",
    title_reports_popup_create_report: "Crear reporte",
    title_reports_popup_button_generate: "Generar",
    title_reports_button_error: "Error",
    title_reports_button_pending: "Pendiente",
    title_reports_button_download: "Descargar",
    label_reports_popup_name: "Nombre del informe",
    label_reports_popup_addresses: "Ubicaciones",
    label_reports_popup_graphs: "Gráficas",
    label_reports_popup_date_range: "Rango de fechas",
    label_reports_popup_interval: "Intervalo",
    label_reports_popup_format: "Formato",
    label_shared_popup_select_interval_hourly: "Horario",
    label_shared_popup_select_interval_daily: "Diario",
    label_shared_popup_select_interval_weekly: "Semanal",
    label_shared_popup_select_interval_monthly: "Mensual",
    placeholder_reports_popup_name: "Ej. Informe mensual",
    placeholder_reports_popup_addresses: "Seleccione las ubicaciones...",
    placeholder_reports_popup_graphs: "Seleccione las gráficas...",
    alert_reports_popup_report_name: "Debes ingresar un nombre para tu informe.",
    alert_reports_popup_addresses: "Debes seleccionar por lo menos un Smart Meter.",
    alert_reports_popup_graphs: "Debes seleccionar por lo menos una gráfica.",
    alert_reports_popup_interval: "Debes seleccionar un intervalo válido.",
    alert_reports_popup_format_type: "Debes seleccionar un formato válido.",
    placeholder_reports_filter_table_report_name: "Nombre del informe",
    placeholder_reports_filter_table_all_formats: "Todos los formatos",
    placeholder_reports_filter_table_all_states: "Todos los estados",
    label_rules_popup_location: "Ubicación",
    label_rules_popup_properties: "Propiedad",
    label_rules_popup_interval: "Intervalo",
    label_rules_popup_rule_names: "Regla",
    label_rules_popup_value: "Valor",
    label_rules_popup_available_days: "Días disponibles",
    label_rules_popup_users: "Usuarios destinatarios",
    placeholder_rules_popup_value: "Ej. 500",
    placeholder_rules_popup_location: "Seleccione una ubicación...",
    placeholder_rules_popup_properties: "Seleccione una propiedad...",
    placeholder_rules_popup_user: "Seleccione los destinatarios...",
    tooltip_rules_create_rule: "Crear nueva regla",
    tooltip_rules_edit_rule: "Editar regla",
    title_popup_update_rule : "Actualizar información de la regla",
    title_rules_popup_create_rule: "Crear regla",
    title_rules_popup_button_create: "Crear",
    title_rules_popup_button_update: "Actualizar",
    title_rules_popup_button_delete: "Eliminar",
    option_table_rules_day_monday: "L",
    option_table_rules_day_tuesday: "M",
    option_table_rules_day_wednesday: "M",
    option_table_rules_day_thursday: "J",
    option_table_rules_day_friday: "V",
    option_table_rules_day_saturday: "S",
    option_table_rules_day_sunday: "D",
    label_rules_select_rule_name_greater: "Mayor que",
    label_rules_select_rule_name_less: "Menor que",
    label_rules_select_rule_name_equal: "Igual que",
    alert_rules_popup_location: "Debes seleccionar una ubicación válida.",
    alert_rules_popup_property: "Debes seleccionar una propiedad válida.",
    alert_rules_popup_rule_name: "Debes seleccionar una regla válida.",
    alert_rules_popup_value: "Debes ingresar un valor válido.",
    alert_rules_popup_available_days: "Debes seleccionar por lo menos un día de la semana.",
    alert_error_address_zero: "Revisa la dirección ingresada, puede estar incompleta.",
    alert_rule_success_update: "¡Excelente! Se actualizó correctamente la información de la regla",
    alert_rule_error_update: "No fue posible actualizar la información de la regla. Por favor intente mas tarde.",
    header_table_date: "Fecha",
    header_table_hour: "Hora",
    header_table_notificacions_project: "Ubicación",
    header_table_notificacions_notification: "Notificación",
    placeholder_notifications_filter_table_all_locations: "Todos los proyectos",
    placeholder_notifications_filter_table_all_types: "Todos los tipos",
    option_notifications_type_alert: "Alerta",
    option_notifications_type_information: "Información",
    option_notifications_type_advertising: "Advertencia",
    option_notifications_type_achievement: "Logro",
    header_table_invoices_code: "Código",
    header_table_invoices_date: "Fecha",
    header_table_invoices_description: "Descripción",
    header_table_invoices_total_value: "Valor total",
    header_table_invoices_location: "Ubicación",
    header_table_state: "Estado",
    header_table_location: "Ubicación",
    header_table_reports_report: "Informe",
    header_table_reports_file_name: "Nombre del archivo",
    header_table_rules_property: "Propiedad",
    header_table_rules_interval: "Intervalo",
    header_table_rules_rule: "Regla",
    header_table_rules_value: "Valor",
    header_table_rules_available: "Disponible",
    option_filter_table_rules_day_monday: "Lunes",
    option_filter_table_rules_day_tuesday: "Martes",
    option_filter_table_rules_day_wednesday: "Miércoles",
    option_filter_table_rules_day_thursday: "Jueves",
    option_filter_table_rules_day_friday: "Viernes",
    option_filter_table_rules_day_saturday: "Sábado",
    option_filter_table_rules_day_sunday: "Domingo",
    placeholder_rules_filter_table_all_locations: "Todas las ubicaciones",
    placeholder_rules_filter_table_all_properties: "Todas las propiedades",
    placeholder_rules_filter_table_all_intervals: "Todos",
    placeholder_rules_filter_table_all_rule_names: "Todas",
    placeholder_rules_filter_table_value: "Valor",
    placeholder_rules_filter_table_all_days: "Todas los días",
    format_date_string: 'es-ES',
    loading_message: "Cargando...",
    thousandSeparator: ".",
    decimalSeparator: ",",
    title_login: "Reinventamos el futuro de la energía",
    label_login_input_data: "Escribe tu teléfono o email registrado",
    placeholder_login_input_data: "Teléfono o email",
    label_login_input_code: "Por favor, escribe el código de verificación que te enviamos a:",
    placeholder_login_input_code: "Código de verificación",
    login_resend_code: "Reenviar código",
    login_resending_code: "Espera #seg seg para reenviar",
    login_want_to_join: "¿Quieres unirte?",
    login_click_here: "Haz click aquí",
    alert_login_user_not_found: "Aún no estas registrado.",
    alert_login_invalid_code: "El código es inválido.",
    button_neugets_want_more: "¡Quiero más!",
    text_neugets_share_code_modal_title: "¡Comparte y gana!",
    text_neugets_share_code_modal_body: "Comparte este código con tus amigos, cuando ellos se registren e ingresen este código ambos ganan NEUgets, genial ¿No? ",
    text_neugets_share_code_title: "Comparte y gana con NEU",
    text_neugets_share_code_message: "Hola\n¿Te quieres ganar un super descuento en tu próxima factura de energía?\n\n¡Te invito a que conozcas a NEU Energy! El único proveedor de energía eléctrica en Colombia que te permite visualizar tu consumo en tiempo real desde tu celular 📊📱 y reducir tu factura de energía con notificaciones de eficiencia energética.⚡\n\nRegístrate con mi código de referido: \n\n#code#\n\nAmbos participaremos para obtener descuentos en futuras facturas de energía eléctrica.🧾⚡\n\nRegístrate en: \n\nhttps://www.neu.com.co/registro",
    text_share_user_salutation_text: "Crea tu perfil y cuéntanos un poco más quien eres…",
    tooltip_neugets_copy_code: "Código copiado",
    title_userAccess: "Usuarios",
    title_menu_userAccess: "Usuarios",
    tooltip_userAccess_button_create_user: "Crear un nuevo usuario para la plataforma",
    tooltip_userAccess_info: "Aquí pudes darle acceso a la plataforma a los usuarios que quieras",
    label_userAccess_button_active: "Activo",
    label_userAccess_button_inactive: "Inactivo",
    title_popup_create_userAccess: "Crear usuario",
    title_popup_update_userAccess: "Actualizar información de usuario",
    label_popup_userAccess_name: "Nombres",
    label_popup_userAccess_lastname: "Apellidos",
    label_popup_userAccess_id_document: "Documento de identidad",
    label_popup_userAccess_email: "Correo electrónico",
    label_popup_userAccess_phone: "Teléfono",
    label_popup_userAccess_address: "Dirección",
    label_popup_userAccess_city: "Ciudad",
    label_popup_userAccess_locations: "Ubicaciones",
    placeholder_popup_userAccess_name: "Ej. Luis Enrique",
    placeholder_popup_userAccess_lastname: "Ej. Cortes López",
    placeholder_popup_userAccess_id_document: "Ej. 1037658620",
    placeholder_popup_userAccess_email: "Ej. lcortes@neu.com.co",
    placeholder_popup_userAccess_phone: "Ej. 3013865253",
    placeholder_popup_userAccess_address: "Ej. Calle 27 S # 100 - 14",
    placeholder_popup_userAccess_city: "Seleciona una ciudad",
    placeholder_popup_userAccess_locations: "Selecciona las ubicaciones",
    title_userAccess_popup_button_create_user: "Crear usuario",
    title_userAccess_popup_button_update_user: "Actualizar",
    title_userAccess_users_empty: "No hay usuarios para mostrar",
    header_table_userAccess_name: "Nombre",
    header_table_userAccess_lastname: "Apellido",
    header_table_userAccess_phone: "Teléfono",
    header_table_userAccess_email: "Correo electrónico",
    header_table_userAccess_state: "Estado",
    filter_table_userAccess_name: "Nombre",
    filter_table_userAccess_lastname: "Apellido",
    filter_table_userAccess_phone: "Teléfono",
    filter_table_userAccess_email: "Correo electrónico",
    filter_table_userAccess_all_states: "Todos los estados",
    tooltip_userAccess_edit: "Editar",
    option_userAccess_active: "Activo",
    option_userAccess_inactive: "Inactivo",
    alert_userAccess_popup_name: "Debes ingresar un nombre válido.",
    alert_userAccess_popup_lastname: "Debes ingresar un apellido válido.",
    alert_userAccess_popup_id_document: "Debes ingresar un documento de identidad válido.",
    alert_userAccess_popup_email: "Debes ingresar un correo electrónico válido.",
    alert_userAccess_popup_phone: "Debes ingresar un teléfono válido.",
    alert_userAccess_popup_address: "Debes ingresar una dirección válida.",
    alert_userAccess_popup_city: "Debes seleccionar una ciudad válida.",
    alert_userAccess_popup_locations: "Debes seleccionar las ubicaciones a los que le dará acceso.",
    alert_userAccess_user_exist: "Este usuario ya se encuentra registrado.",
    alert_userAccess_error_create: "No fue posible crear el usuario. Por favor intente mas tarde.",
    alert_userAccess_error_update: "No fue posible actualizar la información del usuario. Por favor intente mas tarde.",
    alert_userAccess_success_update: "¡Excelente! Se actualizó correctamente la información del usuario",
    alert_userAccess_error_update_status: "No fue posible actualizar el estado del usuario. Por favor intente mas tarde.",
    title_neuplus: "NEU +",
    title_menu_neuplus: "NEU +",
    tooltip_neuplus_info: "Aquí puedes visualizar tu energía en tiempo real.",
    placeholder_neuplus_select_graphs: "Selecciona una gráfica",
    neuplus_nodata_available: "No hay datos disponibles para este dispositivo.",
    neuplus_typeGraphic_Active: "Potencia Activa",
    neuplus_typeGraphic_Reactive: "Potencia Reactiva",
    neuplus_main_checkpicker_placeholder: "Dispositivos disponibles",
    neuplus_main_speaker_new_device: "Crear un nuevo dispositivo para un proyecto.",
    neuplus_main_error_appliance_no_device: "Debe seleccionar al menos un dispositivo.",
    neuplus_main_error_appliance_no_timestamp: "Debe seleccionar al menos un punto en la gráfica.",
    neuplus_main_success_appliance: "Etiqueta creada satisfactoriamente.",
    neuplus_main_header_new_device: "Agregar nuevo dispositivo",
    neuplus_main_body_new_device_name: "Nombre del dispositivo",
    neuplus_main_body_new_device_example: "Ej. Máquina 1",
    neuplus_main_footer_new_device: "Agregar",
    neuplus_main_error_new_device: "Debe agregar un nombre al nuevo dispositivo.",
    neuplus_main_header_new_tag: "Crear etiqueta",
    neuplus_main_footer_new_tag_cancel: "Cancelar",
    neuplus_main_footer_new_tag_create: "Crear",
    neuplus_main_button_finish_tag: "Terminar",
    neuplus_main_button_start_tag: "Etiquetar",
    neuplus_success_new_device: "Dispositivo creado satisfactoriamente.",
    neuplus_error_new_device: "No se pudo crear el dispositivo.",
    neuplus_banner_tittle: "Desbloquea el poder de tu energía, con medición en milisegundos",
    neuplus_banner_body: "Con nuestro asistente que te ayuda a controlar tus gastos, podrás rastrear segundo a segundo el consumo de cada máquina o electrodoméstico.",
    neuplus_banner_final_invitation: "¡Sigue transformando la manera en la que consumes energía!",
    neuplus_banner_button_text: "Súmate a NEU+",
    neuplus_main_error_new_device_no_project: "Debe elegir al menos un proyecto",
    language_locale: 'es',
    alert_generate_user_api_token: "No fue posible generar el token, por favor intente mas tarde.",
    title_user_btn_generate_api_token: "Generar",
    label_user_api_token: "Token API",
    placeholder_user_api_token: "Haz click en generar",
    title_menu_tags: "Etiquetas",
    title_tags: "Etiquetas",
    header_table_tags_name_device: "Dispositivo",
    header_table_tags_location: "Ubicación",
    header_table_tags_average_duration: "Duración promedio",
    header_table_tags_average_consumption: "Consumo promedio",
    header_table_tags_details: "Detalles",
    header_table_records_date: "Fecha",
    header_table_records_active_cons: "Energía activa",
    header_table_records_active_power: "Potencia activa",
    header_table_records_reactive_cons: "Energía reactiva",
    header_table_records_reactive_power: "Potencia reactiva",
    header_table_records_duration: "Duración",
    language_notifications_spanish: "Español (Colombia)",
    language_notifications_english: "Inglés (USA)",
    label_user_language_notifications: "Lenguaje notificaciones",
    alert_multiple_devices_tags: "Para etiquetar un evento, solo puedes tener un dispositivo seleccionado a la vez.",
    alert_multiple_devices_tags_2: "No puedes seleccionar otro dispositivo mientras etiquetas un evento.",
    title_nps_survey: "Cuéntanos tu experiencia",
    label_nps_survey: "¿Qué tan probable es que recomiendes NEU a tus amigos o familiares?",
    label_nps_survey_not_likely: "Nada probable",
    label_nps_survey_too_likely: "Extremadamente probable",
    label_input_nps_survey: "¿Cuál es tu razón principal para asignar esa puntuación?",
    alert_nps_support_thanks: "Se ha enviado correctamente la encuesta, ¡Muchas gracias!.",
    procesing_module_tags: "Procesando...",
    see_more_module_tags: "Ver más",
    neuplus_accuracy_prediction: "Precisión:",
    neuplus_predictions_unknown: "Desconocido",
    module_tags_no_records: "No hay etiquetas",
    module_tags_no_devices: "No hay dispositivos",
    module_tags_label_active_power: "Potencia_activa",
    module_tags_label_reactive_power: "Potencia_reactiva",
    header_table_records_tag: "Etiqueta",
    graph_tag_records_graph_ignition_power: "Potencia de encendido",
    table_tag_records_time_instant: "Instantáneo",
    placeholder_tags_filter_table_all_appliances: "Todos los dispositivos",
    modal_confirm_delete_tag_agree: "Aceptar",
    modal_confirm_delete_tag_cancel: "Cancelar",
    modal_confirm_delete_tag_body: "¿Estás seguro que deseas eliminar esta etiqueta?",
    inquiry_header_select_modal: "Dispositivos",
    inquiry_tooltip_create_new_appliance: "Crea nuevos dispositivos para tu lista",
    inquiry_tooltip_add_new_appliance: "Agrega nuevos dispositivos para tu lista",
    inquiry_select_modal_another_type_appliance: "Otro tipo...",
    inquiry_select_modal_another_brand_appliance: "Otra marca...",
    inquiry_select_modal_placeholder_filter_by_type: "Tipo de dispositivo",
    inquiry_select_modal_placeholder_filter_by_brand: "Marca",
    inquiry_pagination_total_appliances: "Total",
    inquiry_select_modal_no_results: "No hay resultados para tu búsqueda, pero puedes crear un nuevo dispositivo.",
    inquiry_alert_insert_appliances: "Dispositivos agregados exitosamente",
    inquiry_select_modal_save_appliances: "Guardar",
    inquiry_select_modal_create_appliances: "Crear",
    inquiry_select_modal_return: "Regresar",
    inquiry_header_create_modal: "Crear dispositivo",
    inquiry_create_modal_tittle_type_appliance: "Tipo del dispositivo",
    inquiry_create_modal_placeholder_type_appliance: "Tipo de dispositivo",
    inquiry_create_modal_placeholder_input_type_appliance: "Ingresa el tipo.",
    inquiry_create_modal_tittle_brand_appliance: "Marca del dispositivo",
    inquiry_create_modal_placeholder_brand_appliance: "Marca",
    inquiry_create_modal_placeholder_input_brand_appliance: "Ingresa la marca.",
    inquiry_create_modal_tittle_reference_appliance: "Referencia del dispositivo",
    inquiry_create_modal_placeholder_input_reference_appliance: "Ingresa la referencia (opcional).",
    inquiry_create_modal_tittle_quantity_appliance: "Cantidad",
    inquiry_create_modal_banner_aditional_data_appliance: "Para obtener mejores resultados, agrega información adicional sobre el nuevo dispositivo",
    inquiry_select_create_save_appliance: "Guardar",
    inquiry_select_create_return: "Regresar",
    inquiry_header_associated_appliances_modal: "Dispositivos asociados",
    inquiry_no_appliances: "No hay dispositivos",
    inquiry_associated_modal_new_appliance: "Nuevo dispositivo",
    inquiry_header_edit_appliances_modal: "Edición de dispositivo",
    inquiry_edit_tittle_active_power: "Potencia activa",
    inquiry_edit_tittle_reactive_power: "Potencia reactiva",
    inquiry_edit_tittle_monthly_cons: "Consumo activo mensual",
    inquiry_edit_tittle_monthly_cons_reactive: "Consumo reactivo mensual",
    inquiry_edit_tittle_weight: "Peso",
    inquiry_edit_tittle_height: "Altura",
    inquiry_edit_tittle_width: "Ancho",
    inquiry_edit_tittle_depth: "Profundidad",
    inquiry_edit_save_appliance: "Guardar",
    inquiry_edit_cancel: "Cancelar",
    inquiry_header_specifications_modal: "Especificaciones del dispositivo",
    inquiry_specifications_modal_confirm: "Aceptar",
    inquiry_specifications_modal_cancel: "Cancelar",
    inquiry_tooltip_edit_appliance: "Edita los dispositivos disponibles",
    inquiry_alert_edit_appliance_success: "Información actualizada exitosamente",
    inquiry_alert_edit_appliance_error: "No se pudo actualizar la información",
    inquiry_alert_info_no_appliances: "¡Aún no tienes dispositivos asociados para este proyecto! Elige tus dispositivos para descubrir todos los beneficios que NEU+ tiene para ti.",
    inquiry_tittle_custom_name: "Nombre personalizado",
    inquiry_placeholder_custom_name: "Personaliza el nombre",
    title_home_dashboard_indexes_max: "Máximo",
    title_home_dashboard_indexes_avg: "Promedio",
    title_home_dashboard_indexes_min: "Mínimo",
    title_home_dashboard_indexes_total: "Total",
    text_user_info_description: "Completa tu perfil y gana",
    text_user_info_description2: "Podrás redimirlos dentro de NEU o en el pago de tus facturas de energía.",
    title_user_info_section_your_info: "Tu información",
    title_user_info_section_health: "Información Salud",
    title_user_info_section_home: "Información Hogar",
    title_user_info_section_company: "Información Empresa",
    title_user_info_section_vehicle: "Información Automóvil",
    option_user_property_type_aparment: 'Apartamento',
    option_user_property_type_house: 'Casa',
    option_user_property_type_country_house: 'Casa campestre',
    option_user_property_type_farm: 'Finca',
    label_user_height: "Estatura",
    label_user_weight: "Peso",
    label_user_eps: "Eps",
    label_user_insurance: "¿Tiene medicina prepagada?",
    label_user_property_type: "Tipo de inmueble",
    label_user_own_home: "¿Tiene vivienda propia?",
    label_user_house_people: "Número de personas que habitan en la casa",
    label_user_house_area: "Área del inmueble (m2)",
    label_user_floors: "Número de pisos",
    label_user_floor: "¿Qué piso es?",
    label_user_solar_system: "¿Tiene sistema solar?",
    label_user_own_vehicle: "¿Tiene vehículo propio?",
    label_user_armored_vehicle: "¿El vehículo es blindado?",
    label_user_ev: "¿El vehículo es eléctrico?",
    placeholder_user_height: "Ej. 1.75",
    placeholder_user_weight: "Ej. 78",
    placeholder_user_eps: "Ej. Sura",
    placeholder_user_property_type: "Seleccione un tipo de inmueble",
    placeholder_user_house_area: "Ej. 70",
    title_user_win_neugets: "¡Felicitaciones, completaste tu perfil!",
    text_user_win_neugets: "Ganaste #neugets NEUgets, que puedes redimir en tus proximas facturas",
    text_shared_yes: "Sí",
    text_shared_no: "No",
    tooltip_tags_info: "Acá puedes encontrar el historial de etiquetas realizadas en NEU+, clasificado por dispositivos. También podrás editar o crear nuevos dispositivos para futuras etiquetas.",
    text_card_saving_generator: "Total ahorrado en este período",
    title_btn_game_power_challenge_choose: "Elegir",
    title_btn_game_power_challenge_progress: "En progreso",
    title_btn_game_power_challenge_winning: "Vas ganando",
    title_btn_game_power_challenge_losing: "Vas perdiendo",
    title_btn_game_power_challenge_won: "Ganado",
    title_btn_game_power_challenge_lost: "Perdido",
    title_game_pc_change_challenge: "Cancelar reto actual",
    text_game_pc_change_challenge: "¿Estás seguro que deseas cancelar el reto actual?",
    text_game_pc_button_change_challenge: "Continuar",
    text_game_survey: "¿Cuál es tu nivel de satisfacción con el juego?",
    text_game_survey_button_send: "Enviar",
    title_tab_game_power_challenge_current: "Actuales",
    title_tab_game_power_challenge_historic: "Histórico",
    text_game_pc_empty_challenges: "No hay retos para mostrar",
    title_game_achievements: "¡Genial!",
    text_game_achievements: "Tienes nuevos logros",
    title_game_dashboard_ranking: "Ranking",
    title_game_dashboard_medals: "Medallas",
    title_game_dashboard_challenges: "Retos",
    text_game_ranking_tooltip_info: "Gana puntos jugando y posiciónate en los primeros puestos del ranking, para que los demás te vean triunfar!",
    title_game_ranking_button_city: "Ciudad",
    title_game_ranking_button_country: "País",
    label_game_neugets: "NEUgets",
    score_popup_invitation: "¿Qué tal si nos demuestras tu amor?",
    score_popup_button: "¡Califica tu experiencia con NEU de 1 a 10!",
    score_popup_explication: "Con 1 entenderemos que tenemos mucho por mejorar, pero con 10 que todo está saliendo bien con el trabajo realizado",
    score_popup_invitation2: "¡Estamos en tus manos!",
    score_popup_placeholder: "Si deseas, déjanos un comentario.",
    score_popup_gratitude: "Gracias, eres genial",
    placeholder_invoices_popup_type_user_select: "Selecciona el tipo de usuario...",
    score_popup_button_send: "Enviar",
    score_popup_thanks: "¡Gracias por calificarnos!",
    score_popup_subtitle: "Tu opinión es muy importante para nosotros.",
    score_popup_paragraph_good: "Nos llena de buena energía saber que estás feliz con nuestros servicios, cuéntanos qué es lo que más te gusta de NEU.",
    score_popup_paragraph_bad: "Siempre estamos dispuestos a mejorar nuestros servicios para ofrecerte una mejor experiencia. Por eso, cuéntanos en qué crees que no lo estamos haciendo bien.",
    score_popup_service1: "Facturación.",
    score_popup_service2: "Plataforma.",
    score_popup_service3: "Servicio al cliente.",
    score_popup_service4: "Proceso de pago.",
    score_popup_other: "Otro.",
    label_date_modal_export_neuplus: "Fecha",
    label_placeholder_date_modal_export_neuplus: "Selecciona la fecha",
    label_name_modal_export_neuplus: "Nombre del archivo",
    label_name_example_modal_export_neuplus: "Ej: informe_28_julio",
    label_creating_modal_export_neuplus: "Tu archivo está siendo creado. Esto puede tardar algunos segundos...",
    label_downloading_modal_export_neuplus: "Tu archivo se está descargando. Esto puede tardar algunos segundos...",
    label_empty_export_neuplus: "Debes seleccionar solo un dispositivo para esa funcionalidad",
    label_modal_export_neuplus_invalid_name: "Debes ingresar un nombre válido para el archivo.",
    label_modal_export_neuplus_invalid_date: "Debes seleccionar una fecha válida.",
    label_modal_export_neuplus_invalid_type: "Debes seleccionar un tipo de archivo.",
    text_neugets_share_code_modal_tyc: "* Aplican términos y condiciones ",
    text_neugets_share_code_modal_tyc_link: "consúltalos aquí",
};

export const setMenu = payload => {
    return {
        type: shared.SET_MENU,
        payload
    }
}

export const setBlackScreen = payload => {
    return {
        type: shared.SET_BLACK_SCREEN,
        payload
    }
}

export const setPopupShare = payload => {
    return {
        type: shared.SET_POPUP_SHARE,
        payload
    }
}

export const setPopupSupport = payload => {
    return {
        type: shared.SET_POPUP_SUPPORT,
        payload
    }
}

export const setLanguage = payload => {
    return {
        type: shared.SET_LANGUAGE,
        payload
    }
}

export const setCities = payload => {
    return {
        type: shared.SET_CITIES,
        payload
    }
}

export const setGraphScore = payload => {
    return {
        type: shared.SET_GRAPH_SCORE,
        payload
    }
}

export const setGraphScoreValue = payload => {
    return {
        type: shared.SET_GRAPH_SCORE_VALUE,
        payload
    }
}
export const setGraphScoreReason = payload => {
    return {
        type: shared.SET_GRAPH_SCORE_REASON,
        payload
    }
}

export const setShowNpsSurvey = payload => {
    return {
        type: shared.SET_SHOW_NPS_SURVEY,
        payload
    }
}

export const logout = payload => {
    localStorage.setItem('user', null);
    localStorage.setItem('token', null);
    return {
        type: shared.LOGOUT,
        payload
    }
}

export const loadLanguageAsync = () => {
    return (dispatch) => {

        fetchAndActivate(remoteConfig)
            .then(() => {

                let words = {};

                const parameters = getAll(remoteConfig);

                Object.entries(parameters).forEach(value => {
                    const [key, entry] = value;
                    words[key] = entry.asString().replace(/\s+/g, " ");
                });

                dispatch(setLanguage(words));

                const dateLocale = {
                    sunday: words.option_home_datepicker_sunday,
                    monday: words.option_home_datepicker_monday,
                    tuesday: words.option_home_datepicker_tuesday,
                    wednesday: words.option_home_datepicker_wednesday,
                    thursday: words.option_home_datepicker_thursday,
                    friday: words.option_home_datepicker_friday,
                    saturday: words.option_home_datepicker_saturday,
                    ok: words.option_home_datepicker_ok,
                    today: words.option_home_datepicker_today,
                    yesterday: words.option_home_datepicker_yesterday,
                    last7Days: words.option_home_datepicker_last_7_Days,
                }

                dispatch(homeActions.setDateLocale(dateLocale));

            });

    }
}

export const setSupportAsync = (payload) => {
    return (_dispatch, getState) => {

        const userId = getState().login.user.id_user;

        const language = getState().shared.language;

        const data = {
            id_user: userId,
            category: payload.category,
            classification: "Others",
            subject: payload.comment
        }

        SETData("support", "POST", data)
            .then(() => Alert.success(language.alert_support_popup_thanks))
            .catch(response => console.error(response));

    }
}

export const loadCitiesAsync = () => {
    return (dispatch) => {

        GETData("cities", "GET")
            .then(response => {
                if (response !== null) { dispatch(setCities(response)); }
            })
            .catch(response => console.error(response));
    }
}

export const setGraphScoreAsync = (payload) => {
    return (_dispatch, getState) => {

        const userId = getState().login.user.id_user;

        const data = {
            id_user: userId,
            id_graph: payload
        }

        SETData("scores/0", "POST", data)
            .catch(response => console.error(response));
    }
}

export const loadGraphScoreAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        GETData(`scores/${userId}/0`, "GET")
            .then(response => dispatch(setGraphScore(response)))
            .catch(response => console.error(response));
    }
}

export const updateGraphScoreAsync = payload => {
    return (dispatch) => {

        dispatch(setGraphScore(null));

        SETData("scores", "PUT", payload)
            .catch(response => console.error(response));
    }
}

export const loadUserNpsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        GETData(`scores/nps/${userId}`, "GET")
            .then(response => {
                if (response === 0) { dispatch(setShowNpsSurvey(true)) }
                else { localStorage.setItem('nps_survey', true) }
            })
            .catch(response => console.error(response));
        
    }
}

export const setNpsSurveyAsync = (payload) => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        const language = getState().shared.language;

        const data = {
            id_user: userId,
            platform: 0,
            ...payload
        }
        SETData("scores/nps", "POST", data)
            .then(response => {
                localStorage.setItem('nps_survey', true);
                Alert.success(language.alert_nps_support_thanks);
            })
            .catch(response => Alert.error(language.alert_shared_error))
            .finally(() => dispatch(setShowNpsSurvey(false)));
    }
}
