import * as login from '../creators/Login';
import { GETData, SETData } from '../../services/WebServices';

import { Alert } from 'rsuite';

import lang from '../../language/es';

import { getMessaging, getToken, isSupported } from "firebase/messaging";

import * as gameActions from './Game'

//Amplitude
import amplitude from 'amplitude-js';

amplitude.getInstance().init('449c2c9739944d266793c1cc1dc4d267');

const getFirebaseToken = id_user => {
    return () => {
        isSupported().then(support => {
            if (support) {
                let messaging = getMessaging();
                getToken(messaging, { vapidKey: "BJ8gUk5LxiXNbStVF4pbQfUvawXN5OxcmaWEVx1VsUpMsMGE9jfw5-WGFWTMkh0Jg3uFVqcNiVjbWdqpA3UHl_s" })
                    .then(token => SETData(`notifications/devices/${id_user}/${token}/web`, "POST", null));
            }

        })
    }
}

export const setStep = payload => {
    return {
        type: login.SET_STEP,
        payload
    }
}

export const setLoading = payload => {
    return {
        type: login.SET_LOADING,
        payload
    }
}

export const setUserLoading = payload => {
    return {
        type: login.SET_USER_LOADING,
        payload
    }
}

export const setResending = payload => {
    return {
        type: login.SET_RESENDING,
        payload
    }
}

export const setUser = payload => {
    return {
        type: login.SET_USER,
        payload
    }
}

export const setCounter = payload => {
    return {
        type: login.SET_COUNTER,
        payload
    }
}

export const setCodeTries = payload => {
    return {
        type: login.SET_CODE_TRIES,
        payload
    }
}

export const loadInitInfoAsync = () => {
    return (dispatch) => {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        if (token !== null && user !== null) {

            GETData(`user/${user.id_user}`, "GET")
                .then((result) => {
                    if (result !== null) {

                         if (result.redirect_url !== "")
                             window.location.replace(result.redirect_url);

                        localStorage.setItem('user', JSON.stringify(result));

                        dispatch(setUser(result));
                        dispatch(setStep(3));

                        amplitude.getInstance().setUserId(user.id_user);

                        amplitude.getInstance().setUserProperties({
                            name: `${result.name} ${result.lastname}`,
                            email: result.email,
                        });

                        dispatch(updateConnectionAsync());
                        dispatch(getUserExtraDataAsync());

                        dispatch(getFirebaseToken(user.id_user));

                    } else {
                        dispatch(setStep(1));
                    }
                });

        } else {
            dispatch(setStep(1));
        }

    }
}

export const loadInfoUserAsync = (payload, showError) => {
    return (dispatch, getState) => {
        const user = getState().login.user;
        let codeTries = getState().login.codeTries;

        dispatch(setLoading(true));

        GETData(`login/${user.id_user}/${payload}`, "GET")
            .then((result) => {

                if (result !== null) {

                    localStorage.setItem('token', result.token);

                    GETData(`user/${user.id_user}`, "GET").then((userInfo => {

                        if (userInfo !== null) {

                             if (userInfo.redirect_url !== "")
                                 window.location.replace(userInfo.redirect_url);

                            localStorage.setItem('user', JSON.stringify(userInfo));

                            dispatch(setUser(userInfo));

                            dispatch(setStep(3));
                            dispatch(setLoading(false));

                            dispatch(updateConnectionAsync());

                            dispatch(getUserExtraDataAsync());

                            dispatch(getFirebaseToken(user.id_user));

                            amplitude.getInstance().setUserId(user.id_user);

                            amplitude.getInstance().setUserProperties({
                                name: `${user.name} ${user.lastname}`,
                                email: user.email,
                            });

                            amplitude.getInstance().logEvent('Login Completed');
                        
                        } else {

                            localStorage.setItem('token', null);
                            localStorage.setItem('user', null);

                            dispatch(setStep(0));
                            dispatch(setLoading(false))
                            showError(lang.alert_shared_error);
                        }

                    }));

                } else {

                    codeTries += 1;

                    if (codeTries === 4) {

                        codeTries += 1;
                        dispatch(setStep(1));

                    } else {
                        showError(lang.alert_login_invalid_code);
                    }

                    dispatch(setLoading(false))
                    dispatch(setCodeTries(codeTries))

                }

            })
            .catch(() => {
                showError(lang.alert_shared_error);
                dispatch(setLoading(false))
            })

    }
}

export const generateCodeAsync = (payload, showError) => {
    return (dispatch, getState) => {

        const step = getState().login.step;
        const user = getState().login.user;

        let counter = getState().login.counter;
        let messageError;
        const language = getState().shared.language.language_locale;

        if (step === 1) {
            messageError = lang.alert_login_user_not_found;
            dispatch(setLoading(true));
        } else {
            messageError = lang.alert_shared_error;
            dispatch(setResending(true));
        };

        GETData(`login/${payload}/?lan=${language}`, "GET")
            .then((result) => {
                if (result !== null) {

                    dispatch(setUser({ ...user, id_user: result.id_user }));

                    if (step === 1) {
                        dispatch(setLoading(false));
                        dispatch(setStep(2));
                    } else {
                        setInterval(
                            () => {
                                if (counter > 0) {
                                    counter -= 1;
                                } else {
                                    counter = 60;
                                    dispatch(setResending(false));
                                }
                                dispatch(setCounter(counter));
                            },
                            1000
                        );
                    }
                } else {
                    showError(messageError);
                    dispatch(setLoading(false));
                    dispatch(setResending(false));
                }
            })
            .catch(() => {
                showError(messageError);
                dispatch(setLoading(false));
                dispatch(setResending(false));
            });

    }
}

export const updateInfoUserAsync = (payload) => {
    return (dispatch, getState) => {

        let language = getState().shared.language;

        let user = getState().login.user;

        const userExtra = {
            id_user: user.id_user,
            height: payload.height,
            weight: payload.weight,
            eps: payload.eps,
            insurance: payload.insurance,
            property_type: payload.property_type,
            own_home: payload.own_home,
            house_people: payload.house_people,
            house_area: payload.house_area,
            floors: payload.floors,
            floor: payload.floor,
            solar_system: payload.solar_system,
            own_vehicle: payload.own_vehicle,
            armored_vehicle: payload.armored_vehicle,
            ev: payload.ev
        }

        const userInfo = {
            id_user: user.id_user,
            name: payload.name,
            lastname: payload.lastname,
            email: payload.email,
            phone: payload.phone,
            phone2: payload.phone2,
            address: payload.address,
            address2: payload.address2,
            id_language: payload.id_language
        };

        dispatch(setUserLoading(true));

        SETData("user", "PUT", userInfo)
            .catch(response => console.error(response))
            .finally(() => {
                SETData(`user/extra-data/?progress=${payload.progress/100}`, "PUT", userExtra)
                    .then((response) => {
                        dispatch(setUser({...user, ...userInfo, ...userExtra}))
                        if (response > 0) {
                            Alert.success(language.text_user_win_neugets.replace("#neugets", response));
                        } 
                    })
                    .catch(response => console.error(response))
                    .finally(() => dispatch(setUserLoading(false)))
            });

    }
}

export const updateConnectionAsync = () => {
    return (_dispatch, getState) => {

        const userId = getState().login.user.id_user;

        GETData(`user/connection/${userId}`, 'POST')
            .then(() => _dispatch(gameActions.loadNewUsersConnectionAsync()))
            .catch(response => console.error(response));

    }
}

export const getUserExtraDataAsync = () => {
    return (dispatch, getState) => {

        let user = getState().login.user;

        GETData(`user/extra-data/${user.id_user}`, 'GET')
            .then((response) => {
                if (response!== null) {dispatch(setUser({...user, ...response}))}
            })
            .catch(response => console.error(response));

    }
}