import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import * as homeActions from '../../../store/actions/Home';
import * as sharedActions from '../../../store/actions/Shared';

import { setDisbaledGraphsValues } from '../../../services/GraphsHandler';

import { Loader, SelectPicker, CheckPicker, DateRangePicker, Tooltip, Whisper, Modal, Checkbox, CheckboxGroup } from 'rsuite';
import { Input, InputGroup, Alert } from 'rsuite';

import ContentLoader from "react-content-loader";

import { Transition } from 'react-transition-group';

import { getAnalytics, logEvent } from "firebase/analytics";

import DashboardRange from './DashboardRange';
import DashboardIndexes from './DashboardIndexes';
import DashboardGraph from './DashboardGraph';

import amplitude from 'amplitude-js';

const { afterToday } = DateRangePicker;

const analytics = getAnalytics();

const formatTypes = [
    {
        value: 'CSV',
        label: 'CSV'
    },
    {
        value: 'PDF',
        label: 'PDF'
    }
];

const showError = (report, props) => {

    if (report.report_name === null || report.report_name === "") {
        Alert.error(props.language.alert_reports_popup_report_name);
        return;
    }

    if (report.format_type === null) {
        Alert.error(props.language.alert_reports_popup_format_type);
        return;
    }

}

const initLoading = <div className="position-absolute bg-white w-100 h-100 left-0 top-0 zindex-1">
    <ContentLoader
        seed={2}
        foregroundColor="#ffffff"
        backgroundColor="#f1f1f1"
        style={{ width: "100%", height: "100%" }}>
        <circle cx="25" cy="35" r="10"></circle>
        <circle cx="calc(100% - 20px)" cy="35" r="10"></circle>
        <circle cx="calc(100% - 60px)" cy="35" r="10"></circle>
        <rect x="55" y="20" width="290" height="30" rx="5" ry="5"></rect>
        <rect x="25" y="85" width="320" height="30" rx="5" ry="5"></rect>
    </ContentLoader>
</div>;

const Dashboard = (props) => {

    const initReportForm = {
        report_name: '',
        format_type: formatTypes[0].value
    }

    const [disbaledGraphs, setDisbaledGraphs] = useState([]);
    const [activeAddressesModal, setActiveAddressesModal] = useState([]);
    const [showAddressesModal, setAddressesModal] = useState(false);

    const [showReportsModal, setReportsModal] = useState(false);
    const [reportForm, setReportForm] = useState(initReportForm);

    let graphs = props.graphs;
    let activeGraphs = props.activeGraphs;
    let graphsRelations = props.graphsRelations;
    let activeAddresses = props.activeAddresses;

    let setGraphData = props.setGraphData;
    let setGraphActive = props.setGraphActive;
    let loadGraphAsync = props.loadGraphAsync;

    let totalAddressIds = props.addresses
        .map(address => address.id_address);

    let activeAddressIds = props.activeAddresses
        .map(address => address.id_address);

    let isValid = !Object
        .values(reportForm)
        .some(x => x.length === 0);

    useEffect(() => {

        setDisbaledGraphs(setDisbaledGraphsValues(activeGraphs, graphs, graphsRelations));

        if (activeGraphs.length && activeAddresses.length) { loadGraphAsync() }
        else { setGraphData([]) }

    }, [graphs, activeGraphs, activeAddresses, graphsRelations, setGraphData, loadGraphAsync]);

    useEffect(() => { if (graphs.length) { setGraphActive([graphs[0].value]); } }, [graphs, setGraphActive]);

    return (
        <Fragment>
            <Modal
                size="xs"
                show={showAddressesModal}
                onEnter={() => setActiveAddressesModal(activeAddressIds)}
                onHide={() => setAddressesModal(false)}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.title_home_popup_addresses}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {props.addresses.length ?
                        <div id="home-check-address-container" className="overflow-auto w-100 p-3">
                            <Checkbox
                                indeterminate={!totalAddressIds.every(id => activeAddressesModal.includes(id))}                                
                                checked={totalAddressIds.every(id => activeAddressesModal.includes(id))}
                                onChange={(_value, checked) => {
                                    checked ? setActiveAddressesModal(totalAddressIds) : setActiveAddressesModal([])
                                }}>{props.language.title_home_popup_addresses_check_all}</Checkbox>
                            <CheckboxGroup
                                value={activeAddressesModal}
                                onChange={value => setActiveAddressesModal(value)}>
                                {props.addresses.map(address => (
                                    <Checkbox
                                        key={address.id_address}
                                        value={address.id_address}>{address.address_identifier}</Checkbox>
                                ))}
                            </CheckboxGroup>
                        </div> :
                        <div className="txt-semibold txt-12 txt-light-blue text-center py-3">{props.language.title_home_popup_addresses_empty}</div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 mr-4 bg-${activeAddressesModal.length ? "blue" : "light-blue"}`}
                            onClick={() => {
                                if (activeAddressesModal.length) {
                                    setAddressesModal(false);
                                    props.setAddressesActive(activeAddressesModal);
                                    if (props.activeGraphs.length) { props.loadGraphAsync() }
                                }
                            }}>{props.language.title_home_popup_addresses_ok}</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal
                size="xs"
                show={showReportsModal}
                onHide={() => {
                    setReportsModal(false);
                    setReportForm(initReportForm);
                }}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.title_home_popup_export}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {(props.activeGraphs.length && props.activeAddresses.length) ?
                        <div className="d-flex flex-column p-3">
                            <div className="col-12 my-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_reports_popup_name}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-clipboard txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.placeholder_reports_popup_name}
                                        maxLength={30}
                                        onChange={value => setReportForm({ ...reportForm, report_name: value })} />
                                </InputGroup>
                            </div>
                            <div className="col-12 my-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_reports_popup_format}</div>
                                <SelectPicker
                                    className="w-100"
                                    defaultValue={reportForm.format_type}
                                    searchable={false}
                                    cleanable={false}
                                    data={formatTypes}
                                    onChange={value => setReportForm({ ...reportForm, format_type: value })}
                                />
                            </div>
                        </div> :
                        <div className="txt-semibold txt-12 txt-light-blue text-center py-3">{props.language.title_home_popup_graph_empty}</div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 w-auto mr-4 bg-${isValid ? "blue" : "light-blue"}`}
                            onClick={() => {
                                if (isValid) {
                                    props.exportGraphAsync(reportForm)
                                    setReportsModal(false);
                                    setReportForm(initReportForm);

                                } else if (props.activeGraphs.length && props.activeAddresses.length) {
                                    showError(reportForm, props);
                                }
                            }}>
                            {props.language.title_home_popup_button_export}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <div id="home-graph" className="position-relative overflow-hidden column-start bg-white rounded shadow p-3">
                {!props.locations.length ? initLoading : null}
                <Transition
                    in={props.graphLoading}
                    timeout={100}>
                    {state => (
                        <div className={`trans-fade trans-fade-${state} position-absolute bg-white w-100 h-100 top-0 left-0 zindex-1`}>
                            <div className="column-center align-items-center w-100 h-100">
                                <Loader center size="md" content={props.language.loading_message} />
                            </div>
                        </div>
                    )}
                </Transition>
                <div className="row-between align-items-center w-100">
                    <div className="row-between align-items-center col-md-auto col-12 p-0">
                        <Whisper
                            delay={1000}
                            placement="right"
                            trigger="hover"
                            speaker={<Tooltip>{props.language.tooltip_home_dashboard_addresses_filter}</Tooltip>}>
                            <i className="fas fa-filter txt-10 txt-light-blue txt-hover-blue text-center c-pointer mr-3" onClick={() => {

                                logEvent(analytics, 'click_home_filters', {
                                    id_user: props.user.id_user,
                                    user_name: `${props.user.name} ${props.user.lastname}`
                                });

                                setAddressesModal(true);

                            }}></i>
                        </Whisper>
                        <CheckPicker
                            id="home-graph-picker"
                            data={graphs}
                            value={props.activeGraphs}
                            groupBy="group"
                            size="lg"
                            appearance="subtle"
                            searchable={false}
                            placeholder={props.language.placeholder_home_select_graphs}
                            disabledItemValues={[...disbaledGraphs, ...props.disabledGraphsExtra]}
                            onChange={value => {

                                value.forEach(graph => {
                                    let graphValue = graphs
                                        .filter(value => value.value === graph);
                                    props.setGraphScoreAsync(graphValue[0].id_graph);

                                    logEvent(analytics, 'click_home_graphs', {
                                        id_user: props.user.id_user,
                                        user_name: `${props.user.name} ${props.user.lastname}`,
                                        id_graph: graphValue[0].id_graph,
                                        graph_name: graphValue[0].label
                                    });

                                    amplitude.getInstance().logEvent('Report Type Changed', {
                                        id_graph: graphValue[0].id_graph,
                                        graph_name: graphValue[0].label
                                    });

                                });

                                props.setGraphActive(value);

                            }}
                        />
                    </div>
                    <div className="d-none d-md-flex justify-content-end align-items-center">
                        {props.exportLoading ?
                            <Loader className="mx-4" /> :
                            <Whisper
                                delay={1000}
                                placement="left"
                                trigger="hover"
                                speaker={<Tooltip>{props.language.tooltip_home_dashboard_invoices}</Tooltip>}>
                                <i className="fas fa-file-download txt-12 txt-light-blue txt-hover-blue c-pointer mx-4" onClick={() => setReportsModal(true)} />
                            </Whisper>
                        }
                        {props.isCommercialized &&
                            <Whisper
                                delay={1000}
                                placement="left"
                                trigger="hover"
                                speaker={<Tooltip>{props.language.tooltip_home_dashboard_size}</Tooltip>}>
                                <i className={`fas fa-${props.cardsActive ? "expand" : "compress-arrows-alt"} txt-12 txt-light-blue txt-hover-blue c-pointer`}
                                onClick={() => {
                                    props.setCardsActive(!props.cardsActive);

                                    if (!props.cardsActive) {
                                        amplitude.getInstance().logEvent('Report Expanded');
                                    }

                                }}></i>
                            </Whisper>}
                    </div>
                </div>
                <div className="row-between flex-wrap align-items-center w-100 py-3">
                    <div className="row-start align-items-center col-md-6 col-12 p-0">
                        <DateRangePicker
                            showOneCalendar
                            id="home-graph-date-picker"
                            size="lg"
                            format={props.user.hide_invoices? "MM-DD-YYYY" : "DD-MM-YYYY"}
                            defaultValue={[props.graphDateFrom, props.graphDateTo]}
                            locale={props.dateLocale}
                            cleanable={false}
                            disabledDate={afterToday()}
                            onOk={value => {

                                logEvent(analytics, 'click_home_calendar', {
                                    id_user: props.user.id_user,
                                    user_name: `${props.user.name} ${props.user.lastname}`,
                                    date_from: value[0],
                                    date_to: value[1]
                                });

                                amplitude.getInstance().logEvent('Report Time Frame Changed', {
                                    date_from: value[0],
                                    date_to: value[1]
                                });

                                props.setGraphDates({ graphDateFrom: value[0], graphDateTo: value[1] });

                                if (props.activeLocations.length) { props.loadSustaintabilityAsync() }
                                if (props.activeGraphs.length && props.activeAddresses.length) { props.loadGraphAsync() }

                            }}
                        />
                    </div>
                    <div className="d-flex justify-content-md-end justify-content-start align-items-center mt-3 mt-md-0 col-md-6 col-12 p-0">
                        <DashboardRange />
                    </div>
                </div>
                <DashboardIndexes />
                <DashboardGraph />
            </div >
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    language: state.shared.language,
    activeLocations: state.home.activeLocations,
    locations: state.home.locations,
    cardsActive: state.home.cardsActive,
    graphs: state.home.graphs,
    graphsRelations: state.home.graphsRelations,
    disabledGraphsExtra: state.home.disabledGraphsExtra,
    activeGraphs: state.home.activeGraphs,
    addresses: state.home.addresses,
    activeAddresses: state.home.activeAddresses,
    graphDateFrom: state.home.graphDateFrom,
    graphDateTo: state.home.graphDateTo,
    graphLoading: state.home.graphLoading,
    exportLoading: state.home.exportLoading,
    dateLocale: state.home.dateLocale,
    isCommercialized: state.home.isCommercialized
})

const mapDispatchToProps = dispatch => ({
    loadGraphAsync: () => dispatch(homeActions.loadGraphAsync()),
    loadSustaintabilityAsync: () => dispatch(homeActions.loadSustaintabilityAsync()),
    setGraphScoreAsync: payload => dispatch(sharedActions.setGraphScoreAsync(payload)),
    exportGraphAsync: payload => dispatch(homeActions.exportGraphAsync(payload)),
    setGraphData: payload => dispatch(homeActions.setGraphData(payload)),
    setGraphDates: payload => dispatch(homeActions.setGraphDates(payload)),
    setGraphActive: payload => dispatch(homeActions.setGraphActive(payload)),
    setCardsActive: payload => dispatch(homeActions.setCardsActive(payload)),
    setAddressesActive: payload => dispatch(homeActions.setAddressesActive(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
