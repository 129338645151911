import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as gameActions from '../../../store/actions/Game';

import { Carousel, Modal } from 'rsuite';

import GraphChallenge from '../dashboard/powerChallenge/GraphChallenge';
import Medal from '../medals/Medal';

const IconName = (name) =>{
    let icon = ""
    const iconSplit = name.split("fa").pop().split(/(?=[A-Z])/);
    if(iconSplit.length === 1){
        icon = iconSplit[0].toLowerCase();
    }else{
        icon = `${iconSplit[0].toLowerCase()}-${iconSplit[1].toLowerCase()}`;
    }
    return icon;
}  

const renderItem = ( item ) => {
    return (
        <div className='px-4 bg-black'>
            <div className='p-3 column-start rounded bg-dark-blue' style={{height: 350}}>
                <div className='column-start align-items-center'>
                    <div className="row-end w-100">
                        <div className='py-1 px-3 bg-blue rounded'>
                            <div className="txt-semibold txt-8 txt-white text-center">{item.points} xp</div>
                        </div>
                    </div>                
                    <p className='my-2 txt-white txt-semibold txt-12'>{item.id_medal ? item.name : item.title}</p>
                    <p className='txt-white txt-regular txt-10 text-wrap'>{item.description}</p>                
                </div>
                <div className='my-2' style={{height: 200}}>
                    {item.id_medal ?
                        <div className='row-center align-items-center h-100'>
                            <Medal 
                                scale={2}
                                iconSize={26}
                                icon={IconName(item.icon)} 
                                color={item.color} 
                                iconColor={item.icon_color}
                                won={true}/>
                        </div> :
                        <div className='bg-dark-blue2 p-2 rounded' style={{flex:1}}>
                            {GraphChallenge(item)}
                        </div>}
                </div>
            </div>  
        </div>      
    );
}

const NewMedal = (props) => {

    let newAchievements = props.newAchievements;      

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if(newAchievements.length) {                          
            setShowModal(true);
        }
    },[newAchievements]);    

    return (        
        <Modal 
            show={showModal}
            size={"xs"} 
            onHide={() => setShowModal(false)}> 
            {newAchievements.length ? 
                <div className='overflow-hidden bg-black' style={{borderRadius: 'inherit'}}>
                    <div className='row-end w-100 txt-white txt-semibold txt-20 px-3' onClick={() => {                            
                        props.setNewAchievements([]);                            
                        setShowModal(false);}}> x                        
                     </div>
                    <div className='pt-2'>
                        <p className='my-0 txt-green text-center txt-bold txt-18 p-0'>{props.language ? props.language.title_game_achievements : "¡Genial!"}</p>
                        <p className='txt-white text-center txt-semibold txt-14 pb-2'>{props.language ? props.language.text_game_achievements : "Tienes nuevos logros"}</p>   
                    </div>
                    <div className='py-3 bg-black'>                          
                        <Carousel className="carousel">
                            {newAchievements.map(value => renderItem(value))}                        
                        </Carousel>
                    </div>   
                </div>: null}                                              
        </Modal>
        )
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    newAchievements: state.game.newAchievements
})

const mapDispatchToProps = dispatch => ({
    setNewAchievements: payload => dispatch(gameActions.setNewAchievements(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewMedal)