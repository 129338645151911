import * as notifications from '../creators/Notifications';
import { GETData } from '../../services/WebServices';

export const setData = payload => {
    return {
        type: notifications.SET_NOTIFICATIONS_DATA,
        payload
    }
}

export const setPopupData = payload => {
    return {
        type: notifications.SET_NOTIFICATIONS_POPUP_DATA,
        payload
    }
}

export const setAvailableData = payload => {
    return {
        type: notifications.SET_NOTIFICATIONS_AVAILABLE_DATA,
        payload
    }
}

export const setActiveData = payload => {
    return {
        type: notifications.SET_NOTIFICATIONS_ACTIVE_DATA,
        payload
    }
}

export const setLoading = payload => {
    return {
        type: notifications.SET_NOTIFICATIONS_LOADING,
        payload
    }
}

export const setPopupLoading = payload => {
    return {
        type: notifications.SET_NOTIFICATIONS_POPUP_LOADING,
        payload
    }
}

export const loadNotificationsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        dispatch(setLoading(true));

        GETData(`notifications/${userId}/TOTAL`, "GET")
            .then(response => {
                if (response !== null) {
                    dispatch(setData(response));
                }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoading(false)));

    }
}

export const loadActiveNotificationsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        dispatch(setPopupLoading(true));

        GETData(`notifications/${userId}/ACTIVE`, "GET")
            .then(response => {
                if (response !== null) { dispatch(setPopupData(response)); }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setPopupLoading(false)));

    }
}

export const updateActiveNotificationsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        GETData(`notifications/${userId}`, "PUT")
            .then(() => dispatch(setPopupData([])))
            .catch(response => console.error(response));

    }
}
 
export const updateActiveNotificationAsync = (payload) => {
    return (dispatch, getState) => {

        const popupData = getState().notifications.popupData;

        const index = popupData
            .findIndex(notification => notification.id_alarm === payload.id_alarm);

        GETData(`notifications/${payload.id_alarm}/${payload.category}/?web=true`, "PUT")
            .then(() => {
                popupData.splice(index, 1);
                dispatch(setPopupData([...popupData]));
            })
            .catch(response => console.error(response));

    }
}