import * as neuPlus from '../creators/NEUPlus';
import { Alert } from 'rsuite';

import { GETData, SETData} from '../../services/WebServices';

export const setLocations = payload => {
    return {
        type: neuPlus.SET_LOCATIONS,
        payload
    }
}

export const setActiveLocation = payload => {
    return {
        type: neuPlus.SET_ACTIVE_LOCATION,
        payload
    }
}

export const deleteActiveLocation = payload => {
    return {
        type: neuPlus.DELETE_ACTIVE_LOCATION,
        payload
    }
}

export const setActiveDevices = payload => {
    return {
        type: neuPlus.SET_ACTIVE_DEVICES,
        payload
    }
}

export const setGraphLoading = payload => {
    return {
        type: neuPlus.SET_GRAPH_LOADING,
        payload
    }
}

export const updateDataGraphic = data => {
    return{
        type: neuPlus.UPDATE_DATA,
        payload: data
    }
}

export const runGraphic = () =>{
    return{
        type: neuPlus.RUN_GRAPHIC,
        payload: ""
    }
}

export const pauseGraphic = () =>{
    return{
        type: neuPlus.PAUSE_GRAPHIC,
        payload: ""
    }
}

export const setDataGraphic = () =>{
    return{
        type: neuPlus.SET_DATA_GRAPHIC,
        payload: ""
    }
}

export const zoomIn = () =>{
    return{
        type: neuPlus.ZOOM_IN,
        payload: ""
    }
}

export const zoomOut = () =>{
    return{
        type: neuPlus.ZOOM_OUT,
        payload: ""
    }
}

export const lengthData = (payload) =>{
    return{
        type: neuPlus.LENGTH_DATA,
        payload: payload
    }
}

export const typeGraphic = (payload) =>{
    return{
        type: neuPlus.UPDATE_TYPE_GRAPHIC,
        payload: payload
    }
}

export const updateDate = (payload) =>{
    return{
        type: neuPlus.UPDATE_CURRENT_DATE,
        payload: payload
    }
}

export const setDataFlag = (payload) =>{
    return{
        type: neuPlus.SET_DATA_FLAG,
        payload: payload
    }
}

export const rebootStates = () =>{
    return{
        type: neuPlus.REBOOT_STATES,
        payload: ""
    }
}

export const setDataNull = (payload) =>{
    return{
        type: neuPlus.SET_DATA_NULL,
        payload: payload
    }
}

export const setAppliances = (payload) =>{
    return{
        type: neuPlus.SET_APPLIANCES,
        payload: payload
    }
}

export const setTimeStampTags = (payload) =>{
    return{
        type: neuPlus.SET_TIMESTAMP_TAGS,
        payload: payload
    }
}

export const setFlagEventTag = (payload) =>{
    return{
        type: neuPlus.SET_FLAG_EVENT_TAG,
        payload: payload
    }
}

export const setEnablePopupTag = (payload) =>{
    return{
        type: neuPlus.SET_ENABLE_POPUP_TAG,
        payload: payload
    }
}

export const setEnableTags = (payload) =>{
    return{
        type: neuPlus.SET_ENABLE_TAGS,
        payload: payload
    }
}

export const deleteActiveDevices = () =>{
    return{
        type: neuPlus.DELETE_ACTIVE_DEVICES,
        payload: ""
    }
}


export const loadAppliances = () => {
    return (dispatch, getState) => {
        const userId = getState().login.user.id_user;
        const languageLocale = getState().shared.language.language_locale;
        const id_devices = getState().neuPlus.activeDevices.map(item => item.id_device);
        GETData(`neuplus/${userId}/${id_devices[0]}?language=${languageLocale}`, "GET")
        .then(response => {
            if (response !== null) {
                dispatch(setAppliances(response));
            }
            else{
                dispatch(setAppliances([]));
            }
        })
        .catch(response => console.error(response));
    }
}

export const insertLabelEvent = (payload) => {
    return (_dispatch, getState) => {
        var timeStampTags = getState().neuPlus.timeStampTags;
        var initial_time_stamp = timeStampTags[0];
        var final_time_stamp = timeStampTags.length < 2 ? null : timeStampTags[1];
        
        const id_devices = getState().neuPlus.activeDevices.map(item => item.id_device);

        id_devices.forEach(() =>{

            const tag = {
                id_user:getState().login.user.id_user,
                id_appliance_device: payload,
                initial_time_stamp: initial_time_stamp, 
                final_time_stamp: final_time_stamp
            };
            SETData(`neuplus/event-label`, "POST", tag)
                .catch(response => {console.error(response)});

        })
    
        
    }
}

export const insertAppliance = (payload) => {
    return async(_dispatch, getState) => {

        let language = getState().shared.language;
    
        const appliance = {
            name: payload.newDevice,
            id_device: getState().neuPlus.activeDevices[0].id_device,
        };
        await SETData(`neuplus/add-appliance`, "POST", appliance)
            .then(async response => {
                if (response !== null) {
                    if(response){
                        Alert.success(language.neuplus_success_new_device);
                    }
                    else{
                        Alert.error(language.neuplus_error_new_device);
                    }
                }
            })
            .catch(response => {
                console.error(response);
                Alert.error(language.neuplus_error_new_device);
            });
    }
}


export const setInquiryAppliances = (payload) =>{
    return{
        type: neuPlus.SET_APPLIANCES_INQUIRY,
        payload: payload
    }
}

export const setBaseAppliances = (payload) =>{
    return{
        type: neuPlus.SET_BASE_APPLIANCES,
        payload: payload
    }
}

export const setBrandsAppliances = (payload) =>{
    return{
        type: neuPlus.SET_BRANDS_APPLIANCES,
        payload: payload
    }
}

export const setTotalAppliancesInquiry = (payload) =>{
    return{
        type: neuPlus.SET_TOTAL_APPLIANCES_INQUIRY,
        payload: payload
    }
}

export const setLoadingAppliancesInquiry = (payload) =>{
    return{
        type: neuPlus.SET_LOADING_APPLIANCES_INQUIRY,
        payload: payload
    }
}

export const setFlagInquiry = (payload) =>{
    return{
        type: neuPlus.SET_FLAG_INQUIRY,
        payload: payload
    }
}

export const setFlagInquiryCreate = (payload) =>{
    return{
        type: neuPlus.SET_FLAG_INQUIRY_CREATE,
        payload: payload
    }
}

export const setDeviceTagEdit= (payload) =>{
    return{
        type: neuPlus.SET_DEVICE_TAG_EDIT,
        payload: payload
    }
}

export const getInquiryAppliances = (payload) => {
    return async(dispatch, getState) => {
    
        const sizePage = 20;

        const languageLocale = getState().shared.language.language_locale;

        let id_device;

        if(payload.module === "neuplus"){
            id_device = getState().neuPlus.activeDevices[0].id_device;
        }

        if(payload.module === "tags"){
            id_device = getState().neuPlus.deviceTagEdit;
        }

        const filterSearch = payload.filter.search !== "" ? `search=${payload.filter.search}` : "";

        const filterBase = payload.filter.id_base.length ? `&id_base=${payload.filter.id_base.toString()}` : "";

        const filterBrand = payload.filter.id_brand.length ? `&id_brand=${payload.filter.id_brand.toString()}` : "";

        dispatch(setLoadingAppliancesInquiry(true));

        await GETData(`neuplus/inquiry/${sizePage}/${payload.page}/${id_device}?${filterSearch}${filterBase}${filterBrand}&language=${languageLocale}`, "GET")
            .then(async response => {
                if (response !== null) {
                    if(response){
                        dispatch(setInquiryAppliances(response.data));
                        dispatch(setTotalAppliancesInquiry(response.total));
                    }
                }
                else{
                    dispatch(setInquiryAppliances([]));
                    dispatch(setTotalAppliancesInquiry(0));
                }
            })
            .catch(response => {
                console.error(response);
            })
            .finally(() => dispatch(setLoadingAppliancesInquiry(false)));
    }
}


export const getBaseAppliances = () => {
    return async(dispatch, getState) => {

        const languageLocale = getState().shared.language.language_locale;

        await GETData(`neuplus/inquiry/base_appliances?language=${languageLocale}`, "GET")
            .then(async response => {
                if (response !== null) {
                    dispatch(setBaseAppliances(response));
                }
                else{
                    dispatch(setBaseAppliances([]));
                }
            })
            .catch(response => {
                console.error(response);
            });
    }
}


export const getBrandsAppliances = () => {
    return async(dispatch, getState) => {
    
        await GETData(`neuplus/inquiry/brands_appliances`, "GET")
            .then(async response => {
                if (response !== null) {
                    dispatch(setBrandsAppliances(response));
                }
                else{
                    dispatch(setBrandsAppliances([]));
                }
            })
            .catch(response => {
                console.error(response);
            });
    }
}


export const insertNewApplianceInquiry = (payload) => {
    return async(_dispatch, getState) => {

        let language = getState().shared.language;

        let id_device;

        if(payload.module === "neuplus"){
            id_device = getState().neuPlus.activeDevices[0].id_device;
        }

        if(payload.module === "tags"){
            id_device = getState().neuPlus.deviceTagEdit;
        }
        
        const request = {
            base_name_id: payload.id_base,
            brand_id: payload.id_brand,
            brand: payload.brand,
            base: payload.base,
            reference: payload.reference,
            device_id: id_device,
            quantity: payload.quantity,
            custom_name: payload.custom_name,
            active_power: payload.specifications.active_power,
            reactive_power: payload.specifications.reactive_power,
            monthly_cons: payload.specifications.monthly_cons,
            monthly_reactive_cons: payload.specifications.monthly_reactive_cons,
            weight: payload.specifications.weight,
            height: payload.specifications.height,
            width: payload.specifications.width,
            depth: payload.specifications.depth,
        };

        await SETData(`neuplus/inquiry/addNewAppliance`, "POST", request)
            .then(async response => {
                if (response !== null) {
                    if(response){
                        Alert.success(language.neuplus_success_new_device);
                    }
                    else{
                        Alert.error(language.neuplus_error_new_device);
                    }
                }
            })
            .catch(response => {
                console.error(response);
                Alert.error(language.neuplus_error_new_device);
            });
    }
}

export const insertApplianceInquiry = (payload) => {
    return async(_dispatch, getState) => {

            let id_device;

            if(payload.module === "neuplus"){
                id_device = getState().neuPlus.activeDevices[0].id_device;
            }

            if(payload.module === "tags"){
                id_device = getState().neuPlus.deviceTagEdit;
            }

            const request = {
                appliance_id: payload.appliance.id_appliance,
                device_id: id_device,
                quantity: payload.appliance.quantity,
                custom_name: payload.appliance.custom_name
            };
            await SETData(`neuplus/inquiry/addAppliance`, "POST", request)
                .then(response => {
                    if(response){
                    }
                })
                .catch(response => {
                    console.error(response);
                });
    }
}


export const getInquiryAppliancesEdit = (payload) => {
    return async(dispatch, getState) => {
    
        const sizePage = 20;

        const languageLocale = getState().shared.language.language_locale;

        const filterSearch = payload.filter.search !== "" ? `search=${payload.filter.search}` : "";

        let id_device;

        if(payload.module === "neuplus"){
            id_device = getState().neuPlus.activeDevices[0].id_device;
        }

        if(payload.module === "tags"){
            id_device = getState().neuPlus.deviceTagEdit;
        }

        dispatch(setLoadingAppliancesInquiry(true));

        await GETData(`neuplus/inquiry/edit/${sizePage}/${payload.page}/${id_device}?${filterSearch}&language=${languageLocale}`, "GET")
            .then(async response => {
                if (response !== null) {
                    if(response){
                        dispatch(setInquiryAppliances(response.data));
                        dispatch(setTotalAppliancesInquiry(response.total));
                    }
                }
                else{
                    dispatch(setInquiryAppliances([]));
                    dispatch(setTotalAppliancesInquiry(0));
                }
            })
            .catch(response => {
                console.error(response);
            })
            .finally(() => dispatch(setLoadingAppliancesInquiry(false)));
    }
}


export const deleteInquiryAppliance = (payload) => {
    return (dispatch, getState) => {

        GETData(`neuplus/inquiry/delete/${payload}`, "POST")
            .then(response => {
                if (response !== null) {
                
                }
            })
            .catch(response => console.error(response));

    }
}


export const updateApplianceInquiry = (payload) => {
    return async(_dispatch, getState) => {

        let language = getState().shared.language;
        
        const request = {
            id_appliance: payload.id_appliance,
            id_appliance_device: payload.id_appliance_device,
            quantity: payload.quantity,
            custom_name: payload.custom_name,
            active_power: payload.active_power,
            reactive_power: payload.reactive_power,
            monthly_cons: payload.monthly_cons,
            monthly_reactive_cons: payload.monthly_reactive_cons,
            weight: payload.weight,
            height: payload.height,
            width: payload.width,
            depth: payload.depth,
        };

        await SETData(`neuplus/inquiry/update`, "POST", request)
            .then(async response => {
                if (response !== null) {
                    if(response){
                        Alert.success(language.inquiry_alert_edit_appliance_success);
                    }
                    else{
                        Alert.error(language.inquiry_alert_edit_appliance_error);
                    }
                }
            })
            .catch(response => {
                console.error(response);
                Alert.error(language.inquiry_alert_edit_appliance_error);
            });
    }
}