import * as reports from '../creators/Reports';
import { GETData, SETData, GETFile } from '../../services/WebServices';

import { getUnixTime } from 'date-fns';

import amplitude from 'amplitude-js';

export const setData = payload => {
    return {
        type: reports.SET_REPORTS_DATA,
        payload
    }
}

export const setAvailableData = payload => {
    return {
        type: reports.SET_REPORTS_AVAILABLE_DATA,
        payload
    }
}

export const setActiveData = payload => {
    return {
        type: reports.SET_REPORTS_ACTIVE_DATA,
        payload
    }
}

export const setLoading = payload => {
    return {
        type: reports.SET_REPORTS_LOADING,
        payload
    }
}

export const setAddresses = payload => {
    return {
        type: reports.SET_REPORTS_ADDRESSES,
        payload
    }
}

export const loadReportsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        dispatch(setLoading(true));

        GETData(`reports/${userId}`, "GET")
            .then(response => {
                if (response !== null) { dispatch(setData(response)); }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoading(false)));

    }
}

export const loadAddressesAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;

        GETData(`projects/${userId}`, "GET")
            .then(response => { if (response !== null) { dispatch(setAddresses(response)); } })
            .catch(response => console.error(response))
            .finally(() => {
            });
    }
}

export const deleteReportAsync = (payload) => {
    return (dispatch, getState) => {

        let reports = getState().reports.totalData;

        const index = reports
            .findIndex(report => report.id_report === payload);

        reports[index].deleting = true;

        dispatch(setData([...reports]));

        GETData(`reports/${payload}`, "DELETE")
            .then(() => {
                reports.splice(index, 1);
                dispatch(setData([...reports]));
            })
            .catch(response => console.error(response))

    }
}

export const createReportAsync = (payload) => {
    return (dispatch, getState) => {

        let reports = getState().reports.totalData;
        let userId = getState().login.user.id_user;

        const graphs = getState().home.graphs;

        const newReport = {
            id_user: userId,
            date: new Date(),
            report_name: payload.report_name,
            file: "Creando...",
            status: 0
        }

        reports = [newReport, ...reports];

        let inteval;
        let graphType;

        const from = getUnixTime(payload.from);
        const to = getUnixTime(payload.to);

        switch (payload.interval) {
            case 0:
                graphType = "LINEAR";
                inteval = "HOURS";
                break;
            case 1:
                graphType = "BARS";
                inteval = "DAYS";
                break;
            case 2:
                graphType = "BARS";
                inteval = "WEEK";
                break;
            case 3:
                graphType = "BARS";
                inteval = "MONTH";
                break;
            default:
                break;
        }

        const graphsOptions = payload.graphs
            .map(function (graph) {
                const graphOption = graphs.find(x => x.value === graph);
                return {
                    'graph_type': `${graph}_${inteval}`,
                    'label': graphOption.label,
                    'unit': graphOption.unit
                }
            });

        const addresses = payload.addresses
            .map(address => address[0]);

        const id_types = payload.addresses
            .map(address => address[5]);

        let projects = payload.addresses
            .map(function (address) {
                return ({ name: address[1], acronym: address[2], consumer: address[3], generator: address[4] });
            });

        projects = projects
            .filter((item, index) => projects
                .findIndex(i => i.name === item.name) === index);

        const data = {
            id_user: userId,
            report_name: payload.report_name,
            id_addresses: addresses,
            projects: projects,
            index_type: 'SUM',
            graphs_options: graphsOptions,
            graph_type: graphType,
            Format_type: payload.format_type,
            id_types: id_types,
            language: getState().shared.language.language_locale
        };

        dispatch(setData(reports));

        amplitude.getInstance().logEvent('Report Downloaded', {
            graphs: graphsOptions.map(g => g.graph_type).join(', ')
        });

        SETData(`reports/${from}/${to}`, "POST", data)
            .then(response => {
                if (response !== null) {

                    reports[0].id_report = response.reportid;
                    reports[0].file = response.filename;
                    reports[0].status = response.currentstatus;

                    dispatch(setData([...reports]));

                }
                else {
                    reports.splice(0, 1);
                    dispatch(setData([...reports]));
                }
            })
            .catch(response => {

                reports.splice(0, 1);
                dispatch(setData([...reports]));

                console.error(response);
            });
    }
}

export const downloadReportAsync = (payload) => {
    return (dispatch, getState) => {

        let reports = getState().reports.activeData;

        const index = reports
            .findIndex(report => report.id_report === payload.id_report);

        reports[index].downloading = true;

        dispatch(setActiveData([...reports]));

        let metadata = {
            id: payload.id_user,
            folder: "reports",
            name: payload.file
        }

        GETFile("files", "PUT", metadata)
            .then((result) => {

                if (result !== null) {

                    const reader = result.body.getReader();

                    return new ReadableStream({

                        start(controller) {

                            return pump();

                            async function pump() {

                                const { done, value } = await reader
                                    .read();
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            }
                        }
                    });

                }

            })
            .then(stream => new Response(stream, { headers: { "Content-Type": `application/${payload.file.split('.').pop()}` } }))
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {

                const link = document.createElement("a");

                link.href = url;
                link.download = payload.file;
                link.click();

            })
            .catch(response => console.error(response))
            .finally(() => {
                reports[index].downloading = false;
                dispatch(setActiveData([...reports]));
            });
    }
}