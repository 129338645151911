import * as tags from '../creators/Tags';
import { GETData } from '../../services/WebServices';

export const setData = payload => {
    return {
        type: tags.SET_TAGS_DATA,
        payload
    }
}

export const setAvailableData = payload => {
    return {
        type: tags.SET_TAGS_AVAILABLE_DATA,
        payload
    }
}

export const setActiveData = payload => {
    return {
        type: tags.SET_TAGS_ACTIVE_DATA,
        payload
    }
}

export const setLoading = payload => {
    return {
        type: tags.SET_TAGS_LOADING,
        payload
    }
}

export const setLoadingRecords = payload => {
    return {
        type: tags.SET_TAGS_LOADING_RECORDS,
        payload
    }
}

export const setLoadingPredictions = payload => {
    return {
        type: tags.SET_TAGS_LOADING_PREDICTIONS,
        payload
    }
}

export const setRecords = payload => {
    return {
        type: tags.SET_TAGS_RECORDS,
        payload
    }
}

export const setPredictions = payload => {
    return {
        type: tags.SET_TAGS_PREDICTIONS,
        payload
    }
}

export const loadAppliancesTagsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().login.user.id_user;
        const languageLocale = getState().shared.language.language_locale;
        dispatch(setLoading(true));
        
        GETData(`tags/appliances/${userId}?language=${languageLocale}`, "GET")
            .then(response => {
                if (response !== null) {
                    dispatch(setData(response));
                }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoading(false)));

    }
}

export const loadRecordsApplianceTags = (payload) => {
    return (dispatch, getState) => {

        dispatch(setLoadingRecords(true));
        GETData(`tags/records/${payload}`, "GET")
            .then(response => {
                if (response !== null) {
                    dispatch(setRecords(response));
                }
                else{
                    dispatch(setRecords([]));
                }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoadingRecords(false)));

    }
}


export const loadPredictionTagsAsync = (payload) => {
    return (dispatch, getState) => {

        dispatch(setLoadingPredictions(true));

        if (payload.type === 3){
            GETData(`tags/prediction/${payload.from}/${payload.type}/${payload.devices}/${payload.to}`, "GET")
                .then(response => {
                    if (response !== null) {
                        dispatch(setPredictions(response));
                    }
                })
                .catch(response => console.error(response))
                .finally(() => dispatch(setLoadingPredictions(false)));

        }
        else{
            GETData(`tags/prediction/${payload.from}/${payload.type}/${payload.devices}`, "GET")
                .then(response => {
                    if (response !== null) {
                        dispatch(setPredictions(response));
                    }
                    else{
                        dispatch(setPredictions([]));
                    }
                })
                .catch(response => console.error(response))
                .finally(() => dispatch(setLoadingPredictions(false)));
        }
    }
}


export const deleteEventLabel = (payload) => {
    return (dispatch, getState) => {

        GETData(`tags/delete/${payload}`, "POST")
            .then(response => {
                if (response !== null) {
                
                }
            })
            .catch(response => console.error(response));

    }
}