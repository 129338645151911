import React from 'react';
import { connect } from 'react-redux';

import * as sharedActions from '../../store/actions/Shared';

import { Link } from 'react-router-dom';

import { Tooltip, Whisper } from 'rsuite';

const MenuItem = (props) => (
    <Link to={props.path}
        className={`row-start align-items-center c-pointer my-3 mx-4 txt-${props.enable ? "white" : "light-blue"}`}
        onClick={props.onClick}>
        <div className="meun-icon d-flex row-center align-items-center">
            {props.img ? <img src={props.img} alt="neuplus" height={18} width={18}></img> :
                <i className={`fas fa-${props.icon} txt-12 c-pointer`}></i>}
        </div>
        <div className="meun-text d-flex row-start align-items-center">
            <div className="txt-regular txt-12 ml-4 c-pointer">{props.text}</div>
        </div>
        {!props.enable ?
            <Whisper
                placement="right"
                trigger="hover"
                speaker={<Tooltip>{props.language.tooltip_menu_pending}</Tooltip>}>
                <i className="fas fa-clock txt-green txt-12 ml-4 c-pointer"></i>
            </Whisper> : null}
    </Link>
)

const mapStateToProps = (state, ownProps) => ({
    language: state.shared.language,
    icon: ownProps.icon,
    text: ownProps.text,
    path: ownProps.path,
    enable: ownProps.enable,
    img: ownProps.img
})

const mapDispatchToProps = (dispatch) => ({
    setMenu: payload => dispatch(sharedActions.setMenu(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
