import React, { Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';

import * as neuplusActions from '../../store/actions/NEUPlus';

import { ResponsiveLine } from '@nivo/line';

import { SETData } from '../../services/WebServices';

import subDays from 'date-fns/subDays';

import { format } from 'date-fns';

import { Loader, SelectPicker, CheckPicker, DateRangePicker, Tooltip, Whisper, Modal, Input, InputGroup, Alert } from 'rsuite';

import AWS from 'aws-sdk';

const { afterToday } = DateRangePicker;


const formatData = (data) => {
    let unit;
    if(currentTypeGraphic === 1){
        unit = "W";
    }
    if(currentTypeGraphic === 2){
        unit = "VAr"
    }
    if(currentTypeGraphic === 3 || currentTypeGraphic === 6 || currentTypeGraphic === 9){
        unit = ""
    }
    if(currentTypeGraphic === 4 || currentTypeGraphic === 7 || currentTypeGraphic === 10){
        unit = "V"
    }
    if(currentTypeGraphic === 5 || currentTypeGraphic === 8 || currentTypeGraphic === 11){
        unit = "A"
    }
    try{
        if (data < 1000) {
            return (String((data).toFixed(2)) + " " + unit);
        }
        else {
            return (String((data / 1000).toFixed(2)) + " k" + unit);
        }
    }
    catch (err){
        console.log(err);
    }
}

const indicatorEnergy = ({ series, height }) => {

    let finalPoint = 0;
    let finalPosition;
    let point_y = height - 240;

    try {
        finalPosition = series[0].data.slice(-1)[0].position;
        series.map(value => finalPoint = finalPoint + value.data.slice(-1)[0].data.y)
    }
    catch (error) { finalPosition = { "x": 2000, "y": 1000 }; finalPoint = { "x": "", "y": "" } };

    return (
        <g >
            <rect x={finalPosition.x - 55} y="-78" rx="15" ry="15" width="110" height="35"
                sfill="red" stroke="black" strokeWidth="5" opacity="1" />
            <text x={finalPosition.x - 40} y="-55" fill="white">{formatData(finalPoint)}</text>
            <line x1={finalPosition.x} y1={-45} x2={finalPosition.x} y2={point_y} stroke="black" strokeWidth="2" strokeDasharray="8" />
            {series.map((value, index) => (
                <circle key={index} cx={finalPosition.x} cy={value.data.length? 
                value.data.slice(-1)[0].position.y : 1000} r="5" stroke="white" strokeWidth="2" fill={color[index]} />
            ))}
        </g>
        )
}

const subindicatorsEnergy = ({ series, height }) => {

    var indexPosition = [
        { "x": 2000, "y": 1000 },
        { "x": 2000, "y": 1000 },
        { "x": 2000, "y": 1000 },
        { "x": 2000, "y": 1000 }
    ];

    var indexValue = [
        { "x": "", "y": 0 },
        { "x": "", "y": 0 },
        { "x": "", "y": 0 },
        { "x": "", "y": 0 }
    ]

    let offsetLine = 240;

    try {
        let offset = Math.ceil(series[0].data.length * 0.11);
        let length = series[0].data.length - offset >= 0 ? series[0].data.length - offset : 0;
        let increment = Math.ceil(length / 4);
        if (increment !== 0) {
            for (let i = offset; i < series[0].data.length - 1; i = i + increment) {
                let yValue = Math.min(...series.map(value => value.data[i].position.y));
                let dataValue = series.map(value => value.data[i].data.y).reduce((a, b) => a + b, 0);
                indexPosition[(i - offset) / increment].x = series[0].data[i].position.x;
                indexPosition[(i - offset) / increment].y = yValue;
                indexValue[(i - offset) / increment].y = dataValue;
            }
        }
    }
    catch (error) {
        console.log(error);
    };

    return (
        <g>
            <line x1={indexPosition[0].x} y1={0} x2={indexPosition[0].x} y2={height - offsetLine} stroke="#A1C52A" strokeWidth="2" strokeDasharray="8" />
            <rect x={indexPosition[0].x - 40} y={indexPosition[0].y - 35} rx="1" ry="1" width="92" height="25"
                sfill="red" stroke="black" strokeWidth="5"/>
            <text x={indexPosition[0].x - 30} y={indexPosition[0].y - 20} fill="white" fontFamily="semi-bold">
                {formatData(indexValue[0].y)}
            </text>
            <line x1={indexPosition[1].x} y1={0} x2={indexPosition[1].x} y2={height - offsetLine} stroke="#A1C52A" strokeWidth="2" strokeDasharray="8" />
            <rect x={indexPosition[1].x - 40} y={indexPosition[1].y - 35} rx="1" ry="1" width="92" height="25"
                sfill="red" stroke="black" strokeWidth="5"/>
            <text x={indexPosition[1].x - 30} y={indexPosition[1].y - 20} fill="white" fontFamily="semi-bold">
                {formatData(indexValue[1].y)}
            </text>
            <line x1={indexPosition[2].x} y1={0} x2={indexPosition[2].x} y2={height - offsetLine} stroke="#A1C52A" strokeWidth="2" strokeDasharray="8" />
            <rect x={indexPosition[2].x - 40} y={indexPosition[2].y - 35} rx="1" ry="1" width="92" height="25"
                sfill="red" stroke="black" strokeWidth="5"/>
            <text x={indexPosition[2].x - 30} y={indexPosition[2].y - 20} fill="white" fontFamily="semi-bold">
                {formatData(indexValue[2].y)}
            </text>
            <line x1={indexPosition[3].x} y1={0} x2={indexPosition[3].x} y2={height - offsetLine} stroke="#A1C52A" strokeWidth="2" strokeDasharray="8" />
            <rect x={indexPosition[3].x - 40} y={indexPosition[3].y - 35} rx="1" ry="1" width="92" height="25"
                sfill="red" stroke="black" strokeWidth="5"/>
            <text x={indexPosition[3].x - 30} y={indexPosition[3].y - 20} fill="white" fontFamily="semi-bold">
                {formatData(indexValue[3].y)}
            </text>
        </g>)
}

const color = [
    "#A1C52A",
    "#6DB639",
    "#CAD515",
    "#ADD081",
    "#27AA58",
    "#619630",
    "#1C7131",
    "#2F5123",
    "#184E24",
    "#202D19",
    "#31B08A",
    "#009C75",
    "#007866",
    "#006F83",
    "#006F83",
    "#42ACE2",
    "#2599A5",
    "#006DB1",
    "#026577",
    "#20335F"
];

let positions = [];
let positionsGraphic = [{ 'x': 0, 'y': 30000 }, { 'x': 30000, 'y': 30000 }];
let pointsTimeStamp = [];

const HighlightPoint = (props) => {
    
    const { point, series, height } = props;

    if (!point) { return null;}

    let index;

    const { data } = point;

    try{
        let P = series[0].data.filter(element => String(element.data.date) + " " + String(element.data.x) === String(data.date) + " " + String(data.x));

        if (positions.length >= 2 && P.length !== 0) {

            const dif1 = Math.abs(P[0].position.x - positionsGraphic[0].x);

            const dif2 = Math.abs(P[0].position.x - positionsGraphic[1].x);

            index = positions.findIndex(x => x.data.x === P[0].data.x)

            if (index === -1) {
                if (dif1 < dif2) {
                    positions[0] = P[0];
                }
                if (dif1 > dif2) {
                    positions[1] = P[0];
                }
            }
        }
        else {
            if (P.length) {

                index = positions.findIndex(x => x.data.x === P[0].data.x);

                if (index === -1) { positions.push(P[0]); }
            }
        }
        pointsTimeStamp = positions.map(element => String(element.data.date) + " " + String(element.data.x));
        positions.forEach((value, index) => {

            const pointFiltered = series[0].data.filter(element => String(element.data.date) + " " + String(element.data.x) === String(value.data.date) + " " + String(value.data.x));

            if (pointFiltered.length !== 0) {
                positionsGraphic[index] = pointFiltered[0].position;
            }
            else {
                positionsGraphic[index] = { 'x': 0, 'y': 30000 };
            }
        });
        positionsGraphic.sort(function (a, b) {
            if (a.x > b.x) {
                return 1;
            }
            if (a.x < b.x) {
                return -1;
            }
            return 0;
        });
        var finalPosition = series[0].data.slice(-1)[0].position.x - positionsGraphic[1].x;
    }
    catch (err){console.log(err)}
    return (
        <g>
            <rect x={0} y={0} rx="0" ry="0" width={positionsGraphic[0].x} height={height - 240}
                sfill="black" stroke="black" strokeWidth="5" opacity="0.3" />
            <rect x={positionsGraphic[1].x} y={0} rx="0" ry="0" width={positionsGraphic[1].x >= 30000 ? 0 : finalPosition} height={height - 240}
                sfill="black" stroke="black" strokeWidth="5" opacity="0.3" />
        </g>
    );
};


const downloadBlob = (blob, name) => {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.dispatchEvent(
        new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
        })
    );
    document.body.removeChild(link);
}


const handleDownloadCSV = async (path, name, type, setStepDownload) => {
    AWS.config.update({
        accessKeyId: 'AKIA3MNLSUCN4VGVIRVF',
        secretAccessKey: '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'
    });
    const s3 = new AWS.S3();
    const params = {
      Bucket: 'neu-plus',
      Key: path,
    };
    
    await s3.getObject(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        let csvBlob = new Blob([data.Body.toString()], {
          type: `text/${type};charset=utf-8;`,
        });
        downloadBlob(csvBlob, name);
        setStepDownload(0);
      }
    });

}

const exportFile = async (payload, setStepDownload) => {
    const endpoint = 'https://g5c5659uk1.execute-api.us-east-2.amazonaws.com/PDN/file';
    setStepDownload(1);
    await SETData('', 'POST', payload, endpoint, true)
    .then(async response => {
        if(response.statusCode === 200){
            const path = response.body;
            const name = path.split("/")[2];
            const type = name.split(".")[1];
            setStepDownload(2);
            await handleDownloadCSV(path, name, type, setStepDownload);
        }
    })
    .catch(response => {
        console.error(response);
    });
}


const showError = (payloadExport, props) => {

    if (payloadExport.nameFile === null || payloadExport.nameFile === "") {
        Alert.error(props.language.label_modal_export_neuplus_invalid_name);
        return;
    }

    if (payloadExport.request === null) {
        Alert.error(props.language.label_modal_export_neuplus_invalid_type);
        return;
    }

    if (payloadExport.startTime === null) {
        Alert.error(props.language.label_modal_export_neuplus_invalid_date);
        return;
    }

}


const validate = (payloadExport) => {

    if (payloadExport.nameFile === null || payloadExport.nameFile === "") {
        return false;
    }

    if (payloadExport.request === null) {
        return false;
    }

    if (payloadExport.startTime === null) {
        return false;
    }

    return true

}

let currentTypeGraphic = 1;

const Graph = (props) => {

    let dataGraphic = props.dataGraphic;

    let dataGraphicOrganized = [];

    currentTypeGraphic = props.graphicType;

    props.dataGraphic.map(element => element.devices_id.map(value => dataGraphicOrganized.push(value)));

    let dataGraphicStructured = props.activeDevices.map(value => {
            var object = {"id": value.address_identifier,
                        "data": dataGraphicOrganized.filter(element => element.device_id === String(value.address_identifier))};
            return object;
        });

    let enablePopUpTag = props.enablePopUpTag;

    let flagEventTag = props.flagEventTag;

    let setTimeStampTags = props.setTimeStampTags;
    let setFlagEventTag = props.setFlagEventTag;

    const formatTypes = [
        {
            value: 'CSV',
            label: 'CSV'
        },
        {
            value: 'HTML',
            label: 'HTML (Gráfica interactiva)'
        }
    ];

    const payloadToExportInit = {
        request: "CSV",
        nameFile: null,
        startTime: null,
        endTime: null,
        deviceId: null,
        projectName: ""
    }

    const [clickedPoint, setClickedPoint] = useState(null);
    const [showModalExport, setShowModalExport] = useState(false);
    const [payloadToExport, setPayloadToExport] = useState(payloadToExportInit);
    const [stepDownload, setStepDownload] = useState(0);
    
    useEffect(() => {
        if (document.getElementById("scroll-container") !== null)
            document.getElementById("scroll-container").scrollLeft = 1000;
    }, [dataGraphic.length]);

    useEffect(() => {

        setTimeStampTags(pointsTimeStamp);

        if (flagEventTag) {
            positions = [];
            positionsGraphic = [{ 'x': 0, 'y': 30000 }, { 'x': 30000, 'y': 30000 }];
            setClickedPoint(null);
            setFlagEventTag(false);
            pointsTimeStamp = [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.flagEventTag, positions, positions.length]);

    useEffect(() => {
        if(props.activeDevices.length){
            setPayloadToExport({ ...payloadToExport, deviceId: props.activeDevices[0].id_device, projectName: props.activeLocations[0].name });
        }
    }, [props.activeDevices])

    useEffect(() => {
        if(stepDownload === 0){
            setShowModalExport(false);
        }
    }, [stepDownload])
    

    return (
        <Fragment>
            <Modal
                size="xs"
                show={showModalExport}
                onExited={async () => {setPayloadToExport({ ...payloadToExport, nameFile: null, startTime: null, endTime: null });}}
                onHide={() => {
                    setShowModalExport(false);
                    setPayloadToExport(payloadToExportInit);
                }}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{props.language.title_home_popup_export}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {(props.activeDevices.length === 1) ?
                        <div className="d-flex flex-column p-3">
                            <div className="col-12 my-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_date_modal_export_neuplus}</div>
                                <DateRangePicker
                                    oneTap
                                    showOneCalendar
                                    id="home-graph-date-picker"
                                    size="lg"
                                    format={"YYYY-MM-DD"}
                                    placeholder={props.language.label_placeholder_date_modal_export_neuplus}
                                    ranges={[
                                        {
                                          label: 'today',
                                          value: [new Date(), new Date()]
                                        },
                                        {
                                          label: 'yesterday',
                                          value: [subDays(new Date(), 1), subDays(new Date(), 1)]
                                        }
                                      ]}
                                    locale={props.dateLocale}
                                    cleanable={false}
                                    disabledDate={afterToday()}
                                    onChange={value => {
                                        const date = format(value[0], "yyyy-MM-dd")
                                        setPayloadToExport({ ...payloadToExport, startTime: `${date} 00:00:00`, endTime: `${date} 23:59:59` });
                                    }}
                                />
                            </div>
                            <div className="col-12 my-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_name_modal_export_neuplus}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-clipboard txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.label_name_example_modal_export_neuplus}
                                        maxLength={30}
                                        onChange={value => setPayloadToExport({ ...payloadToExport, nameFile: value })} />
                                </InputGroup>
                            </div>
                            <div className="col-12 my-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_reports_popup_format}</div>
                                <SelectPicker
                                    className="w-100"
                                    defaultValue={payloadToExport.request}
                                    searchable={false}
                                    cleanable={false}
                                    data={formatTypes}
                                    onChange={value => setPayloadToExport({ ...payloadToExport, request : value })}
                                />
                            </div>
                            <div className="text-wrap txt-semibold txt-12 txt-light-blue text-center py-3">{stepDownload === 1 ? props.language.label_creating_modal_export_neuplus : stepDownload === 2 ? props.language.label_downloading_modal_export_neuplus : null}</div>
                        </div> :
                        <div className="text-wrap txt-semibold txt-12 txt-light-blue text-center py-3">{props.language.label_empty_export_neuplus}</div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 w-auto mr-4 bg-${validate(payloadToExport) ? "blue" : "light-blue"}`}
                            onClick={async () => {
                                if(validate(payloadToExport) && stepDownload === 0){
                                    await exportFile(payloadToExport, setStepDownload);
                                }
                                else{
                                    showError(payloadToExport, props)
                                }
                            }}>
                            {stepDownload === 0 ? props.language.title_home_popup_button_export : <Loader inverse />}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <div className="row-between align-items-center flex-grow-1 w-100 h-100">
                <div className="position-relative row-center overflow-hidden h-100 w-100">
                    {!props.activeDevices.length ?
                        <div className="row-center align-items-center w-100 h-100 pr-3">
                            <i className="fas fa-plus-circle txt-20 txt-light-blue" />
                            <div className="txt-20 txt-bold txt-light-blue mx-3 w-auto">{props.language.title_home_select_location}</div>
                        </div> :
                        props.dataNull ?
                            <div className="row-center align-items-center w-100 h-100 pr-3">
                                <i className="fas fa-database txt-20 txt-light-blue" />
                                <div className="txt-20 txt-bold txt-light-blue mx-3 w-auto">{props.language.neuplus_nodata_available}</div>
                            </div> :
                            <Fragment>
                                <div id="neuplus-graph-zoom-container" className="column-center align-items-center zindex-3">
                                    <i className="fa fa-search-plus neuplus-graph-zoom txt-12 txt-white bg-black c-pointer p-2 m-2" onClick={() => props.zoomIn()}></i>
                                    <i className="fa fa-search-minus neuplus-graph-zoom txt-12 txt-white bg-black c-pointer p-2 m-2" onClick={() => props.zoomOut()}></i>
                                    <i className="fa fa-file-download neuplus-graph-zoom txt-12 txt-white bg-black c-pointer p-2 m-2" onClick={() => setShowModalExport(true)}></i>
                                    
                                </div>
                                <div id="scroll-container" className="position-absolute overflow-auto h-100 w-100">
                                    <div id="neuplus-graph-container" className="h-100 overflow-hidden" style={{ width: 900 }}>
                                        {dataGraphic.length ?
                                            <Fragment>
                                            <ResponsiveLine
                                                data={dataGraphicStructured}
                                                xScale={{ type: 'point' }}
                                                yScale={{ type: 'linear', min: props.minValue, max: "auto", stacked: false, reverse: false }}
                                                margin={{ top: 80, right: 90, bottom: 160, left: 10 }}
                                                curve="catmullRom"
                                                colors={color}
                                                areaOpacity={0.2}
                                                lineWidth={2}
                                                enableArea={true}
                                                enablePoints={false}
                                                enableGridX={false}
                                                enableGridY={false}
                                                useMesh={true}
                                                animate={false}
                                                axisTop={null}
                                                axisRight={null}
                                                axisLeft={null}
                                                axisBottom={{
                                                    orient: 'bottom',
                                                    tickSize: 3,
                                                    tickPadding: 3,
                                                    tickRotation: 90,
                                                    tickValues: props.xValues,
                                                }}
                                                theme={{
                                                    fontSize: 12,
                                                    fontFamily: "semi-bold"
                                                }}
                                                tooltip={({ point }) => (
                                                    <div className="column-between align-items-center bg-white p-2 shadow" style={{ borderRadius: 6 }}>
                                                        <div className="row-start align-items-center w-100 mr-1 ml-auto">
                                                            <div style={{ background: point.serieColor, height: '12px', width: '12px' }} />
                                                            <div className="row-between align-items-center txt-dark-blue txt-semibold txt-10">
                                                                <div className="mx-2 txt-dark-blue txt-10 txt-semibold">{point.data.x} - {formatData(point.data.y)} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                legends={[
                                                    {
                                                        anchor: 'bottom-left',
                                                        direction: 'row',
                                                        justify: false,
                                                        translateX: 0,
                                                        translateY: 77,
                                                        itemsSpacing: 70,
                                                        itemDirection: 'left-to-right',
                                                        itemWidth: 100,
                                                        itemHeight: 12,
                                                        itemOpacity: 1,
                                                        symbolSize: 10,
                                                        symbolShape: 'square',
                                                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                                        effects: [
                                                            {
                                                                on: 'hover',
                                                                style: {
                                                                    itemOpacity: 1
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]}
                                                onClick={value => { if (enablePopUpTag) { setClickedPoint(value) } }}
                                                isInteractive={true}
                                                layers={
                                                    [
                                                        'grid',
                                                        'markers',
                                                        'axes',
                                                        'areas',
                                                        'crosshair',
                                                        'lines',
                                                        'points',
                                                        'slices',
                                                        'legends',
                                                        indicatorEnergy,
                                                        subindicatorsEnergy,
                                                        (props) => (
                                                            <HighlightPoint
                                                                {...props}
                                                                onClick={() => { setClickedPoint(null) }}
                                                                point={enablePopUpTag ? clickedPoint : null}
                                                                enablePopUpTag={enablePopUpTag}
                                                            />
                                                        ),
                                                        'mesh'
                                                    ]
                                                }>
                                            </ResponsiveLine>
                                            </Fragment>
                                            : <div></div>}
                                    </div>
                                </div>
                            </Fragment>}
                </div>
            </div>
        </Fragment>)
}

const mapStateToProps = state => {
    var data;
    var xValues = [];
    var increment;
    var lenghtZoom;
    var datazoom;

    try {
        data = JSON.parse(state.neuPlus.data_graphic);

        lenghtZoom = Math.round(Object.keys(data).length / state.neuPlus.zoom);

        datazoom = data.slice(-lenghtZoom);

        increment = Math.ceil(Object.keys(datazoom).length / 15);

        for (let i = 1; i <= Object.keys(datazoom).length; i = i + increment) {
            if (i - 1 !== Object.keys(datazoom).length - 1) {
                xValues.push(datazoom[i - 1].x)
            }
        }
        xValues.push(datazoom[Object.keys(datazoom).length - 1].x);

    }

    catch (err) { data = []; datazoom = [] };

    return {
        language: state.shared.language,
        dataGraphic: datazoom,
        run: state.neuPlus.run,
        zoom: state.neuPlus.zoom,
        xValues: xValues,
        activeLocations: state.neuPlus.activeLocations,
        activeDevices: state.neuPlus.activeDevices,
        minValue: state.neuPlus.minValue,
        dataNull: state.neuPlus.dataNull,
        graphicType: state.neuPlus.graphicType,
        flagEventTag: state.neuPlus.flagEventTag,
        enablePopUpTag: state.neuPlus.enablePopUpTag,
        dateLocale: state.home.dateLocale,
    }
}

const mapDispatchToProps = dispatch => ({
    zoomIn: () => dispatch(neuplusActions.zoomIn()),
    zoomOut: () => dispatch(neuplusActions.zoomOut()),
    setTimeStampTags: payload => dispatch(neuplusActions.setTimeStampTags(payload)),
    setFlagEventTag: payload => dispatch(neuplusActions.setFlagEventTag(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Graph);