import React from 'react';
import { connect } from 'react-redux';

import * as homeActions from '../../../store/actions/Home';

import { getAnalytics, logEvent } from "firebase/analytics";

import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const DashboardRange = (props) => {

    const setEventAmplitud = (interval) => {
        amplitude.getInstance().logEvent('Report Time Period Changed', {
            inverval: interval
        });
    };
    
    return (
        <div id="home-graph-range-picker-container" className="position-relative bg-clear-gray rounded-pill p-2">
            <div id="home-graph-range-picker" className="position-absolute rounded-pill bg-blue col-3" style={{ left: `${25 * props.graphInterval}%` }}></div>
            <div className="row-between align-items-center w-100">
                <div className={`home-graph-range-picker-option txt-bold txt-12 txt-${props.graphInterval === 0 ? "white" : "light-blue"} text-center c-pointer h-100 col-3`}
                    onClick={() => {

                        logEvent(analytics, 'click_home_hour', {
                            id_user: props.user.id_user,
                            user_name: `${props.user.name} ${props.user.lastname}`
                        });

                        setEventAmplitud('HOURLY');

                        props.setGraphInterval(0);

                        if (props.activeGraphs.length && props.activeAddresses.length) { setTimeout(() => { props.loadGraphAsync(); }, 250) }

                    }}>{props.language.title_home_hourly}</div>
                <div
                    className={`home-graph-range-picker-option txt-bold txt-12 txt-${props.graphInterval === 1 ? "white" : "light-blue"} text-center c-pointer h-100 col-3`}
                    onClick={() => {

                        logEvent(analytics, 'click_home_day', {
                            id_user: props.user.id_user,
                            user_name: `${props.user.name} ${props.user.lastname}`
                        });

                        setEventAmplitud('DAILY');

                        props.setGraphInterval(1);
                        if (props.activeGraphs.length && props.activeAddresses.length) { setTimeout(() => { props.loadGraphAsync(); }, 250) }
                    }}> {props.language.title_home_daily}</div>
                <div
                    className={`home-graph-range-picker-option txt-bold txt-12 txt-${props.graphInterval === 2 ? "white" : "light-blue"} text-center c-pointer h-100 col-3`}
                    onClick={() => {

                        logEvent(analytics, 'click_home_week', {
                            id_user: props.user.id_user,
                            user_name: `${props.user.name} ${props.user.lastname}`
                        });

                        setEventAmplitud('WEEKLY');

                        props.setGraphInterval(2);

                        if (props.activeGraphs.length && props.activeAddresses.length) { setTimeout(() => { props.loadGraphAsync(); }, 250) }
                    }}>{props.language.title_home_weekly}</div>
                <div
                    className={`home-graph-range-picker-option txt-bold txt-12 txt-${props.graphInterval === 3 ? "white" : "light-blue"} text-center c-pointer h-100 col-3`}
                    onClick={() => {

                        logEvent(analytics, 'click_home_month', {
                            id_user: props.user.id_user,
                            user_name: `${props.user.name} ${props.user.lastname}`
                        });

                        setEventAmplitud('MONTHLY');

                        props.setGraphInterval(3);

                        if (props.activeGraphs.length && props.activeAddresses.length) { setTimeout(() => { props.loadGraphAsync(); }, 250) }
                    }}>{props.language.title_home_monthly}</div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    language: state.shared.language,
    graphInterval: state.home.graphInterval,
    activeAddresses: state.home.activeAddresses,
    activeGraphs: state.home.activeGraphs
})

const mapDispatchToProps = dispatch => ({
    loadGraphAsync: () => dispatch(homeActions.loadGraphAsync()),
    setGraphInterval: payload => dispatch(homeActions.setGraphInterval(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRange)
