import React, { Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';

import * as gameActions from '../../../../store/actions/Game';

import Survey from '../../shared/Survey';
import LoaderMore from '../../shared/LoaderMore';
import BubbleChallenge from './BubbleChallenge';

import { Loader } from 'rsuite';
import { Transition } from 'react-transition-group';

const Challenges = (props) =>{
    
        let type = props.type;
        let challenges = props.challenges;

        const loadGamesPcChallengesAsync = props.loadGamesPcChallengesAsync;

        const [surveyVisible, setSurveyVisible] = useState(false);
        const [init, setInit] = useState(true);

        useEffect(() => loadGamesPcChallengesAsync(), [loadGamesPcChallengesAsync, type]);

        useEffect(() => {
            if(init && challenges.length) {
                setSurveyVisible(props.survey);                
                setInit(false);
            }
        },[challenges, init, setInit, setSurveyVisible]);   

        return(
            <Fragment>
                <Survey visible={surveyVisible} setVisible={setSurveyVisible} idGame={props.id_game}/>
                <div className='column-center align-items-center'>
                    <div className="game-picker-container position-relative bg-dark rounded-pill row-center align-items-center">
                        <div id="game-picker" className="position-absolute rounded-pill bg-blue col-6" style={{ left: `${50 * type}%` }}></div>
                        <div className="row-between align-items-center w-100">
                            <div className={`home-graph-range-picker-option txt-bold txt-10 txt-${!type ? "white" : "light-blue"} text-center c-pointer h-100 col-6`}
                                onClick={()=>props.setType(0)}>{props.language.title_tab_game_power_challenge_current}</div>
                            <div
                                className={`home-graph-range-picker-option txt-bold txt-10 txt-${type ? "white" : "light-blue"} text-center c-pointer h-100 col-6`}
                                onClick={()=> props.setType(1)}>{props.language.title_tab_game_power_challenge_historic}</div>                                        
                        </div>
                    </div>                 
                    <Transition
                        in={props.loadingInitialChallenge}
                        timeout={100}>
                        {state => (
                            <div className={`trans-fade trans-fade-${state} position-absolute bg-black w-100 h-100 top-0 left-0 zindex-1`} >
                                <div className="column-center align-items-center w-100 h-100">
                                    <Loader inverse center size="md" content={props.language.loading_message}/>
                                </div>
                            </div>
                        )}
                    </Transition>
                    {props.challenges.length ?
                        <div className='w-100 my-2'>
                            {props.challenges.map((challenge, i) =>
                                <BubbleChallenge
                                    key={i}
                                    type={type}
                                    challenge={challenge}
                                    survey={props.survey} 
                                />
                            )}  
                            {props.nextPage && <LoaderMore loading={props.loadingNext} val={props.nextPage} fun={props.loadGamesPcNextAsync} />}                  
                        </div> :
                        <div className='row-center align-items-center col-12 txt-bold txt-18 txt-light-blue mt-5 p-1'>
                            {props.language.text_game_pc_empty_challenges}
                        </div>
                    }
                </div>
            </Fragment>
        )
    }

const mapStateToProps = (state) => ({
    language: state.shared.language,
    type: state.game.gamesPcType,
    challenges: state.game.gamesPcChallenges,
    nextPage: state.game.gamesPcNextPage,
    loadingNext: state.game.loadingNext,
    loadingInitialChallenge: state.game.loadingInitialChallenge,
    showSurvey: state.game.gamesShowSurvey
})

const mapDispatchToProps = dispatch => ({
    setType: payload => dispatch(gameActions.setGamesPcType(payload)),
    loadGamesPcChallengesAsync: () => dispatch(gameActions.loadGamesPcChallengesAsync()),
    loadGamesPcNextAsync: (url) => dispatch(gameActions.loadGamesPcNextAsync(url))
})

export default connect(mapStateToProps, mapDispatchToProps)(Challenges)