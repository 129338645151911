import React, { Component } from 'react';
import { Provider } from 'react-redux';

import { Route, Switch } from 'react-router-dom';

import Store from './store/Store';

import Main from './components/shared/Main';
import Login from './components/login/Login';
import Paid from './components/invoices/paid/Paid';

import './style/style.scss';
import './icons/css/all.min.css';
import './style/rsuite/custom.less';

import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

export default class App extends Component {
    static displayName = App.name;

    componentDidMount() {
        window.location.href = 'https://app.enrg.ai';
        logEvent(analytics, "open_app");
    }
    
    componentWillUnmount(){
        logEvent(analytics, "close_app");
    }

    render() {
        return (
            <Provider store={Store}>
                <Switch>
                    {/* <Route path="/paid" exact component={Paid}/> */}
                    <Route path="/login" exact component={Login} />
                    <Route component={Main} />
                    <Route path="/" Redirect="login" />
                </Switch>
            </Provider>
        );
    }
}
