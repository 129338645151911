import React, {useEffect, useState} from "react";

import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { getLatLng, geocodeByAddress } from 'react-places-autocomplete';
import { SelectPicker, Divider, DateRangePicker, Whisper, Tooltip, Modal, Alert, Loader, Checkbox, CheckboxGroup} from 'rsuite';
import marker from "../../imgs/marker-neu.svg";

import Options from '../shared/Options';
import * as homeActions from "../../store/actions/Home";
import * as locationsActions from "../../store/actions/Locations";
import {connect} from "react-redux";
import {startOfMonth} from "date-fns";

const { afterToday } = DateRangePicker;

function Map({ activeGraphs, dateLocale, disabledGraphsExtra, language, locations, graphs, graphData, graphLoading, loadGraphAsyncIndividual, setActiveLocation, setActiveLocations, deleteActiveLocations, deleteActiveGraph, deleteGraphData, setGraphActive, setInfoLocation }) {

    const [coords, setCoords] = useState({ lat: 6.281506, lng: -75.556851 });
    const [coordsLocations, setCoordsLocations] = useState();
    const [data, setData] = useState([]);
    const [locationInput, setLocationInput] = useState();
    const [location, setLocation] = useState();
    const [showAddressesModal, setAddressesModal] = useState(false);
    const [activeAddressesModal, setActiveAddressesModal] = useState([]);
    const [activeInfoWindow, setActiveInfoWindow] = useState([]);
    const [loadedInfo, setLoadedInfo] = useState(false);
    const [locationsShow, setLocationsShow] = useState(0);
    const [locationsVisited, setLocationsVisited] = useState(0);
    const [infoToShow, setInfoToShow] = useState([]);
    const [title, setTitle] = useState(language.title_locations_total);
    const [map, setMap] = useState(null);
    
    const [locationSelected, setLocationSelected] = useState();
    const [addresses, setAddresses] = useState([]);
    const [activeAddresses, setActiveAddresses] = useState([]);
    
    let activeAddressIds = activeAddresses.map(address => address.id_address);
    let totalAddressIds = addresses.map(address => address.id_address);

    useEffect(() => {
        const auxLocations = [];
        locations.forEach(async(location) => {
            setData(state => [...state, { "label": location.name, "value": location.acronym, "role": "locations" }])
            if (location.address) {
                try {
                    const results = await geocodeByAddress(location.address + location.name_city);
                    const latLng = await getLatLng(results[0]);
                    const arrayLocations = await addedLocation(latLng, location, auxLocations);
                    setCoordsLocations(arrayLocations);
                    setLocationsShow(prevState => prevState + 1);
                    setLocationsVisited(state => state + 1);
                }catch(e){
                    console.error('Error', e)
                }
            }
        })
    }, []);

    useEffect(() => {
        if (coordsLocations) {
            const coordsFind = coordsLocations.find(coordsArr => coordsArr.acronym === locationInput);
            if (coordsFind) {
                setCoords(coordsFind.latLng);
                const locationFind = locations.find(locationArr => locationArr.acronym === locationInput)
                if (locationFind) {
                    setLocation(locationFind);
                    map.setZoom(18)
                }
            } else {
                const locationFind = locations.find(locationArr => locationArr.acronym === locationInput);
                if (locationFind) { Alert.error(language.alert_error_address_zero); setInfoLocation(locationFind); }
            }
        }
    }, [locationInput, coordsLocations]);

    useEffect(() => {
        if(coordsLocations && locationsShow === coordsLocations.length){
            setLoadedInfo(true)
        }
    }, [locationsShow, coordsLocations])
    
    useEffect(() => {
        activeGraphs.forEach(active => {
            if (active.acronym === locationSelected) {
                setAddresses(active.activeAddresses);
                setActiveAddresses(active.activeAddresses);
            }
        })
    }, [locationSelected])

    useEffect(() => {
        const auxGraphs = activeGraphs; 
        auxGraphs.map(active => {
            if (active.acronym === locationSelected) {
                active.activeAddresses = activeAddresses
            }
            return active;
        })
        setGraphActive(auxGraphs)
    }, [activeAddresses, locationSelected])

    const checkChangeLoading = (graphLoading) => {
        return graphLoading.some(graph => graph.loading === false);
    }
    
    useEffect(() => {
        if(checkChangeLoading(graphLoading)){
            loadInfoLocationGraph();
        }
    }, [graphLoading]);
    
    const handleActiveMarker = async (marker) => {
        const location = searchLocationByAcronym(coordsLocations[marker].acronym);
        setLocation(location);
        setActiveLocation(location);
        let newStatus = null;
        const activeGraphsAux = activeGraphs;
        setActiveInfoWindow(prevState => prevState.map(info => {
            if (info.acronym === coordsLocations[marker].acronym) {
                newStatus = !info.isActive;
                return { acronym: location.acronym, isActive: !info.isActive}
            } else return info}))
        if(newStatus){
            setActiveLocations(location);
            activeGraphsAux.push({
                acronym: location.acronym, 
                value: graphs[0].value, 
                graphDateFromMap: startOfMonth(new Date()), 
                graphDateToMap: new Date(),
                activeAddresses: location.addresses
            });
            setGraphActive(activeGraphsAux);
            loadGraphAsyncIndividual(location.acronym);
        }else{
            deleteActiveLocations(location);
            deleteActiveGraph(location);
            deleteGraphData(location);
        }
    };
    
    const loadInfoLocationGraph = () => {
        let total;
        let avg;
        
        const hasData = graphData.length === activeGraphs.length && activeGraphs.length > 0;
        
        if (hasData) {
            const infoToShowAux = []
            activeGraphs.forEach(activeGraph => {
                const graph = graphs.filter(graph => graph.value === activeGraph.value)[0];

                const graphDataIndividual = graphData.filter(data => data.acronym === activeGraph.acronym)[0].response;
                
                let array = graphDataIndividual
                    .map(value => value.data
                        .filter(value => value.y > 0)
                        .map(value => value.y))
                    .reduce((a, b) => a.concat(b), []);
    
                switch (graph.type) {
                    case "SUM":
                        setTitle(language.title_locations_total)
                        if (array.length) {
                            total = array.reduce((prev, curr) => curr += prev).toFixed(2);
                        } else {
                            total = avg = 0;
                        }
                        infoToShowAux.push({acronym: activeGraph.acronym, value: total, units: graph.unit})
                        break;
                    case "AVG":
                        setTitle(language.title_locations_avg);
                        if (array.length) {
                            total = array.reduce((prev, curr) => curr += prev).toFixed(2);
                            avg = (total / array.length).toFixed(2);
                        } else {
                            avg = 0;
                        }
                        infoToShowAux.push({acronym: activeGraph.acronym, value: avg, units: graph.unit})
                        break;
                    default:
                        break;
                }
            })
            setInfoToShow(infoToShowAux)
        }
    }

    const handleOnLoad = (map) => {
        if(coordsLocations) {
            setMap(map)
            const bounds = new window.google.maps.LatLngBounds();
            const activeAux = []
            coordsLocations.forEach(location => {
                bounds.extend(location.latLng);
                activeAux.push({acronym: location.acronym, isActive: false})
            });
            setActiveInfoWindow(activeAux)
            map.setOptions({ styles: [
                    {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    }]})
            map.fitBounds(bounds);
        }
    }

    const handleMarker = e => {
        const { latLng } = e;

        setCoords({ lat: latLng.lat(), lng: latLng.lng() });

        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat()},${latLng.lng()}&key=${process.env.REACT_APP_API_KEY_GOOGLE}`)
            .then(response => response.json())
            .then(data => changeLocation(data.results[0].formatted_address));
    };

    const searchLocationByAcronym = acronym => {
        return locations.find(locationArr => locationArr.acronym === acronym);
    }

    const changeLocation = newLocation => {
        let locationFind;
        if (locationInput) {
            locationFind = locations.find(locationArr => locationArr.acronym === locationInput)
        } else {
            locationFind = locations.find(locationArr => locationArr.acronym === location.acronym)
        }
        locationFind.address = newLocation;
        setInfoLocation(locationFind);
    }

    const addedLocation = (latLng, location, mainArray) => {
        return new Promise((resolve, reject) => {
            try {
                mainArray.push({ latLng, acronym: location.acronym });
                setCoords(mainArray[0].latLng);
                setLocation(searchLocationByAcronym(mainArray[0].acronym));
                resolve(mainArray);
            } catch (e) {
                reject(e);
            }
        });
    };

    const changeGraph = (value, acronym) => {
        const auxActiveGraphs = [];
        activeGraphs.forEach(active => {
            if (active.acronym === acronym) {
                active["value"] = value;
            }
            auxActiveGraphs.push(active)})
        setGraphActive(auxActiveGraphs);
        loadGraphAsyncIndividual(acronym)
    }

    const changeDate = (value, acronym) => {
        const auxActiveGraphs = [];
        activeGraphs.forEach(active => {
            if (active.acronym === acronym) {
            active["graphDateFromMap"] = value[0];   
            active["graphDateToMap"] = value[1];   
        }
        auxActiveGraphs.push(active)})
        setGraphActive(auxActiveGraphs);
        loadGraphAsyncIndividual(acronym); 
    }

    const getGraph = (acronym) => {
        let graph = "";
        activeGraphs.forEach(active => active.acronym === acronym ? graph = active.value : null );
        return graph;
    }

    const getDates = (acronym) => {
        const dates = [];
        activeGraphs.forEach(active => active.acronym === acronym ? dates.push(active.graphDateFromMap, active.graphDateToMap) : null );
        if(dates.length > 0) return dates 
        else return [startOfMonth(new Date()), new Date()];
    }

    const getLoading = (acronym) => {
        let load = false;
        graphLoading.forEach(active => active.acronym === acronym ? load = active.loading : null );
        return load;
    }

    const getInfoToShow = (acronym) => {
        let infoToShowAux = undefined;
        infoToShow.forEach(info => info.acronym === acronym ? infoToShowAux = info : null );
        return infoToShowAux;
    }

    const isActiveInfoWindow = (acronym) => {
        const showInfo = activeInfoWindow.find(active => active.acronym === acronym);
        return showInfo ? showInfo.isActive : false;
    }

    const idToAddresses = (addressesIds) => {
        return addressesIds.map(addressId => addresses.find(active => active.id_address === addressId))
    }
    
    return (
        <div className="w-100 h-100">
            <style>{"\
                .gm-style-iw, .gm-style-iw-c {\
                    padding: 0 !important;\
                    min-width: 300px !important;\
                    border-radius: 1.4rem !important;\
                }\
                .gm-style-iw  button, .gm-style-iw-c button {\
                    display: none !important;\
                    width: 0 !important;\
                    height: 0 !important;\
                }\
                .gm-style-iw-d {\
                    overflow: hidden !important;\
                    width: 100% !important;\
                }\
                .gm-svpc {\
                    display: none !important;\
                }\
                .gm-fullscreen-control {\
                    display: none !important;\
                }\
                .gmnoprint {\
                    top: revert !important;\
                    bottom: 20px;\
                }"}\
            </style>
            <Modal
                size="xs"
                show={showAddressesModal}
                onEnter={() => setActiveAddressesModal(activeAddressIds)}
                onHide={() => setAddressesModal(false)}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{language.title_home_popup_addresses}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {addresses.length ?
                        <div id="home-check-address-container" className="overflow-auto w-100 p-3">
                            <Checkbox
                                indeterminate={!totalAddressIds.every(id => activeAddressesModal.includes(id))}
                                checked={totalAddressIds.every(id => activeAddressesModal.includes(id))}
                                onChange={(_value, checked) => {
                                    checked ? setActiveAddressesModal(totalAddressIds) : setActiveAddressesModal([])
                                }}>{language.title_home_popup_addresses_check_all}</Checkbox>
                            <CheckboxGroup
                                value={activeAddressesModal}
                                onChange={value => setActiveAddressesModal(value)}>
                                {addresses.map(address => (
                                    <Checkbox
                                        key={address.id_address}
                                        value={address.id_address}>{address.address_identifier}</Checkbox>
                                ))}
                            </CheckboxGroup>
                        </div> :
                        <div className="txt-semibold txt-12 txt-light-blue text-center py-3">{language.title_home_popup_addresses_empty}</div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 mr-4 bg-${activeAddressesModal.length ? "blue" : "light-blue"}`}
                            onClick={() => {
                                if (activeAddressesModal.length) {
                                    setAddressesModal(false);
                                    setActiveAddresses(idToAddresses(activeAddressesModal));
                                    if (activeGraphs.length) { loadGraphAsyncIndividual(locationSelected) }
                                }
                            }}>{language.title_home_popup_addresses_ok}</button>
                    </div>
                </Modal.Footer>
            </Modal>
            {
                (loadedInfo && coordsLocations.length === locationsVisited) &&
                <div className="position-relative w-100 h-100">
                    <div className="position-absolute top-0 left-0 row-between flex-wrap-reverse aling-items-center p-3 zindex-1 w-100">
                        <div className="col-12 col-md-4 txt-dark-blue p-0 mt-3 mt-md-0">
                            <SelectPicker
                                className="w-100"
                                searchable={false}
                                placeholder={language.title_locations_search_projects}
                                data={data}
                                value={locationInput}
                                onChange={setLocationInput} />
                        </div>
                        <div className="col-12 col-md-6 p-0">
                            <Options />
                        </div>
                    </div>
                    <GoogleMap
                        zoom={13}
                        center={coords}
                        onLoad={handleOnLoad}
                        mapTypeId={"roadmap"}
                        mapContainerStyle={{ height: "100%", width: "100%" }}>
                        {coordsLocations.map((showLocation, i) => (
                            <Marker
                                key={i}
                                position={showLocation.latLng}
                                onClick={() => handleActiveMarker(i)}
                                draggable
                                onDragEnd={handleMarker}
                                icon={marker}>
                                {getInfoToShow(showLocation.acronym) && isActiveInfoWindow(showLocation.acronym) ? (
                                    <InfoWindow>
                                        <div className="column-center py-2">
                                            <div className="w-100 row-between align-items-center my-1 px-3">
                                                <div className="row-start align-items-center col-10 p-0">
                                                    <div className="text-center col-2 p-0">
                                                        <div className={`location-info-img position-relative overflow-hidden column-center align-items-center rounded-circle c-pointer ${searchLocationByAcronym(showLocation.acronym).image === null ? "bg-blue" : null}`}>
                                                            {searchLocationByAcronym(showLocation.acronym).image === null ?
                                                                <i className="fas fa-building txt-12 txt-dark-blue3" /> :
                                                                <img className="home-location-img position-absolute w-100 h-100" src={`${process.env.REACT_APP_IMAGES_URL}/locations/photos/${searchLocationByAcronym(showLocation.acronym).id_project}/50x50/${searchLocationByAcronym(showLocation.acronym).image}`} alt="location" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-10 p-0">
                                                        <div className={"pt-1 mx-2 txt-bold txt-10 txt-dark-blue"}> {`${searchLocationByAcronym(showLocation.acronym).acronym} - ${searchLocationByAcronym(showLocation.acronym).name}`}</div>
                                                    </div>
                                                </div>
                                                <div className="text-center col-1 p-0">
                                                    <i className="fas fa-info-circle txt-14 txt-light-blue txt-hover-blue"
                                                       onClick={() => setInfoLocation(searchLocationByAcronym(showLocation.acronym))} />
                                                </div>
                                            </div>
                                            <Divider />
                                            <div className="py-2 px-3" id="location-date-picker">
                                                <DateRangePicker
                                                    showOneCalendar
                                                    id="locations-graph-date-picker"
                                                    size="lg"
                                                    format="DD-MM-YYYY"
                                                    defaultValue={getDates(showLocation.acronym)}
                                                    locale={dateLocale}
                                                    cleanable={false}
                                                    disabledDate={afterToday()}
                                                    onOk={value => changeDate(value, showLocation.acronym)}
                                                />
                                            </div>
                                            <div className="row-between align-items-center col-md-auto col-12 py-2 px-3">
                                                <Whisper
                                                    delay={1000}
                                                    placement="right"
                                                    trigger="hover"
                                                    speaker={<Tooltip>{language.tooltip_home_dashboard_addresses_filter}</Tooltip>}>
                                                    <i className="fas fa-filter txt-10 txt-light-blue txt-hover-blue text-center c-pointer mr-3" onClick={() => {setLocationSelected(showLocation.acronym); setAddressesModal(true)}} />
                                                </Whisper>
                                                <SelectPicker
                                                    id="location-graph-picker"
                                                    data={graphs}
                                                    value={getGraph(showLocation.acronym)}
                                                    groupBy="group"
                                                    size="md"
                                                    appearance="subtle"
                                                    searchable={false}
                                                    placeholder={language.placeholder_home_select_graphs}
                                                    disabledItemValues={[...disabledGraphsExtra]}
                                                    onChange={value => changeGraph(value, showLocation.acronym)}
                                                />
                                            </div>
                                            <Divider />
                                            { !getLoading(showLocation.acronym) ?
                                                <div className="row-between py-2 px-3">
                                                    <div className="txt-bold txt-12 txt-dark-blue">{title}</div>
                                                    <div className="row-start align-items-end text-right">
                                                        <div className="pr-2">
                                                            <div className="txt-bold txt-12 txt-dark-blue">{getInfoToShow(showLocation.acronym).value}</div>
                                                        </div>
                                                        <div>
                                                            <div className="txt-semibold txt-8 txt-light-blue">{getInfoToShow(showLocation.acronym).units}</div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className="row-center py-2 px-3">
                                                    <Loader content={`${language.title_locations_loading_info}...`} />
                                                </div>
                                            }
                                        </div>
                                    </InfoWindow>
                                ) : null}
                            </Marker>
                        ))}
                    </GoogleMap>
                </div>
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    activeGraphs: state.locations.activeGraphsMap,
    activeLocations: state.locations.activeLocationsMap,
    dateLocale: state.home.dateLocale,
    disabledGraphsExtra: state.locations.disabledGraphsExtraMap,
    language: state.shared.language,
    locations: state.home.locations,
    graphs: state.home.graphs,
    graphData: state.locations.graphDataMap,
    graphLoading: state.locations.graphLoadingMap
})

const mapDispatchToProps = dispatch => ({
    loadGraphAsyncIndividual: payload => dispatch(locationsActions.loadGraphAsyncUnitMap(payload)),
    setActiveLocation: payload => dispatch(locationsActions.setLocationMap(payload)),
    setActiveLocations: payload => dispatch(locationsActions.setActiveLocationsMap(payload)),
    deleteActiveLocations: payload => dispatch(locationsActions.deleteActiveLocationMap(payload)),
    deleteActiveGraph: payload => dispatch(locationsActions.deleteActiveGraphMap(payload)),
    deleteGraphData: payload => dispatch(locationsActions.deleteGraphDataMap(payload)),
    setGraphActive: payload => dispatch(locationsActions.setGraphActiveMap(payload)),
    setGraphDates: payload => dispatch(homeActions.setGraphDates(payload)),
    setInfoLocation: payload => dispatch(homeActions.setInfoLocation(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Map)