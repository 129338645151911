import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import * as notificationsActions from '../../store/actions/Notifications';
import * as sharedActions from '../../store/actions/Shared';

import Notification from './Notification';

import { Loader, Popover, Whisper } from 'rsuite';

import { getAnalytics, logEvent } from "firebase/analytics";

import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const Speaker = ({ whisper, popupData, popupLoading, language, setBlackScreen, updateActiveNotificationsAsync, ...props }) => {

    return (
        <Popover {...props}>
            <div id="shared-notifications-container" className={`column-start align-items-center ${popupData.length ? "shared-notifications-margin-header" : null}`}>
                {popupLoading ?
                    <Loader center size="sm" content={language.loading_message} /> :
                    !popupData.length ?
                        <div className="row-center align-items-center flex-grow-1">
                            <div className="txt-12 txt-light-blue txt-bold text-center">{language.title_shared_notifications_empty}</div>
                        </div> :
                        <div>
                            <div id="shared-notifications-header" className="position-absolute d-flex align-items-center top-0 left-0 border-bottom w-100 px-3">
                                <div className="txt-bold txt-dark-blue txt-hover-blue txt-10 c-pointer"
                                    onClick={() => updateActiveNotificationsAsync()}>{language.title_shared_notifications_clear}</div>
                            </div>
                            {popupData.map((notification) =>
                                <Notification
                                    key={notification.category + notification.id_alarm}
                                    notification={notification}
                                    whisper={whisper} />)}
                        </div>}
            </div>
        </Popover>
    );
};

const Notifications = (props) => {

    const whisper = useRef(null);

    const loadActiveNotificationsAsync = props.loadActiveNotificationsAsync;

    useEffect(() => loadActiveNotificationsAsync(), [loadActiveNotificationsAsync]);

    return (
        <Whisper
            ref={whisper}
            trigger="click"
            placement="autoVertical"
            speaker={<Speaker
                whisper={whisper}
                language={props.language}
                popupData={props.popupData}
                popupLoading={props.popupLoading}
                setBlackScreen={props.setBlackScreen}
                updateActiveNotificationsAsync={props.updateActiveNotificationsAsync} />}
            onClick={() => {
                logEvent(analytics, "click_notifications", {
                id_user: props.user.id_user,
                name: `${props.user.name} ${props.user.lastname}`});

                amplitude.getInstance().logEvent('Home Notifications Button Tapped');                
            }}
            onEntering={() => props.setBlackScreen(true)}
            onExiting={() => {
                props.setBlackScreen(false);
            }}>
            <div className="position-relative">
                {props.popupData.length ? <div id="shared-notifications-badge" className="position-absolute bg-green rounded-circle"></div> : null}
                <i className="fas fa-bell txt-12 txt-light-blue txt-hover-blue mr-4 ml-2 c-pointer pt-1"></i>
            </div>
        </Whisper>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    language: state.shared.language,
    popupData: state.notifications.popupData,
    popupLoading: state.notifications.popupLoading
})

const mapDispatchToProps = dispatch => ({
    loadActiveNotificationsAsync: () => dispatch(notificationsActions.loadActiveNotificationsAsync()),
    updateActiveNotificationsAsync: () => dispatch(notificationsActions.updateActiveNotificationsAsync()),
    setBlackScreen: payload => dispatch(sharedActions.setBlackScreen(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
