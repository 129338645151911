import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';

import * as homeActions from '../../store/actions/Home';
import * as usersActions from '../../store/actions/Users';
import * as sharedActions from '../../store/actions/Shared';

import { Loader, Table, Input, InputGroup, Tooltip, Whisper, Alert } from 'rsuite';
import { Modal } from 'rsuite';
import { CheckPicker } from 'rsuite';
import { SelectPicker } from 'rsuite';

import { Transition } from 'react-transition-group';

import Options from '../shared/Options';

import { getAnalytics, logEvent } from "firebase/analytics";

import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const { Column, HeaderCell, Cell } = Table;

const lengthMenu = [
    {
        value: 20,
        label: 20
    },
    {
        value: 50,
        label: 50
    },
    {
        value: 100,
        label: 100
    }
];

const isValidateEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const isValidUser = user => {

    if (user.name === null || user.name === "") {
        return false;
    }

    if (user.lastname === null || user.lastname === "") {
        return false;
    }

    if (!isValidateEmail(user.email)) {
        return false;
    }

    if (user.phone === null || user.phone === "") {
        return false;
    }

    if (user.id_document === null || user.id_document === "") {
        return false;
    }

    if (user.address === null || user.address === "") {
        return false;
    }

    if (user.city === null || user.city === "") {
        return false;
    }

    if (user.locations === null || user.locations === "") {
        return false;
    }

    return true;

}

const showError = (user, language) => {

    if (user.name === null || user.name === "") {
        Alert.error(language.alert_userAccess_popup_name);
        return;
    }

    if (user.lastname === null || user.lastname === "") {
        Alert.error(language.alert_userAccess_popup_lastname);
        return;
    }

    if (user.id_document === null || user.id_document === "") {
        Alert.error(language.alert_userAccess_popup_id_document);
        return;
    }

    if (!isValidateEmail(user.email)) {
        Alert.error(language.alert_userAccess_popup_email);
        return;
    }

    if (user.phone === null || user.phone === "") {
        Alert.error(language.alert_userAccess_popup_phone);
        return;
    }

    if (user.address === null || user.address === "") {
        Alert.error(language.alert_userAccess_popup_address);
        return;
    }

    if (user.city === null || user.city === "") {
        Alert.error(language.alert_userAccess_popup_city);
        return;
    }

    if (user.locations === null || !user.locations.lenght) {
        Alert.error(language.alert_userAccess_popup_locations);
        return;
    }

}

const ImageCell = ({ rowData, language, updateUserStatus, dataKey, ...props }) => {

    let button;

    if (rowData[dataKey]) {
        button = <button className="btn-state txt-12 txt-white rounded-pill px-3 py-2 bg-blue c-dafault"
            onClick={() => updateUserStatus(rowData)}>{language.label_userAccess_button_active}</button>;
    } else {
        button = <button className="btn-state txt-12 txt-dark-blue2 rounded-pill px-3 py-2 bg-light-blue2 c-default"
            onClick={() => updateUserStatus(rowData)}>{language.label_userAccess_button_inactive}</button>;
    }

    return (
        <Cell {...props} style={{ padding: 0 }}>
            <div className="row-center align-items-center h-100">
                {button}
            </div>
        </Cell>);

};

const UserAccess = (props) => {

    const states = [
        {
            value: false,
            label: props.language.option_userAccess_inactive
        },
        {
            value: true,
            label: props.language.option_userAccess_active
        }
    ];

    const initFilterData = {
        search_value: "",
        name: "",
        lastname: "",
        phone: "",
        email: "",
        status: null
    }

    const initUserForm = {
        id_user: null,
        name: '',
        lastname: '',
        id_document: '',
        email: '',
        phone: '',
        address: '',
        city: null,
        locations: null
    }

    let availableData = props.availableData;
    let totalData = props.totalData;
    let locations = props.locations;

    const [activePage, setActivePage] = useState(1);
    const [pageLength, setPageLength] = useState(20);
    const [totalPages, setTotalPages] = useState(0);

    const [showFilters, setShowFilters] = useState(false);
    const [filterData, setFilterData] = useState(initFilterData);

    const [showUserModal, setUserModal] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [userForm, setUserForm] = useState(initUserForm);

    const setActiveData = props.setActiveData;
    const setAvailableData = props.setAvailableData;
    const loadUsersAsync = props.loadUsersAsync;
    const loadLocationsAsync = props.loadLocationsAsync;

    useEffect(() => {
        if (!locations.length)
            loadLocationsAsync();

        if (!totalData.length)
            loadUsersAsync();

    }, [loadUsersAsync, totalData, locations, loadLocationsAsync]);

    useEffect(() => {
        setTotalPages(availableData.length);
        setActiveData(availableData.slice((activePage - 1) * pageLength, activePage * pageLength));
    }, [loadUsersAsync, setActiveData, setTotalPages, activePage, pageLength, availableData]);

    useEffect(() => {

        let data = totalData;
        const search = filterData.search_value.toLowerCase();

        data = data.filter(option =>
        ((option.name.toLowerCase().includes(filterData.name) || !filterData.name.length)
            && (option.lastname.toLowerCase().includes(filterData.lastname) || !filterData.lastname.length)
            && (option.phone.toLowerCase().includes(filterData.phone) || !filterData.phone.length)
            && (option.email.toLowerCase().includes(filterData.email) || !filterData.email.length)
            && (filterData.status === option.status || filterData.status === null)
            && (option.name.toLowerCase().includes(search) || option.lastname.toLowerCase().includes(search)
                || option.phone.toLowerCase().includes(search) || option.email.toLowerCase().includes(search) || search === "")
        ));

        setActivePage(1);
        setAvailableData(data);

    }, [setActivePage, setAvailableData, filterData, totalData]);

    useEffect(() => logEvent(analytics, "users", {
        id_user: props.user.id_user,
        name: `${props.user.name} ${props.user.lastname}`
    }), []);

    return (
        <Fragment>
            <Modal
                size="sm"
                show={showUserModal}
                onHide={() => {
                    setUserModal(false);
                    setUserForm(initUserForm);
                }}>
                <Modal.Header>
                    <div className="row-end align-items-center h-100 w-100">
                        <div className="txt-14 txt-bold txt-dark-blue text-left mx-4">{editUser ? props.language.title_popup_update_userAccess : props.language.title_popup_create_userAccess}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column p-3">
                        <div className="row-center align-items-center flex-wrap my-2">
                            <div className="col-md-6 col-12">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_popup_userAccess_name}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-align-justify txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.placeholder_popup_userAccess_name}
                                        value={userForm.name}
                                        maxLength={30}
                                        onChange={value => setUserForm({ ...userForm, name: value })} />
                                </InputGroup>
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_popup_userAccess_lastname}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-align-justify txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.placeholder_popup_userAccess_lastname}
                                        value={userForm.lastname}
                                        maxLength={30}
                                        onChange={value => setUserForm({ ...userForm, lastname: value })} />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="row-center align-items-center flex-wrap my-2">
                            <div className="col-md-6 col-12">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_popup_userAccess_id_document}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-id-card txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.placeholder_popup_userAccess_id_document}
                                        value={userForm.id_document}
                                        maxLength={30}
                                        disabled={editUser}
                                        onChange={value => setUserForm({ ...userForm, id_document: value })} />
                                </InputGroup>
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_popup_userAccess_email}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-envelope txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.placeholder_popup_userAccess_email}
                                        value={userForm.email}
                                        maxLength={50}
                                        onChange={value => setUserForm({ ...userForm, email: value })} />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="row-center align-items-center flex-wrap my-2">
                            <div className="col-md-6 col-12">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_popup_userAccess_phone}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-mobile txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.placeholder_popup_userAccess_phone}
                                        value={userForm.phone}
                                        maxLength={30}
                                        onChange={value => setUserForm({ ...userForm, phone: value })} />
                                </InputGroup>
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_popup_userAccess_address}</div>
                                <InputGroup
                                    inside >
                                    <InputGroup.Addon>
                                        <i className="fas fa-map-marker-alt txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder={props.language.placeholder_popup_userAccess_address}
                                        value={userForm.address}
                                        maxLength={30}
                                        onChange={value => setUserForm({ ...userForm, address: value })} />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="row-start align-items-center flex-wrap my-2">
                            <div className="col-md-6 col-12 mt-md-0 mt-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_popup_userAccess_city}</div>
                                <SelectPicker
                                    className="w-100"
                                    cleanable={false}
                                    placeholder={props.language.placeholder_popup_userAccess_city}
                                    value={userForm.city}
                                    data={props.cities}
                                    valueKey={"id_city"}
                                    labelKey={"name"}
                                    onChange={value => setUserForm({ ...userForm, city: value })}
                                />
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-2">
                                <div className="txt-light-blue txt-10 txt-semibold mb-2">{props.language.label_popup_userAccess_locations}</div>
                                <CheckPicker
                                    className="w-100"
                                    searchable={false}
                                    value={userForm.locations}
                                    placeholder={props.language.placeholder_popup_userAccess_locations}
                                    valueKey={"id_project"}
                                    labelKey={"name"}
                                    data={locations}
                                    onChange={value => setUserForm({ ...userForm, locations: value })}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row-end align-items-center h-100 w-100">
                        <button
                            style={{ width: 150 }}
                            className={`txt-12 txt-semibold txt-white border-0 rounded-pill px-4 py-2 mr-4 bg-${isValidUser(userForm) ? "blue" : "light-blue"}`}
                            onClick={() => {
                                if (isValidUser(userForm)) {
                                    setFilterData(initFilterData);

                                    if (editUser)
                                        props.updateUserAsync(userForm);
                                    else {
                                        props.createUserAsync(userForm);
                                        amplitude.getInstance().logEvent('Create New User Finished', userForm);
                                    }

                                    setUserModal(false);
                                    setShowFilters(false);
                                    document.getElementById("input-search").value = "";
                                    document.getElementById("input-name").value = "";
                                    document.getElementById("input-lastname").value = "";
                                    document.getElementById("input-email").value = "";
                                    document.getElementById("input-phone").value = "";
                                } else {
                                    showError(userForm, props.language);
                                }
                                setUserForm(initUserForm);
                            }}>
                            {editUser ? props.language.title_userAccess_popup_button_update_user : props.language.title_userAccess_popup_button_create_user}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <div id="userAccess" className="column-start align-items-start px-3 py-2 h-100 w-100">
                <div className="row-between align-items-center flex-wrap-reverse w-100 pb-2">
                    <div className="row-start align-items-center flex-wrap col-md-8 col-12 p-0">
                        <div className="row-start align-items-center col-md-6 col-12 my-md-0 my-2 p-0">
                            <div className="txt-20 txt-bold txt-dark-blue w-auto">{props.language.title_userAccess}</div>
                            <Whisper
                                delay={500}
                                placement="right"
                                trigger="hover"
                                speaker={<Tooltip>{props.language.tooltip_userAccess_button_create_user}</Tooltip>}>
                                <button
                                    id="reports-add-report"
                                    className="d-flex justify-content-center align-items-center border-0 rounded-circle p-2 bg-blue ml-3 mr-2"
                                    onClick={() => {

                                        logEvent(analytics, "click_users_add", {
                                            id_user: props.user.id_user,
                                            name: `${props.user.name} ${props.user.lastname}`
                                        });

                                        amplitude.getInstance().logEvent('Create New User Started');

                                        if (!props.cities.lenght)
                                            props.loadCitiesAsync();

                                        setEditUser(false);
                                        setUserModal(true);
                                        setUserForm({ ...userForm, locations: locations.map(location => location.id_project) });
                                    }}>
                                    <i className="fas fa-plus txt-12 txt-white"></i>
                                </button>
                            </Whisper>
                        </div>
                        <div className="row-start align-items-center col-md-6 col-12 p-0">
                            <Whisper
                                delay={1000}
                                placement="bottom"
                                trigger="focus"
                                speaker={<Tooltip>{props.language.tooltip_shared_search}</Tooltip>}>
                                <InputGroup
                                    inside>
                                    <Input
                                        id="input-search"
                                        placeholder={`${props.language.title_shared_search}`}
                                        onPressEnter={e => setFilterData({ ...filterData, search_value: e.target.value })} />
                                    <InputGroup.Addon>
                                        <i className="fas fa-search txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                </InputGroup>
                            </Whisper>
                        </div>
                    </div>
                    <div className="d-block d-md-flex justify-content-end align-items-center col-md-4 col-12 p-0">
                        <Whisper
                            placement="bottom"
                            trigger="click"
                            speaker={<Tooltip>{props.language.tooltip_userAccess_info}</Tooltip>}>
                            <i className="fas fa-info-circle d-none d-md-block txt-12 txt-light-blue txt-hover-blue mx-2 c-pointer"></i>
                        </Whisper>
                        <Options />
                    </div>
                </div>
                <div className="position-relative column-start flex-grow-1 w-100 bg-white rounded shadow overflow-hidden">
                    <Transition
                        in={props.loading}
                        timeout={100}>
                        {state => (
                            <div className={`trans-fade trans-fade-${state} position-absolute bg-white w-100 h-100 top-0 left-0 zindex-1`}>
                                <div className="column-center align-items-center w-100 h-100">
                                    <Loader center size="md" content={props.language.loading_message} />
                                </div>
                            </div>
                        )}
                    </Transition>
                    {!props.activeData.length && !props.loading ?
                        <div className={`position-absolute column-center align-items-center left-0 bg-white border-top w-100 p-3 zindex-1 empty-message-${!showFilters ? "complete" : "filtered"}`}>
                            <div className="row-center align-items-center w-100">
                                <i className="fas fa-address-book txt-20 txt-light-blue" />
                                <div className="txt-20 txt-bold txt-light-blue w-auto mx-3">{props.language.title_userAccess_users_empty}</div>
                            </div>
                        </div> : null}
                    <div className="table-data-container flex-grow-1 w-100">
                        <div className="position-relative d-flex flex-column h-100" style={{ minWidth: 1200 }}>
                            <div className="table-header position-absolute top-0 row-start align-items-center zindex-1 py-3 w-100" style={{ paddingRight: 20 }}>
                                <div className="text-center" style={{ width: 60, paddingLeft: 10, paddingRight: 10 }}>
                                    <Whisper
                                        delay={500}
                                        placement="top"
                                        trigger="hover"
                                        speaker={<Tooltip>{props.language.tooltip_shared_filter}</Tooltip>}>
                                        <i className="fas fa-filter txt-10 txt-light-blue txt-hover-blue c-pointer"
                                            onClick={() => setShowFilters(!showFilters)} />
                                    </Whisper>
                                </div>
                                <div className="text-left" style={{ width: 200, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_userAccess_name}</div>
                                </div>
                                <div className="text-left" style={{ width: 200, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_userAccess_lastname}</div>
                                </div>
                                <div className="text-left " style={{ width: 200, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_userAccess_phone}</div>
                                </div>
                                <div className="text-left " style={{ width: 300, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_userAccess_email}</div>
                                </div>
                                <div className="text-center flex-grow-1 " style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_userAccess_state}</div>
                                </div>
                            </div>
                            <div className={`table-filter position-absolute row-start align-items-end zindex-1 pb-3 w-100 ${!showFilters ? "d-none" : null}`} style={{ paddingRight: 20 }}>
                                <div className="text-left" style={{ marginLeft: 60, width: 200, paddingLeft: 10, paddingRight: 10 }}>
                                    <InputGroup
                                        inside>
                                        <InputGroup.Addon>
                                            <i className="fas fa-search txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input
                                            id="input-name"
                                            placeholder={props.language.filter_table_userAccess_name}
                                            onPressEnter={e => setFilterData({ ...filterData, name: e.target.value })} />
                                    </InputGroup>
                                </div>
                                <div className="text-left " style={{ width: 200, paddingLeft: 10, paddingRight: 10 }}>
                                    <InputGroup
                                        inside>
                                        <InputGroup.Addon>
                                            <i className="fas fa-search txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input
                                            id="input-lastname"
                                            placeholder={props.language.filter_table_userAccess_lastname}
                                            onPressEnter={e => setFilterData({ ...filterData, lastname: e.target.value })} />
                                    </InputGroup>
                                </div>
                                <div className="text-left " style={{ width: 200, paddingLeft: 10, paddingRight: 10 }}>
                                    <InputGroup
                                        inside>
                                        <InputGroup.Addon>
                                            <i className="fas fa-search txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input
                                            id="input-phone"
                                            placeholder={props.language.filter_table_userAccess_phone}
                                            onPressEnter={e => setFilterData({ ...filterData, phone: e.target.value })} />
                                    </InputGroup>
                                </div>
                                <div className="text-left " style={{ width: 300, paddingLeft: 10, paddingRight: 10 }}>
                                    <InputGroup
                                        inside>
                                        <InputGroup.Addon>
                                            <i className="fas fa-search txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input
                                            id="input-email"
                                            placeholder={props.language.filter_table_userAccess_email}
                                            onPressEnter={e => setFilterData({ ...filterData, email: e.target.value })} />
                                    </InputGroup>
                                </div>
                                <div className="text-left flex-grow-1 " style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <SelectPicker
                                        className="w-100"
                                        searchable={false}
                                        placeholder={props.language.filter_table_userAccess_all_states}
                                        value={filterData.status}
                                        data={states}
                                        onChange={value => setFilterData({ ...filterData, status: value })}
                                    />
                                </div>
                            </div>
                            <div className={`position-relative flex-grow-1 table-vertical-scroll border-top table-content-${!showFilters ? "complete" : "filtered"}`}>
                                <Table
                                    autoHeight
                                    wordWrap
                                    data={props.activeData}
                                    hover={false}
                                    showHeader={false}>
                                    <Column width={60} verticalAlign="middle">
                                        <HeaderCell>edit</HeaderCell>
                                        <Cell dataKey="edit">
                                            {rowData => (
                                                <Whisper
                                                    placement="top"
                                                    trigger="hover"
                                                    speaker={<Tooltip>{props.language.tooltip_userAccess_edit}</Tooltip>}>
                                                    <i className="fas fa-pen txt-12 txt-hover-blue txt-light-blue ml-3 c-pointer"
                                                        onClick={(() => {
                                                            if (!props.cities.lenght)
                                                                props.loadCitiesAsync();

                                                            setUserForm({
                                                                ...userForm,
                                                                id_user: rowData.id_user,
                                                                name: rowData.name,
                                                                lastname: rowData.lastname,
                                                                id_document: rowData.id_document,
                                                                email: rowData.email,
                                                                phone: rowData.phone,
                                                                address: rowData.address,
                                                                city: rowData.id_city,
                                                                locations: rowData.locations.split(',').map(Number)
                                                            })

                                                            setEditUser(true);
                                                            setUserModal(true);
                                                        })} />
                                                </Whisper>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={200} verticalAlign="middle">
                                        <HeaderCell>name</HeaderCell>
                                        <Cell dataKey="name">
                                            {rowData => (<div className="txt-10 txt-dark-blue txt-regular">{rowData.name}</div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={200} verticalAlign="middle">
                                        <HeaderCell>lastname</HeaderCell>
                                        <Cell dataKey="lastname">
                                            {rowData => (
                                                <div className="txt-10 txt-dark-blue txt-regular">{rowData.lastname}</div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={200} verticalAlign="middle">
                                        <HeaderCell>phone</HeaderCell>
                                        <Cell dataKey="phone">
                                            {rowData => (
                                                <div style={{ width: 260 }}>
                                                    <div className="txt-10 txt-dark-blue txt-semibold">{rowData.phone}</div>
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={300} verticalAlign="middle">
                                        <HeaderCell>email</HeaderCell>
                                        <Cell dataKey="email">
                                            {rowData => (
                                                <div style={{ width: 260 }}>
                                                    <div className="txt-10 txt-dark-blue txt-semibold">{rowData.email}</div>
                                                </div>)}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={1} verticalAlign="middle">
                                        <HeaderCell>status</HeaderCell>
                                        <ImageCell
                                            dataKey="status"
                                            updateUserStatus={props.updateUserStatusAsync}
                                            language={props.language}
                                        />
                                    </Column>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className={`border-top ${!props.activeData.length ? "d-none" : null}`}>
                        <Table.Pagination
                            lengthMenu={lengthMenu}
                            activePage={activePage}
                            displayLength={pageLength}
                            total={totalPages}
                            renderLengthMenu={picker => picker}
                            onChangeLength={value => {
                                const maxActivePage = Math.ceil(props.availableData.length / value);
                                const page = activePage <= maxActivePage ? activePage : maxActivePage;

                                setPageLength(value);
                                setActivePage(page);
                            }}
                            onChangePage={value => { setActivePage(value) }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    totalData: state.users.totalData,
    availableData: state.users.availableData,
    activeData: state.users.activeData,
    loading: state.users.loading,
    language: state.shared.language,
    locations: state.home.locations,
    cities: state.shared.cities,
})

const mapDispatchToProps = dispatch => ({
    loadUsersAsync: () => dispatch(usersActions.loadUsersAsync()),
    createUserAsync: payload => dispatch(usersActions.createUserAsync(payload)),
    updateUserAsync: payload => dispatch(usersActions.updateUserAsync(payload)),
    updateUserStatusAsync: payload => dispatch(usersActions.updateUserStatusAsync(payload)),
    setAvailableData: payload => dispatch(usersActions.setAvailableData(payload)),
    setActiveData: payload => dispatch(usersActions.setActiveData(payload)),
    loadLocationsAsync: () => dispatch(homeActions.loadLocationsAsync()),
    loadCitiesAsync: () => dispatch(sharedActions.loadCitiesAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserAccess)

