import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';

import * as invoicesActions from '../../store/actions/Invoices';
import * as homeActions from '../../store/actions/Home';

import { Loader, Table, Input, InputGroup, DateRangePicker, SelectPicker, Tooltip, Whisper, CheckPicker, Checkbox } from 'rsuite';

import { Transition } from 'react-transition-group';

import CurrencyFormat from 'react-currency-format';

import Options from '../shared/Options';
import Pay from './Pay';

import { getAnalytics, logEvent } from "firebase/analytics";

import { format } from 'date-fns';
import esLocale from "date-fns/locale/es";
import enLocale from 'date-fns/locale/en-US';

import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const { Column, HeaderCell, Cell } = Table;
const { afterToday } = DateRangePicker;

const lengthMenu = [
    {
        value: 20,
        label: 20
    },
    {
        value: 50,
        label: 50
    },
    {
        value: 100,
        label: 100
    }
];

const initFilterData = {
    search_value: "",
    from: new Date(2020, 0, 1),
    to: new Date(),
    code: "",
    description: "",
    value: "",
    state: null,
    locations: []
}

const ImageCell = ({ user, rowData, dataKey, banks, language, setInvoicesToPay, loadPayDataAsync, downloadFile, invoicesChecked, setInvoicesChecked, ...props }) => {

    let button;

    switch (rowData[dataKey]) {
        case 0:
        case 3:
            button = <button
                className="btn-state row-center align-items-center txt-12 txt-semibold txt-white rounded-pill px-4 py-2 bg-blue"
                onClick={() => {

                    logEvent(analytics, "click_bills_pay", {
                        id_user: user.id_user,
                        user_name: `${user.name} ${user.lastname}`
                    });

                    amplitude.getInstance().logEvent('Billing Payment Started');

                    if (!invoicesChecked.some(invoice => invoice.id_bill === rowData.id_bill)) {
                        setInvoicesChecked([...invoicesChecked, rowData]);
                        invoicesChecked.push(rowData);
                    }
                    if (banks.length) { setInvoicesToPay(invoicesChecked) }
                    else { loadPayDataAsync(rowData.id_bill, invoicesChecked) }
                }}>{rowData.downloading ? <Loader inverse /> : language.title_invoices_state_pay}</button>;
            break;
        case 1:
            button = <button className="btn-state txt-12 txt-semibold txt-green rounded-pill px-4 py-2 bg-clear-green c-default">{language.title_invoices_state_paid}</button>;
            break;
        case 2:
            button = <button className="btn-state txt-12 txt-semibold txt-red rounded-pill px-3 py-2 bg-clear-red c-default">{language.title_invoices_state_cancelled}</button>;
            break;
        default:
            break;
    }

    return (
        <Cell
            {...props}
            style={{ padding: 0 }}>
            <div className="row-center align-items-center h-100">
                <div className="mr-5">
                    {rowData.downloading_file ?
                        <Loader /> :
                        <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={<Tooltip>{language.tooltip_shared_download}</Tooltip>}>
                            <i className="fas fa-arrow-down txt-12 txt-light-blue txt-hover-blue c-pointer"
                                onClick={() => {

                                    logEvent(analytics, "bill_detail", {
                                        user: {
                                            id_user: user.id_user,
                                            name: `${user.name} ${user.lastname}`
                                        }
                                    });

                                    amplitude.getInstance().logEvent('Bill Downloaded');

                                    downloadFile(rowData)
                                }}></i>
                        </Whisper>
                    }
                </div>
                {button}
            </div>
        </Cell>);

};

const Invoices = (props) => {

    let availableData = props.availableData;

    const states = [
        {
            value: 0,
            label: props.language.title_invoices_state_pay
        },
        {
            value: 1,
            label: props.language.title_invoices_state_paid
        },
        {
            value: 2,
            label: props.language.title_invoices_state_cancelled
        }
    ];

    const totalData = props.totalData;
    const locations = props.locations;

    const [activePage, setActivePage] = useState(1);
    const [pageLength, setPageLength] = useState(20);
    const [totalPages, setTotalPages] = useState(0);

    const [showFilters, setShowFilters] = useState(false);
    const [filterData, setFilterData] = useState(initFilterData);

    const [invoicesChecked, setInvoicesChecked] = useState([]);
    const [init, setInit] = useState(true);

    const setActiveData = props.setActiveData;
    const setAvailableData = props.setAvailableData;
    const loadInvoicesAsync = props.loadInvoicesAsync;
    const loadLocationsAsync = props.loadLocationsAsync;

    let locale = props.language.language_locale == "en" ? enLocale : esLocale;

    useEffect(() => {

        if (!locations.length)
            loadLocationsAsync();

        if (!totalData.length) { loadInvoicesAsync(); }
        else {
            setTotalPages(availableData.length);
            setActiveData(availableData.slice((activePage - 1) * pageLength, activePage * pageLength));
        }
    }, [loadInvoicesAsync, setActiveData, setTotalPages, activePage, pageLength, availableData, totalData, locations, loadLocationsAsync]);

    useEffect(() => {
        if (totalData.length && init) {
            //setInvoicesChecked(totalData.filter(invoice => [0, 3].includes(invoice.state)));
            setInit(false);
        }
    }, [setInvoicesChecked, setInit, totalData, invoicesChecked, init]);

    useEffect(() => {

        if (totalData.length) {

            let data = totalData;
            const search = filterData.search_value.toLowerCase();

            data = data.filter(option =>
            ((Date.parse(filterData.from) <= Date.parse(option.date) && (Date.parse(filterData.to) + 86400000) >= Date.parse(option.date))
                && (filterData.code.toLowerCase() === (option.prefix_reference.toLowerCase() + option.payment_reference.toLowerCase()) || !filterData.code.length)
                && (filterData.description.toLowerCase() === option.description.toLowerCase() || !filterData.description.length)
                && (parseFloat(filterData.value) === option.payment_value || !filterData.value.length)
                && (filterData.locations.includes(option.id_project) || !filterData.locations.length)
                && (filterData.state === option.state || filterData.state === null)
                && (option.description.toLowerCase().includes(search) || option.project.toLowerCase().includes(search) ||
                    (option.prefix_reference.toLowerCase() + option.payment_reference.toLowerCase()).includes(search) || String(option.payment_value).toLowerCase().includes(search))
            ));

            setActivePage(1);
            setAvailableData(data);
        }
    }, [setActivePage, setAvailableData, filterData, totalData]);

    useEffect(() => logEvent(analytics, "bills", {
        id_user: props.user.id_user,
        user_name: `${props.user.name} ${props.user.lastname}`
    }), []);

    return (
        <Fragment>
            <Pay />
            <div id="invoices" className="column-start align-items-start px-3 py-2 h-100 w-100">
                <div className="row-between align-items-center flex-wrap-reverse w-100 pb-2">
                    <div className="row-start align-items-center flex-wrap col-md-8 col-12 p-0">
                        <div className="row-start align-items-center col-md-6 col-12 my-md-0 my-2 p-0">
                            <div className="txt-20 txt-bold txt-dark-blue w-auto">{props.language.title_invoices}</div>
                        </div>
                        <div className="row-start align-items-center col-md-6 col-12 p-0">
                            <Whisper
                                delay={1000}
                                placement="bottom"
                                trigger="focus"
                                speaker={<Tooltip>{props.language.tooltip_shared_search}</Tooltip>}>
                                <InputGroup
                                    inside>
                                    <Input
                                        placeholder={`${props.language.title_shared_search}`}
                                        onPressEnter={e => setFilterData({ ...filterData, search_value: e.target.value })} />
                                    <InputGroup.Addon>
                                        <i className="fas fa-search txt-10 txt-light-blue"></i>
                                    </InputGroup.Addon>
                                </InputGroup>
                            </Whisper>
                        </div>
                    </div>
                    <div className="d-block d-md-flex justify-content-end align-items-center col-md-4 col-12 p-0">
                        <Whisper
                            placement="bottom"
                            trigger="click"
                            speaker={<Tooltip>{props.language.tooltip_invoices_info}</Tooltip>}>
                            <i className="fas fa-info-circle d-none d-md-block txt-12 txt-light-blue txt-hover-blue mx-2 c-pointer"></i>
                        </Whisper>
                        <Options />
                    </div>
                </div>
                <div className="position-relative column-start flex-grow-1 w-100 bg-white rounded shadow overflow-hidden">
                    <Transition
                        in={props.loading}
                        timeout={100}>
                        {state => (
                            <div className={`trans-fade trans-fade-${state} position-absolute bg-white w-100 h-100 top-0 left-0 zindex-1`}>
                                <div className="column-center align-items-center w-100 h-100">
                                    <Loader center size="md" content={props.language.loading_message} />
                                </div>
                            </div>
                        )}
                    </Transition>
                    {!props.activeData.length && !props.loading ?
                        <div className={`position-absolute column-center align-items-center left-0 bg-white border-top w-100 p-3 zindex-1 empty-message-${!showFilters ? "complete" : "filtered"}`}>
                            <div className="row-center align-items-center w-100">
                                <i className="fas fa-file-invoice-dollar txt-20 txt-light-blue" />
                                <div className="txt-20 txt-bold txt-light-blue w-auto mx-3">{props.language.title_invoices_empty}</div>
                            </div>
                        </div> : null}
                    <div className="table-data-container flex-grow-1 w-100">
                        <div className="position-relative d-flex flex-column h-100" style={{ minWidth: 1200 }}>
                            <div className="table-header position-absolute top-0 row-start align-items-center zindex-1 py-3 w-100" style={{ paddingRight: 20 }}>
                                <div className="text-center" style={{ width: 80, paddingLeft: 10, paddingRight: 10 }}>
                                    <Whisper
                                        delay={500}
                                        placement="top"
                                        trigger="hover"
                                        speaker={<Tooltip>{props.language.tooltip_shared_filter}</Tooltip>}>
                                        <i className="fas fa-filter txt-10 txt-light-blue txt-hover-blue c-pointer"
                                            onClick={() => {

                                                logEvent(analytics, "click_bills_filters", {
                                                    id_user: props.user.id_user,
                                                    user_name: `${props.user.name} ${props.user.lastname}`
                                                });

                                                amplitude.getInstance().logEvent('Billing Filtered');

                                                setShowFilters(!showFilters);

                                            }} />
                                    </Whisper>
                                </div>
                                <div className="text-left" style={{ width: 170, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_invoices_date}</div>
                                </div>
                                <div className="text-left" style={{ width: 130, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_invoices_code}</div>
                                </div>
                                <div className="text-left" style={{ width: 220, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_invoices_location}</div>
                                </div>
                                <div className="text-left" style={{ width: 180, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_invoices_description}</div>
                                </div>
                                <div className="text-left" style={{ width: 200, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_invoices_total_value}</div>
                                </div>
                                <div className="text-center flex-grow-1 " style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="txt-12 txt-dark-blue txt-bold">{props.language.header_table_state}</div>
                                </div>
                            </div>
                            <div className={`table-filter position-absolute row-start align-items-end zindex-1 pb-3 w-100 ${!showFilters ? "d-none" : null}`} style={{ paddingRight: 20 }}>
                                <div className="row-center align-items-center" style={{ width: 80 }}>
                                    <Checkbox
                                        checked={invoicesChecked.length === props.activeData.filter(i => [0,3].includes(i.state)).length}
                                        onChange={(value, checked) => {
                                            if (checked) {
                                                setInvoicesChecked([...props.activeData.filter(i => [0, 3].includes(i.state))])
                                            } else {
                                                setInvoicesChecked([])
                                            }
                                    }} />
                                </div>
                                <div className="text-left" style={{ width: 170, paddingLeft: 10, paddingRight: 10 }}>
                                    <DateRangePicker
                                        className="w-100"
                                        showOneCalendar
                                        format={props.user.hide_invoices? "MM-DD-YYYY" : "DD-MM-YYYY"}
                                        value={[filterData.from, filterData.to]}
                                        locale={props.dateLocale}
                                        disabledDate={afterToday()}
                                        onChange={value => setFilterData({ ...filterData, from: value[0], to: value[1] })}
                                        onClean={() => setFilterData({ ...filterData, from: initFilterData.from, to: initFilterData.to })}
                                    />
                                </div>
                                <div className="text-left" style={{ width: 130, paddingLeft: 10, paddingRight: 10 }}>
                                    <InputGroup
                                        inside>
                                        <InputGroup.Addon>
                                            <i className="fas fa-search txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input
                                            placeholder={props.language.placeholder_invoices_filter_table_code}
                                            onPressEnter={e => setFilterData({ ...filterData, code: e.target.value })} />
                                    </InputGroup>
                                </div>
                                <div className="text-left" style={{ width: 220, paddingLeft: 10, paddingRight: 10 }}>
                                    <CheckPicker
                                        className="w-100"
                                        searchable={false}
                                        placeholder={props.language.placeholder_invoices_filter_table_all_locations}
                                        valueKey={"id_project"}
                                        labelKey={"name"}
                                        value={filterData.locations}
                                        onChange={value => setFilterData({ ...filterData, locations: value })}
                                        data={locations}
                                    />
                                </div>
                                <div className="text-left" style={{ width: 180, paddingLeft: 10, paddingRight: 10 }}>
                                    <InputGroup
                                        inside>
                                        <InputGroup.Addon>
                                            <i className="fas fa-search txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input
                                            placeholder={props.language.placeholder_invoices_filter_table_description}
                                            onPressEnter={e => setFilterData({ ...filterData, description: e.target.value })} />
                                    </InputGroup>
                                </div>
                                <div className="text-left" style={{ width: 200, paddingLeft: 10, paddingRight: 10 }}>
                                    <InputGroup
                                        inside>
                                        <InputGroup.Addon>
                                            <i className="fas fa-search txt-10 txt-light-blue"></i>
                                        </InputGroup.Addon>
                                        <Input
                                            type="number"
                                            placeholder={props.language.placeholder_invoices_filter_table_value}
                                            onPressEnter={e => setFilterData({ ...filterData, value: e.target.value })} />
                                    </InputGroup>
                                </div>
                                <div className="text-left flex-grow-1 " style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <SelectPicker
                                        className="w-100"
                                        searchable={false}
                                        placeholder={props.language.placeholder_invoices_filter_table_all_states}
                                        value={filterData.state}
                                        data={states}
                                        onChange={value => setFilterData({ ...filterData, state: value })}
                                    />
                                </div>
                            </div>
                            <div className={`position-relative flex-grow-1 table-vertical-scroll border-top table-content-${!showFilters ? "complete" : "filtered"}`}>
                                <Table
                                    autoHeight
                                    wordWrap
                                    data={props.activeData}
                                    hover={false}
                                    showHeader={false}>
                                    <Column width={80} verticalAlign="middle">
                                        <HeaderCell>alert</HeaderCell>
                                        <Cell dataKey="alert">
                                            {rowData => (
                                                rowData.state === 3 || rowData.state === 0 ?
                                                    <div className="row-center align-items-center w-100">
                                                        <Checkbox
                                                            key={rowData.id_bill}
                                                            value={rowData}
                                                            checked={invoicesChecked.some(invoice => invoice.id_bill === rowData.id_bill)}
                                                            onChange={(value, checked) => {
                                                                if (checked) {
                                                                    setInvoicesChecked([...invoicesChecked, value])
                                                                } else {
                                                                    setInvoicesChecked([...invoicesChecked.filter(invoice => invoice.id_bill !== value.id_bill)])
                                                                }
                                                            }} />
                                                        {rowData.state === 3 ?
                                                            <Whisper
                                                                placement="top"
                                                                trigger="hover"
                                                                speaker={<Tooltip>{props.language.tooltip_overdue_invoice}</Tooltip>}>
                                                                <i className="fas fa-exclamation-triangle txt-12 txt-yellow ml-2"></i>
                                                            </Whisper>
                                                            : null
                                                        }
                                                    </div> : null)}
                                        </Cell>
                                    </Column>
                                    <Column width={170} verticalAlign="middle">
                                        <HeaderCell>date</HeaderCell>
                                        <Cell dataKey="date">
                                            {rowData => (<div className={`txt-10 txt-${rowData.state === 3 ? 'red' : 'dark-blue'} txt-regular`}>{
                                                format(new Date (rowData.date), props.user.hide_invoices? "MMM dd yyyy" : "dd MMM yyyy", { locale: locale })
                                            }
                                            </div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={130} verticalAlign="middle">
                                        <HeaderCell>payment_reference</HeaderCell>
                                        <Cell dataKey="payment_reference">
                                            {rowData => (
                                                <div className={`txt-10 txt-${rowData.state === 3 ? 'red' : 'dark-blue'} txt-semibold`}>{rowData.prefix_reference}{rowData.payment_reference}</div>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={220} verticalAlign="middle">
                                        <HeaderCell>location</HeaderCell>
                                        <Cell dataKey="location">
                                            {rowData => (
                                                <div className={`txt-10 txt-${rowData.state === 3 ? 'red' : 'dark-blue'} txt-semibold`}>{rowData.project}</div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={180} verticalAlign="middle">
                                        <HeaderCell>description</HeaderCell>
                                        <Cell dataKey="description">
                                            {rowData => (
                                                <div className={`txt-10 txt-${rowData.state === 3 ? 'red' : 'dark-blue'} txt-semibold`}>{rowData.description}</div>)}
                                        </Cell>
                                    </Column>
                                    <Column width={200} verticalAlign="middle">
                                        <HeaderCell>payment_value</HeaderCell>
                                        <Cell dataKey="payment_value" >
                                            {rowData => (<CurrencyFormat
                                                className={`txt-10 txt-${rowData.state === 3 ? 'red' : 'dark-blue'} txt-regular text-wrap`}
                                                value={rowData.payment_value}
                                                prefix={'$'}
                                                thousandSeparator={props.language.thousandSeparator}
                                                decimalSeparator={props.language.decimalSeparator}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                displayType={'text'}
                                            />)}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={1} verticalAlign="middle">
                                        <HeaderCell>state</HeaderCell>
                                        <ImageCell
                                            dataKey="state"
                                            user={props.user}
                                            banks={props.banks}
                                            invoicesChecked={invoicesChecked}
                                            setInvoicesChecked={setInvoicesChecked}
                                            language={props.language}
                                            setInvoicesToPay={props.setInvoicesToPay}
                                            downloadFile={props.downloadInvoiceAsync}
                                            loadPayDataAsync={props.loadPayDataAsync}
                                        />
                                    </Column>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className={`border-top ${!props.activeData.length ? "d-none" : null}`}>
                        <Table.Pagination
                            lengthMenu={lengthMenu}
                            activePage={activePage}
                            displayLength={pageLength}
                            total={totalPages}
                            renderLengthMenu={picker => picker}
                            onChangeLength={value => {
                                const maxActivePage = Math.ceil(props.availableData.length / value);
                                const page = activePage <= maxActivePage ? activePage : maxActivePage;

                                setPageLength(value);
                                setActivePage(page);
                            }}
                            onChangePage={value => { setActivePage(value) }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>)
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    banks: state.invoices.banks,
    totalData: state.invoices.totalData,
    availableData: state.invoices.availableData,
    activeData: state.invoices.activeData,
    loading: state.invoices.loading,
    language: state.shared.language,
    dateLocale: state.home.dateLocale,
    locations: state.home.locations,

})

const mapDispatchToProps = dispatch => ({
    loadInvoicesAsync: () => dispatch(invoicesActions.loadInvoicesAsync()),
    loadPayDataAsync: (btn, payload) => dispatch(invoicesActions.loadPayDataAsync(btn, payload)),
    downloadInvoiceAsync: payload => dispatch(invoicesActions.downloadInvoiceAsync(payload)),
    setAvailableData: payload => dispatch(invoicesActions.setAvailableData(payload)),
    setActiveData: payload => dispatch(invoicesActions.setActiveData(payload)),
    setInvoicesToPay: payload => dispatch(invoicesActions.setInvoicesToPay(payload)),
    loadLocationsAsync: () => dispatch(homeActions.loadLocationsAsync()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)

