import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';

import * as loginActions from '../../store/actions/Login';
import * as sharedActions from '../../store/actions/Shared';

import { Loader, Input, InputGroup, Alert } from 'rsuite';

import { Transition } from 'react-transition-group';

import logo from '../../imgs/logo-erco-gray.svg'
import logoErco from '../../imgs/logo-erco-white.svg'

import { getAnalytics, logEvent } from "firebase/analytics";

import amplitude from 'amplitude-js';

const analytics = getAnalytics();

const showAlert = message => {
    Alert.error(message);
}

const Login = (props) => {

    const step = props.step;
    const loading = props.loading;
    const resending = props.resending;
    const counter = props.counter;

    const [inputValue, setInputValue] = useState("");
    const [data, setData] = useState("");

    const loadInitInfoAsync = props.loadInitInfoAsync;
    const loadLanguageAsync = props.loadLanguageAsync;

    const setInput = (() => {
        if (step === 1) {

            logEvent(analytics, "click_login_email", {
                email: String(inputValue).toLowerCase()
            });

            props.generateCodeAsync(inputValue, showAlert);

            setData(inputValue);
            setInputValue("");

        } else {

            logEvent(analytics, "click_login_code");

            props.loadInfoUserAsync(inputValue, showAlert);
            setInputValue("");

        }
    })

    useEffect(() => loadLanguageAsync(), [loadLanguageAsync]);

    useEffect(() => loadInitInfoAsync(), [loadInitInfoAsync])

    useEffect(() => logEvent(analytics, "login"), []);

    useEffect(() => {
        if (step === 1) {
            amplitude.getInstance().logEvent('Login Screen Viewed');
        }
    }, [step]);

    return (
        <div className="row-start align-items-start vh-100 w-100 bg-white">
            <Transition
                in={!step}
                timeout={100}>
                {state => (
                    <div className={`trans-fade trans-fade-${state} position-absolute w-100 h-100 top-0 left-0 zindex-1`}>
                        <div className="column-center align-items-center w-100 h-100">
                            <Loader inverse center size="md" content={props.language.loading_message} />
                        </div>
                    </div>
                )}
            </Transition>
            {step === 3 ?
                <Redirect to="/home" /> :
                step === 1 || step === 2 ?
                    <div id="login" className="column-start align-items-center">
                        <div className="p-3">
                            <img src={logo} style={{ width: 120 }} alt="logotype" />
                        </div>
                        <div className="position-absolute column-center align-items-center top-0 left-0 h-100 w-100">
                            <div className="row-center txt-dark-blue txt-30 txt-bold text-wrap px-md-0 px-4 py-2">{props.language.title_login}</div>
                            <div className="row-center align-items-center w-100 mt-4 px-md-0 px-4">
                                <div className="column-center align-items-center col-md-6 col-12 p-0">
                                    <div className="txt-dark-blue txt-14 txt-semibold text-wrap my-3">
                                        {step === 1 ?
                                            props.language.label_login_input_data :
                                            props.language.label_login_input_code + " " + data}
                                    </div>
                                    <InputGroup className="my-4">
                                        <Input
                                            value={inputValue}
                                            style={{ fontSize: 16 }}
                                            className="txt-semibold p-3"
                                            placeholder={step === 1 ?
                                                props.language.placeholder_login_input_data :
                                                props.language.placeholder_login_input_code}
                                            onChange={value => setInputValue(value)}
                                            onPressEnter={() => setInput()}
                                        />
                                        <InputGroup.Addon className="bg-white">
                                            {loading ?
                                                <Loader /> :
                                                <i className="fa fa-chevron-circle-right txt-20 txt-green c-pointer"
                                                    onClick={() => setInput()} />}
                                        </InputGroup.Addon>
                                    </InputGroup>
                                    {step === 1 ?
                                        null :
                                        resending ?
                                            <div className="row-center txt-14 txt-bold txt-dark-blue">
                                                {props.language.login_resending_code.replace('#seg', counter)}
                                            </div> :
                                            <div className="row-center"
                                                onClick={() => {
                                                    logEvent(analytics, "click_login_sendcode");
                                                    props.generateCodeAsync(data, showAlert);
                                                }}>
                                                <i className="fa fa-paper-plane txt-14 txt-dark-blue c-pointer mr-3" />
                                                <div className="txt-14 txt-bold txt-dark-blue c-pointer">{props.language.login_resend_code}</div>
                                            </div>}
                                </div>
                            </div>
                        </div>
                    </div> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    step: state.login.step,
    loading: state.login.loading,
    resending: state.login.resending,
    counter: state.login.counter,
    language: state.shared.language
})

const mapDispatchToProps = dispatch => ({
    setStep: payload => dispatch(loginActions.setStep(payload)),
    setLoading: payload => dispatch(loginActions.setLoading(payload)),
    setResending: payload => dispatch(loginActions.setResending(payload)),
    loadInitInfoAsync: () => dispatch(loginActions.loadInitInfoAsync()),
    loadLanguageAsync: () => dispatch(sharedActions.loadLanguageAsync()),
    generateCodeAsync: (payload, showAlert) => dispatch(loginActions.generateCodeAsync(payload, showAlert)),
    loadInfoUserAsync: (payload, showAlert) => dispatch(loginActions.loadInfoUserAsync(payload, showAlert))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)


