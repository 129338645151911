import React from 'react';
import { connect } from 'react-redux';

import { Route, Redirect } from 'react-router-dom';

import Score from './Score';
import Menu from '../menu/Menu';
import Home from '../home/Home';
import Locations from '../locations/Locations';
import Notifications from '../notifications/Notifications';
import Invoices from '../invoices/Invoices';
import Reports from '../reports/Reports';
import Rules from '../rules/Rules';
import UserAccess from '../users/UserAccess';
import NEUPlus from '../neuplus/NEUPlus';
import Tags from '../tags/Tags'

import { Transition } from 'react-transition-group';

const Main = (props) => {

    return (
        <section className="row-start align-items-start vh-100 w-100 bg-clear-gray">
            <Transition
                in={props.showBlackScreen}
                timeout={100}>
                {state => (
                    <div className={`position-fixed left-0 top-0 w-100 vh-100 zindex-3 trans-fade trans-fade-${state}`}>
                        <div className="black-screen w-100 h-100"></div>
                    </div>)}
            </Transition>
            <Menu />
            <Score />
            {props.step === 3 ?
                <section id="main" className="position-relative row-start align-items-start flex-grow-1 h-100">
                    <Route path='/home' exact component={Home} />
                    {/* <Route path='/locations' exact component={Locations} />
                    <Route path='/invoices' exact component={Invoices} />
                    <Route path='/reports' exact component={Reports} />
                    <Route path='/neuplus' exact component={NEUPlus} />
                    <Route path='/tags' exact component={Tags} /> */}
                    <Route path='/notifications' exact component={Notifications} />
                    <Route path='/rules' exact component={Rules} />
                    <Route path='/users' exact component={UserAccess} />
                </section> : <Redirect to='/login' />}
        </section >
    )
}

const mapStateToProps = (state) => ({
    step: state.login.step,
    showBlackScreen: state.shared.showBlackScreen
})

export default connect(mapStateToProps)(Main)
