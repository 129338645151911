export const SET_MENU = 'SET_MENU';
export const SET_BLACK_SCREEN = 'SET_BLACK_SCREEN';
export const SET_POPUP_SHARE = 'SET_POPUP_SHARE';
export const SET_POPUP_SUPPORT = 'SET_POPUP_SUPPORT';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_CITIES = 'SET_CITIES';
export const SET_GRAPH_SCORE = 'SET_GRAPH_SCORE';
export const SET_GRAPH_SCORE_VALUE = 'SET_GRAPH_SCORE_VALUE';
export const SET_GRAPH_SCORE_REASON = 'SET_GRAPH_SCORE_REASON';
export const LOGOUT = 'LOGOUT';
export const SET_SHOW_NPS_SURVEY = 'SET_SHOW_NPS_SURVEY';
