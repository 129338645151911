import * as creators from '../creators/Invoices';

const initialState = {
    totalData: [],
    availableData: [],
    activeData: [],
    banks: [],
    invoicesToPay: [],
    permalink: null,
    loading: true
}

export const invoicesReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case creators.SET_INVOICES_DATA:
            return { ...state, totalData: payload };
        case creators.SET_INVOICES_AVAILABLE_DATA:
            return { ...state, availableData: payload };
        case creators.SET_INVOICES_ACTIVE_DATA:
            return { ...state, activeData: payload };
        case creators.SET_INVOICES_TO_PAY:
            return { ...state, invoicesToPay: payload };
        case creators.SET_INVOICES_PERMALINK:
            return { ...state, permalink: payload.permalink };
        case creators.SET_INVOICES_BANKS:
            return { ...state, banks: payload.data };
        case creators.SET_INVOICES_LOADING:
            return { ...state, loading: payload };
        default:
            return state;
    }

}