import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import * as gameActions from '../../../store/actions/Game';

import { Modal, Loader } from 'rsuite';

import 'react-circular-progressbar/dist/styles.css';
import { Transition } from 'react-transition-group';

import neugetsLogo from '../../../imgs/neugets-logo.svg';

import MedalBubble from '../medals/MedalBubble';
import Ranking from '../ranking/Ranking';
import GameCard from './GameCard';
import Medal from '../medals/Medal';
import NewMedal from '../shared/Achievement';

import amplitude from 'amplitude-js';

const Dashboard = (props) => {

    let positions = props.positions;  

    const loadProfileAsync = props.loadProfileAsync;
    const loadGamesAsync = props.loadGamesAsync;
    const loadNewAchievementsAsync = props.loadNewAchievementsAsync;
    const loadMedalsAsync = props.loadMedalsAsync;
    const loadRankingAsync = props.loadRankingAsync;

    const [show, setShow] = useState(false);
    
    useEffect(() => {
        if(show){
            loadGamesAsync();
            loadNewAchievementsAsync();
        }
    }, [loadGamesAsync, loadNewAchievementsAsync, show]);

    useEffect(() => loadProfileAsync(), [loadProfileAsync]);

    useEffect(() => {
        if (!positions.length)
            loadRankingAsync();
    }, [loadRankingAsync, positions]);

    useEffect(() => {if(show){loadMedalsAsync()}}, [show]);

    const IconName = (name) =>{
        let icon = ""
        const iconSplit = name.split("fa").pop().split(/(?=[A-Z])/);
        if(iconSplit.length === 1){
            icon = iconSplit[0].toLowerCase();
        }else{
            icon = `${iconSplit[0].toLowerCase()}-${iconSplit[1].toLowerCase()}`;
        }
        return icon;
    }    

    return (
        <Fragment>
            <NewMedal/>
            <Modal
                size="games"
                overflow={false}
                show={show}
                onShow={()=> amplitude.getInstance().logEvent('Games Opened')}
                onHide={() => setShow(false)}>
                <Modal.Body> 
                    <div className="modal-close c-pointer row-center align-items-center position-absolute top-0 right-0 m-3 bg-dark-blue rounded-circle zindex-2" onClick={() => setShow(false)}>
                        <i className="fas fa-times txt-white txt-12" />
                    </div>                                                       
                    <div className="position-relative w-100 h-100 py-1 my-0 overflow-hidden">    
                        <Transition
                            in={!(props.medals.length && props.positions.length && props.profile && props.games.length && props.challenges.length)}
                            timeout={100}>
                            {state => (
                                <div className={`trans-fade trans-fade-${state} position-absolute bg-black w-100 h-100 top-0 left-0 zindex-4 rounded`} >
                                    <div className="column-center align-items-center w-100 h-100">
                                        <Loader inverse center size="md" content={props.language.loading_message}/>
                                    </div>
                                </div>
                            )}
                        </Transition>
                        <div className="row-between align-items-start flex-wrap col border-bottom-dark-blue py-3">
                            {/*<div className="txt-16 txt-semibold text-center txt-white col">{props.language.title_game_dashboard_ranking}</div>*/}
                            <div className="txt-16 txt-semibold text-center txt-white col">{props.language.title_game_dashboard_medals}</div>
                            <div className="txt-16 txt-semibold text-center txt-white col">{props.language.title_game_dashboard_challenges}</div>
                        </div>
                        <div className="row-between align-items-start flex-wrap col">
                            {/*<div className='row-center col-12 col-md-6 col-xl-4'>
                                <Transition
                                    in={props.loadingInitial}
                                    timeout={100}>
                                    {state => (
                                        <div className={`trans-fade trans-fade-${state} position-absolute bg-black w-100 h-100 top-0 left-0 zindex-1`} >
                                            <div className="column-center align-items-center w-100 h-100">
                                                <Loader inverse center size="md" content={props.language.loading_message}/>
                                            </div>
                                        </div>
                                    )}
                                </Transition>
                                <div className="games-section-container position-relative align-items-center pt-3">
                                    <Ranking/>
                                </div>
                            </div>*/}
                            <div className='row-center col-12 col-md-6'>
                                <div className="games-section-container row-between align-items-center flex-wrap pt-3 position-relative">
                                    {props.medals.length && props.positions.length ?
                                        props.medals.map((value, i) =>
                                            <div key={i} className="col-4 p-1">
                                                <MedalBubble 
                                                    points={value.points} 
                                                    name={String(value.name)} 
                                                    description={value.description} 
                                                    icon={IconName(value.icon)} 
                                                    color={value.color} 
                                                    iconColor={value.icon_color} 
                                                    won={value.won}/> 
                                            </div>) :                     
                                            <Transition
                                                in={true}
                                                timeout={100}>
                                                {state => (
                                                    <div className={`trans-fade trans-fade-${state} position-absolute rounded bg-black w-100 h-100 top-0 left-0 zindex-2`} >
                                                        <div className="column-center align-items-center w-100 h-100">
                                                            <Loader inverse center size="md" content={props.language.loading_message}/>
                                                        </div>
                                                    </div>
                                                )}
                                            </Transition>
                                    }                                   
                                </div>    
                            </div>
                            <div className='row-center col-12 col-md-6'>
                                <div className='games-section-container pt-3'>
                                    <div className="row-start align-items-center mb-5">
                                        <div id="games-profile-image" className={`row-center align-items-center border-0 rounded-circle ${props.user.image !== null ? "bg-dark-blue" : null}`}>
                                            {props.user.image !== null ?
                                                <i className="fas fa-user txt-30 txt-black"></i> :
                                                <img className="home-location-img position-absolute w-100 h-100" src={`${process.env.REACT_APP_IMAGES_URL}/profile-photos/${props.user.id_user}/150x150/${props.user.image}`} alt="user-profile" />
                                            }
                                        </div>
                                        {props.profile ?
                                            <div className="ml-3 column-start align-items-start">
                                                <div className="txt-white txt-bold txt-14">{props.profile.level}</div>
                                                <div className="row-start align-items-center my-2">
                                                    <div>
                                                        <div id="neugets-logo-small" className="row-center align-items-center rounded-circle p-1">
                                                            <img src={neugetsLogo} alt="neugets-logo" className="m-1 w-100 h-100"></img>
                                                        </div>
                                                    </div>
                                                    <div className="ml-2 txt-10 txt-white txt-semibold text-center c-pointer">{props.user.neugets} {props.language.label_game_neugets}</div>
                                                </div>
                                                <div className="row-start align-items-center">
                                                    {props.profile.medals.map((value, i) =>
                                                        <div key={i} className='position-relative'>
                                                            <div className={`position-absolute game-profile-medals-${i + 1}`}>
                                                                <Medal
                                                                    scale={0.35}
                                                                    iconSize={8}
                                                                    icon={IconName(value.icon)}
                                                                    color={value.color}
                                                                    iconColor={value.icon_color}
                                                                    won={true} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div> : null
                                        }
                                    </div>
                                    {props.games.map( (game, i) => <GameCard key={i} game={game} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <i className={`fas fa-gamepad txt-16 txt-${show ? "green" : "light-blue"} d-none d-xl-block txt-hover-green mx-2 c-pointer`}
                onClick={() => {
                    setShow(true);
                    amplitude.getInstance().logEvent('Games Screen Viewed');
                }
            }>                    
            </i>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.login.user,
    language: state.shared.language,
    profile: state.game.profile,
    medals: state.game.medals,
    games: state.game.games,
    loadingInitial: state.game.loadingInitial,
    positions: state.game.positions,  
    challenges: state.game.gamesPcChallenges,
})

const mapDispatchToProps = dispatch => ({
    loadProfileAsync: () => dispatch(gameActions.loadProfileAsync()),
    loadRankingAsync: () => dispatch(gameActions.loadRankingAsync()),
    loadGamesAsync: () => dispatch(gameActions.loadGamesAsync()),
    loadNewAchievementsAsync: () => dispatch(gameActions.loadNewAchievementsAsync()),
    loadMedalsAsync : () => dispatch(gameActions.loadMedalsAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)



